import React from 'react'
import './Login.css'
import { usePartnerStateContext } from '../store/PartnerContextProvider'
const BeforeLoginContainer = (props: any) => {
    const { PartnerConfigState } = usePartnerStateContext()
    return (
        <div className="login_bg"

        >
            <div className="form_container">
                {props.children}
            </div>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                {PartnerConfigState?.PartnerVersion && <span style={{ marginTop: 'auto' }}>Version: {PartnerConfigState?.PartnerVersion}</span>}
            </div>
        </div>
    )
}

export default BeforeLoginContainer