
const compare = (a: any, b: any) => {
    if (a.label < b.label) {
        return -1;
    }
    if (a.label > b.label) {
        return 1;
    }
    return 0;
}


export const KafkaFieldArrayNew = [

    {
        name: 'Zscaler ZIA (Web)',
        placeholder: 'Enter comma separated index names',
        label: 'Zscaler ZIA (Web)',
        placeholder_topic: 'Enter topic name',
        placeholder_partition: 'Enter partition',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'Palo Alto Firewall & VPN',
        placeholder: 'Enter comma separated index names',
        label: 'Palo Alto Firewall & VPN',
        placeholder_topic: 'Enter topic name',
        placeholder_partition: 'Enter partition',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'Cisco ASA',
        placeholder: 'Enter comma separated index names',
        label: 'Cisco ASA',
        placeholder_topic: 'Enter topic name',
        placeholder_partition: 'Enter partition',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'Zscaler ZIA (DNS)',
        placeholder: 'Enter comma separated index names',
        label: 'Zscaler ZIA (DNS)',
        placeholder_topic: 'Enter topic name',
        placeholder_partition: 'Enter partition',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
    {
        name: 'Zscaler ZPA',
        placeholder: 'Enter comma separated index names',
        label: 'Zscaler ZPA',
        placeholder_topic: 'Enter topic name',
        placeholder_partition: 'Enter partition',
        placeholder_timezone: 'Enter logs timezone value, for example: EST',
        default_timezone: "UTC_+00:00",
        disabledTimezone: true
    },
].sort(compare) as Array<any>;
