import React, { useCallback, useEffect, useRef, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts/highstock";
import { RiskLevelData } from "../../../types/response-types";
import "./RiskTrendIncidentWidget.scss";
import { useHistory } from "react-router-dom";
import { getRiskLevelFromColor } from "../../../utils/risk-level";
import RiskChart from "./RiskChart";
import ChartTrend from "../chart-trend/ChartTrend";
import { convertToCommaValue, convertToPascal, getRoundedUnit } from "../../../utils/util-methods";
import { ExcludeRiskRuleList, flattenSelectedItems } from "./RiskTrendHelper";
import { RuleType } from "../../../pages/playbooks/constants/Constants";

type Props = {
    irl: any | undefined | null;
    widgetId: string | undefined | null;
    groupData: any;
    selectedItems: any,
    playbookData
};

const RiskColor = new Map([
    ["critical", "#fa1262"],
    ["high", "#fd7122"],
    ["medium", "#fcbf2e"],
    // ["low", "#cccccc"],
]);

const getPostureLinkFromEntityType = (eType: string = '') => {
    const entity = eType.trim().toLowerCase();

    switch (entity) {
        case 'identity': return '/posture/identities';
        case 'asset': return '/posture/assets';
        case 'directory': return '/posture/directory';
        default: return '/posture/assets';
    }
}

type DataObject = {
    [key: string]: any[];
};

const findIDByName = (dataObject: DataObject, name: string): string => {
    for (const key in dataObject) {
        const array = dataObject[key];
        if (Array.isArray(array)) {
            for (const obj of array) {
                const foundItem = obj.items.find((item: any) => item.name === name);
                if (foundItem) {
                    return foundItem._id;
                }
            }
        }
    }
    return name; // Return the original name if no match is found
};

const getPosturePBFromEntityType = (eType: string = '') => {
    const entity = eType.trim().toLowerCase();

    switch (entity) {
        case 'identity': return 'identity_playbooks';
        case 'asset': return 'asset_playbooks';
        case 'directory': return 'directory_playbooks';
        default: return 'asset_playbooks';
    }
}

const RiskTrendIncidentWidget = ({ irl, widgetId, groupData, selectedItems, playbookData }: Props) => {

    const [riskDataList, setRiskDataList] = useState<{ chartData: any; color: string, riskName: string }[]>([]);
    const history = useHistory();
    const isPosturePlaybookEnabled = false;// localStorage.getItem("POSTURE_PLAYBOOK_FEATURE") ? JSON.parse(localStorage.getItem("POSTURE_PLAYBOOK_FEATURE")) : false;
    const [calculatedChartData, setCalculatedChartData] = useState({ count: 0, created_issues: 0, closed_issues: 0 });
    const handleOnBarClick = useCallback((title: string = '', rule_name?: string, entityType: string = '', is_issue_type: boolean = false) => {
        let url;
        if (rule_name === 'Shadow External Access') {
            url = `/issues?q=status%3AOpen%2Brule_name%3AShadow%20External%20Access%2Brisk:${title}&sort_by=issue_flows_count&order_by=desc`;
        } else {
            url = isPosturePlaybookEnabled || !is_issue_type ? getPostureLinkFromEntityType(entityType).concat(`?q=${getPosturePBFromEntityType(entityType)}:${findIDByName(groupData, rule_name)}%2Brisk:${title}&order_by=desc&sort_by=flow_count`) : getPostureLinkFromEntityType(entityType).concat(`?q=rule_name:${rule_name}%2Brisk:${title}&order_by=desc&sort_by=flow_count`);
            /*  url = getPostureLinkFromEntityType(entityType).concat(`?q=${getPosturePBFromEntityType(entityType)}:${findIDByName(groupData, rule_name)}%2Brisk:${title}&order_by=desc&sort_by=flow_count`) */
        }
        history.push(url, { breadcrumbId: 'RiskLevelPosture' });
    }, []);

    useEffect(() => {
        const chartDataList = [];
        for (let entry of RiskColor) {
            // the same as of recipeMap.entries()
            const [key, value] = entry;
            if (irl?.[key] && irl?.[key]?.rule_issues && irl?.[key]?.rule_issues?.length > 0) {
                chartDataList.push({ chartData: irl?.[key]?.rule_issues, color: value, riskName: key, count: irl?.[key]?.count, created: irl?.[key]?.created_issues, closed: irl?.[key]?.closed_issues });
            }
            else {
                chartDataList.push({ chartData: [], color: value, riskName: key, count: 0, created: 0, closed: 0 });
            }
        }
        setRiskDataList(chartDataList);
    }, [irl]);

    const renderChartTrend = (last_7days_created = 0, last_7days_closed = 0, trendClickHandler: Function) => {
        const markerValueActual = last_7days_created - last_7days_closed;
        const [iconClass, textClass, markerValue] = markerValueActual <= 0 ? ['down', 'green-text', Math.abs(markerValueActual)] : ['up', 'red-text', Math.abs(markerValueActual)];

        return <ChartTrend {...{ iconClass, textClass, markerValue: markerValue, last_7days_created: last_7days_created, trendClickHandler, trendClass: 'no-cursor' }} />
    }


    const calculateCounts = (data: any, groupData?, selectedItems?, playbookData?) => {
        const flattenedItems = flattenSelectedItems(selectedItems)
        const clonedData = JSON.parse(JSON.stringify(data))
        const res: Array<any> = [];
        let mainOb: Array<any> = []

        flattenedItems.filter(k => k.is_issue_type).forEach(element => {
            const tempGroupItems = playbookData[element._id]
            if (tempGroupItems) {
                const entity = {
                    entity_type: convertToPascal(tempGroupItems.entity_type),
                    count: 0,
                    rule_name: element._id,
                    is_issue_type: true,
                    created: 0,
                    closed: 0
                }

                tempGroupItems.playbooks.forEach((k) => {
                    const ind = clonedData.findIndex((val) => val.rule_name === k._id)
                    if (ind > -1 && clonedData[ind]) {
                        entity.count += clonedData[ind].issue_count || 0
                        entity.closed += clonedData[ind].closed_issues || 0
                        entity.created += clonedData[ind].created_issues || 0
                        clonedData.splice(ind, 1)
                    }
                })
                res.push(entity)
            }
        });

        mainOb = [...clonedData.map(i => ({
            ...i, created: i.created_issues || 0,
            closed: i.closed_issues || 0,
            count: i.issue_count || 0
        })).filter((i) => ((flattenedItems.filter((k) => k._id === i.rule_name).length === 1 && i.issue_count > 0) || Object.values(ExcludeRiskRuleList).includes(i.rule_type))), ...res.filter((i) => i.count > 0)]

        return mainOb.reduce((prev, v: any) => {
            return {
                count: v.count + (prev.count || 0),
                created: v.created + (prev.created || 0),
                closed: v.closed + (prev.closed || 0),
            };
        }, { count: 0, created: 0, closed: 0 })
    }


    useEffect(() => {
        const chartDataObject = { count: 0, created_issues: 0, closed_issues: 0 };
        riskDataList?.forEach((chartProps) => {
            const counts = calculateCounts(chartProps.chartData, groupData, selectedItems, playbookData);
            chartDataObject.closed_issues += counts.closed
            chartDataObject.count += counts.count
            chartDataObject.created_issues += counts.created
        });
        setCalculatedChartData(chartDataObject);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [riskDataList, selectedItems, groupData])

    return (
        <>
            <div className="risk-wise-incident-container">
                {riskDataList?.length > 0 ? (
                    <div className="risk-wise-incident-content">
                        <div className="rwic-total-section border-right">
                            <div className="total-incident-marker">
                                <span className='risk-incident-title'>Total Issues</span>
                                <div className="marker-container">
                                    <span className="total-risk-count">{getFormatedNumeric(calculatedChartData?.count || 0, 6)}</span>
                                    <span className="total-risk-marker">{renderChartTrend(calculatedChartData?.created_issues, calculatedChartData?.closed_issues, () => { })}</span>
                                </div>
                            </div>
                        </div>
                        {riskDataList?.map((chartProps) => {
                            const counts = calculateCounts(chartProps.chartData, groupData, selectedItems, playbookData)
                            return (
                                <div key={chartProps.riskName} className={`rwic-all-risk-section border-right typeof-${chartProps.riskName}`}>
                                    <div className="risk-incident-marker">
                                        <span className={`risk-incident-title dot-${chartProps.riskName}`}>
                                            {chartProps.riskName}
                                            {isPosturePlaybookEnabled && ' (Top 6 Playbooks)'}
                                        </span>
                                        <div className="marker-container">
                                            <span className="total-risk-count">{getFormatedNumeric(counts?.count, 6)}</span>
                                            <span className="total-risk-marker">
                                                {renderChartTrend(counts?.created, counts?.closed, () => { })}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="risk-incident-chart">
                                        <RiskChart {...chartProps} groupData={groupData} selectedItems={selectedItems}
                                            playbookData={playbookData}
                                            onBarClick={handleOnBarClick.bind(null, chartProps.riskName)} />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div className="risk-wise-incident-content">
                        <div className="rwic-total-section border-right">
                            <div className="total-incident-marker">
                                <span className='risk-incident-title'>Total Issues</span>
                                <div className="marker-container">
                                    <span className="total-risk-count">{getFormatedNumeric(irl?.total?.count || 0, 6)}</span>
                                    <span className="total-risk-marker">{renderChartTrend(irl?.total?.created_issues, irl?.total?.closed_issues, () => { })}</span>
                                </div>
                            </div>
                        </div>
                        {Array.from(RiskColor.keys()).map((key) => {

                            return (
                                <div key={key} className={`rwic-all-risk-section border-right typeof-${key}`}>
                                    <div className="risk-incident-marker">
                                        <span className={`risk-incident-title dot-${key}`}>{key}</span>
                                        <div className="marker-container">
                                            <span className="total-risk-count">{getFormatedNumeric(0, 6)}</span>
                                            <span className="total-risk-marker">
                                                {renderChartTrend(0, 0, () => { })}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="risk-incident-chart">

                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </>
    );
};
function getFormatedNumeric(num: number, len: number) {
    try {
        let val = "0";
        if (num && num !== undefined) {
            if (num?.toString()?.length || 0 < len) {
                val = convertToCommaValue(num || 0)
            }
            else {
                val = getRoundedUnit(num)
            }
        }
        return val;
    }
    catch (err) {
        console.log(err);
        return num.toString();
    }
}
export default RiskTrendIncidentWidget;