import React from "react";
import { useState } from "react";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";

export interface Element {
  _id: string;
  name: string;
  is_issue_type:boolean;
  order?:number;
}

interface Props {
  widId: string;
  elements: Element[];
  onDoneClick: (widId: string, updatedElements: Element[]) => void;
  OnCancel: (widId: string) => void;
}

const DragAndDropList: React.FC<Props> = ({
  widId,
  elements,
  onDoneClick,
  OnCancel,
}) => {
  const [items, setItems] = useState<Element[]>(elements);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const newItems = Array.from(items);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setItems(newItems);
  };

  const getItemStyle = (isDragging: any, draggableStyle: any, newPB: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // padding: window.matchMedia("(min-width: 2560px)").matches ? grid * 4 : grid * 2,
    // margin: window.matchMedia("(min-width: 2560px)").matches ? `0 0 ${grid * 2}px 0` : `0 0 ${grid}px 0`,
    cursor: "move",
    // borderRadius: window.matchMedia("(min-width: 2560px)").matches ? '6px' : '3px',
    // //border: (newPB==="newItem") ? "1px dashed white" : "1px solid grey",
    // boxShadow: window.matchMedia("(min-width: 2560px)").matches ? "0 0 6px 2px #c1c1c1" : "0 0 3px 1px #c1c1c1",

    // change background colour if dragging
    background: isDragging ? "#CBCBD1" : "#fffff",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  //   style={getItemStyle(
  //     snapshot.isDragging,
  //     provided.draggableProps.style,
  //     item.id
  //   )}

  const handleDoneClick = (widId: string) => {
    onDoneClick(widId, items);
  };

  const handleCancel = (widId: string) => {
    OnCancel(widId);
  };

  return (
    <>
      <div className="widget-container scrollbar-container">
        <div
          className="dx-scrollable dx-scrollview dx-visibility-change-handler dx-scrollable-vertical dx-scrollable-simulated dx-list dx-widget dx-collection dx-has-next"
          aria-roledescription="list"
          role="group"
        >
          <div className="dx-scrollable-wrapper">
            <div className="dx-scrollable-container">
              <div className="dx-scrollable-content">
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {items.map((item, index) => (
                          <Draggable
                            key={item._id}
                            draggableId={item._id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="dx-item dx-list-item"
                                role="option"
                              >

                                <div className="dx-list-item-after-bag dx-list-reorder-handle-container">
                                  <div className="hand-setting-icon"></div>
                                </div>
                                <div className="dx-item-content dx-list-item-content">
                                  {index + 1}. {item.name}{" "}
                                  {/* Display order number */}
                                  <span className="arrow-up"></span>
                                  <span className="arrow-down"></span>
                                </div>
                               
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="launch_button">
        <button
          type="button"
          className={"clr-btn button_styled  ghost"}
          onClick={() => handleCancel(widId)}
        >
          Cancel
        </button>
        <button
          type="button"
          className={"done-btn button_styled btn-color-555555"}
          onClick={() => handleDoneClick(widId)}
        >
          Save
        </button>
      </div>
    </>
  );
};

export default DragAndDropList;
