import { IIdAssetState } from "../IdPopUp";
import { IPatternPayload } from "../PatternForm/PatternFormGenerator"

export interface IPlaybookRule {
    search_field_info: {
        search_field: Record<string, IPatternPayload>
    },
    no_identities?: boolean,
    no_assets?: boolean,
    [key: string]: unknown
}

export interface IPlaybook {
    _id: string,
    actions: Array<IPlaybookActionsState>,
    created_by: string,
    created_date: string,
    is_active: boolean,
    issue_rule_list: Array<IPlaybookRule>,
    issue_type: string,
    last_72_hour_active_incident_count: number,
    last_72_hour_new_incident_count: number,
    last_modified_by: string,
    modified_date: string,
    order: number,
    playbook_name: string,
    risk: number,
    entity_type?: string,
    is_posture_playbook?: boolean
}

export const PlaybookKeys = [
    "_id",
    "actions",
    "created_by",
    "created_date",
    "is_active",
    "issue_rule_list",
    "issue_type",
    "last_72_hour_active_incident_count",
    "last_72_hour_new_incident_count",
    "last_modified_by",
    "modified_date",
    "order",
    "playbook_name",
    "risk"
]

export interface IPlaybookModalProps {
    isOpen: boolean;
    title: string;
    onCloseHandler(state?: boolean): void,
    rule?: string,
    formType?: string,
    totalPlaybooks?: number,
    entityType?: string,
    query?: any
    playbookId?: any,
    playbookImportConfig: any
}

export interface IPlaybookActionsState {
    name: string,
    config?: unknown
}

export interface IPlaybookActionsProps {
    formType?: string,
    ruleType: string,
    saveActions(data: Array<IPlaybookActionsState>): void,
    defaultActions?: Array<IPlaybookActionsState>
}

export interface IPlaybookActivate {
    id: string,
    status: boolean,
    name: string,
    onPlaybookActivateClose(status: boolean): void,
    payload: IPlaybook | undefined
}

export interface IPlaybookRuleState {
    config: unknown;
    identity: IIdAssetState,
    asset: IIdAssetState,
    intermediary: IIdAssetState
}

export interface IPlaybookRulesProps {
    defaultRules?: Array<IPlaybookRuleState>,
    ruleType: string,
    formType: string,
    saveRules(data: Array<IPlaybookRuleState>): void,
    entityType?: string
}


export interface IPlaybookConfigProps {
    isConfigSaved: boolean,
    ruleType: string,
    saveRule(data: unknown): void,
    config: unknown,
    closeConfigPopUp(): void,
    formType: string
}

export interface IPlaybookExceptionModalProps {
    title: string;
    ruleType: string;
}

export interface IPlaybookActions<T> {
    actionId: string,
    actionLabel: string,
    actionCallback?: (arg0: T, e: React.MouseEvent<HTMLElement>) => any,
    isDisabled?: boolean,
    isApplicable?: boolean
}

export interface IPlaybookTableActionsProps<T> {
    actions?: IPlaybookActions<T>[],
    rowData: T,
    formType?: string,
    isDisabled?: boolean,
    id?: string
}


export interface IPlaybookPriority {
    priorityFlag: boolean,
    createPB(data: any): void,
    handlePriorityBack(): void,
    createData: any,
    setPriorityItems(data: any): void,
    priorityItems: any
}

export interface IPlaybookCloseIncPopUpProps {
    showConfirm: true,
    onCloseHandler(): void,
    onNoHandler(): void,
    onKeepInc(): void
}