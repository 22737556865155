import * as React from "react";
import './sensorConfiguration.scss'
import { GlobalConsts } from "../../GlobalConst";


interface SensorConfigurationProps {
    selected: boolean;
    onClick: () => void;
    title: string;
    description: string;
    imageUrl1: string;
    imageUrl2?: string;
    imageUrl3?: string;
    imageUrl:string;
    
}

const SensorConfiguration: React.FC<SensorConfigurationProps> = ({ selected, onClick, title, description,imageUrl, imageUrl1, imageUrl2, imageUrl3 }) => {
    const deploymentGuides = [
        {
            sensorType: "AuthMind AD Sensor",
            url: `${GlobalConsts.DEPLOYMENT_LINK}/installing-active-directory-sensor?${GlobalConsts.DOC_TOKEN}`,
        },
        {
            sensorType: "AuthMind AD/LDS Sensor",
            url: `${GlobalConsts.DEPLOYMENT_LINK}/installing-active-directory-sensor?${GlobalConsts.DOC_TOKEN}`,
        },
        {
            sensorType: "Flow Sensor",
            url: `${GlobalConsts.DEPLOYMENT_LINK}/installing-a-flow-sensor?${GlobalConsts.DOC_TOKEN}`,
        },
        {
            sensorType: "Enrichment Sensor",
            url: `${GlobalConsts.DEPLOYMENT_LINK}/installing-an-enrichment-sensor?${GlobalConsts.DOC_TOKEN}`,
        },
        {
            sensorType: "Hosted Flow Sensor",
            url: `${GlobalConsts.DEPLOYMENT_LINK}/installing-a-flow-sensor?${GlobalConsts.DOC_TOKEN}`,
        },
        {
            sensorType: "SIEM Sensor",
            url: `${GlobalConsts.DEPLOYMENT_LINK}/collect-from-siem?${GlobalConsts.DOC_TOKEN}`,
        },
    ];
    return (
        <div className={`sensor-config ${selected ? "selected" : ""} ${title === 'SIEM Sensor' ? "selected" : ""}`} onClick={onClick}>
            {/* <div className="images">
                <img loading="lazy"  src={imageUrl1} className={'image '+ imageUrl}/>
                {imageUrl2  &&<img loading="lazy" src={imageUrl2} className={'image '+ imageUrl}  />}
            </div> */}
            <div className="images">
                <img loading="lazy"  src={imageUrl1} className='image'/>
                {imageUrl2  &&<img loading="lazy" src={imageUrl2} className='image'  />}
                {imageUrl3  &&<img loading="lazy" src={imageUrl3} className='image'  />}
            </div>
            <div className="title-sensor">{title}</div>
            <div className="description">{description}<a style={{paddingLeft:'3px'}} href={deploymentGuides.find((obj)=> obj.sensorType ===  title)?.url} target="_blank" className="link-text">Learn More</a></div>
        </div>
    );
};

export default SensorConfiguration;


