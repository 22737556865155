import { useAuthDetails } from "../../../../components/Authorization";
import { PostureMenuItem, PostureMenuItemAction } from "../PostureActionMenus/PostureActionMenu";

const accessRolesObjectByString = function (o: any, s: string) {
    if (s) {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        var a = s.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return '';
            }
        }
        return o;
    }
    return '';
}



export const usePostureAction = () => {

    const ActionPermmissionMap = {
        [PostureMenuItemAction.ADD_EXCEPTION.toString()]: 'Admin.exception',
        [PostureMenuItemAction.ADD_EX_SHDW_ACC_ASS.toString()]: 'Admin.exception',
        [PostureMenuItemAction.ADD_EX_COPWD_USR.toString()]: 'Admin.exception',
        [PostureMenuItemAction.CLOSE_INCIDENT.toString()]: 'Issues_and_Prevention.bulk_close_issues',
        [PostureMenuItemAction.ADD_KNOWN_PUBLIC.toString()]: 'Admin.known_public_ip',
        [PostureMenuItemAction.KNOWN_APP.toString()]: 'Admin.knownapps',
        [PostureMenuItemAction.KNOWN_DIR.toString()]: 'Admin.directories',
        [PostureMenuItemAction.KNOWN_INTER.toString()]: 'Admin.intermediary',
        [PostureMenuItemAction.KNOWN_MFA.toString()]: 'Admin.mfa'
    }
    
    const { authDetails } = useAuthDetails()!;

    const isAuthorizedAction = (role: string) => {
        const action = ActionPermmissionMap[role];
        if (!action) {
            if (role === PostureMenuItemAction.MARK_KNOWN.toString()) {
                return isMarkKnownOptionAllowed();
            }
        }
        const perm = accessRolesObjectByString(authDetails.permissions, action);
        return (perm === 'readwrite');
    }

    const isMarkKnownOptionAllowed = () => {
        const a = [PostureMenuItemAction.KNOWN_APP.toString(), PostureMenuItemAction.KNOWN_DIR.toString(),
        PostureMenuItemAction.KNOWN_INTER.toString(), PostureMenuItemAction.KNOWN_MFA.toString()];
        const l: number = a.map((i: string) => isAuthorizedAction(i)).filter((i: boolean) => i).length;
        return l > 0;
    }

    /* Specific to posture page */
    const isAnyActionsAllowed = (menuItems: PostureMenuItem[]) => {
        let isAllowed = false;
        menuItems.forEach((i: PostureMenuItem) => {
            isAllowed = isAllowed || isAuthorizedAction(i.action.toString());
        });
        return isAllowed;
    }

    return {
        isAuthorizedAction,
        isAnyActionsAllowed,
        isMarkKnownOptionAllowed
    }
}