import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Popup from 'reactjs-popup';
import { AssetsObj, AssetMatchedRuleObj, IssueParams, MatchedRuleData } from '../../../../types/response-types';
import { ArrayParam, NumberParam, QueryParams, StringParam, withDefault, withQueryParams } from 'use-query-params';
import * as qs from "qs";
import { Api } from '../../../../components/Axios';
import { convertToCommaValue, getRuleName, syntaxHighlight } from '../../../../utils/util-methods';
import moment from 'moment';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useTimeBasedSearchActions } from '../../../../components/TimeBasedSearch/TimeBasedSearchActions';

type Props = {
    data: AssetMatchedRuleObj | undefined,
    assetId: string | undefined,
    ruleId: string | undefined,
}

const MatchRule = ({ data, assetId, ruleId, handleRuleMatchClose }: any) => {


    let history = useHistory();
    let snippetRef = useRef() as React.MutableRefObject<HTMLDivElement>;
    const [loading, setLoading] = useState<boolean>(false);
    const [flowResponse, setFlowResponse] = useState<any>(undefined);
    const [flowError, setFlowError] = useState('Fetching latest data...');
    const [suspectedPopup, setSuspectedPopUp] = useState(false)
    const [showShareIcon, setShowShareIcon] = useState(true);
    const [matchRuleData, setMatchRuleData] = useState<MatchedRuleData>();
    const { appliedFilterTimestamp } = useTimeBasedSearchActions();

    useEffect(() => {
        if (flowResponse && flowResponse?.incident_info) {
            const matchData = { ...flowResponse?.incident_info }
            setMatchRuleData(matchData);
        }

    }, [flowResponse])

    const updateListItem = () => {
        // if (data?.matching_rule_name == RuleType.COMPROMISED_USER) {
        //     const listItems = document.querySelectorAll('.code-snippet li');
        //     const count = document.querySelector('.breach-count')?.innerHTML;
        //     if (count && parseInt(count) > 5) {
        //         const ol = document.createElement(`ol`);
        //         ol.innerHTML = `<i style="list-style: none;text-decoration: none;">...</i>`;
        //         listItems[4].after(ol);
        //     }
        // }
    }

    const updateMatchingField = (data: any) => {
        if (data) {
            if (data?.matching_rule_details?.matching_field_name === 'am_analysis.am_destination.Protocol') {
                return "am_destination.Protocol";
            } else if (data?.matching_rule_details?.matching_field_name == 'am_analysis.password_analysis.crack_times_seconds.online_no_throttling_10_per_second') {
                return 'am_analysis.password_analysis.crack_times_display.online_no_throttling_10_per_second';
            } else {
                return data?.matching_rule_details?.matching_field_name!;
            }
        }
        return '';
    }

    const highlightData = (flowData: any, fieldName: string) => {
        const exludeFields = ['NA'];
        try {
            if (data?.status?.toLowerCase() == 'resolved' && snippetRef?.current) {
                snippetRef.current.innerHTML = '<pre><code>' + JSON.stringify(flowData, null, 2) + '</code></pre>';
                return;
            }
            let result = '', objkeys = '';
            var highlightedField = fieldName.split(".");

            if (fieldName == 'is_external_access' && !flowData['is_external_access']) {
                flowData['is_external_access'] = false;
            }

            if (!exludeFields.includes(fieldName)) {
                try {
                    if (highlightedField.length === 1 && flowData[fieldName] !== undefined) {
                        flowData[fieldName] = ("<mark id='highlightmatchrule' style='scroll-margin: 20px;'>" + flowData[fieldName] + "</mark>").replaceAll(',', ',<br/>');
                    } else if (highlightedField.length === 2 && flowData[highlightedField[0]] && flowData[highlightedField[0]][highlightedField[1]] !== undefined) {
                        flowData[highlightedField[0]][highlightedField[1]] =
                            ("<mark id='highlightmatchrule' style='scroll-margin: 20px;'>" +
                                flowData[highlightedField[0]][highlightedField[1]] + "</mark>").replaceAll(',', ',<br/>');
                    } else if (highlightedField.length === 3 && flowData[highlightedField[0]] && flowData[highlightedField[0]][highlightedField[1]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]] !== undefined) {
                        const str = JSON.stringify(flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]]);
                        flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]] =
                            ("<mark id='highlightmatchrule' style='scroll-margin: 20px;'>" +
                                str + "</mark>").replaceAll(',', ',<br/>');
                    } else if (highlightedField.length === 4 && flowData[highlightedField[0]] && flowData[highlightedField[0]][highlightedField[1]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]] !== undefined) {
                        flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]] =
                            ("<mark id='highlightmatchrule' style='scroll-margin: 20px;'>" +
                                flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]] + "</mark>").replaceAll(',', ',<br/>');
                    } else if (highlightedField.length === 5 && flowData[highlightedField[0]] && flowData[highlightedField[0]][highlightedField[1]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]][highlightedField[4]] !== undefined) {
                        flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]][highlightedField[4]] =
                            ("<mark id='highlightmatchrule' style='scroll-margin: 20px;'>" +
                                flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]][highlightedField[4]] +
                                "</mark>").replaceAll(',', ',<br/>');
                    } else if (highlightedField.length === 6 && flowData[highlightedField[0]] && flowData[highlightedField[0]][highlightedField[1]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]][highlightedField[4]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]][highlightedField[4]][highlightedField[5]] !== undefined) {
                        flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]][highlightedField[4]][highlightedField[5]] =
                            ("<mark id='highlightmatchrule' style='scroll-margin: 20px;'>" +
                                flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]][highlightedField[4]][highlightedField[5]] +
                                "</mark>").replaceAll(',', ',<br/>');
                    } else if (highlightedField.length === 7 && flowData[highlightedField[0]] && flowData[highlightedField[0]][highlightedField[1]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]][highlightedField[4]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]][highlightedField[4]][highlightedField[5]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]][highlightedField[4]][highlightedField[5]][highlightedField[6]] !== undefined) {
                        flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]][highlightedField[4]][highlightedField[5]][highlightedField[6]] =
                            ("<mark id='highlightmatchrule' style='scroll-margin: 20px;'>" +
                                flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]][highlightedField[4]][highlightedField[5]][highlightedField[6]] +
                                "</mark>").replaceAll(',', ',<br/>');
                    }
                } catch (error) {
                    console.log('Field is not available in flowData:', error);
                }
            }

            if (snippetRef?.current) {
                snippetRef.current.innerHTML = '<pre><code>' + JSON.stringify(flowData, null, 2).replaceAll('\\', '') + '</code></pre>';
                let highglightELement = document.getElementById('highlightmatchrule');
                highglightELement?.scrollIntoView();
            }

        } catch (er) {
            setFlowError('Incorrect flow data received.');
            console.log(er);
            setFlowResponse(undefined);
        }
    }

    useEffect(() => {

        const currentTime = +new Date();
        const flowData: any = sessionStorage.getItem(`${data?.identity}-${data?.asset}-${data?._id}`);
        if (!flowData || ((JSON.parse(flowData)?.expiry_time) < (currentTime))) {
        //  updateListItem()
        setLoading(true);
        let timeFilter = appliedFilterTimestamp();
            const activityTime = timeFilter[1];            
        Api.get(`/posture/rawaccessflow?q=${encodeURIComponent(`identity:${data?.identity}+identity_type:${data?.identity_type}+asset:${data?.asset}+asset_type:${data?.asset_type}+protocol:${data?.asset_protocol}+directory:${data?.directory_hostname}+is_directory_public:${data?.is_directory_public}+activityTime:${activityTime?activityTime:'' }` )}`).then(((res: any) => {
            setLoading(false);
            setFlowResponse(res?.data?._source);
            const matchData = { ...res?.data?._source }
            const result = JSON.parse(JSON.stringify(res?.data?._source));
            result.expiry_time = currentTime + 600000;
            sessionStorage.setItem(`${data?.identity}-${data?.asset}-${data?._id}`, JSON.stringify(result));
            // if (matchData?.matching_rule_details?.highlighted_field_value?.length || false) {
            //     matchData?.matching_rule_details?.highlighted_field_value.forEach((field: string) => highlightData(res?.data?._source, field));
            // } else {
            //     highlightData(res?.data?._source, updateMatchingField(matchData));
            // }
        })).catch(er => {
            console.log(er);
            setLoading(false);
            setFlowResponse(undefined);
            setFlowError('Error occurred while fetching flow data')
        });
        } else {
            setLoading(true)
            const parsedData = JSON.parse(flowData);
            setFlowResponse(parsedData)
            setTimeout(() => {
                setLoading(false)
                // if (match_rule_details?.matching_rule_details?.highlighted_field_value?.length || false) {
                //     setMatchRuleData({ ...flowResponse?.incident_info });
                //     match_rule_details?.matching_rule_details?.highlighted_field_value.forEach((field: string) => highlightData(parsedData, field));
                // } else {
                //     highlightData(JSON.parse(flowData), updateMatchingField(match_rule_details));
                // }
                // updateListItem();
            }, 500)

        }
        const d = document.getElementById('unauth-access-gentimestamp');
        if (d) {
            d.innerHTML = moment(data?.genTimestamp).format('MMM DD YYYY,') + ' ' + moment(data?.genTimestamp).format('h:mm A')
        }
    

    }, [])



    const handleClose = () => {
        // history.push("/posture/assets/rulematch");
        // removeQuery();
        handleRuleMatchClose();
    }




    const copyData = () => {
        if (flowResponse) {
            setShowShareIcon(false);
            navigator.clipboard.writeText(snippetRef.current.innerText)
            // Show the ShareIcon again after 5 seconds
            setTimeout(() => {
                setShowShareIcon(true);
            }, 5000);
        }
    }

    return (
        <>
            <Popup overlayStyle={{ zIndex: 15001, background: 'rgba(227, 242, 253, .6)' }}
                open={true}
                closeOnDocumentClick={false}
                modal={true}
                closeOnEscape={false}
            >
                <div className={"modal"}>
                    <div className={"close"} onClick={handleClose}></div>
                    <div className={"header"}>
                        <span className="rule_table_header" title={(data?.identity ? `${data.identity} → ` : "") + (data?.asset ? `${data.asset}` : "")}>Latest Flow for the Access "{data?.identity ? `${data.identity} access to ` : ""}<i>{data?.asset ? `${data.asset}` : ""}</i>"</span>
                        {/* <span className='rules_count'>({convertToCommaValue(data?.rules_count)})</span> */}
                    </div>
                    
                    <div className={"content"}>
                    <div style={{columnCount:2}}>
                    <div className='key-value-pair'>
                    <label style={{height: 'auto' , marginTop:0}}><strong>Identity Name:</strong></label>
                    <div className='ellipsis_common' title='data?.identity'>{data?.identity}</div>
                    </div>
                    <div className='key-value-pair'>
                    <label style={{height: 'auto'}}><strong>Asset Name:</strong></label>
                    <div className='ellipsis_common' title='data?.asset'>{data?.asset}</div>
                    </div>
                    <div className='key-value-pair'>
                    <label style={{height: 'auto'}}><strong>Protocol:</strong></label>
                    <div>{data?.asset_protocol}</div>
                    </div>
                    <div className='key-value-pair'>
                   { ( data?.is_directory_present && (data.is_directory_public || data?.directory_category_list?.length > 0)) && <div>
                    <label style={{height: 'auto'}}><strong>Identity Provider:</strong></label>
                    <div>{data?.directory_hostname}</div>
                    </div> }
                    </div>
                    <div className='key-value-pair'>
                   { ( data?.is_directory_present && !data.is_directory_public &&  data?.directory_category_list?.length === 0) && <div>
                    <label style={{height: 'auto'}}><strong>Directory Hostname:</strong></label>
                    <div>{data?.directory_hostname}</div>
                    </div>}
                    </div>
                    <div className='key-value-pair'>
                    <label style={{height: 'auto', marginTop:0}}><strong>Identity Type:</strong></label>
                    <div>{!data?.identity_type.startsWith('User') ? 'NHI-'+ data?.identity_type.replace('Service/Application', 'Application')
            .replace('Service/Service Account', 'Service') : data?.identity_type }</div>
                    </div>
                    <div className='key-value-pair'>
                    <label style={{height: 'auto'}}><strong>Asset Type:</strong></label>
                    <div>{data?.asset_type.replace('Service/Application', 'Application')
            .replace('Service/Service Account', 'Service')}</div>
                    </div>
                    <div className='key-value-pair'>
                    <label style={{height: 'auto'}}><strong>Latest Activity:</strong></label>
                    <div> {moment(data.latest_time).format('MMM DD YYYY, hh:mm A')}</div>
                    </div>
                    </div>
                       
                        {/* <label className='code-snippet' style={{ marginTop: 0, height: 'auto', marginBottom: window.matchMedia("(min-width: 2560px)").matches ? 13 : 13 }}>
                            {
                                data?.identity_full_name ?
                                <div>
                                Raw flow data of <b>{data?.identity_full_name}</b> accessing <b>{data?.asset}</b> {data?.directory_name ? `using ${data.directory_name}` : ''} using  protocol <b>{data?.asset_protocol}</b>
                                 </div>
                               :
                                    <div>Summary is not available.</div>

                            }</label>  */}


                        <div
                            style={
                                { display: 'flex', alignItems: 'center', width: '101%' }
                            }
                        >


                            <label>
                                <strong>Raw Flow Data:</strong>
                            </label>
                            {showShareIcon ? (
                                <div className='copy_btn' title={'Click here to copy raw flow'} style={{ display: 'block' }}
                                    // onClick={() => navigator.clipboard.writeText(JSON.stringify(flowResponse || JSON.parse(sessionStorage.getItem(`${data?.flowId}`) || ''), null, 6))}
                                    onClick={copyData}
                                ></div>
                            )
                                :
                                (
                                    <CheckBoxIcon style={{ color: 'green', fontSize: '16px', borderRadius: '10%' }} />
                                )}
                        </div>
                        {
                            flowResponse ?
                                <div ref={snippetRef} className="am_snippet" id="am_snippet_highlight" style={{maxHeight:'300px'}}>
                                    {loading ?
                                        <><div className='loader spinner'></div><div className='fetch-lat-data'>Fetching latest data...</div></> :
                                        flowResponse ? 
                                        <pre
                                                dangerouslySetInnerHTML={{
                                                __html: syntaxHighlight(JSON.stringify(flowResponse, undefined, 6))
                                                }}
                                        />
                                            : <span>{flowError}</span>
                                    }
                                </div>
                                : <><div className={loading ? 'loader spinner' : ''}></div><div className='fetch-lat-data'>{flowError}</div></>}


                    </div>
                </div>
            </Popup>
        </>
    )
}

export default React.memo(MatchRule);