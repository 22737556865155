import React, { useImperativeHandle } from 'react'
import { IPatterFormRef, IPatternConfigItem, IPatternFormValue, PatternFormGenerator } from "../../playbooks/ref/PatternForm/PatternFormGenerator";
import { useState, useEffect, useRef } from 'react';
import { ExceptionAdvancedSearchConfig } from "./ExceptionsAdvanceSearchConfig";
import { Api } from "../../../components/Axios";
import { useExceptionAdvanceSearch } from './useExceptions';
import { GLOBAL_SERVICE } from '../../services/GlobalService';
import { getCategoryByKey } from '../../../utils/util-methods';
import { IAMSelectOption } from '../../../components/core/AMSelect/AMSelect';

export interface IExceptionAdvancedSearchFormRef {
	handleExceptionFormSave(data?: any): IPatternFormValue[];
	getExceptionFormIntermediateValues(): IPatternFormValue[][];
}

interface IExceptionAdvanceSearchProps {
	defaultState: IPatternFormValue[][];
	setIsExceptionFormValid(val: boolean): void;
	issueTypes: string[];
	isAllIssues: boolean | undefined;
}

export const ExceptionsAdvanceSearch = React.forwardRef(
	(
		{ defaultState, setIsExceptionFormValid,isAllIssues,issueTypes }: IExceptionAdvanceSearchProps,
		ref: any
	) => {
		useImperativeHandle(
			ref,
			() =>
				({
					handleExceptionFormSave() {
						if (patternRef?.current) {
							return handleExceptionSave();
						}
						return undefined;
					},
					getExceptionFormIntermediateValues() {
						return getExceptionFormIntermediateValues();
					}
				} as IExceptionAdvancedSearchFormRef)
		);

		const [state, setState] =
			useState<Array<IPatternFormValue[]>>(defaultState);

		const patternRef = useRef([]);
		const { deTransformFormValues } = useExceptionAdvanceSearch();
		const [rules, setRules] = useState<Array<Number>>(
			Array.from({ length: state?.length || 0 }, (_, i) => i + 1)
		);
		const [isValidForm, setIsValidForm] = useState<Array<boolean>>([]);
		const [config, setConfig] = useState<IPatternConfigItem[]>(ExceptionAdvancedSearchConfig);
		const [categoryList, setCategoryList] = useState();
		const [loading, setLoading] = useState(true);
		const validateForm = (val: boolean) => {
			let _isValid = val;
			let validArr: Array<boolean> = [];
			patternRef.current.forEach((i: IPatterFormRef, index: number) => {
				if (i) {
					_isValid = _isValid && i.isFormValid;
					validArr[index] = i.isFormValid;
				}
			});
			setIsValidForm(validArr);
			setIsExceptionFormValid(_isValid);
		};

		const deleteRule = (index: number) => {
			const r = [...rules];
			r[index] = null;
			const s = [...state];
			s[index] = null;
			setRules(r);
			setState(s);
		};

		const addRule = () => {
			setRules([...rules, 1]);
			setState([...state, [{ operator: '', searchKey: '', value: '' }]]);
		};

		const filterPerIssue = (tempConfig:IPatternConfigItem[]) => {
			const FIELD_PER_ISSUE = {
				'Access to Unauthorized Countries': ['d_hostname', 'd_ip', 'd_is_public', 'd_mac', 'd_name', 'd_type', 
				'd_port', 'd_is_known','d_protocol', 't_protocol', 'src_countries',
					's_hostname', 's_ip', 's_is_cdn', 's_is_datacenter', 's_is_icloudrelday', 's_organization', 's_name', 's_type', 's_port',
					's_is_known', 's_susp_external_scanner', 'src_category', 'dir_hostname', 'dir_name', 'dir_ip', 'dir_category', 'flow_time', 'src_inter_ip',
				'loc','is_streaming_flow','is_svc_to_svc_flow','collector_name'
				]		
			};

			const resultSet: Array<string> = [];
			issueTypes.forEach((issue: string) => {
				resultSet.push(...FIELD_PER_ISSUE[issue as keyof typeof FIELD_PER_ISSUE]);
			});

			const resultFields = Array.from(resultSet);
			
			config.forEach((conf: IPatternConfigItem) => {
				const index = resultFields.findIndex((field: string) => conf.id == field);
				if (index == -1) {
					const ind = tempConfig.findIndex((field: IPatternConfigItem) => field.id == conf.id);
					if (ind > -1 && !conf.isGroupRoot) {
						tempConfig.splice(ind, 1)	
					}					
				}
			});
		}

		const filterCategoriesBasedOnCategoryType = (tempConfig: IPatternConfigItem[]) => {
			const ISSUE_CATEGORY = {
				'Access to Unauthorized Countries': {
					src_category: [520, 540, 560],
					dst_category: [520, 540, 560],
					dir_category: [520, 540, 560]
				}
			};
			
			const destinationCategory: IPatternConfigItem | undefined = tempConfig.filter((configItem: IPatternConfigItem) => {
				return configItem.id == 'dst_category'
			})[0];
			const sourceCategory : IPatternConfigItem | undefined = tempConfig.filter((configItem: IPatternConfigItem) => {
				return configItem.id == 'src_category'
			})[0];
			const directoryCategory : IPatternConfigItem | undefined = tempConfig.filter((configItem: IPatternConfigItem) => {
				return configItem.id == 'dir_category'
			})[0];

			destinationCategory.selectOptions = [];
			sourceCategory.selectOptions = [];
			directoryCategory.selectOptions = [];

			issueTypes.forEach((issue: string) => {
				const issueCategory = ISSUE_CATEGORY[issue as keyof typeof ISSUE_CATEGORY];
				if (issueCategory) {
					const cats = issueCategory.dst_category.map((key: number) =>
					{return { optionValue: getCategoryByKey(key.toString()), optionLabel: getCategoryByKey(key.toString())}}) as IAMSelectOption[];
					destinationCategory.selectOptions = destinationCategory.selectOptions?.concat(cats);
				}
			});

		}

		useEffect(() => {

			async function updateConfig() {
				const tempConfig = JSON.parse(JSON.stringify(config)) as IPatternConfigItem[];
			
				const ISSUE_CATEGORY_MAP = {
				src_category: ['Access to Unauthorized Countries', 'Access to Anonymous IP', 'Access to Public VPN', 'Auth Hash Quality','Auth Hash Security',
					'Compromised Password', 'Compromised User', 'Enumeration of AD Admins', 'Enumeration of AD Users',
					'Lack of MFA', 'Repeated AD Login Attempts at Invalid Time', 'Repeated AD Login Attempts from Invalid Device',
					'Shadow Identity Systems',	'Shadow External Access',
					'Suspected AD NTLM Relay Attack', 'Suspected Attack on Disabled AD Account', 'Suspected Attack on Expired AD Account',
					'Suspected Attack on Locked AD Account','Suspicious Outbound Access','Unknown SaaS Access','Weak Password'
				],
				dst_category: [
					'Access from Unauthorized Countries', 'Access from Anonymous IP', 'Access from Public VPN',
					'Auth Hash Quality', 'Auth Hash Security', 'Compromised Password', 'Compromised User',
					'Enumeration of AD Admin', 'Enumeration of AD Users', 'Repeated AD Login Attempts at Invalid Time',
					'Repeated AD Login Attempts from Invalid Device', 'Shadow Access', 'Shadow Assets',
					'Suspected AD NTLM Relay Attack', 'Suspected Attack on Disabled AD Account', 'Suspected Attack on Expired AD Account',
						  'Suspected Attack on Locked AD Account', 'Suspected Directory / IdP Bot Attack',
					'Suspected Directory/IdP Identity Brute-force Attack', 'Suspected Directory/IdP Password Spray Attack', 'Suspicious Inbound Access',
					'Weak Password'
				],
				dir_category: [
					'Enumeration of AD Admins','Enumeration of AD Users', 'Repeated AD Login Attempts at Invalid Time','Repeated AD Login Attempts from Invalid Device',
					'Suspected AD NTLM Relay Attack', 'Suspected Attack on Disabled AD Account',
					'Suspected Attack on Expired AD Account',	  'Suspected Attack on Locked AD Account',
					'Suspicious Inbound Access','Suspicious Outbound Access','Unknown Access'
				]
			}

			if (!isAllIssues && issueTypes?.length > 0) {
				let hideDir = true, hideSrc = true, hideDst = true;
				issueTypes.forEach((issue: string) => {
					hideDir = hideDir && ISSUE_CATEGORY_MAP.dir_category.includes(issue);
					hideSrc = hideSrc && ISSUE_CATEGORY_MAP.src_category.includes(issue);
					hideDst = hideDst && ISSUE_CATEGORY_MAP.dst_category.includes(issue);
				})

				if (hideDir) {
					const dirIndex = tempConfig.findIndex((conf: IPatternConfigItem) => conf.id == 'dir_category');
					tempConfig.splice(dirIndex, 1);
				}
				if (hideSrc) {
					const srcIndex = tempConfig.findIndex((conf: IPatternConfigItem) => conf.id == 'src_category');
					tempConfig.splice(srcIndex, 1);
				}
				if (hideDst) {
					const dstIndex = tempConfig.findIndex((conf: IPatternConfigItem) => conf.id == 'dst_category');
					tempConfig.splice(dstIndex, 1);
				}
					
				if (issueTypes?.length === 1 && issueTypes[0] === 'Shadow Identity Systems') {					
					const cat = await GLOBAL_SERVICE.Category.GET();
					const dirCategories = (cat || { dir_category: [] }).dir_category;
					console.log(dirCategories)
					const dst = tempConfig.find((conf: IPatternConfigItem) => conf.id == 'dst_category');
					if (dst) {
						dst.selectOptions = dirCategories;	
					}					
				}
				setConfig([...tempConfig]);	
			}			
			}			
			updateConfig()
					
		}, [isAllIssues, issueTypes])

		useEffect(() => {
			const t = rules.filter((i) => i).length;
			if (t === 0) {
				validateForm(false);
			} else {
				validateForm(true);
			}
		}, [rules]);

		/*     useEffect(() => {
            if (rules.length === 0) {
                setIsValidForm(true)
            }
        }, [rules]) */

		useEffect(() => {
			(() => {				
				Api.get('issues/uniquefields')
					.then(async(res: any) => {					
						let categoryList = await GLOBAL_SERVICE.Category.GET();
						setCategoryList(categoryList);
						const data = { ...res.data, ...categoryList };
						data['d_type'] = ['Application',
							'Device',
							'Service',
							'Service/Computer Account',
							"Service/Key and Secret",
							'Service/Service Principal',
							"Service/Token"];
					    data['s_type'] = ['Non Human Identities (NHI)',
							'Application',
							'Device',
							'Service',
							'Service/Computer Account',
							"Service/Key and Secret",
							'Service/Service Principal',
							"Service/Token",
							'Users',
							'User',
							'User/Agent',
							'User/Background Browsing'];
						/* transform data before sending */
						Object.keys(data).map((s: string) => {
							const c = config.find(
								(i: IPatternConfigItem) => i.id === s
							);
							if (c) {
								c.selectOptions = data[s];
							}
							return c;
						});
						setConfig(config);
						setState(JSON.parse(JSON.stringify(state)));
						setLoading(false);
					})
					.catch((er) => {
						console.log(er);
						setLoading(false);
					});
			})();
		}, []);

		useEffect(() => {
			patternRef.current = patternRef?.current.slice(0, rules.length);
		}, [rules]);

		const handleExceptionSave = () => {
			if (patternRef?.current) {
				const result: Array<any> = [];
				patternRef.current.forEach((i: IPatterFormRef) => {
					if (i) {
						const data =
							i.handlePatternFormSave() as IPatternFormValue[];
						const d = deTransformFormValues(data);
						result.push(d);
					}
				});
				return result;
			}
			return undefined;
		};

		const getExceptionFormIntermediateValues =
			(): IPatternFormValue[][] => {
				if (patternRef?.current) {
					const result: Array<any> = [];
					patternRef.current.forEach((i: IPatterFormRef) => {
						if (i) {
							const data =
								i.handlePatternFormSave() as IPatternFormValue[];
							result.push(data);
						}
					});
					return result;
				}
				return [];
			};

		const onControlRemove = (controlIndex: number, index: number) => {
			if (patternRef?.current && patternRef?.current[index]) {
				const dState = [...state];
				dState[index].splice(controlIndex, 1);
				setState(dState);
			}
		};

		if (loading) return (<div className='loader spinner'></div>);

		return (
			<>
				{rules.map((i: any, index: number) => {
					if (i) {
						const currentIndex =
							rules.filter(
								(k: any, ind: number) => k && ind < index
							).length + 1;
						return (
							<>
								<fieldset
									className={
										'add-exception-form-fieldset ' +
										(isValidForm[index]
											? ''
											: ' field-set-error ')
									}
								>
									<legend className='rule-legend'>
										Rule {currentIndex}
									</legend>
									<div style={{ height: '20px' }}>
										<span
											onClick={(e) => deleteRule(index)}
											className='rule-delete-icon'
										>
											X
										</span>
									</div>
									<PatternFormGenerator
										config={config}
										defaultState={{
											searchFilter: state[index] || []
										}}
										formType={'create'}
										showOperatorBetween={false}
										validateForm={validateForm}
										ref={(el) => {
											patternRef.current[index] = el;
										}}
										propsConfig={{ isGroupedList: true }}
										onControlRemove={(i) =>
											onControlRemove(i, index)
										}
										/*   defaultState={{
                                searchFilter: [{ value: '5455', searchKey: 'hostname', operator: 'not_equals' },
                                { value: '22.22.22.22', searchKey: 'ip', operator: 'equals' },
                                { value: '23434', searchKey: 'name', operator: 'equals' }
                                ]
                            }} */
									/>
								</fieldset>
							</>
						);
					} else {
						return <span></span>;
					}
				})}
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<button
						type='button'
						className={
							'button_main width25per' +
							(isValidForm.filter((i: boolean) => !i).length > 0
								? ' disableItems'
								: '')
						}
						onClick={addRule}
					>
						Add Rule
					</button>
				</div>
			</>
		);
	}
);