import React from "react"
import './PostureChartsSkeleton.scss'
export const PostureChartRuleRootSkeleton = () => {
    return <div className='idasset_chart'>
        <div className="idasset-skeleton-wrapper">
            <div className="idasset-skeleton-content">
                <div className="idasset-skeleton-section idasset-skeleton-text">
                    <div className="idasset-skeleton idasset-skeleton-line idasset-skeleton-line-short"></div>
                    {
                        Array.from({ length: 7 }).map(() => <div className="idasset-skeleton idasset-skeleton-line"></div>)
                    }
                </div>
                <div className="idasset-skeleton-section idasset-skeleton-circle">
                    <div className="idasset-skeleton idasset-skeleton-circle"></div>
                </div>
            </div>
        </div>
    </div>
}

export const PostureChartsSkeleton = () => {
    const loopArray = Array.from({ length: 4 }, (_, index) => index + 1);

    return (<div className="posture-chart-skeleton-container"><div className="idasset_widget_summary_box ">
        <div className='summary_widget'>
            {
                loopArray.map((i) => <div className='summary_widget_box '>
                    <div className='smry-skeleton summary_details smry-skeleton-line smry-skeleton-line-short'></div>
                    <div className='smry-skeleton summary_details smry-skeleton-line'></div>
                    <div className='smry-skeleton summary_details smry-skeleton-line'></div>
                </div>)
            }
        </div>
        {
            Array.from({ length: 2 }).map(() => (<PostureChartRuleRootSkeleton />))
        }
    </div></div>)

}