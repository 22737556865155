import { Fade, Link, Tooltip } from "@mui/material";
import * as React from "react";
import { useAuthDetails } from "../../components/Authorization";
import { Category } from "@mui/icons-material";
import { useHistory } from "react-router-dom";

interface AMCollectorProps {
  item: any;
  onAddCollector: () => {};
  isDisabled:boolean;
  category:string;s
}

const CollectorCard: React.FC<AMCollectorProps> = ({
  item,
  onAddCollector,
  isDisabled,
  category
}) => {
  const { authDetails } = useAuthDetails()!;
  const history = useHistory();

  const getDeployementSummaryTableIndex = (category) => { 
    switch (category) { 
      case 'Active Directory & Cloud IdPs':
        return 0;
      case 'Network Access':
        return 1;
      case 'Cloud & On-Premise Infrastructure':
        return 2;
    }
  }

  const configuredCollectorClickHandler = (data) => {
    let tableIndex = getDeployementSummaryTableIndex(data.category);
    let query = data.collector_data_source;
    history.push(`/deploy/manageCollector?page=1&rpp=20&tableIndex=${tableIndex}&q=${encodeURIComponent(query)}`);
  }

  return (
    <>
      <div className="div">
        <div className="div-2">
          <div
            className={
              item.vendor_name.replace(/\s+/g, "_").toLowerCase() +
              " collector-logo"
            }
          ></div>
          <div className="div-3">
            <div className="div-3-group">
            {(item.installed_count && item.installed_count > 0) ? (
              <div className="installed-count-container"><div className="installed-count-group" onClick={e => configuredCollectorClickHandler(item)}><div className="installed-count-icon"></div><span className="installed-count-text">{`${item.installed_count} Configured`}</span></div></div>            
            ) : <></>}
            {authDetails.permissions.Admin.sensors !== "readonly" && (
              <div className="div-4">
             {isDisabled ? ( 
              <Tooltip 
              classes={{ tooltip: "generic-tooltip-popper" }}
              title={ category === 'Network Access' ? 'To add a Network Access collector, first add Active Directory or IDP' :'To add a Cloud and On-Premise collector, first add a Network Access collector'}
              > <div
                  className="div-5 "

                  style={{opacity:0.5 , cursor:'default'}}
                >
                  + Add
                </div> </Tooltip>) :(
                   <div
                   className="div-5"
                   onClick={() => {
                     onAddCollector(item);
                   }}
                 >
                   + Add
                 </div>
                )}
              </div>
            )}
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 0 }}
              classes={{ tooltip: "am-admin-action-content" }}
              style={{ display: "block" }}
              PopperProps={{ style: { zIndex: 999 } }}
              title={
                <div>
                  <ul>
                    <li>
                      <a
                        style={{ cursor: "pointer", padding: "10px 5px" }}
                        href={item.link_to_document}
                        target="_blank"
                      >
                        Read more..
                      </a>
                    </li>
                  </ul>
                </div>
              }
            >
              <div className="action_button" style={{ width: "11px" }}>
                <div></div>
              </div>
            </Tooltip>
            </div>
            </div>
        </div>
        <div className="div-8">{item?.collector_data_source}</div>
        <div className="div-9">
          {item.description.substring(0, 135)}{" "}
          {item.description.length > 135 && <span>...</span>}
        </div>
      </div>
    </>
  );
};

export default CollectorCard;
