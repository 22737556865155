import React, { useState } from "react";
import { useHistory } from "react-router";
import Popup from "reactjs-popup";
import {
  StringParam,
  withQueryParams,
} from "use-query-params";
import styles from "../grid/Playbooks.module.css";
import './create.css';
import { Autocomplete, TextField } from '@mui/material';

import { PlaybookMap } from "../constants/Constants";
import { RuleDescription } from "../constants/RuleDescription";
import { PlaybookListItem, getPlaybookList, isNewPostureIssuesPlaybook } from "../helpers/pb-helper";
import { PlaybookModal } from "../ref/AdvancedPlaybook/PlaybookModal";
import { POSTURE_ISSUE, ENTITIES } from '../constants/Constants';
import { stringifyUrl } from "query-string";
import CheckboxAutocomplete from "../../../components/core/AMAutoComplete/AMAutoComplete";
import { PlaybookImportExport } from "../ref/AdvancedPlaybook/PlaybookImportExport";

type Props = {
  query: any,
  setQuery: () => {},
  updatePlaybooksData: () => void,
  totalPlaybooks: any,
}

const AddPlaybook = ({ query, updatePlaybooksData, totalPlaybooks }: Props) => {

  const [ruleType, setRuleType] = useState<string | undefined>(isNewPostureIssuesPlaybook(query?.type ? query.type : '', query?.entity_type ? query.entity_type : '') ? POSTURE_ISSUE.type : '');
  const [ruleReqErr, setRuleReqErr] = useState<boolean>(false);
  const [nextFlag, setNextFlag] = useState<boolean>(isNewPostureIssuesPlaybook(query?.type ? query.type : '', query?.entity_type ? query.entity_type : '') ? true : false);
  const [selectedValue, setSelectedValue] = useState<string>('');
  const options: PlaybookListItem[] = getPlaybookList();
  const [playbookImportConfig, setPlaybookImportConfig] = useState();

  const handleSelectChange = (e, newValue: Array<PlaybookListItem> | null) => {
    if (newValue) {
      setSelectedValue(newValue[0].key);
      setRuleType(newValue[0].key);
      setRuleReqErr(false)

    } else {
      setSelectedValue('');
      setRuleType(undefined)
    }
  };

  const getOptionLabelWithSpacing = (option: any) => {
    return `  ${option.label}`;
  };

  let history = useHistory();

  const handleClose = () => {
    history.push(`/playbooks${history.location.search}`);
  };

  const handleNext = () => {
    if (ruleType == '') {
      setRuleReqErr(true);
    }
    else {
      setNextFlag(true);
    }
  }

  const getIssueValue = () => {
    if (ruleType) {
      const rule = getPlaybookList().filter((i) => i.key == ruleType)[0];
      if (rule && rule.value) {
        return rule.value
      }
    }
    return ""
  }

  const onPlaybookImport = (playbookConfig) => {
    setRuleType(undefined)
    setPlaybookImportConfig(playbookConfig);
    setNextFlag(true)
  }

  const PlaybookList = () => {
    return <>
      <div style={{
        clear: "both"
      }}></div>
      <label
        className="rule_type width100per font14"
        htmlFor="rule_type"
        key="rule_type_label"
      >
        Issue Type<span style={{ color: 'red' }}></span>
      </label>
      <CheckboxAutocomplete
        options={options}
        hideClearBtn={true}
        getItemLabel={(o) => typeof o == 'string' ? o : o.value}
        onChange={handleSelectChange}
        multiple={false}
        label={"Select Issue Type"}
        value={{ key: ruleType, value: getIssueValue() }}
        classes={{ root: 'width50per', popper: 'playbook-listing-popper' }}
      />
      {
        <div className="menu-item-unknown-saas">
          <RuleDescription ruleType={ruleType} showLink={true} />
        </div>
      }
      <div style={{ clear: "both", marginTop: 30 }}></div>
      {ruleReqErr ? <span className="error">* Issue type is required</span> : null}
      <div className={styles.shadow_footer}></div>
      <div className={`${styles.popup_footer} dialog-button`}>
        <button
          className={styles.policy_save_button}
          style={{ margin: "0 auto", float: "none" }}
          id="new_playbook_tab2"
          onClick={handleNext}
        >
          Next
        </button>
      </div>
    </>
  }

  return (
    <>
      <Popup
        closeOnDocumentClick={false}
        overlayStyle={{ zIndex: 999, background: "rgba(227, 242, 253, .6)" }}
        open={true}
        onClose={handleClose}
        closeOnEscape={false}
        modal
      >
        <div style={{ marginBottom: '5%', visibility: nextFlag ? 'hidden' : 'visible' }} className={`${styles['modal']} modal_new`}
        >
          {/* <PlaybookImportExport onPlaybookImport={onPlaybookImport} /> */}
          <div className={styles.close} onClick={handleClose}></div>
          <div className={styles.header}
          >New Playbook: <span>{ruleType && ruleType !== POSTURE_ISSUE.type ? getIssueValue() : ''}</span></div>
          <div className={styles.content} style={{ width: "100%" }}>
            {!nextFlag && <PlaybookList />}
          </div>
        </div>
      </Popup >
      {
        nextFlag && <PlaybookModal
          playbookImportConfig={playbookImportConfig}
          isOpen={nextFlag}
          onCloseHandler={(state?: boolean) => {
            setPlaybookImportConfig(undefined)
            if (isNewPostureIssuesPlaybook(query?.type ? query.type : '', query?.entity_type ? query.entity_type : '')) {
              history.push(`/playbooks?order_by=desc&sort_by=playbook_name`)
            }
            if (state) {
              setNextFlag(false);
            } else {
              updatePlaybooksData()
            }
          }}
          rule={ruleType || ''}
          title=""
          formType={'create'}
          entityType={query?.entity_type ? query?.entity_type : ''}
          query={query}
        />
      }
    </>
  );
};

// export default React.memo(AddPlaybook);

export default withQueryParams(
  {
    order_by: StringParam,
    sort_by: StringParam,
    q: StringParam,
    type: StringParam,
    entity_type: StringParam
  },
  AddPlaybook
);