import React from "react";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Popup from "reactjs-popup";

import styles from "../../grid/Playbooks.module.css";
import { IPlaybookPriority } from "./PlaybookTypes";

const grid = 8;

const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging: any, draggableStyle: any, newPB: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: window.matchMedia("(min-width: 2560px)").matches
    ? grid * 2
    : grid * 2,
  margin: window.matchMedia("(min-width: 2560px)").matches
    ? `0 0 ${grid}px 0`
    : `0 0 ${grid}px 0`,
  cursor: "move",
  borderRadius: window.matchMedia("(min-width: 2560px)").matches
    ? "3px"
    : "3px",
  //border: (newPB==="newItem") ? "1px dashed white" : "1px solid grey",
  boxShadow: window.matchMedia("(min-width: 2560px)").matches
    ? "0 0 3px 1px #c1c1c1"
    : "0 0 3px 1px #c1c1c1",

  // change background colour if dragging
  background: isDragging
    ? "#CBCBD1"
    : newPB === "newItem"
    ? "#CBCBD1"
    : "#fffff",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "" : "",
  padding: grid,
  width: window.matchMedia("(min-width: 2560px)").matches ? 450 : 450,
});

export const PlaybookPriority = ({
  createPB,
  priorityFlag,
  handlePriorityBack,
  createData,
  priorityItems,
  setPriorityItems,
}: IPlaybookPriority) => {
  function onDragEnd(result: any) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const quotes = reorder(
      priorityItems,
      result.source.index,
      result.destination.index
    );
    setPriorityItems(quotes);
  }

  const handleAutoPriority = (pbData: any) => {
    //Add insertAfter or insertBefore to the API request
    const insertLocation = priorityItems.findIndex(
      (p: any) => p._id == "newItem"
    );

    //Insert at top location
    if (insertLocation === 0) {
      delete pbData.insert_after;
      pbData.insert_before = priorityItems[insertLocation + 1]._id;
    }
    //Insert at last location
    else if (insertLocation === priorityItems.length - 1) {
      delete pbData.insert_before;
      pbData.insert_after = priorityItems[insertLocation - 1]._id;
    }
    //Insert somewhere between
    else {
      delete pbData.insert_after;
      pbData.insert_before = priorityItems[insertLocation + 1]._id;
    }

    createPB(pbData);
  };

  return (
    <Popup
      closeOnDocumentClick={false}
      overlayStyle={{ zIndex: 17000, background: "rgba(227, 242, 253, .6)" }}
      open={priorityFlag}
      closeOnEscape={false}
      modal
      //lockScroll
    >
      <div
        style={{ marginBottom: "5%" }}
        className={`${styles["modal"]} ${styles['playbook-priority-modal']}`}

      >
        <div className={styles.header}>Set Playbook Priority</div>
        <div className={styles.content}>
          <div
           className={`${styles['dx-scrollable']} ${styles['dx-scrollview']} ${styles['dx-visibility-change-handler']} ${styles['dx-scrollable-vertical']} ${styles['dx-scrollable-simulated']} ${styles['dx-list']} ${styles['dx-widget']} ${styles['dx-collection']} ${styles['dx-has-next']}`} 
            aria-roledescription="list"
            role="group"
          >
            <div className={`${styles['dx-scrollable-wrapper']}`}  >
              <div className={`${styles['dx-scrollable-container']}`}>
                <div className={`${styles['dx-scrollable-content']}`}>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          {priorityItems?.map((item: any, index: any) => (
                            <div style={{ position: "relative" }}>
                              <span className="highlow">
                                {index === 0
                                  ? "Highest"
                                  : index === priorityItems.length - 1
                                  ? "Lowest"
                                  : ""}
                              </span>
                              <Draggable
                                key={item._id}
                                draggableId={item._id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style,
                                      item._id
                                    )}
                                    className={`${styles['dx-item']} ${styles['dx-list-item']}`}
                                    
                                  >
                                    <div  className={`${styles['dx-list-item-after-bag']} ${styles['dx-list-reorder-handle-container']}`} >
                                      <div className={`${styles['hand-setting-icon']}`} ></div>
                                      {/* <span
                                        className={
                                          item._id === "newItem" ? "grippy" : ""
                                        }
                                      ></span> */}
                                    </div>
                                    <div   className={`${styles['dx-item-content']} ${styles['dx-list-item-content']}`} >
                                      {index + 1}. {item.playbook_name}{" "}
                                      {/* Display order number */}
                                      <span className={`${styles['arrow-up']}`} ></span>
                                      <span className={`${styles['arrow-down']}`}></span>
                                    </div>
                                    
                                  </div>
                                )}
                              </Draggable>
                            </div>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shadow_footer fl"></div>
        <div className="popup_footer fl">
          <div
            id="back_playbook"
            className="policy_defualt_button"
            onClick={handlePriorityBack}
          >
            Back
          </div>
          <div
            className="policy_save_button"
            onClick={() => handleAutoPriority(createData)}
          >
            Done
          </div>
        </div>
      </div>
    </Popup>
  );
};
