import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Api } from "../../../components/Axios";
import { useToasts } from "../../../components/core";
import { AMModalError } from "../../../components/core/AMModalPopup/AMModalError";
import { SensorErrors } from "../constants/SensorErrors";
import { AVAILABLE_SIEMS, MESSAGES } from "../constants/sensor-labels";
import { SplunkSiemConfiguration } from "../reference/Splunk/SplunkSiemConfiguration";
import { SumoLogicSiemConfiguration } from "../reference/SumoLogicCollector/SumoLogicSiemConfiguration";
import { QRadarSiemConfiguration } from "../reference/QRadarCollector/QRadarSiemConfiguration";
import CheckboxAutocomplete from "../../../components/core/AMAutoComplete/AMAutoComplete";
import { formatDate, isAmopsUser } from "../../../utils/util-methods";
import { AlertComponent } from "../AlertComponent";
import { useHistory } from "react-router-dom";
import { AMAdminPopUp } from "../../ref/AMAdminCommonComponents";
import moment from "moment";
import { KafkaSiemConfiguration } from "../reference/Kafka/KafkaSiemConfiguration";

const getSiemVendorId = (siemDataSource) => {
  switch (siemDataSource) {
    case "Splunk":
      return "12";
    case "IBM QRadar SIEM":
      return "13";
    case "Sumo Logic":
      return "14";
    case "Splunk Cloud":
      return "15";
    case "IBM QRadar (Cloud-Native SaaS)":
      return "16";
    case "Kafka":
        return "20";
    default:
      return "";
  }
};

export const SiemConfiguration = ({
  onCloseHandler,
  id = "",
  data,
  AfterSensorOperation,
}) => {
  const [availableSiemVendors, setAvailableSiemVendors] = useState(AVAILABLE_SIEMS);
  const [type, setType] = useState(
    data && data["collector_data_source"]
      ? getSiemVendorId(data["collector_data_source"])
      : ""
  );
  const [sensorId, setSensorId] = useState(id);
  const [isEdit, setIsEdit] = useState(id !== "" ? true : false);
  const [sensorData, setSensorData] = useState(null);
  const [availableFlowSensors, setAvailableFlowSensors] = useState([]);
  const [flowSensor, setFlowSensor] = useState();
  const [alerts, setAlerts] = useState([]);
  const [isLoaded, setIsLoaded] = useState(true);
  const { addToast } = useToasts();
  const {
    register,
    setError,
    getValues,
    setValue,
    clearErrors,
    watch,
    trigger,
    errors,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      flow_sensors: [],
    },
  });
  const values = watch();

  const handleSelectionChange = (event, filteredValue) => {
    setFlowSensor(filteredValue);
    const selectedCategory = filteredValue[0]?.value || null;
  };

  const history = useHistory();

  const [showPopUp, setShowPopUp] = useState(false);

  const setKafkaValue = (value)=>{
    setValue('kafka_brokers',value)
  }

  const getSiemComponent = (siem) => {
    let props = { register };
    if (!siem) return <></>;
    if (isEdit) {
      props = { ...props, sensorData: sensorData };
    }
    switch (siem) {
      case "12":
      case "15":
        return <SplunkSiemConfiguration {...props}></SplunkSiemConfiguration>;
      case "13":
      case "16":
        props = {
          ...props,
          verifySSL: values && values["qradar_verify_ssl"],
        };
        return <QRadarSiemConfiguration {...props}></QRadarSiemConfiguration>;
      case "14":
        return (
          <SumoLogicSiemConfiguration {...props}></SumoLogicSiemConfiguration>
        );
      case "20":
          return (
            <KafkaSiemConfiguration {...props } setValue={setKafkaValue} sensorData={sensorData} ></KafkaSiemConfiguration>
          );
      default:
        return <></>;
    }
  };

  const onSave = async () => {
    const data = getValues();
    saveConfiguration(prepareData(data));
  };

  const prepareData = (data) => {
    if( !["14", "15", "16"].includes(type)){
    data.type = type;
    data.flow_sensors = data.flow_sensors
      ? data.flow_sensors.map((data) => ({ _id: data.key, name: data.value }))
      : [];
    } else {
      data.type = type;
      data.flow_sensors = sensorData?.flow_sensors
      ? sensorData.flow_sensors.map((data) => ({ _id: data._id, name: data.name }))
      : [];

    }

    return data;
  };

  const saveConfiguration = async (data) => {
    const formTrigger = await trigger();
    if (!formTrigger) {
      return;
  }
    let headers = {},
      apiUrl = ``;
    if (sensorId !== "") {
      headers = { Operation: "PUT" };
      apiUrl = `/sensors/${sensorId}`;
    } else {
      apiUrl = `/sensors`;
    }
    setIsLoaded(false);
    Api.post(apiUrl, data, { headers: headers, timeout: 15000})

      .then((res) => {
        if (res) {
            setIsLoaded(true);
          if (res.data && typeof res.data != 'string') {
            setAlerts(getTestConnectionAlertMessages(res.data));
          } else {
            if (isEdit) {
              AfterSensorOperation("edit");
              addToast("Sensor updated successfully.", {
                appearance: "success",
                autoDismiss: true,
              });
            } else {
              addToast("Sensor added successfully.", {
                appearance: "success",
                autoDismiss: true,
              });
            }
            setShowPopUp(true);
          }
        }
      })
      .catch((error) => {
        setIsLoaded(true);
        if (error.response.status === 500) {
          addToast("Sorry, something went wrong there, try again.", {
            appearance: "error",
            autoDismiss: true,
          });
        } else if (
          error.response.status === 419 ||
          error.response.status === 400
        ) {
          addToast(error.response.data, {
            appearance: "error",
            autoDismiss: true,
            autoHideDuration: 5000,
          });
        } else if (error.response.status === 404) {
          addToast(
            "We are not able to find associated email, please check and try again.",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        } else if (error.response.status === 409) {
          addToast(error.response.data, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  const fetchFlowSensor = () => {
    Api.get("/sensors/" + id)
      .then((res) => {
        if (res.status === 200) {
          setSensorData(res.data);
          setAlerts(getAlertMessages(res.data));
          setTimeout(() => {
            setValue("name", res.data.name);
            if (res.data.type == 12) {
              setType("12");
              setValue("splunk_hostname", res.data.splunk_hostname);
              setValue("splunk_port", res.data.splunk_port);
              setValue("splunk_token", res.data.splunk_token);
            } else if (res.data.type == 13) {
              setType("13");
              setValue("qradar_url", res.data.qradar_url);
              setValue("qradar_verify_ssl", res.data.qradar_verify_ssl);
              setValue("qradar_certificate", res.data.qradar_certificate);
              setValue("qradar_token", res.data.qradar_token);
            } else if (res.data.type == 14) {
              setType("14");
              setValue("sumologic_s3_bucket", res.data.sumologic_s3_bucket);
              setValue("sumologic_aws_region", res.data.sumologic_aws_region);
              setValue(
                "sumologic_aws_access_key",
                res.data.sumologic_aws_access_key
              );
              setValue(
                "sumologic_aws_access_secret",
                res.data.sumologic_aws_access_secret
              );
            } else if (res.data.type == 16) {
              setType("16");
              setValue("qradar_url", res.data.qradar_url);
              setValue("qradar_verify_ssl", res.data.qradar_verify_ssl);
              setValue("qradar_certificate", res.data.qradar_certificate);
              setValue("qradar_token", res.data.qradar_token);
            } else if (res.data.type == 15) {
              setType("15");
              setValue("splunk_hostname", res.data.splunk_hostname);
              setValue("splunk_port", res.data.splunk_port);
              setValue("splunk_token", res.data.splunk_token);
            }
            else if (res.data.type == 20) {
              setType("20");
              setValue("kafka_brokers", res.data.kafka_brokers);
            }
          });

          if (res.data.flow_sensors) {
            const initialFlowSensors = res.data.flow_sensors.map((fs) => ({
              key: fs._id,
              value: fs.name,
            }));
            setValue("flow_sensors", initialFlowSensors);
            setFlowSensor(initialFlowSensors);
            setAvailableFlowSensors((prevSensors) => {
              // Filter out initialFlowSensors already present in prevSensors
              const filteredInitialFlowSensors = initialFlowSensors.filter(
                (initialSensor) =>
                  !prevSensors.some(
                    (prevSensor) => prevSensor.key === initialSensor.key
                  )
              );
  
              return [...prevSensors, ...filteredInitialFlowSensors];
            });
          }
        }
      })
      .catch((error) => {});
  };

  const fetchFlowSensorsForSIEM = () => {
    const apiUrl =
      ["14", "15", "16"].includes(type)
        ? "/sensors/configuredfsforsiem?type=cloud"
        : "/sensors/configuredfsforsiem";

    Api.get(apiUrl)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            setAvailableFlowSensors(
              res.data.map((data) => ({ key: data._id, value: data.name }))
            );
          } else {
            if (res.data.length === 0) {
              setAlerts([createAlertMessage(`${MESSAGES[10]}`, "error", false)]);
            }
            setAvailableFlowSensors([]);
          }
          if (isEdit && id !== "") {
            fetchFlowSensor();
          }
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetchFlowSensorsForSIEM();
  }, [id]);

  useEffect(() => {
    fetchFlowSensorsForSIEM();
  }, []);

  const getItemLabel = (option) => {
    if (Array.isArray(option)) {
      return option[0]?.value;
    } else {
      return option?.value;
    }
  };


  const userDetails = localStorage.getItem("user");
  const user = JSON.parse(userDetails);
  const handleClose = () => {
    setShowPopUp(false);
    onCloseHandler();
  };

  const updatePopUpButtons = {
    primary: {
      label: "Ok",
      action: handleClose,
      className: "siem-configuration-ok_button"
    },
  };

  const getTestConnectionAlertMessages = (data) => {
    let res = [];
    
    if (data['error_message'] && data['error_message'] !== '') {
      if (data && Object.keys(data).length > 0) {
        if (res.length === 0) {
            res.push(
                createAlertMessage(
                    `${MESSAGES[11]} Expand to view specific issue details.`,
                    'error',
                    true
                )
            );
      }
      let tempAdditionalErrors = []
        Object.keys(data).forEach(item => {
          let isSecondary = item === 'User-Inputs';
          if (isSecondary) {
            tempAdditionalErrors[1] = createAlertMessage(
              `${data[item]}`,
              'error',
              false,
              isSecondary
            )
          } else { 
            tempAdditionalErrors[0] = createAlertMessage(
              `${data[item]}`,
              'error',
              false,
              isSecondary
            )            
          }
        })
      res = res.concat(tempAdditionalErrors)
    }
    } else { 
      res.push(
        createAlertMessage(
            `${MESSAGES[2]}`,
            'success',
            false
        )
    );

    }
    return res;
  };

  const getAlertMessages = (data) => {
    let res = [];
    if (
        moment
            .duration(moment().diff(moment(data.last_flow_time)))
            .asHours() < 2 && moment
                .duration(moment().diff(moment(data.fs_last_flow_time)))
                .asHours() < 2
    ) {
        res.push(
            createAlertMessage(
                `${MESSAGES[0]} ${formatDate(data.last_flow_time)}`,
                'success',
                false
            )
        );
    } else {
        if (data.last_flow_time && moment(data.last_flow_time).isAfter("2021-12-31T01:14:00Z")) {
            res.push(
                createAlertMessage(
                    `${MESSAGES[1]} ${formatDate(
                        data.last_flow_time
                    )} Expand to view specific issue details.`,
                    'error',
                    true
                )
            );

            res.push(
                createAlertMessage(
                    `${MESSAGES[4]} ${formatDate(data.last_flow_time)}`,
                    'error',
                    false
                )
            );
        } else {
            res.push(
                createAlertMessage(
                    `${MESSAGES[11]} Expand to view specific issue details.`,
                    'error',
                    true
                )
            );
            res.push(
                createAlertMessage(
                    `${MESSAGES[5]}`,
                    'error',
                    false
                )
            );
        }
    }

    const additionalErrors = sensorSettingsErrors(data);
    if (data.sensor_settings && Object.keys(data.sensor_settings).length > 0) {
        if (res.length === 0) {
            res.push(
                createAlertMessage(
                    `${MESSAGES[11]} Expand to view specific issue details.`,
                    'error',
                    true
                )
            );
      }
      let tempAdditionalErrors = []
        Object.keys(data.sensor_settings).forEach(item => {
          let isSecondary = item === 'User-Inputs';
          if (isSecondary) {
            tempAdditionalErrors[1] = createAlertMessage(
              `${data.sensor_settings[item]}`,
              'error',
              false,
              isSecondary
            )
          } else { 
            tempAdditionalErrors[0] = createAlertMessage(
              `${data.sensor_settings[item]}`,
              'error',
              false,
              isSecondary
            )            
          }
        })
      res = res.concat(tempAdditionalErrors)
    }
    return res;
  };
  
  const createAlertMessage = (text, type, isSummary, isSecondary) => {
    return { type, isSummary, text, isSecondary };
  };

  const sensorSettingsErrors = (data) => {
      let res = [];
      if (data.sensor_settings) {
          res = Object.keys(data.sensor_settings).map((sensor_setting: any, idx: number) =>
              `${sensor_setting} - ${data.sensor_settings[sensor_setting]}`
          )
      }
      return res;
  }


  const SIEMPopUp = ({ popUpButtons, vendor }) => {
    const handleRadarClick = (vendor) => {
      history.push(
        `deploy/supportedCollector?category=${encodeURIComponent(vendor)}`
      );
      setShowPopUp(false);
      onCloseHandler();
    };
    return (
      <AMAdminPopUp
        popUpTitle={"SIEM Configuration Saved"}
        popUpButtons={popUpButtons}
        handleClose={handleClose}
      >
        <form>
          <div className="font14">
          To complete the setup, select and configure one or more collectors. To view collectors click
            <a
              className="link-text"
              onClick={() => handleRadarClick(vendor)}
              style={{ paddingLeft: "10px" }}
            >
              View {vendor} Collectors
            </a>
          </div>
        </form>
      </AMAdminPopUp>
    );
  };

  return (
    <>
      <form
        className="sensor-form-container"
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="form-body-div scrollbar-container siem-configuration-container">
        {alerts.length > 0 && <AlertComponent messages={alerts} />}
          <div className="sensor-flex-container">
            <div className="sensor-flex-container-item">
              <label>Name*</label>
              <input
                type="text"
                placeholder="Enter Sensor name"
                name="name"
                ref={register({
                  required: true,
                  pattern: /^[^'"]*$/,
                  shouldUnregister: true,
                })}
                className={"flex-basis-100"}
                autoComplete="off"
              />
            </div>

            <div className="sensor-flex-container-item">
              {["12", "13","20"].includes(type) && (
                <>
                  <label className="paddingright20 marginbottom10">
                    Flow Sensor
                  </label>
                  <div style={{ clear: "both" }}>
                    <Controller
                      name="flow_sensors"
                      control={control}
                      defaultValue={
                        sensorData
                          ? sensorData?.flow_sensors.map((fs) => ({
                              key: fs._id,
                              value: fs.name,
                            }))
                          : []
                      }
                      render={({ onChange, value = "" }) => (
                        <CheckboxAutocomplete
                          label="Select options"
                          options={availableFlowSensors}
                          value={value}
                          onChange={(event, newValue) => onChange(newValue)}
                          getItemLabel={getItemLabel}
                          multiple={true}
                          name="flow_sensors"
                          formType="edit"
                        />
                      )}
                    />
                  </div>
                </>
              )}
            </div>
          </div>

          {getSiemComponent(type)}
        </div>
        <AMModalError errors={errors} errorMap={SensorErrors} />
        <div className="sensor-footer-container">
          <div className="launch_button">
            <button
              type="button"
              onClick={onCloseHandler}
              className={"float_left stepper-action-btn button_grey"}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                if (isLoaded)
                  onSave(false)
              }}
              type="button"
              className={"float_right stepper-action-btn button_styled"}
            >
            {isLoaded ? <>
              {isEdit ? ["12", "13","20"].includes(type) ? 'Save' : 'Test and Save' :  ["12", "13" ,"20"].includes(type) ? 'Add': 'Test and Add'}</> : (<div className='loader-spinner'></div>) }              
            </button>

          </div>
        </div>
      </form>
      {showPopUp && (
        <SIEMPopUp
          popUpButtons={updatePopUpButtons}
          vendor={
            type === "14" ? "Collect Via Sumologic" : ["12", "15"].includes(type) ? "Collect via Splunk" : ["20"].includes(type) ? "Collect via Kafka": "Collect via IBM Qradar"
          }
        />
      )}
    </>
  );
};
