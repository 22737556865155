import React, { useState, useEffect, useMemo, useRef } from "react";
import Popup from "reactjs-popup";
import { TopIssuesObj } from "../../types/response-types";
import moment from "moment";
import { useHistory } from "react-router";
import { AxiosError } from "axios";
import { Api } from "../Axios";
import { useToasts } from "react-toast-notifications";
import { getRisk } from "../../utils/risk-level";
import { getRiskLevelColor, getRoundedUnit, getRiskTitleByIndex } from "../../utils/util-methods";
import { Tooltip } from "@mui/material";
import { DashboardRuleMap } from "../../constants/dashboard-rule";
import "./TopRecentIssues.css";
import "./TopIssues.css";
import { useDashboardStateContext } from "../../store/DashboardStateContextProvider";
import { getIssueNameById } from "../../pages/playbooks/helpers/playbook-helper";

type Props = {
    top_issues_prop: TopIssuesObj[] | any;
    isRecentAccessEnabled: boolean;
};

const TopIssues = ({ top_issues_prop, isRecentAccessEnabled }: Props) => {
    let history = useHistory();
    const isEnabled = isRecentAccessEnabled ? 1 : 3;
    const [toggleDropdown, setToggleDropdown] = useState(false);
    //const [selectedListType,setSelectedListType] = useState('top new risky entities (30d)');
    const { TopRiskyEntitiesState: { selectedOption: selectedListType, setSelectedOption: setSelectedListType } } = useDashboardStateContext();
    const [recentIssues, setRecentIssues] = useState<any | null | undefined>(null);
    const minuteIntervalId = useRef<any>();
    const handleRedirect = (ruleType: any, assetName: any) => {
        const breadcrumb = { breadcrumbId: "TopNewRiskyPosture" };
        assetName = encodeURIComponent(assetName);
        if (ruleType === "Identity") history.push(`/posture/identities?order_by=desc&page=1&q=identity_name%3A${assetName}&sort_by=flow_count`, breadcrumb);
        else if (ruleType === "Directory") history.push(`/posture/directory?order_by=desc&page=1&q=dir_name%3A${assetName}&sort_by=flow_count`, breadcrumb);
        else if (ruleType === "Access") history.push(`/posture/accesses?order_by=desc&page=1&q=asset_name%3A${assetName}&sort_by=flow_count`, breadcrumb);
        else history.push(`/posture/assets?order_by=desc&page=1&q=asset_name%3A${assetName}&sort_by=flow_count`, breadcrumb);
    };

    const getIconClassOnType = (idnType = "") => {
        switch (idnType) {
            case "Service":
                return "gear_icon_dashboard";
            case "Device":
                return "device_icon_dashboard";
            case "User":
                return "user_icon_dashboard";
            default:
                return "settings_icon_dashboard";
        }
    };


    const  getItemIconClass = (idnType)=> {
        switch (idnType) {
        case "Device":
            return "device_dash_icon";
          case "App":
            return "device_dash_icon";
          case "Service":
            return "gear_dash_icon_issue";
          case "Service/Application":
            return "service_application_account_dash_icon";
          case "Service/Computer Account":
            return "service_computer_account_dash_icon";
          case "Service/Service Account":
            return "service_service_account_dash_icon";
          case "Service/Service Principal":
            return "service_service_principal_dash_icon";
        case "Service/Key and Secret":
            return "service_key_secret_dash_icon";
		  case "Service/Token":
            return "service_service_token_dash_icon";
        case "User/Agent":
            return "user_agent_dash_icon";
        case "User/Background Browsing":
            return "user_browsing_dash_icon";
        case "User":
            return "user_interactive_dash_icon";
          default:
            // If identity_category_list exists, return 'settings_icon_cloud', otherwise 'settings_icon'
            return  'settings_icon_dash';
        }
      }

    const handleIncidentColumnClick = (item: any, e: any) => {
        e.stopPropagation();
        let breadCrumbLabelText = '';
        let breadCrumbHeading = '';
        let momentFrom: number | undefined;
        let momentTo: number | undefined;

        const _from = new Date();
        const _to = new Date();
        if (selectedListType === 'top new risky entities (7d)' || selectedListType === 'top new risky entities (30d)') {
            const _fromTs = selectedListType === 'top new risky entities (7d)' ? _from.setDate(_from.getDate() - 7) : _from.setDate(_from.getDate() - 30);
            const _toTs = _to.setMinutes(_to.getMinutes());
            momentFrom = moment.utc(new Date(_fromTs)).unix();
            momentTo = moment.utc(new Date(_toTs)).unix();
        }

        switch (selectedListType) {
            case 'top new risky entities (7d)':
                breadCrumbLabelText = 'in last 7 days';
                breadCrumbHeading = 'Top New Risky Entities';
                break;
            case 'top new risky entities (30d)':
                breadCrumbLabelText = 'in last 30 days';
                breadCrumbHeading = 'Top New Risky Entities';
                break;
            default:
                breadCrumbLabelText = '';
                breadCrumbHeading = 'Top Risky Entities';
        }

        const bread = { breadcrumbId: 'TopNewRiskyIncidents', customData: { ...item, title: breadCrumbLabelText, heading: breadCrumbHeading } };
        const name = encodeURIComponent(item.name);

        const dateFilter = momentFrom !== undefined && momentTo !== undefined ? `%2Blast_flow_time%7C${momentFrom}%2C${momentTo}` : '';

        const baseUrl = `issues?disable_filter=true&page=1&q=`;
        const query = `${name}%2Bstatus:Open%2Brule_name:${item.incident_names}${dateFilter}&sort_by=issue_flows_count&order_by=desc`;

        if (item.entity_Name === "Identity")
            history.push(`${baseUrl}s_name:${query}`, bread);
        else if (item.entity_Name === "Directory")
            history.push(`${baseUrl}dir_name:${query}`, bread);
        else if (item.entity_Name === "Asset")
            history.push(`${baseUrl}d_name:${query}`, bread);
        else
            history.push(`${baseUrl}d_name:${query}`, bread);
    };


    const renderRiskScore = ({ _id, score }: any) => {
        const value = score < 0 ? '0.00' : score?.toFixed(2)?.toString() || '0.00'
        let bar = null;
        if (Math.round(score) >= 80) {
            bar = <div className="risk-score"><span
                title={getRiskTitleByIndex(4)}
                style={{ color: getRiskLevelColor(4) }}>&#10074;</span>{value}</div>
        }
        else if (Math.round(score) >= 60) {
            bar = <div className="risk-score"><span
                title={getRiskTitleByIndex(3)}
                style={{ color: getRiskLevelColor(3) }}>&#10074;</span>{value}</div>
        }
        else if (Math.round(score) >= 40) {
            bar = <div className="risk-score"><span
                title={getRiskTitleByIndex(2)}
                style={{ color: getRiskLevelColor(2) }}>&#10074;</span>{value}</div>
        }
        else if (Math.round(score) >= 1) {
            bar = <div className="risk-score"><span
                title={getRiskTitleByIndex(1)}
                style={{ color: getRiskLevelColor(1) }}>&#10074;</span>{value}</div>
        }
        else {
            bar = <span className="risk-score-0">&#9711;</span>
        }
        return bar;
    };

    const renderIssuesTooltip = (item: any) => {
        item?.incident_names.replaceAll('Suspected Attack on Expired AD Account', 'Suspected Attack on Expired AD Account/Password')
        return item?.incident_names.split(",").map((i) => getIssueNameById(i)).join("\n");
    }
    const setEntitiesTitle = (value, e) => {
        setSelectedListType(value);
        setToggleDropdown(false);

        clearInterval(minuteIntervalId.current);
        let params = '';
        switch (value) {
            case 'top new risky entities (7d)': params = '?last_days=7'; break;
            case 'top new risky entities (30d)': params = '?last_days=30'; break;
            case 'top risky entities': break;
            default: params = '?last_days=30'
        }

        Api.get('/dashboard/topnewissues' + params).
            then((res: any) => {
                setRecentIssues(res.data);
            }).catch((err: any) => console.log(err));

        minuteIntervalId.current = setInterval(() => {
            Api.get('/dashboard/topnewissues' + params).
                then((res: any) => {
                    setRecentIssues(res.data);
                }).catch((err: any) => console.log(err));
        }, 60000);

    }

    useEffect(() => {
        let params = '';
        switch (selectedListType) {
            case 'top new risky entities (7d)': params = '?last_days=7'; break;
            case 'top new risky entities (30d)': params = '?last_days=30'; break;
            case 'top risky entities': break;
            default: params = '?last_days=30'
        }

        Api.get('/dashboard/topnewissues' + params).
            then((res: any) => {
                setRecentIssues(res.data);
            }).catch((err: any) => console.log(err));

        minuteIntervalId.current = setInterval(() => {
            Api.get('/dashboard/topnewissues' + params).
                then((res: any) => {
                    setRecentIssues(res.data);
                }).catch((err: any) => console.log(err));
        }, 60000);

        return () => clearInterval(minuteIntervalId.current); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])

    const getIssueNameByType = (item) => {
        item?.incident_names.replaceAll('Suspected Attack on Expired AD Account', 'Suspected Attack on Expired AD Account/Password')
        const issues = item?.incident_names.split(",").map((i) => getIssueNameById(i)).join(",");
        return issues
    }

    const generateTitle = (item)=> {
        if (!item) return ''; // Handle cases where item is null or undefined
    
        const { entity_Name = '', type = '' } = item;


    
        // Replace the required substrings in the type
        let  formattedType = type
            .replace('Service/Application', 'Application')
            .replace('Service/Service Account', 'Service');

            if(entity_Name ==='Identity' &&  !formattedType.startsWith('User')){
                formattedType = 'NHI-' + formattedType;
            }
            


    
        // Concatenate the entity name and formatted type
        return `${entity_Name} - ${formattedType}`;
    }


    const renderGrid = useMemo(() => {
        const data = (selectedListType === 'top risky entities') ? top_issues_prop : recentIssues;

        return data?.map((item: any) => {
            return (
                <>
                    <div className={`tigc-r-data-col ${getItemIconClass(item?.type)}`} title={generateTitle(item)}></div>
                    <div className="tigc-r-data-col redirect-link" onClick={() => handleRedirect(item.entity_Name, item.name)} title={item?.name}>
                        {item?.name}
                    </div>
                    <div className="tigc-r-data-col">
                        <span title={renderIssuesTooltip(item)}>{getIssueNameByType(item)}</span>
                        <span>{(item?.incident_names.split(',') || []).length > 3 ? `(+${item?.incident_names.split(',').length - 3})` : ``}</span>

                    </div>
                    <div onClick={handleIncidentColumnClick.bind(this, item)} className="tigc-r-data-col risk-incident-link">{getRoundedUnit(item?.issue_count)}</div>
                    <div className="tigc-r-data-col">{renderRiskScore(item)}</div>
                </>
            );
        });
    }, [selectedListType, recentIssues, top_issues_prop]);

    return (
        <>
            <div className="top-issues-title-dropdown">
                {!toggleDropdown ? <span className="issue-type-arrow" onClick={() => setToggleDropdown(true)}></span> : <span className="issue-type-arrow" onClick={() => setToggleDropdown(false)}></span>}
                {selectedListType}
                {toggleDropdown &&
                    (<ul className="issues-list-type-content" onMouseLeave={() => setToggleDropdown(false)}>
                        <li><span onClick={setEntitiesTitle.bind(this, 'top risky entities')}>top risky entities</span></li>
                        <li ><span onClick={setEntitiesTitle.bind(this, 'top new risky entities (7d)')}>top new risky entities (7d)</span></li>
                        <li ><span onClick={setEntitiesTitle.bind(this, 'top new risky entities (30d)')}>top new risky entities (30d)</span></li>
                    </ul>)
                }
            </div>
            {/* <div className="top-issues-title-dropdown">Top Recent Issues
                <span>&#9662;</span>
            </div> */}
            <div className="top-issues-grid-container">
                <div className="tigc-r-header-col">Type</div>
                <div className="tigc-r-header-col">Name</div>
                <div className="tigc-r-header-col">Issues</div>
                <div className="tigc-r-header-col">Incidents</div>
                <div className="tigc-r-header-col">Score</div>
                {renderGrid}
            </div>
            {(!top_issues_prop || top_issues_prop?.length == 0) && <div className="no-issues-data">No issues available</div>}
        </>
    );
};

export default React.memo(TopIssues);
