import React from "react";
import AMTabs, { TabData } from "../../components/core/AMTab/AMTab";
import Collectors from "./collectors";
import Sensors from "./sensor";
import { Box } from "@mui/material";
import SupportedCollector from "./supportedCollector";
import ManageCollector from "./manageCollector";
import './eod.scss'

const EOD: React.FC = () => {
  const tabs: TabData[] = [
    {
      label: "DEPLOYMENT SUMMARY",
      Component: <ManageCollector></ManageCollector>,
      route: "/deploy/manageCollector",
      selected: false,
      title: "Deployment Summary"
    },
    {
      label: "COLLECTOR STATUS",
      Component: <Collectors></Collectors>,
      route: "/deploy/collector",
      selected: false,
      title: "Collector Status",
    },
    {
      label: "MANAGE SENSORS & SIEM",
      Component: <Sensors></Sensors>,
      route: "/deploy/sensor",
      selected: false,
      title: "Manage Sensors & Siem",
    },
    {
      label: "SUPPORTED COLLECTORS & SIEM",
      Component: <SupportedCollector></SupportedCollector>,
      route: "/deploy/supportedCollector",
      selected: false,
      title: "Supported Collectors & Siem",
    },
  ];

  const tabClick = (val: string) => {
    console.log(val)
    sessionStorage.removeItem('sensor');
  }

  return (
    <>
      <Box style={{ marginTop: '20px' }}>
        <AMTabs tabs={tabs} onTabClick={tabClick} />
      </Box>
    </>
  );
};

export default EOD;