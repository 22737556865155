import { Fade, Tooltip, imageListClasses } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import './AMAdminTable.css'
import AMTableEmpty from "./AMAdminTableEmpty";

export interface ITableColumn<T> {
    key: string,
    headerText: string,
    headerHtml?(): any,
    styles?: React.CSSProperties,
    classes?: any,
    html?(data: T): any,
    sortable?: boolean
}

export interface ITableResult<T> {
    result: T[];
    rpp?: number;
    sort_by?: string;
    order_by?: string;
    page?: number;
    total?: number;
}

interface ITableProps<T> {
    data: ITableResult<T>;
    refresh?: () => any;
    pagination?: () => any;
    sort?: () => any;
    columns: ITableColumn<T>[];
    query: any;
    setQuery: any;
    actions?: ITableAction<T>[];
    showEmptyTable?: boolean; // Added prop to control showing empty table
    handleButtonClick: () => void; // Added prop to handle button click
    imageClasses: string[]; // Added prop for image classes
    title: string;
    subTitle: string;
    showPagination?: boolean;
    tableIndex?: number,
    isQueryBasedRefresh?: boolean,
    preSortingHandler?(p: any, tableIndex: number): void
}

export interface ITableAction<T> {
    actionId: string,
    actionLabel: string,
    actionCallback?: (arg0: T, e: React.MouseEvent<HTMLElement>) => any
    shouldDisplay?: (item: T) => boolean
}

export interface ITableParams {
    rpp?: number;
    sort_by?: string;
    order_by?: string;
    q?: string;
    page?: number;
    tableIndex?: number
}

export interface ITableReference {
    onSearch: (data: any) => any,
    onSetTableError: (msg: string) => void
}

const AMAdminTable = <T extends unknown>() => React.forwardRef(({
    tableIndex = 0, data, columns, setQuery, query, actions, refresh, showEmptyTable = false, // Default value set to false
    handleButtonClick, isQueryBasedRefresh = false, preSortingHandler,
    imageClasses, title, subTitle, showPagination = true }: ITableProps<T>, ref: any) => {

    React.useImperativeHandle(ref, () => ({
        onSearch(data: any) {
            return onSearch(data);
        },
        onSetTableError(msg: string) {
            return setTableErorr(msg);
        }
    } as ITableReference));

    const { q, page: page_number, rpp: record_per_page, order_by: order, sort_by: sort, tableIndex: tabIndx = 0 } = query;

    const [tableError, setTableErorr] = useState<string | unknown>('No records found');

    const [selectedColumn, setSelectedColumn] = useState((query.sort_by || ''));
    const [currentSort, setCurrentSort] = useState((query.order_by === 'asc' ?
        ' tablesort_up tablesort_up_selected ' : ' tablesort_down tablesort_down_selected '));

    const highlightSortDir = () => {
        if (tabIndx === tableIndex && query.order_by === "asc") {
            setCurrentSort(" tablesort_up tablesort_up_selected ")
        } else if (tabIndx === tableIndex && query.order_by === "desc") {
            setCurrentSort(" tablesort_down tablesort_down_selected ")
        } else {
            setCurrentSort(" tablesort_down ")
        }
    }

    useEffect(() => {
        highlightSortDir();
    }, [query])

    let p: ITableParams = {
        q: q,
        page: page_number,
        rpp: record_per_page || 20,
        order_by: order,
        sort_by: sort,
        tableIndex: tableIndex || 0
    }

    const LoadSorting = (field: string) => {
        if (preSortingHandler) {
            preSortingHandler(p, tableIndex);
        }
        if (p.sort_by === field) {
            p.order_by = p.order_by === 'asc' ? 'desc' : 'asc'
            setQuery(p);
            onRefresh();
        } else {
            p.sort_by = field
            p.order_by = 'desc'
            setQuery(p)
        }
    };

    const onHeaderClick = (e: any, column: ITableColumn<T>) => {
        if (column.sortable) {
            LoadSorting(column.key);
            setSelectedColumn(column.key);
        } else {
            e.preventDefault();
        }
    }

    const Paginate = (selectedPage: any) => {
        p.page = selectedPage.selected === 0 ? 1 : selectedPage.selected + 1
        setQuery(p)
    }

    const onSearch = (data: any) => {
        p.q = data.query;
        p.page = 1
        setQuery(p)
    }

    const onRefresh = () => {
        if (refresh && !isQueryBasedRefresh)
            refresh();
    }

    return <>
        <table className="tablesorter sticky_table_top admin-page am-admin-table">
            <thead>
                <tr>
                    {
                        columns.map((column: ITableColumn<T>) => {
                            return <th
                                onClick={(e) => onHeaderClick(e, column)}
                                className={column.classes +
                                    (column.sortable ?
                                        ((selectedColumn === column.key) ? currentSort : " tablesort_down") : '')
                                }
                                style={column.styles} key={column.headerText} > {column.headerHtml ? column.headerHtml() : column.headerText}
                            </th>
                        })
                    }
                    {
                        actions && actions?.length > 0 ?
                            <th className="am-admin-action-header">Action</th> : null
                    }
                </tr>
            </thead>
            <tbody className="am-admin-table-body-container">
                {
                    data?.result && data.result.map((item: T, ind: number) => {
                        return <tr key={`row-data-${ind}`}>
                            {
                                columns.map((column: ITableColumn<T>, index: number) => {
                                    return <td
                                        key={`${column.key}-col-data-${index}`}
                                        className={column.classes}
                                        style={column.styles}
                                    >{
                                            column.html ? column.html(item) :
                                                item[column.key as keyof typeof item]
                                        }</td>
                                })
                            }
                            {
                                actions && actions?.length > 0 ?
                                    <td className="am-admin-table-action-column vert_middle">
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 0 }}
                                            classes={{ tooltip: 'am-admin-action-content' }}
                                            style={{ display: 'block' }}
                                            PopperProps={{ style: { zIndex: 999 } }}
                                            title={<div >
                                                <ul>
                                                    {
                                                        actions.filter(action => action.shouldDisplay ? action.shouldDisplay(item) : true)
                                                            .map((i: ITableAction<T>, ind: number) => {
                                                                return <li key={`${i.actionId}-${ind}`}
                                                                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                                                                        if (i.actionCallback)
                                                                            i?.actionCallback(item, e)
                                                                    }}>
                                                                    <Link to="#" onClick={(e) => e.preventDefault()}>{i.actionLabel}</Link>
                                                                </li>
                                                            })

                                                    }
                                                </ul>
                                            </div>}>
                                            <button className="action_button"></button>
                                        </Tooltip>
                                    </td> : null
                            }
                        </tr>
                    }
                    )
                }
                {
                    !data || !data.result ?
                        <tr>
                            <td colSpan={18}>Processing</td>
                        </tr> : null
                }
                {
                    data && data.result.length == 0 ? showEmptyTable ?
                        <tr>
                            <td colSpan={18} style={{ padding: 0 }}> <AMTableEmpty title={title} buttonClick={handleButtonClick} imageClasses={imageClasses} subTitle={subTitle} /></td>
                        </tr>
                        :
                        <tr>
                            <td colSpan={18} >{tableError}</td>
                        </tr> : null

                }
            </tbody>
        </table>
        {data && showPagination && (data?.total || 0) > 0 &&
            <ReactPaginate
                previousLabel={"← Previous"}
                nextLabel={"Next →"}
                pageCount={(Math.ceil((data.total || 0) / (data.rpp || 20)))}
                onPageChange={Paginate}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
            />
        }
    </>
})

export default AMAdminTable();