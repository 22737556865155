import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
	withQueryParams,
	StringParam,
	NumberParam,
	ArrayParam,
	withDefault,
} from 'use-query-params'
import { useHistory, Link, useLocation } from "react-router-dom"
import { PanelType } from 'react-sliding-side-panel'
import 'react-sliding-side-panel/lib/index.css'
import { Api } from '../../components/Axios'
import { AddPanel } from './sub'
import ReactPaginate from 'react-paginate'
import { useToasts } from '../../components/core'
import { ActionMenu } from '../../theme/ActionMenu'
import moment from 'moment'
import { useAuthDetails } from '../../components/Authorization'
import ModalPopup from '../../components/core/AMModalPopup/ModalPopup'
import { ExceptionRulesSummary } from './ref/ExceptionRulesSummary'
import { useExceptionAdvanceSearch, useExceptionService } from './ref/useExceptions'
import { IPatternFormValue } from '../playbooks/ref/PatternForm/PatternFormGenerator'
import { IAMSelectOption } from '../../components/core/AMSelect/AMSelect'
import { ActivatePopUp, DeletePopUp } from './ref/TransportProtocolPopUp';
import WarningIcon from '@mui/icons-material/Warning';
import { GLOBAL_SERVICE } from '../services/GlobalService'
import { getCategories } from '../../utils/util-methods'
import { getIssueNameById } from '../playbooks/helpers/playbook-helper'
// import './listing.css'

type Search = {
	query?: string;
};

type Listing = {
	_id: string;
	company_id: string;
	user_id: string;
	name: string;
	all_rules_enables: boolean;
	rule_names: string[];
	src_ips: string[];
	src_port: number;
	src_hostnames: string[];
	src_type: string;
	dst_ips: string[];
	dst_port: number;
	dst_hostnames: string[];
	dst_type: string;
	protocols: string[];
	sensor_locations: string[];
	dropped_flows: number;
};

type Result = {
	total: number;
	result: Listing[];
	rpp: number;
	sort_by: string;
	order_by: string;
};

type ActionType = {
	type?: string;
	id?: string;
	status?: string;
};

interface Params {
	rpp?: number;
	sort_by?: string;
	order_by?: string;
	q?: string;
	page?: number;
}

interface DeletePopUpDataState {
	data?: Listing;
	show: boolean;
}

const Groups = ({ query, setQuery }: any) => {
	const { register, handleSubmit } = useForm<Search>();
	let history = useHistory();
	const { authDetails } = useAuthDetails()!;
	const {
		q: q,
		page: page_number,
		rpp: record_per_page,
		order_by: order,
		sort_by: sort
	} = query;
	const [actionType, setActionType] = useState<ActionType>({
		id: '',
		type: '',
		status: ''
	});
	const [openPanel, setOpenPanel] = React.useState<boolean>(false);
	const [panelType, setPanelType] = React.useState<PanelType>('right');
	const [panelSize, setPanelSize] = React.useState<number>(30);
	const [panelTitle, setPanelTitle] = useState<string>('');
	const [result, setResult] = useState<Result>();
	const { addToast } = useToasts();
	const location = useLocation();
	const [showSummary, setShowSummary] = useState<Array<boolean>>([]);
	const { transformValues, clearExceptionDataStore, getConfigItemByName } =
		useExceptionAdvanceSearch();
	const { updateCategoryCritieria } = useExceptionService();
	const [showIssueTypes, setShowIssueTypes] = useState<number | undefined>();
	const [showDeletePopUp, setShowDeletePopUp] =
		useState<DeletePopUpDataState>({ data: undefined, show: false });
	let p: Params = {
		q: q,
		page: page_number,
		rpp: record_per_page || 100,
		order_by: order,
		sort_by: sort
	};

	const [nameSortDir, setNameSortDir] = useState<string | undefined>(
		'tablesort_down'
	);
	const [droppedFlows, setDroppedFlows] = useState<string | undefined>(
		'tablesort_down'
	);
	const [lastModifiedBy, setLastModifiedBy] = useState<string | undefined>(
		'tablesort_down'
	);
	const [lastModifiedAt, setLastModifiedAt] = useState<string | undefined>(
		'tablesort_down'
	);
	const [statusSort, setStatusSort] = useState<string | undefined>(
		'tablesort_down'
	);
	const [showActivatePopUp, setShowActivatePopUp] = useState(false);

	const highlightSortDir = () => {
		if (query.sort_by === 'name' && query.order_by === 'asc') {
			setNameSortDir('tablesort_up_selected');
		} else if (query.sort_by === 'name' && query.order_by === 'desc') {
			setNameSortDir('tablesort_down_selected');
		} else if (
			query.sort_by === 'dropped_flows' &&
			query.order_by === 'asc'
		) {
			setDroppedFlows('tablesort_up_selected');
		} else if (
			query.sort_by === 'dropped_flows' &&
			query.order_by === 'desc'
		) {
			setDroppedFlows('tablesort_down_selected');
		} else if (
			query.sort_by === 'modified_by' &&
			query.order_by === 'asc'
		) {
			setLastModifiedBy('tablesort_up_selected');
		} else if (
			query.sort_by === 'modified_by' &&
			query.order_by === 'desc'
		) {
			setLastModifiedBy('tablesort_down_selected');
		} else if (
			query.sort_by === 'modified_date' &&
			query.order_by === 'asc'
		) {
			setLastModifiedAt('tablesort_up_selected');
		} else if (
			query.sort_by === 'modified_date' &&
			query.order_by === 'desc'
		) {
			setLastModifiedAt('tablesort_down_selected');
		} else if (query.sort_by === 'status' && query.order_by === 'asc') {
			setStatusSort('tablesort_up_selected');
		} else if (query.sort_by === 'status' && query.order_by === 'desc') {
			setStatusSort('tablesort_down_selected');
		}
	};

	useEffect(() => {
		if (history.location.pathname == '/exceptions/add') {
			setActionType({ type: 'add' });
			history.replace({
				pathname: '/exceptions'
			});
		} else {
			localStorage.removeItem('addException');
		}
	}, []);

	useEffect(() => {
		highlightSortDir();
	}, [query]);

	useEffect(() => {
		Listing();
	}, [query]);

	useEffect(() => {
		switch (actionType?.type) {
			case 'add':
				setPanelTitle('Add Exception');
				setOpenPanel(true);
				return;

			case 'copy':
				setPanelTitle('Add Exception');
				setOpenPanel(true);
				return;

			case 'edit':
				setPanelTitle('Edit Exception');
				setOpenPanel(true);
				return;

			case 'delete':
				const d = result?.result?.filter(
					(i: Listing) => i._id == actionType.id
				)[0] as Listing;
				setShowDeletePopUp({ show: true, data: d });
				return;
			case 'enable':
				setShowActivatePopUp(true);
				return;
			default:
				return;
		}
	}, [actionType]);

	const onSubmit = (data: any) => {
		p.q = data.query;
		p.page = 1;
		setQuery(p);
	};

	const renderPopup = () => {
		return (
			<ModalPopup
				classNames='add-exception-form-pop-container exception-popup'
				onCloseHandler={() => setOpenPanel(false)}
				title={panelTitle}
				isOpen={openPanel}
			>
				{actionType?.type === 'add' && (
					<AddPanel
						AfterExceptionsOperation={AfterExceptionsOperation}
						formType='create'
					/>
				)}

				{actionType?.type === 'copy' && (
					<AddPanel
						AfterExceptionsOperation={AfterExceptionsOperation}
						formType='edit'
						id={actionType.id}
						actionType='copy'
					/>
				)}

				{actionType?.type === 'edit' && (
					<AddPanel
						id={actionType.id}
						AfterExceptionsOperation={AfterExceptionsOperation}
						formType='edit'
					/>
				)}
			</ModalPopup>
		);
	};

	const tranformCategory = (data: any) => {
		if (data?.result.length > 0) {
			data.result.forEach((i: any) => {
				updateCategoryCritieria(i);
			});
		}
	};

	const Listing = async () => {
		const category = getCategories();
		if (!category) {
			await GLOBAL_SERVICE.Category.GET();
		}
		Api.get('/exception', {
			params: p
		})
			.then((res: { data: any }) => {
				tranformCategory(res.data);
				setResult(res.data);
			})
			.catch((error: any) => {
				if (error.response.status === 500) {
					addToast('Sorry, something went wrong there, try again.', {
						appearance: 'error',
						autoDismiss: true
					});
				} else if (error.response.status === 419) {
					addToast(
						'We encounted validation problem, please correct and try again.',
						{
							appearance: 'error',
							autoDismiss: true
						}
					);
				} else if (error.response.status === 404) {
					addToast(
						'We are not able to find exceptions, please check and try again.',
						{
							appearance: 'error',
							autoDismiss: true
						}
					);
				}
			});
	};

	const Action = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		setActionType({
			id: e.currentTarget.id,
			type: e.currentTarget.dataset.action,
			status: e.currentTarget.dataset.status
		});
		e.preventDefault();
	};

	const Paginate = (selectedPage: any) => {
		p.page = selectedPage.selected === 0 ? 1 : selectedPage.selected + 1;
		setQuery(p);
	};

	const AfterExceptionsOperation = (action: string) => {
		setOpenPanel(false);
		if (action === 'add') {
			p.page = 1;
			p.q = '';
			setQuery(p);
			Listing();
		} else {
			Listing();
		}
		localStorage.removeItem('addException');
	};

	const LoadSorting = (field: string) => {
		if (p.sort_by === field) {
			p.order_by = p.order_by === 'asc' ? 'desc' : 'asc';
			setQuery(p);
		} else {
			p.sort_by = field;
			p.order_by = 'desc';
			setQuery(p);
		}
	};

	const SortBy = (name: string) => {
		if (p.sort_by === name) {
			return p.order_by === 'desc' ? 'tablesort_down' : 'tablesort_up';
		}
	};

	// const Sorting = (name: string, field: string) => {
	//     const fl = name.toLowerCase()
	//     return (
	//         <Link to="#" data-field={field} onClick={LoadSorting}>
	//             <div className={SortBy(field)}>{name}</div>
	//         </Link>
	//     )
	// }

	const handleFlowClick = (e: React.MouseEvent<HTMLDivElement>) => {
		let idx = e.currentTarget.id.toString();
		history.push(
			'/issues?disable_filter=true&order_by=desc&page=1&q=exception_id:' +
			idx
		);
	};

	const renderValue = (i: IPatternFormValue) => {
		const c = getConfigItemByName(i.searchKey);
		let val = i.value;
		if (c?.type == 'list') {
			const v = c.selectOptions?.find(
				(k: IAMSelectOption) => k.optionValue == i.value
			);
			if (v) {
				val = v?.optionLabel;
			}
		}

		if (c.key === 's_type') {
			val = Array.isArray(val)
				? val.map((item) => 
					typeof item === 'string' && !item.startsWith('User')
						? `NHI-${item}`
						: item
				  )
				: val?.value && typeof val === 'string' && !val.startsWith('User')
					? `NHI-${val}`
					: val;
		}
		return (
			<>
				{i.searchKey !== 'flow_time'
					? (val?.split ? val?.split(',') : val)?.join(', ')
					: `Details can't be viewed`}
			</>
		);
	};

	

	const SummaryCell = ({ index, criteria, data }: any) => {
		const searchFilter = [] as any;
		if (criteria) {
			criteria.forEach((i: any) => {
				searchFilter.push(transformValues(i));
			});
		}
		return (
			<>
				<div className='summary-cell'>
					<div>
						<span>Rule 1 of {searchFilter?.length || 0}</span>
						{searchFilter?.length > 0 &&
							searchFilter[0]
								.slice(0, 3)
								.map((i: IPatternFormValue, ind: number) => {
									if (ind > 1) {
										return <><li style={{ fontWeight: 'bold' }}> {(searchFilter[0].length - 2)} more...</li></>;
									}
									return (
										<li className='ellipsis_exception_criteria'>
											<strong>
												{getConfigItemByName(
													i.searchKey
												)?.label || ''}
											</strong>{' '}
											- {i.operator} - {renderValue(i)}
										</li>
									);
								})}

						{searchFilter?.length > 0 && searchFilter[0].length < 2 && searchFilter[1] && searchFilter[1].length === 1 && searchFilter.length <= 2 &&
							searchFilter[1]
								.slice(0, 3)
								.map((i: IPatternFormValue, ind: number) => {
									return (
										<>	<span>Rule 2 of {searchFilter?.length || 0}</span>
											<li className='ellipsis_exception_criteria'>
												<strong>
													{getConfigItemByName(
														i.searchKey
													)?.label || ''}
												</strong>{' '}
												- {i.operator} - {renderValue(i)}
											</li>
										</>
									);
								})}

						{searchFilter?.length > 0 && searchFilter[0].length <= 2 && searchFilter[1] && (searchFilter.length > 1 || searchFilter[1].length > 1) &&

							<li className='ellipsis_exception_criteria'>
								<strong>
									more...
								</strong>
							</li>

						}
					</div>
				</div>
				<ModalPopup
					isOpen={showSummary[index]}
					title={'Rules Summary for ' + data.name}
					onCloseHandler={() => {
						const s = [...showSummary];
						s[index] = false;
						setShowSummary(s);
					}}
					classNames='exception-popup'
				>
					<div
						className='exception-summary-container'
						style={{ marginBottom: '2%' }}
					>
						<div
							className='error-tooltip'
							style={{ display: 'flex', gap: '10px' }}
						>
							<WarningIcon
								color='error'
								className='warning-icon'
							/>
							<div>
								Please review this summary carefully because
								this exception could lead to unintended closure
								of incidents or desired incidents not being
								created.
							</div>
						</div>
					</div>
					<ExceptionRulesSummary criteria={searchFilter || []} />
				</ModalPopup>
				<div
					className='shadowbox'
					style={{ width: '10%', marginTop: '2%', float: 'right' }}
					onClick={() => {
						const s = [...showSummary];
						s[index] = true;
						setShowSummary(s);
					}}
				>
					...
				</div>
			</>
		);
	};

	const handleActivatePopUpConfirm = () => {
		const s =
			actionType?.status === 'Active' ? ' deactivated ' : ' activated ';
		const s1 =
			actionType?.status === 'Active' ? ' deactivating ' : ' activating ';
		Api.post(`/exception/status/${actionType?.id}`, '')
			.then((res: { data: any }) => {
				addToast(`Exception ${s} successfully.`, {
					appearance: 'success',
					autoDismiss: true
				});
				p.page = 1;
				setQuery(p);
				Listing();
				setShowActivatePopUp(false);
			})
			.catch((error: any) => {
				addToast(`Error occurred while ${s1} exception.`, {
					appearance: 'error',
					autoDismiss: true
				});
				setShowActivatePopUp(false);
			});
	};

	const handleDeleteException = () => {
		const headers = { Operation: 'DELETE' };
		Api.post('/exception/' + actionType.id, '', { headers: headers })
			.then((res: { data: any }) => {
				addToast('Exception deleted successfully.', {
					appearance: 'success',
					autoDismiss: true
				});
				p.page = 1;
				setQuery(p);
				Listing();
				setShowDeletePopUp({ show: false });
			})
			.catch((error: any) => { });
	};

	return (
		<>
			{/* <RightPanel /> */}
			{renderPopup()}
			<div className='page_title_area'>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<div className='font18'>Exceptions</div>
						<div className="search_container issue_page scrollbar-container marginleft20 admin-page-search-container">
							<div
								className='search_white_icon flex_none'
								style={{ cursor: 'pointer' }}
								onClick={handleSubmit(onSubmit)}
							>
								&nbsp;
							</div>
							&nbsp;
							<input
								className='search_input flex_none admin-page'
								defaultValue={p.q}
								placeholder='Description, Issues, Criteria and Last Modified By'
								ref={register}
								name='query'
							/>
						</div>
						{authDetails.permissions.Admin.exception !==
							'readonly' ? (
							<div
								className='launch_button admin_page_launch_btn'
							>
								<button
									type='button'
									className='button_main'
									onClick={() => {
										clearExceptionDataStore();
										setActionType({ id: '', type: 'add' });
									}}
								>
									Add
								</button>
							</div>
						) : (
							<div
								className='launch_button hide-launch-button'
							>
								<div
									className='button_main'
									style={{ background: 'transparent' }}
								></div>
							</div>
						)}
					</div>
				</form>
			</div>
			<table
				id='table'
				className='tablesorter exception_table sticky_table_top admin-page'
			>
				<thead>
					<tr>
						<th
							className={nameSortDir + ' width150'}
							onClick={() => {
								LoadSorting('name');
								setNameSortDir(SortBy('name'));
								setDroppedFlows('tablesort_down');
								setLastModifiedBy('tablesort_down');
								setLastModifiedAt('tablesort_down');
								setStatusSort('tablesort_down');
							}}
						>
							<span className='align_left float_left'>
								Description
							</span>
						</th>

						<th
						// style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 840 : 420 }}
						>
							<span className='align_left float_left'>Rules</span>
						</th>
						<th
							style={{
								minWidth: window.matchMedia(
									'(min-width: 2560px)'
								).matches
									? 175
									: 175
							}}
						>
							<span
								className='align_left float_left'
								title='Which issue types should this Exception apply to'
							>
								For issue types
							</span>
						</th>
						<th
							className={droppedFlows}
							onClick={() => {
								LoadSorting('dropped_flows');
								setDroppedFlows(SortBy('dropped_flows'));
								setNameSortDir('tablesort_down');
								setLastModifiedBy('tablesort_down');
								setLastModifiedAt('tablesort_down');
								setStatusSort('tablesort_down');
							}}
						>
							<span className='align_left float_left'>
								Incident Flows Matched
							</span>
						</th>
						<th
							className={lastModifiedBy}
							onClick={() => {
								LoadSorting('modified_by');
								setLastModifiedBy(SortBy('modified_by'));
								setDroppedFlows('tablesort_down');
								setNameSortDir('tablesort_down');
								setLastModifiedAt('tablesort_down');
								setStatusSort('tablesort_down');
							}}
						>
							<span className='align_left float_left'>
								Last Modified By
							</span>
						</th>
						<th
							className={lastModifiedAt}
							onClick={() => {
								LoadSorting('modified_date');
								setLastModifiedAt(SortBy('modified_date'));
								setDroppedFlows('tablesort_down');
								setLastModifiedBy('tablesort_down');
								setNameSortDir('tablesort_down');
								setStatusSort('tablesort_down');
							}}
						>
							<span className='align_left float_left'>
								Last Modified At
							</span>
						</th>
						<th
							className={statusSort}
							onClick={() => {
								LoadSorting('status');
								setStatusSort(SortBy('status'));
								setDroppedFlows('tablesort_down');
								setLastModifiedBy('tablesort_down');
								setNameSortDir('tablesort_down');
								setLastModifiedAt('tablesort_down');
							}}
						>
							<span className='align_left float_left'>
								Status
							</span>
						</th>
						{authDetails.permissions.Admin.exception !==
							'readonly' ? (
							<th>Action</th>
						) : null}
					</tr>
				</thead>
				<tbody>
					{result &&
						result.result.length > 0 &&
						result.result.map((u: any, index: number) => {
							return (
								<tr key={u._id}>
									<td className='align_left width150 vert_middle'>
										<div
											title={u.name}
											className=' width150'
										>
											{u.name}
										</div>
									</td>
									<td className='align_left width400px'>
										<SummaryCell
											index={index}
											criteria={u?.criteria}
											data={u}
										/>
									</td>
									<td className='align_left'>
										<div className='summary-cell'>
											{u.all_rules_enabled ? (
												<>All Issue Types</>
											) : u.rule_names ? (
												<div className='exception_table_scroll'>
													<ul>
														{u.rule_names.map((i) => getIssueNameById(i))
															.sort()
															.slice(0, 4)
															.map(
																(
																	item: any,
																	index: number
																) => {
																	if (
																		index <
																		3
																	)
																		return (
																			<li>
																				<span
																					style={{
																						fontSize:
																							'16px'
																					}}
																				>
																					&#8226;
																				</span>{' '}
																				{
																					item
																				}
																			</li>
																		);
																	else
																		return (
																			<li>
																				<span
																					style={{
																						fontSize:
																							'16px'
																					}}
																				>
																					&#8226;
																				</span>{' '}
																				{u
																					.rule_names
																					.length -
																					3}{' '}
																				more...
																			</li>
																		);
																}
															)}
													</ul>
												</div>
											) : null}
										</div>
										<ModalPopup
											onCloseHandler={() =>
												setShowIssueTypes(undefined)
											}
											isOpen={showIssueTypes === index}
											title={`Issue Types for ${u.name}`}
											classNames='exception-popup'
										>
											<div className='font14 issue-types-listing-container scrollbar-container'>
												{u.rule_names
													.map((i: string) => getIssueNameById(i.replace('Suspected Attack on Expired AD Account', 'Suspected Attack on Expired AD Account/Password')))
													.sort()
													.map((i: string) => (
														<li>{i}</li>
													))
												}
											</div>
										</ModalPopup>
										<div
											className='shadowbox'
											style={{
												width: '20%',
												marginTop: '4%',
												float: 'right'
											}}
											onClick={() =>
												setShowIssueTypes(index)
											}
										>
											...
										</div>
									</td>
									<td
										className='align_left vert_middle'
									>
										{u.dropped_flows !== undefined &&
											u.dropped_flows !== 0 &&
											u.flow_handling != 1 ? (
											<div
												className='shadowbox num_flows'
												onClick={handleFlowClick}
												id={u._id.toString()}
											>
												<span>
													{u.dropped_flows
														.toString()
														.replace(
															/\B(?=(\d{3})+(?!\d))/g,
															','
														)}
												</span>
											</div>
										) : u.flow_handling == 1 ? (
											<div>
												<span>
													{(
														u?.dropped_flows || 0
													).toString()}
												</span>
											</div>
										) : (
											<div>
												<span>0</span>
											</div>
										)}
									</td>
									<td className='align_left vert_middle'>
										{u.modified_by}
									</td>
									<td className='align_left vert_middle'>
										{moment(u.modified_date).format(
											'DD MMM YYYY,'
										)}{' '}
										{moment(u.modified_date).format(
											'hh:mm A'
										)}
									</td>
									<td
										className='align_left vert_middle'
										style={{
											color:
												u.status === 'Active'
													? 'black'
													: 'red'
										}}
									>
										{u.status}
									</td>
									{authDetails.permissions.Admin.exception !==
										'readonly' ? (
										<td className='vert_middle'>
											<ActionMenu>
												<ul>
													<li>
														<Link
															to='#'
															onClick={
																Action
															}
															data-action='copy'
															id={u._id}
														>
															Copy
														</Link>
													</li>
													<li>
														<Link
															to='#'
															onClick={Action}
															data-action='enable'
															data-status={
																u.status
															}
															id={u._id}
														>
															{u.status ===
																'Active'
																? 'Deactivate'
																: 'Activate'}
														</Link>

													</li>
													{!u.is_default && (
														<>
															{u.status ===
																'Active' && (
																	<li>
																		<Link
																			to='#'
																			onClick={
																				Action
																			}
																			data-action='edit'
																			id={
																				u._id
																			}
																		>
																			Edit
																		</Link>
																	</li>
																)}
															<li>
																<Link
																	to='#'
																	onClick={
																		Action
																	}
																	data-action='delete'
																	id={u._id}
																>
																	Delete
																</Link>
															</li>

														</>
													)}
												</ul>
											</ActionMenu>
										</td>
									) : null}
								</tr>
							);
						})}

					{!result && (
						<tr style={{ height: 'auto' }}>
							<td colSpan={8}>Processing</td>
						</tr>
					)}

					{result && result.result.length == 0 && (
						<tr>
							<td
								style={{
									height: window.matchMedia(
										'(min-width: 2560px)'
									).matches
										? 20
										: 20
								}}
								colSpan={8}
							>
								No exceptions found.
							</td>
						</tr>
					)}
				</tbody>
			</table>
			{showActivatePopUp && (
				<ActivatePopUp
					isActive={actionType?.status}
					closeConfirm={() => setShowActivatePopUp(false)}
					okConfirm={handleActivatePopUpConfirm}
				/>
			)}
			{showDeletePopUp.show && (
				<DeletePopUp
					data={showDeletePopUp?.data}
					handleNo={() =>
						setShowDeletePopUp({ data: undefined, show: false })
					}
					handleYes={handleDeleteException}
				/>
			)}
			{result && result.result.length > 0 && (
				<ReactPaginate
					previousLabel={'← Previous'}
					nextLabel={'Next →'}
					pageCount={Math.ceil(result.total / result.rpp)}
					onPageChange={Paginate}
					containerClassName={'pagination'}
					previousLinkClassName={'pagination__link'}
					nextLinkClassName={'pagination__link'}
					disabledClassName={'pagination__link--disabled'}
					activeClassName={'pagination__link--active'}
				/>
			)}
		</>
	);
};

export default withQueryParams({
	q: StringParam,
	page: StringParam,
	rpp: NumberParam,
	sort_by: StringParam,
	order_by: StringParam,
	filters: withDefault(ArrayParam, [])
}, Groups)