import React, { useCallback, useEffect, useState, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { FilterItem } from "./PostureFilterItem";
import { getFilterObjFromTag, getTagsForTimeBasedSearch } from "./posture-filter-utils";
import Popup from "reactjs-popup";
import Draggable from "react-draggable";
import { POSTURE_ISSUE, CREATE_PLAYBOOK_ROLES } from '../../../pages/identity_asset/constants/Constants'
import { useAuthDetails } from '../../Authorization'
import { useTimeBasedSearchActions } from "../../TimeBasedSearch/TimeBasedSearchActions";
import { CalendarIcon } from "@mui/x-date-pickers";
import { TIME_BASED_SEARCH_FIELD } from "../../TimeBasedSearch/Constants";
import { capitalizeFirstLetter, epochToDate, getCurrentDate, getExportFileDateTime } from "../../../utils/util-methods";
import moment from "moment";
import TimeBasedSearchContainer from "../../TimeBasedSearch/TimeBasedSearchContainer";
import { ImportWarning } from "./ImportWarning";
import { ImportExportSearch } from "./ImportExportSearch";
import { usePostureAdvanceSearch } from "../../../pages/identity_asset/ref/Hooks/Posture";

const Filter = ({
	filterTemplate,
	notSupportedKeys = [],
	filters: savedFilters,
	onApplyFilter,
	onClose,
	isOpen,
	disableCreatePlaybookBtn,
	matchingPlaybookFoundFn,
	entityType,
	query,
	onlyFields,
	validFormCheck,
	showCreatePlaybookBtn,
	showAllFields,
	preMetaDataHandler,
	isTimeBasedSearchApplied,
	applyTimeBasedSearchHandler,
	apiUrl,
	pageType,
	PartnerConfigState
}) => {
	const { authDetails } = useAuthDetails();
	const [metaData, setMetaData] = useState([]);
	const [template, setTemplate] = useState(null);
	const [isFormValid, setIsFormValid] = useState(true);
	const [addedFilters, setAddedFilters] = useState([]);
	const [deletedFilters, setDeletedFilters] = useState([]);
	const [noFilterSelected, setNoFilterSelected] = useState(false);
	const [showClearConfirmationPopup, setShowClearConfirmationPopup] =
		useState(false);
	const formDirty = useRef(false);
	const scrolRef = useRef();
	const [showPlaybookConfirmationPopup, setShowPlaybookConfirmationPopup] =
		useState(false);

	const { handleSubmit, control, getValues, setValue } = useForm();
	const { fields, remove, append, insert } = useFieldArray({
		control,
		name: 'filtersArray'
	});

	const [playbookData, setPlaybookData] = useState(null);
	const [loadEmptyField, setLoadEmptyField] = useState(true);
	const [errorMsg, setErrorMsg] = useState('');

	const [timeSearchData, setTimeSearchData] = useState(null);
	const [isClearFilter, setIsClearFilter] = useState(false);
	const [isClear, setIsClear] = useState(false);
	const [importTimeTag, setImportTimeTag] = useState('');

	const history = useHistory();
	const { getTimeBasedSearchAppliedFilterView, getTimeBasedSearchAppliedFilterViewValue } = useTimeBasedSearchActions();
	
	const isEnabledRaw = localStorage.getItem("TIME_BASED_SEARCH_FEATURE");
	const isPosturePlaybookEnabled =  false ; //localStorage.getItem("POSTURE_PLAYBOOK_FEATURE") ? JSON.parse(localStorage.getItem("POSTURE_PLAYBOOK_FEATURE")) : false;
	//const isPosturePlaybookEnabled = false;

    let isEnabled = false;
    if (isEnabledRaw) {
        isEnabled = JSON.parse(isEnabledRaw);
	}

	const updateAddedFilterList = (filterState) => {
		const { search_key, previousFilter, isPrestine } = filterState;
		// update addedfilterlist string[] on adding of any filteritem.
		if (isPrestine === false) {
			formDirty.current = true;
		}

		setAddedFilters((prev) => {
			const newList = [...prev];
			if (previousFilter !== search_key) {
				const index = newList.indexOf(previousFilter);
				if (index > -1) {
					newList.splice(index, 1);
					const deletedKey = previousFilter;
					if (deletedFilters.indexOf(deletedKey) === -1) {
						setDeletedFilters((prev) => {
							return [...prev, deletedKey];
						});
					}
				}
			}
			const index = newList.indexOf(search_key);
			if (index === -1) {
				newList.push(search_key);
			}
			return newList;
		});
	};

	// used to disable Apply button. by setting setIsFormValid
	const reValidateFormData = useCallback(
		(onChange, event) => {
			setTimeout(() => {
				try {
					const formData = getValues();
					const { filtersArray: data } = formData;
					// all fields are deleted. condition change after useFieldArray
					if (Object.keys(data || {}).length === 0) {
						//alert('all records deleted...');
						setIsFormValid(true);
						setAddedFilters([]);
					}
					
					// any field invalid
					for (let d of data) {
						if (!d?.isValid || !d?.search_key) {
							setIsFormValid(false);
							return;
						}
					}
					setIsFormValid(true);
				} catch (error) {
					console.log(error);
				}
			}, 100);
			onChange(event);
		},
		[getValues]
	);

	const addFilterHandler = (event) => {
		event.preventDefault();
		append({
			item: {
				search_key: '',
				operator: 0,
				value: ''
			}
		});

		if (event?.target) {
			setTimeout(() => {
				scrolRef.current.scrollIntoView({
					block: 'end',
					behavior: 'smooth'
				});
			}, 100);
		} else {
			scrolRef.current.scrollIntoView({
				block: 'end',
				behavior: 'smooth'
			});
		}
	};

	const onSubmit = ({ filtersArray: data }, isExport = false) => {
		formDirty.current = false;
		if (!data && !timeSearchData) {
			remove();
			setAddedFilters([]);
			onApplyFilter({}, true);
			return;
		}
		const list = [];
		let deletedFiltersList = [...deletedFilters];
		let arrayReq = {};
		if (data) { 

			for (let d of data) {
				if (d?.isValid) {
					const deletedKeyIndex = deletedFiltersList?.indexOf(
						d.search_key
					);
					if (deletedKeyIndex > -1) {
						deletedFiltersList.splice(deletedKeyIndex, 1);
					}
					list.push({
						search_key: d.search_key,
						operator: d.operator,
						value: d.value
					});
					if (d.controlType === 'number-array') {
						const intList = d.value.split(',');
						arrayReq[d.search_key] = {
							type: d.operator,
							value: intList.map((p) => {
								if (p.includes('-')) {
									return p;
								}
								return parseInt(p);
							})
						};
					} else if (d.controlType === 'number-range') {
						arrayReq[d.search_key] = {
							type: d.operator,
							value: d.value.map((p) => parseInt(p))
						};
					} else if (d.controlType === 'time-number') {
						arrayReq[d.search_key] = {
							type: d.operator,
							value: d.value.map((p, idx) => {
								if (idx === 0) {
									return parseInt(p);
								}
								return p;
							})
						};
					} else {
						arrayReq[d.search_key] = {
							type: d.operator,
							value: d.value
						};
					}
				}
			}
		}

		if (timeSearchData) {
			arrayReq = { ...arrayReq, ...timeSearchData };
		} else { 
			if(isClear){
		     deletedFiltersList.push(TIME_BASED_SEARCH_FIELD);
			}
		}
		if (isExport.target || !isExport) {
			onApplyFilter(arrayReq, false, deletedFiltersList, isFormValid);	
		} else {
			exportJSONSearch(list)
		}
		
	};

	const exportJSONSearch = (data) => {
		if (timeSearchData) {
			data.push(timeSearchData)	
		}
		const jsonString = JSON.stringify(data, null, 2);
		const blob = new Blob([jsonString], { type: "application/json" });
		const url = URL.createObjectURL(blob);
	
		const currentDate = new Date();
		const formattedDate = getExportFileDateTime(currentDate);
	
		const a = document.createElement('a');
		a.href = url;
		a.download = `${PartnerConfigState?.PartnerShortProduct}_${capitalizeFirstLetter(pageType)}_Search_${formattedDate}.json`;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		URL.revokeObjectURL(url);
	}

	const cancelClear = () => {
		setShowClearConfirmationPopup(false);
	};

	const clearConfirm = () => {
		formDirty.current = true;
		remove();
		setAddedFilters([]);
		const textBoxSupportedKeys = notSupportedKeys.map((p) => p.search_key);
		const advSearchSupportedKeys = filterTemplate.map((p) => p.search_key);
		const allSearchKeys =
			advSearchSupportedKeys.concat(textBoxSupportedKeys);
		const deletedKeys = [];
		for (let filterTag of savedFilters) {
			const [key] = getFilterObjFromTag(filterTag, allSearchKeys);
			if (textBoxSupportedKeys.indexOf(key) === -1) {
				deletedKeys.push(key);
			}
		}
		setDeletedFilters(deletedKeys);
		// onApplyFilter({}, true); // requirement change: clear just the form.
		setShowClearConfirmationPopup(false);
		setTimeSearchData(null);
		onApplyFilter([], true, [TIME_BASED_SEARCH_FIELD], true);
		setIsClearFilter(true);
	};

	useEffect(() => {
		if (isClearFilter) { 
			setTimeout(() => { 
				setIsClearFilter(false);
			}, 100)
		}
	})

	const clearHandler = () => {
		setShowClearConfirmationPopup(true);
	};

	const removeFilterHandler = useCallback(
		(idx) => {
			formDirty.current = true;
			const dt = getValues();
			const deletedKey = dt?.filtersArray[idx]?.search_key;
			if (deletedFilters.indexOf(deletedKey) === -1) {
				setDeletedFilters((prev) => {
					return [...prev, deletedKey];
				});
			}
			const updatedAddedFilters = [
				...addedFilters.filter((p) => p !== deletedKey)
			];
			setAddedFilters(updatedAddedFilters);
			remove(idx);
		},
		[remove, addedFilters, deletedFilters, getValues]
	);

	useEffect(() => {
		remove();
		if (preMetaDataHandler) {
			preMetaDataHandler(setMetaData);
		}
	}, []);

	useEffect(() => {
		// if (savedFilters.length === 0 && !isTimeBasedSearchApplied && loadEmptyField) {
		// 	addFilterHandler({ preventDefault: () => { } });
		// }
		setLoadEmptyField(false);
	}, [savedFilters])

	const hideAdd = useMemo(() => {
		return (
			filterTemplate.length <
			addedFilters.filter((p) => p !== '' && p !== null)?.length
		);
	}, [addedFilters, filterTemplate.length]);

	useEffect(() => {
		const textBoxSupportedKeys = notSupportedKeys.map((p) => p.search_key);
		const advSearchSupportedKeys = filterTemplate.map((p) => p.search_key);
		const allSearchKeys =
			advSearchSupportedKeys.concat(textBoxSupportedKeys);
		if (savedFilters?.length > 0) {
			remove();
			const updatedFilters = [];
			const addedFiltersSet = new Set([]);
			for (let filterTag of savedFilters) {
				const [key, val, operator] = getFilterObjFromTag(
					filterTag,
					allSearchKeys
				);

				//ignore textbox supported keys only
				if (textBoxSupportedKeys.indexOf(key) === -1) {
					const duplicateKey = addedFiltersSet.has(key);
					//check for duplicate key other than any_field, else override latest value.
					if (!duplicateKey || key === 'any_field') {
						updatedFilters.push({
							search_key: key,
							operator: operator,
							value: val
						});
					} else {
						const duplicateIdx = updatedFilters
							.map((p) => p.search_key)
							.indexOf(key);
						if (duplicateIdx >= 0) {
							updatedFilters[duplicateIdx].value = val;
						}
					}
					addedFiltersSet.add(key);
				}
			}

			// bundle any_fields key to array.
			let anyFieldsValues = [];
			updatedFilters.forEach((val, index) => {
				if (val.search_key === 'any_field') {
					anyFieldsValues.push(val.value);
				}
			});

			let anyFieldIndex = updatedFilters.findIndex(
				(p) => p.search_key === 'any_field'
			);
			const filteredList = updatedFilters.filter(
				(p) => p.search_key !== 'any_field'
			);

			if (anyFieldsValues.length > 0) {
				// order of anyfield fixed... (bugfix)
				filteredList.splice(anyFieldIndex, 0, {
					search_key: 'any_field',
					operator: 'matches',
					value: anyFieldsValues
				});
				// filteredList.push({
				//   search_key: "any_field",
				//   operator: "contains",
				//   value: anyFieldsValues,
				// });
			}
			insert(0, filteredList);
			setAddedFilters(Array.from(addedFiltersSet));
		}
	
	}, [savedFilters, filterTemplate, notSupportedKeys, remove, insert]);

	
	useEffect(() => {
		console.log(savedFilters)
		console.log(timeSearchData)
		console.log(importTimeTag)
	}, [savedFilters,timeSearchData, importTimeTag])
	
	useEffect(() => {
    let tmpl = [];
    for (let option of filterTemplate) {
        if (option) {
            let filter = metaData?.[option.search_key];
            
            // Check if the search_key is 'identity_type'
        
           

			if (option.search_key === 'identity_type') {
                // Add the extra values to optionValues
                option.optionValues = filter
                    ? [
						'Non Human Identities (NHI)',
						'Application',
						'Device',
						'Service',
						'Service/Computer Account',
						"Service/Key and Secret",
						'Service/Service Principal',
					    "Service/Token",
						'Users',
						'User',
						'User/Agent',
						'User/Background Browsing'
						]
                    : ['Device',
						'Service',
						'User',
						'Service/Application Account',
						'Service/Computer Account',
						'Service/Service Account',
						'Service/Service Principal',
						'User/Agent',
						'User/Background Browsing',
						'User/Interactive'];
            } else  if (option.search_key === 'asset_type') {
                // Add the extra values to optionValues
                option.optionValues = filter
                    ? [
						
						'Application',
						'Device',
						'Service',
						'Service/Computer Account',
						"Service/Key and Secret",
						'Service/Service Principal',
					    "Service/Token"]
                    : ['Device',
						'Service',
						'User',
						'Service/Application Account',
						'Service/Computer Account',
						'Service/Service Account',
						'Service/Service Principal',
						'User/Agent',
						'User/Browsing',
						'User/Interactive'];
            } else if (filter) {
                option.optionValues = filter;
            }
            
            
            tmpl.push({ ...option });
        }
    }
    setTemplate(tmpl);
}, [filterTemplate, metaData, setTemplate]);

	useEffect(() => {
		const { filtersArray: dt } = getValues();
		const fld = fields;
		if (!dt) {
			// set form valid when no filters selected.
			setNoFilterSelected(true);
			setIsFormValid(true);
			return;
		}
		setNoFilterSelected(false);
		for (let d of dt) {
			if (d) {
				if (!d?.search_key) {
					setIsFormValid(false);
					return;
				} else {
					if (!d?.isValid) {
						setIsFormValid(false);
						return;
					}
				}
			} else {
				setIsFormValid(false);
				return;
			}

			setIsFormValid(true);
		}
	}, [getValues, fields]);

	// const dt = getValues();
	const dragContainerRef = useRef(null);
	const onControlledDrag = (e, position) => {
		let { x, y } = position;
		// top bounds check
		if (y < -10) {
			y = -10;
		}
		// bottom bounds check (leave 13% of container top area outside)
		if (y > window.innerHeight - window.innerHeight * 0.13) {
			y = window.innerHeight - window.innerHeight * 0.13;
		}
		// right bounds check (leave 7% of container right area outside)
		if (
			x + dragContainerRef.current.clientWidth >
			window.innerWidth - window.innerWidth * 0.07
		) {
			x =
				window.innerWidth -
				dragContainerRef.current.clientWidth -
				window.innerWidth * 0.07;
		}

		// left bounds check
		if (x < -(window.innerWidth - 100)) {
			x = -(window.innerWidth - 100);
		}
		setControlledPosition({ x, y });
	};

	const onControlledDragStop = (e, position) => {
		onControlledDrag(e, position);
	};
	const [controlledPosition, setControlledPosition] = useState({
		x: -(window.innerWidth * 0.25),
		y: 10
	});

	const hidePlaybookConfirm = () => {
		setShowPlaybookConfirmationPopup(false);
	};

	const onCreatePlaybook = async () => {
		const matchingPlaybook = await matchingPlaybookFoundFn();
		if (matchingPlaybook && matchingPlaybook?.is_exists) {
			setPlaybookData(matchingPlaybook);
			setShowPlaybookConfirmationPopup(true);
		} else {
			setPlaybookData(null);
			history.push(
				`/playbooks/new?order_by=desc&sort_by=playbook_name&entity_type=${entityType}&type=${
					POSTURE_ISSUE.type
				}&q=${encodeURIComponent(query.q)}`
			);
		}
	};

	const onEditPlaybook = () => {
		setShowPlaybookConfirmationPopup(false);
		// setPlaybookData(null);
		if (playbookData) {
			history.push(
				`/playbooks/edit/${
					playbookData?.playbook_id
				}?order_by=desc&sort_by=playbook_name&entity_type=${entityType}&type=${
					POSTURE_ISSUE.type
				}&q=${encodeURIComponent(query.q)}`
			);
		}
	};

	useEffect(() => {
		setTimeout(() => {
			if (getValues) {
				const data = getValues();
				if (data && data?.filtersArray && onlyFields) {
					const { filtersArray: dt } = getValues();

					// Refactor below code if needed.
					// const newDt = dt.map(item => {
					// 	if (item && item['value'] !== '' && item['value'] !== undefined && item['value'] !== null)
					// 		return {...item, value: getSearchFieldObject(item['controlType'], item['value'])}
					// 	return item;
					// })
					// console.log({newDt})

					onApplyFilter(dt);
				}
			}
		}, 100);
	});

	useEffect(() => {
		if (validFormCheck) {
			validFormCheck(isFormValid);
		}
	}, [isFormValid]);

	if (onlyFields) {
		return (
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='search-filter-wrapper'>
					{/* {noFilterSelected && (
						<div className='no-filters-selected'>
							<span className='no-filter-title'>
								No search criteria specified, Click (+) to add
								one
							</span>
						</div>
					)} */}
					<div className='filters-items-container'>
						{fields.length > 0 &&
							fields.map((field, index) => (
								<>
									{/* {`${JSON.stringify(fields)}`}{" "} */}
									<Controller
										key={field.id}
										control={control}
										name={`filtersArray.${index}`}
										render={(props) => {
											const { onChange } = props;
											return (
												template?.length > 0 && (
													<FilterItem
														onRemoveFilter={
															removeFilterHandler
														}
														addedFiltersList={
															addedFilters
														}
														updateFilter={
															updateAddedFilterList
														}
														index={index}
														value={field}
														options={template}
														onChange={reValidateFormData.bind(
															null,
															onChange
														)}
														entityType={entityType}
														onlyFields={onlyFields}
													/>
												)
											);
										}}
									></Controller>
								</>
							))}
						<div
							className={`add-filter-item ${
								!isFormValid && 'disabled grayscaleOne'
							} ${hideAdd && 'hide'}`}
						>
							<span
								className='add-filter'
								onClick={addFilterHandler}
							>
								+
							</span>
						</div>
						{/* {`${JSON.stringify(addedFilters)}`} */}
						<span ref={scrolRef} className='scrollToElement'></span>
					</div>
				</div>
			</form>
		);
	}

	const timeFilterVals = getTimeBasedSearchAppliedFilterViewValue();


	const applyTimeBasedSearchFromAdvancedSearchHandler = (data, isClear) => {
		formDirty.current = true;
		if (isClear) {
			setIsClearFilter(true);
			setIsClear(true);
			setTimeSearchData(null);
		} else { 
			setTimeSearchData(data);
		}
	}

	return ( <Draggable
            position={controlledPosition}
            handle='.search-header-container'
            onStop={onControlledDragStop}
        >
            <div
                ref={dragContainerRef}
                className={`search-filter-container ${isOpen ? 'open' : ''}`}
            >
                <div className='search-header-container'>
					<div className='header'>Advanced Search</div>
					{/* <ImportExportSearch
						setErrorMsg={setErrorMsg}
						setIsFormValid={setIsFormValid}
						setValue={setValue}
						isFormValid={isFormValid}
						onSubmit={onSubmit}
						getValues={getValues}					
						setTimeSearchData={setTimeSearchData}
						setImportTimeTag={setImportTimeTag}
						pageType={pageType}
					filterTemplate={filterTemplate}
					timeSearchData={timeSearchData}
					setIsClearFilter={setIsClearFilter}
					formDirty={formDirty}
					/> */}
					<div
                        onClick={onClose}
                        className='search-close-container'
                    ></div>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* {`${JSON.stringify(savedFilters)}`} */}
                    <div className='search-filter-wrapper'>
                        {/* {noFilterSelected && !isTimeBasedSearchApplied && (
                            <div className='no-filters-selected'>
                                <span className='no-filter-title'>
                                    No search criteria specified, Click (+) to
                                    add one
                                </span>
                            </div>
                        )} */}
                        <div className='filters-items-container'>
                            {isEnabled && (
                                <div className='search-field-wrapper'>
                                    <div className='search-filter'>
                                        <div
                                            className='MuiAutocomplete-root MuiAutocomplete-hasClearIcon MuiAutocomplete-hasPopupIcon checkbox-autocomplete checkbox-padding css-gehbk-MuiAutocomplete-root adv-time-search-input'
                                        >
                                            <div className='auto-populate-container'>
                                                <div className='adv-search-input false'>
                                                    <input
                                                        placeholder='Select Field'
                                                        type='text'
                                                        className='undefined MuiAutocomplete-input MuiAutocomplete-inputFocused'
                                                        aria-autocomplete='list'
                                                        aria-expanded='false'
                                                        autocomplete='off'
                                                        autocapitalize='none'
                                                        spellcheck='false'
                                                        role='combobox'
                                                        value='Time Filter'
                                                        id='mui-96'
                                                        style={{
                                                            flexGrow: 0,
                                                            width: '100%'
														}}
														readOnly
														disabled
                                                    />
                                                </div>
                                                <span className=''>
                                                    {/* <svg
                                                        className='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root'
                                                        focusable='false'
                                                        aria-hidden='true'
                                                        viewBox='0 0 24 24'
                                                        data-testid='ArrowDropDownIcon'
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            right: '0px',
                                                            top: '50%',
                                                            cursor: 'pointer',
                                                            transform:
                                                                'translateY(-50%)',
                                                            color: 'rgb(119, 119, 119)',
                                                            fontSize: '18px'
                                                        }}
                                                    >
                                                        <path d='m7 10 5 5 5-5z'></path>
                                                    </svg> */}
                                                </span>
                                            </div>
                                        </div>
									</div>
									<div className='search-operator'>
                                        <div
                                            className='MuiAutocomplete-root MuiAutocomplete-hasClearIcon MuiAutocomplete-hasPopupIcon checkbox-autocomplete checkbox-padding css-gehbk-MuiAutocomplete-root'
                                            style={{
                                                width: '100%',
                                                zIndex: '10000'
                                            }}
                                        >
                                            <div className='auto-populate-container'>
                                                <div className='adv-search-input false'>
                                                    <input
                                                        placeholder='Operator'
                                                        type='text'
                                                        className='undefined MuiAutocomplete-input MuiAutocomplete-inputFocused'
                                                        aria-autocomplete='list'
                                                        aria-expanded='false'
                                                        autocomplete='off'
                                                        autocapitalize='none'
                                                        spellcheck='false'
                                                        role='combobox'
                                                        value={timeFilterVals['operator']}
                                                        id='mui-97'
                                                        style={{
                                                            flexGrow: '0',
                                                            width: '100%'
														}}
														readOnly
														disabled
                                                    />
                                                </div>
                                                <span className=''>
                                                    {/* <svg
                                                        className='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root'
                                                        focusable='false'
                                                        aria-hidden='true'
                                                        viewBox='0 0 24 24'
                                                        data-testid='ArrowDropDownIcon'
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            right: '0px',
                                                            top: '50%',
                                                            cursor: 'pointer',
                                                            transform:
                                                                'translateY(-50%)',
                                                            color: 'rgb(119, 119, 119)',
                                                            fontSsize: '18px'
                                                        }}
                                                    >
                                                        <path d='m7 10 5 5 5-5z'></path>
                                                    </svg> */}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
									<div className='search-input adv-time-search-container'>
									<TimeBasedSearchContainer onApply={applyTimeBasedSearchFromAdvancedSearchHandler} filters={importTimeTag || getTagsForTimeBasedSearch(savedFilters)} apiUrl={apiUrl} pageType={pageType} forAdvancedSearch={true} isClearFilter={isClearFilter}></TimeBasedSearchContainer>
										</div>
                                    <div className='delete-filter' style={{cursor: "default"}}>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            version='1.0'
                                            width='15pt'
                                            height='15pt'
                                            viewBox='0 0 512.000000 512.000000'
                                            preserveAspectRatio='xMidYMid meet'
                                            id='trash-icon'
											className='trash-icon-btn'
											style={{visibility: "hidden", cursor: "default"}}
                                        >
                                            <g
                                                transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
                                                fill='#2d2d2d'
                                                stroke='none'
                                            >
                                                <path d='M1931 5109 c-77 -15 -182 -72 -240 -130 -97 -97 -140 -221 -141 -401 l0 -78 -302 0 c-341 0 -416 -8 -506 -50 -81 -38 -178 -134 -215 -213 -84 -178 -48 -384 90 -521 48 -48 161 -116 192 -116 8 0 11 -422 13 -1542 l3 -1543 27 -80 c38 -111 92 -196 168 -269 79 -74 162 -120 266 -146 75 -19 115 -20 1274 -20 1159 0 1199 1 1274 20 243 62 418 264 456 527 6 42 10 639 10 1560 l0 1491 38 13 c143 46 267 199 293 359 27 169 -57 358 -202 451 -109 69 -126 72 -510 77 l-346 4 -6 117 c-7 129 -28 204 -77 282 -67 104 -188 186 -309 209 -66 12 -1186 12 -1250 -1z m1264 -251 c90 -49 126 -118 133 -255 l5 -103 -773 0 -772 0 3 108 c4 95 7 112 32 154 15 26 41 57 58 69 69 49 66 49 694 46 528 -2 593 -4 620 -19z m1080 -625 c51 -27 80 -58 101 -108 38 -92 6 -203 -75 -260 l-43 -30 -1676 -3 c-1171 -2 -1688 0 -1714 8 -100 29 -164 144 -138 249 19 79 82 141 165 162 17 4 775 6 1685 6 l1655 -2 40 -22z m-217 -2118 c2 -1003 -1 -1492 -8 -1540 -21 -138 -93 -240 -208 -297 l-67 -33 -1189 -3 c-1290 -3 -1256 -4 -1343 51 -82 52 -150 153 -172 255 -8 38 -11 479 -11 1548 l0 1494 1498 -2 1497 -3 3 -1470z'></path>
                                                <path d='M1772 3231 c-16 -17 -32 -44 -36 -62 -3 -17 -6 -542 -6 -1166 0 -1209 -1 -1172 47 -1216 31 -27 84 -32 126 -12 71 34 67 -47 67 1240 0 1255 3 1191 -54 1229 -14 9 -45 16 -70 16 -36 0 -50 -6 -74 -29z'></path>
                                                <path d='M2488 3237 c-14 -12 -31 -32 -37 -44 -8 -15 -11 -361 -11 -1178 0 -1287 -4 -1206 67 -1240 42 -20 95 -15 126 12 48 44 47 7 47 1216 0 624 -3 1149 -6 1166 -4 18 -20 45 -36 62 -38 38 -109 41 -150 6z'></path>
                                                <path d='M3184 3226 l-34 -34 0 -1171 c0 -1007 2 -1176 15 -1200 15 -31 69 -61 108 -61 37 0 90 41 104 80 16 46 18 2276 3 2332 -15 54 -61 88 -117 88 -38 0 -50 -5 -79 -34z'></path>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            )}

                            {fields.length > 0 &&
                                fields.map((field, index) => (
                                    <>
                                        {/* {`${JSON.stringify(fields)}`}{" "} */}
                                        <Controller
                                            key={field.id}
                                            control={control}
                                            name={`filtersArray.${index}`}
                                            render={(props) => {
                                                const { onChange } = props;
                                                return (
                                                    template?.length > 0 && (
                                                        <FilterItem
                                                            onRemoveFilter={
                                                                removeFilterHandler
                                                            }
                                                            addedFiltersList={
                                                                addedFilters
                                                            }
                                                            updateFilter={
                                                                updateAddedFilterList
                                                            }
                                                            index={index}
                                                            value={field}
                                                            options={template}
                                                            onChange={reValidateFormData.bind(
                                                                null,
                                                                onChange
                                                            )}
                                                            entityType={
                                                                entityType
                                                            }
                                                            onlyFields={
                                                                onlyFields
                                                            }
                                                            showAllFields={
                                                                showAllFields
                                                            }
                                                        />
                                                    )
                                                );
                                            }}
                                        ></Controller>
                                    </>
                                ))}
                            <div
                                className={`add-filter-item ${
                                    !isFormValid && 'disabled grayscaleOne'
                                } ${hideAdd && 'hide'}`}
                            >
                                <span
                                    className='add-filter'
                                    onClick={addFilterHandler}
                                >
                                    +
                                </span>
                            </div>
                            {/* {`${JSON.stringify(addedFilters)}`} */}
                            <span
                                ref={scrolRef}
                                className='scrollToElement'
                            ></span>
						</div>					
						{errorMsg && <div className="marginleft10">
							<span className="error">Error: {errorMsg}</span>
						</div>}
                        <div className='search-filter-actions'>
                            <button
                                disabled={noFilterSelected && !timeSearchData}
                                type='button'
                                className={`clear-search ${
                                    (noFilterSelected && !timeSearchData) && 'disable-clear'
                                }`}
                                onClick={clearHandler}
                            >
                                Clear the Search
                            </button>
                            <div className='search-filter-actions-rt-btns display-flex' >
                                {isPosturePlaybookEnabled &&
                                    CREATE_PLAYBOOK_ROLES.includes(
                                        authDetails?.role?.toLowerCase()
                                    ) &&
                                    showCreatePlaybookBtn && pageType !== 'incidents' && (
                                        <button
                                            disabled={
                                                disableCreatePlaybookBtn ||
                                                formDirty?.current ||
                                                !isFormValid
                                            }
                                            type='button'
                                            className={`issue-advance-search-create-playbook ${
                                                (disableCreatePlaybookBtn ||
                                                    formDirty?.current ||
                                                    !isFormValid) &&
                                                'disabled'
                                            }`}
                                            onClick={onCreatePlaybook}
                                        >
                                            Create Playbook
                                        </button>
                                    )}
                                <button
                                    disabled={
                                        (!isFormValid || !formDirty?.current) && !isClearFilter
                                    }
                                    type='submit'
                                    className={`issue-advance-search-apply ${
                                        ((!isFormValid || !formDirty?.current) && !isClearFilter) &&
                                        'disabled'
                                    }`}
                                >
                                    Apply
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                {/* <pre className="debugging">{`${JSON.stringify(dt, null, "\t")}`}</pre>  */}
                <Popup
                    open={showClearConfirmationPopup}
                    closeOnDocumentClick
                    closeOnEscape={false}
                    onClose={cancelClear}
                    overlayStyle={{
                        zIndex: 15001,
                        background: 'rgba(227, 242, 253, .6)'
                    }}
                    modal
                >
                    <div className='remove-filter-confirmation-modal'>
                        <div className='close' onClick={cancelClear}></div>
                        <div style={{ color: '#65686b' }} className='header'>
                            Clear The Search
                        </div>
                        <div className='content'>
                            <div
                                style={{ color: '#585a5b' }}
                                className='font16 margintop20 marginbottom20'
                            >
                                Are you sure you want to clear the Advanced
                                Search?
                            </div>
                        </div>
                        <div className='popup_footer'>
                            <button
                                style={{ display: 'inline' }}
                                type='button'
                                className='button_gray width25per margintop10'
                                onClick={cancelClear}
                            >
                                No
                            </button>
                            <button
                                style={{ float: 'right' }}
                                type='button'
                                className='button_main width25per'
                                onClick={clearConfirm}
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                </Popup>
                <Popup
                    open={showPlaybookConfirmationPopup}
                    closeOnDocumentClick
                    closeOnEscape={false}
                    onClose={hidePlaybookConfirm}
                    overlayStyle={{
                        zIndex: 15001,
                        background: 'rgba(227, 242, 253, .6)'
                    }}
                    modal
                >
                    <div className='remove-filter-confirmation-modal'>
                        <div
                            className='close'
                            onClick={hidePlaybookConfirm}
                        ></div>
                        <div style={{ color: '#65686b' }} className='header'>
                            Duplicate Playbook Found
                        </div>
                        <div className='content'>
                            <div
                                style={{ color: '#585a5b' }}
                                className='font16 margintop20 marginbottom20'
                            >
                                A duplicate playbook (Playbook Name) was found
                                with the same search criteria. Would you like to
                                edit this playbook or cancel?
                            </div>
                        </div>
                        <div className='popup_footer'>
                            <button
                                style={{ display: 'inline' }}
                                type='button'
                                className='button_gray width25per margintop10'
                                onClick={hidePlaybookConfirm}
                            >
                                Cancel
                            </button>
                            <button
                                style={{ float: 'right' }}
                                type='button'
                                className='button_main width25per'
                                onClick={onEditPlaybook}
                            >
                                Edit
                            </button>
                        </div>
                    </div>
                </Popup>
			</div>			
		</Draggable>);
};

export default React.memo(Filter);
