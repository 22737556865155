import {
	IPatternConfigItem,
	IPatternFormValue
} from '../../playbooks/ref/PatternForm/PatternFormGenerator';
import { ExceptionAdvancedSearchConfig } from './ExceptionsAdvanceSearchConfig';
import { Api } from '../../../components/Axios';
import { useToasts } from '../../../components/core';
import { CategoriesFields } from '../../../constants/Categories';
import { generateCategoriesForResponse, generateCategoriesForValues, getCategories } from '../../../utils/util-methods';
import { IAMSelectOption } from '../../../components/core/AMSelect/AMSelect';

export const useExceptionAdvanceSearch = () => {
	const getConfigItemByName = (name: string) => {
		return ExceptionAdvancedSearchConfig.find(
			(i: IPatternConfigItem) => i.id === name
		);
	};

	const transformValues = (res: any) => {
		const result = [] as any;
		res.forEach((i: any) => {
			const k = Object.keys(i)[0] as string;
			const r = {
				searchKey: k,
				operator: i[k].type,
				value: ''
			};
			const config = getConfigItemByName(k);
			if (config?.valueType === 'boolean') {
				r.value = `${i[k].value}`;
			} else if (config?.type == 'list' && config.valueType == 'string') {
				r.value = i[k].value;
			} else if (config?.type === 'list' && !Array.isArray(i[k].value)) {
				r.value = i[k].value.toString().split(',');
			} else {
				if(k === 's_type' || k === 'd_type'){
					i[k].value = i[k].value.map(item => {
						if (item === 'Service/Application') {
							return 'Application';
						} else if (item === 'Service/Service Account') {
							return 'Service';
						} 
						return item; // Keep the item unchanged if no match
					});
				}
				r.value = i[k].value;
			}
			result.push(r);
		});
		return result;
	};

	const convertToKeyValue = (r) => {
		if (r && Array.isArray(r) && r?.length > 0) {
			r.forEach((i) => {
				const config = getConfigItemByName(i?.searchKey);
				if (i?.value && Array.isArray(i.value) && ['array-autocomplete', 'multi-select', 'list'].includes(config?.type || '')) {
					i.value = i.value.map((i) => ({ key: i, value: i }))
				}
			})
		}
	}


	const deTransformFormValues = (result: IPatternFormValue[]) => {
		const o = [] as any;
		result.forEach((i: IPatternFormValue) => {
			const config = getConfigItemByName(i.searchKey);
			let val;
			if (
				config?.valueType === 'string-array' &&
				!Array.isArray(i.value)
			) {
				val = i.value.split(',');
			} else if (
				config?.valueType === 'number-array' &&
				!Array.isArray(i.value)
			) {
				val = i.value.split(',').map((a: string) => parseInt(a));
			} else if (config?.valueType === 'boolean') {
				val = i.value === 'true';
			} else {
				val = i.value;
			}

			if (CategoriesFields.includes(i.searchKey)) {
				if (Array.isArray(i.value) && i.value[0].key) {
					i.value = i.value.map((item: IAMSelectOption) => item.value);
				}
				val = generateCategoriesForValues(i.value);
			}

			if (Array.isArray(val) && ['array-autocomplete', 'list'].includes(config?.type || '')) {
				val = val.map((i: IAMSelectOption) => (i?.key || i));
			}

			if (Array.isArray(val) && [ 'multi-select'].includes(config?.type || '')) {
			    if(config.id === 's_type' || config.id ==="d_type"){
				  val = val.map((i) => {
					if (i?.key) {
						i = i?.key || i;
					}
						
					i = i === 'Service' ? 'Service/Service Account' : i === 'Application' ? 'Service/Application':i; 
					
					return i;
				});
				}  else {
				val = val.map((i: IAMSelectOption) => (i?.key || i));
				}
			}

			o.push({
				[i.searchKey]: {
					value: val,
					type: i.operator
				}
			});
		});
		return o;
	};

	const fetchExceptionDataFromStore = () => {
		const _cache = localStorage.getItem('stepper_exception') || undefined;
		if (_cache) {
			return JSON.parse(_cache);
		}
		return undefined;
	};

	const loadExceptionDataToStore = (data: any) => {
		const _d = localStorage.getItem('stepper_exception');
		if (_d) {
			const _t = JSON.parse(_d);
			const _data = { ..._t, ...data };
			localStorage.setItem('stepper_exception', JSON.stringify(_data));
		} else {
			localStorage.setItem('stepper_exception', JSON.stringify(data));
		}
	};

	const clearExceptionDataStore = () => {
		localStorage.removeItem('stepper_exception');
	};

	return {
		deTransformFormValues,
		fetchExceptionDataFromStore,
		loadExceptionDataToStore,
		clearExceptionDataStore,
		transformValues,
		getConfigItemByName,
		convertToKeyValue
	};
};

export const useExceptionService = () => {
	const { addToast } = useToasts();
	const { clearExceptionDataStore } = useExceptionAdvanceSearch();

	const updateCategoryCritieria = (i: any) => {
		i.criteria.forEach((k: any) => {
			k.forEach((val: any) => {
				const valueKey = Object.keys(val)[0];
				if (CategoriesFields.includes(valueKey)) {
					val[valueKey].value = generateCategoriesForResponse(
						val[valueKey].value
					);
				}
			});
		});
	};

	const fetchExceptionById = async (id: string) => {
		const req = await Api.get('/exception/' + id);
		updateCategoryCritieria(req.data);
		return req;
	};

	const updateData = (
		request: any,
		id: any,
		setLoading: any,
		AfterExceptionsOperation: any
	) => {
		setLoading(true);

		const headers = { Operation: 'PUT' };
		Api.post('/exception/' + id, request, { headers: headers })
			.then((res: { data: any }) => {
				setLoading(false);
				AfterExceptionsOperation('edit');
				addToast('Exceptions updated successfully.', {
					appearance: 'success',
					autoDismiss: true
				});
				clearExceptionDataStore();
			})
			.catch((error: any) => {
				/* setShowTransportPopUp(false); */
				setLoading(false);
				if (error.response.status === 500) {
					addToast('Sorry, something went wrong there, try again.', {
						appearance: 'error',
						autoDismiss: true
					});
				} else if (error.response.status === 419) {
					addToast(
						'We encounted validation problem, please correct and try again.',
						{
							appearance: 'error',
							autoDismiss: true
						}
					);
				} else if (error.response.status === 404) {
					addToast(
						'We are not able to find associated email, please check and try again.',
						{
							appearance: 'error',
							autoDismiss: true
						}
					);
				} else if (error.response.status === 409) {
					addToast(error.response.data, {
						appearance: 'error',
						autoDismiss: true
					});
				} else if (
					error.response.status >= 400 &&
					error.response.status <= 499
				) {
					addToast(error.response.data, {
						appearance: 'error',
						autoDismiss: true
					});
				}
			});
	};

	const addData = (
		request: any,
		setLoading: any,
		AfterExceptionsOperation: any
	) => {
		setLoading(true);
		Api.post('/exception', request)
			.then((res: { data: any }) => {
				setLoading(false);
				AfterExceptionsOperation('add');
				addToast('Exception added successfully.', {
					appearance: 'success',
					autoDismiss: true
				});
				clearExceptionDataStore();
			})
			.catch((error: any) => {
				setLoading(false);
				/* setShowExceptionPopUp(false);
				setShowTransportPopUp(false); */
				if (error.response.status === 500) {
					addToast('Sorry, something went wrong there, try again.', {
						appearance: 'error',
						autoDismiss: true
					});
				} else if (error.response.status === 419) {
					addToast(
						'We encounted validation problem, please correct and try again.',
						{
							appearance: 'error',
							autoDismiss: true
						}
					);
				} else if (error.response.status === 404) {
					addToast(
						'We are not able to save exception, please check and try again.',
						{
							appearance: 'error',
							autoDismiss: true
						}
					);
				} else if (error.response.status === 409) {
					addToast(error.response.data, {
						appearance: 'error',
						autoDismiss: true
					});
				} else if (
					error.response.status >= 400 &&
					error.response.status <= 499
				) {
					addToast(error.response.data, {
						appearance: 'error',
						autoDismiss: true
					});
				}
			});
	};
	return { addData, fetchExceptionById, updateData, updateCategoryCritieria };
};
