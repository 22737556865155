import * as React from 'react';
import {
    PERIOD_OPTIONS,
    AVAILABLE_PRESETS,
    TIME_RANGE_OPERATORS
} from '../Constants';
import {
    useTimeBasedSearchStateContext,
    useTimeBasedSearchDispatchContext,
    useTimeBasedSearchStorage,
    useTimeBasedSearchPropsContext
} from '../useTimeBasedSearch';
import PresetsComponent from './PresetsContent';
import ACTION_TYPES from '../TimeBasedSearchActionTypes';
import CustomPeriodComponent from './CustomRangeComponent';
import { dispatchWithResolve, dateToEpoch } from '../utils';
import moment from 'moment';
import HistogramComponent from './HistogramComponent';
import { Tooltip } from '@mui/material';
import { getPageLinks } from '../TimeBasedutils';

const TimeBasedSearchPopupLayout = () => {
    const store = useTimeBasedSearchStateContext();
    const dispatchAction = useTimeBasedSearchDispatchContext();
    const props = useTimeBasedSearchPropsContext();
    const [isApplyHovered, setIsApplyHovered] = React.useState(false);

    const onPeriodSelect = (e, id) => {
        if (store.selectedPeriod === id) return;
        dispatchAction({ type: ACTION_TYPES.SELECT_PERIOD_OPTION, value: id });
    };

    const onApply = async () => {
        const resolved = await dispatchWithResolve(
            dispatchAction,
            ACTION_TYPES.APPLY_FILTERS,
            store
        );
        if (resolved) {
            let data = prepareData(resolved);
            props.onApply(data);
        }
    };

    const prepareData = (data) => {
        let res = {};
        if (data['selectedPeriod'] === PERIOD_OPTIONS.PRESETS.id) {
            res = {
                any_activity_time: {
                    type: TIME_RANGE_OPERATORS.BETWEEN.id,
                    value: data['selectedPreset']
                }
            };
        } else if (data['selectedPeriod'] === PERIOD_OPTIONS.HISTOGRAM.id) {
            res = {
                any_activity_time: {
                    type: TIME_RANGE_OPERATORS.BETWEEN.id,
                    value: [
                        `${dateToEpoch(data['selectedStartDateHistogram'])}_H`,
                        `${dateToEpoch(data['selectedEndDateHistogram'])}_H`
                    ]
                }
            };
        } else {
            if (data['selectedTimeRange'] === TIME_RANGE_OPERATORS.BETWEEN.id) {
                res = {
                    any_activity_time: {
                        type: TIME_RANGE_OPERATORS.BETWEEN.id,
                        value: [
                            dateToEpoch(data['selectedStartDate']),
                            dateToEpoch(data['selectedEndDate'])
                        ]
                    }
                };
            } else if (
                data['selectedTimeRange'] === TIME_RANGE_OPERATORS.EQUALS.id
            ) {
                let startDate = dateToEpoch(
                    moment(data['selectedStartDate']).startOf('day')
                );
                let endDate = dateToEpoch(
                    moment(data['selectedStartDate']).endOf('day')
                );
                res = {
                    any_activity_time: {
                        type: TIME_RANGE_OPERATORS.EQUALS.id,
                        value: [startDate, endDate]
                    }
                };
            } else if (
                data['selectedTimeRange'] === TIME_RANGE_OPERATORS.AFTER.id
            ) {
                let startDate = dateToEpoch(moment(data['selectedStartDate']));
                let endDate = dateToEpoch(moment(data['selectedStartDate']).add(15, 'days').diff(moment(), 'days') > 0 ? moment() : moment(data['selectedStartDate']).add(15, 'days'))
                
                res = {
                    any_activity_time: {
                        type: TIME_RANGE_OPERATORS.AFTER.id,
                        value: [startDate, endDate]
                    }
                };
            }
        }
        return res;
    };

    const onClear = () => {
        dispatchAction({ type: ACTION_TYPES.CLEAR_FILTERS, value: store });
        props.onApply(null, true);
    };

    const getRightPaneContent = () => {
        switch (store.selectedPeriod) {
            case PERIOD_OPTIONS.PRESETS.id:
                return <PresetsComponent></PresetsComponent>;
            case PERIOD_OPTIONS.CUSTOM_RANGE.id:
                return <CustomPeriodComponent></CustomPeriodComponent>;
            case PERIOD_OPTIONS.HISTOGRAM.id:
                return <HistogramComponent isApplyHovered={isApplyHovered}></HistogramComponent>;
            default:
                return <></>;
        }
    };

    const getPeriodOptiomItemClass = (id) => {
        let classes = `period-option-item`;
        if (store.selectedPeriod === id) {
            return `${classes} period-option-item-selected`;
        }
        return classes;
    };

    const handleClose = (event) => {
        event.stopPropagation();
        dispatchAction({ type: ACTION_TYPES.RESET_FILTERS, value: false });
    };

    return (
        <div className='time-based-search-popup-layout'>
            <div className='popup-header'>
                <div>
                <div className='popup-header-title'>
                    Select Time Range to Filter Results
                </div>
                    <div className="header-subtitle text-ellipsis">View posture and incident results for the selected time range. <a className="link-text" href={ getPageLinks(props.pageType)} target="_blank">Learn More</a></div>
                    </div>
                <div className="popup-close" onClick={e => handleClose(e)}></div>
            </div>
            <div className='popup-body'>
                <div className='popup-body-left-pane'>
                    {Object.keys(PERIOD_OPTIONS).map((option) => (
                        <div
                            className={getPeriodOptiomItemClass(
                                PERIOD_OPTIONS[option]['id']
                            )}
                            key={PERIOD_OPTIONS[option]['id']}
                            onClick={(e) =>
                                onPeriodSelect(e, PERIOD_OPTIONS[option]['id'])
                            }
                        >
                            {PERIOD_OPTIONS[option]['label']}
                        </div>
                    ))}
                </div>
                <div className='popup-body-right-pane'>
                    {getRightPaneContent()}
                </div>
            </div>
            <div className='popup-footer'>
                <button
                    className='button_styled popup-footer-secondary-btn'
                    onClick={onClear}
                    style={!store.isFilterApplied ? { opacity: 0.4, pointerEvents: 'none' } : {}}
                >
                    Clear
                </button>
                {store.isApplyDisabled ? (
                    <Tooltip
                        classes={{ tooltip: 'playbook-level-widget-tooltip' }}
                        title={store.applyBtnValidMsg}
                    >
                        <button
                            className='popup-footer-primary-btn disabled-btn'
                            disabled={store.isApplyDisabled}
                        >
                            {props.forAdvancedSearch ? 'Ok' : 'Apply'}
                        </button>
                    </Tooltip>
                ) : (
                    <button
                        className='button_styled popup-footer-primary-btn'
                        onClick={onApply}
                        onMouseEnter={() => setIsApplyHovered(true)}  // Handle hover start
                        onMouseLeave={() => setIsApplyHovered(false)}  // Handle hover end
                    >
                        {props.forAdvancedSearch ? 'Ok' : 'Apply'}
                    </button>
                )}
            </div>
        </div>
    );
};

export default TimeBasedSearchPopupLayout;