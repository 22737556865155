import React, { ReactNode } from "react";
import { getConfigItemByName, getConfigLabelById } from "../../helpers/playbook-helper";
import { IPatternConfigItem, IPatternPayload } from "../PatternForm/PatternFormGenerator";
import { AMToolTipAd } from "../../../../components/core/AMTooltipAd/AMTooltipAd";
import { IAMSelectOption } from "../../../../components/core/AMSelect/AMSelect";
import ModalPopup from "../../../../components/core/AMModalPopup/ModalPopup";
import { PlaybookService } from "./PlaybookService";
import { IPlaybook, IPlaybookActivate, IPlaybookCloseIncPopUpProps, IPlaybookRule } from "./PlaybookTypes";
import { RuleType } from "../../constants/Constants";


const transformValue = (config: IPatternConfigItem, value: any) => {
    if(config.id === 's_type' || config.id === 'd_type'){
        value = value.map(item => {
            if (item === 'Service/Application') {
                return 'Application';
            } else if (item === 'Service/Service Account') {
                return 'Service';
            } 
            return item; // Keep the item unchanged if no match
        });
    }
    if (Array.isArray(value) && value.length > 0 && value[0].key) {
        value = value.map((v) => {
            let newValue = v.value;
            if (newValue === 'Service/Application') {
                newValue = 'Application';
            } else if (newValue === 'Service/Service Account') {
                newValue = 'Service';
            }
            return newValue;
        });
    }
    if (config?.valueType === 'boolean') {
        return `${value}`;
    } else if (config?.type === 'list' && !Array.isArray(value)) {
        return value.toString().split(',');
    } else {
        return value;
    }
}

const renderValue = (searchKey: string, value: unknown) => {
    const c = getConfigItemByName(searchKey) as any;
    let val = transformValue(c, value);
    /*    if (value && Array.isArray(value)) {
           val = value.map((i) => i.value);
       } else */
    if (c?.type == 'list') {
        const v = c.selectOptions?.find(
            (k: IAMSelectOption) => k.optionValue == val
        );
        if (v) {
            val = v?.optionLabel;
        }
    }
    return (
        <>
           {
  searchKey !== 'flow_time'
    ? searchKey === 's_type'
      ? (val?.split ? val?.split(',') : val)
          ?.map(item => (item.startsWith('User') ? item : `NHI-${item}`))
          .join(', ')
      : (val?.split ? val?.split(',') : val)?.join(', ')
    : `Details can't be viewed`
}
        </>
    );
};

export const renderList = (list: Record<string, IPatternPayload>, showCategory?: boolean) => {
    return <>{Object.keys(list).map((field: string, index: number) => {
        if (index < 4) {
            return <AMToolTipAd title={
                <span>{getConfigLabelById(field)} - {list[field].type} - {renderValue(field, list[field].value)}</span>}>
                <li className="pb-rules-field-li">
                    <div className="pb-rules-field">
                        <strong>{getConfigLabelById(field, showCategory)}</strong> - {list[field].type} - {renderValue(field, list[field].value)}
                    </div>
                </li>
            </AMToolTipAd >
        } else if (index == 4) {
            return <li><strong>More...</strong></li>
        } else return null
    }).filter((field: JSX.Element | null) => field)}
    </>
}

export const renderPlaybookSummaryColumn = (list: Record<string, IPatternPayload>, val: IPlaybookRule, showCategory?: boolean, item?: IPlaybook) => {
    const keys = Object.keys(list || {});
    const result: Array<ReactNode> = [];
    const sourceKeys = keys.map((l: string) => getConfigItemByName(l)).filter((c: IPatternConfigItem | undefined) => c && c.groupId == 'source_identity');
    const destKeys = keys.map((l: string) => getConfigItemByName(l)).filter((c: IPatternConfigItem | undefined) => c && c.groupId == 'destination_asset');
    const dirKeys = keys.map((l: string) => getConfigItemByName(l)).filter((c: IPatternConfigItem | undefined) => c && c.groupId == 'directory');
    const src =
        val?.no_identities ? 'No Identities' :
            sourceKeys[0];
    const dest =
        val?.no_assets ? 'No Assets' :
            destKeys[0];
    const dir = ([RuleType.UNAUTHORIZED_ASSET_ACCESS.toString(), RuleType.UNAUTHORIZED_IDENTITY_ACCESS.toString(), RuleType.LACK_OF_MFA.toString(),
    RuleType.ACCOUNT_TAKEOVER.toString()].includes(item?.issue_type || '')) ? dirKeys[0] : (sourceKeys[1] || destKeys[1]);

    let categoryMap: any;
    if (RuleType.DEVIATION_IN_DAILY_ASSET.toString() === item?.issue_type) {
        categoryMap = {
            0: 'All Assets'
        };
    } else if ([RuleType.UNAUTHORIZED_ASSET_ACCESS.toString(), RuleType.UNAUTHORIZED_IDENTITY_ACCESS.toString(),
    RuleType.LACK_OF_MFA.toString(), RuleType.ACCOUNT_TAKEOVER.toString()].includes(item?.issue_type || '')
    ) {
        categoryMap = {
            0: 'All Identities',
            1: 'Any Access Control',
            2: 'All Assets'
        };
    } else {
        categoryMap = {
            0: 'All Identities',
            1: 'All Assets'
        };
    }

    const noEntityMap = {
        0: 'No Identities',
        2: 'No Assets'
    };

    let resultArray = [];
    if (RuleType.DEVIATION_IN_DAILY_ASSET === item?.issue_type) {
        resultArray = [dest]
    } else if (RuleType.UNAUTHORIZED_ASSET_ACCESS == item?.issue_type ||
        RuleType.LACK_OF_MFA == item?.issue_type || RuleType.ACCOUNT_TAKEOVER == item?.issue_type
        || RuleType.UNAUTHORIZED_IDENTITY_ACCESS == item?.issue_type) {
        resultArray = [src, dir, dest]
    } else {
        resultArray = [src, dest]
    }

    resultArray.forEach((value: IPatternConfigItem | string | undefined, index: number) => {
        if (value && noEntityMap[index] == value) {
            result.push(
                <li>
                    {/*  <div className="pb-rules-field">
                        <strong>{noEntityMap[index]}</strong>
                    </div> */}
                    <strong>{noEntityMap[index]}</strong>
                </li>)
        } else if (value && value?.id) {
            result.push(
                <li>
                    {/*  <div className="pb-rules-field">
                        <strong>{getConfigLabelById(value?.id, showCategory)}</strong> - {list[value?.id].type} - {renderValue(value?.id, list[value?.id].value)}
                    </div> */}
                    <strong>{getConfigLabelById(value?.id, showCategory)}</strong> - {list[value?.id].type} - {renderValue(value?.id, list[value?.id].value)}
                </li>)
        } else {
            result.push(
                <li>
                    {/*  <div className="pb-rules-field">
                        <strong>{categoryMap[index]}</strong>
                    </div> */}
                    <strong>{categoryMap[index]}</strong>
                </li>)
        }
    });

    if (item && RuleType.UNAUTHORIZED_ASSET_ACCESS != item?.issue_type
        && RuleType.UNAUTHORIZED_IDENTITY_ACCESS != item?.issue_type
        && RuleType.LACK_OF_MFA != item?.issue_type && RuleType.ACCOUNT_TAKEOVER != item?.issue_type
        &&
        dir) {
        /* handle case if source and asset is there without fencing playbook */
        if (dir?.groupId == 'source_identity') {
            result.splice(1, 0, <li>
                <strong>{getConfigLabelById(dir?.id, showCategory)}</strong> - {list[dir?.id].type} - {renderValue(dir?.id, list[dir?.id].value)}
            </li>)
        } else {
            result.push(<li>
                <strong>{getConfigLabelById(dir?.id, showCategory)}</strong> - {list[dir?.id].type} - {renderValue(dir?.id, list[dir?.id].value)}
            </li>)
        }
    }

    if (item) {
        const condtn =
            RuleType.UNAUTHORIZED_ASSET_ACCESS == item.issue_type || RuleType.UNAUTHORIZED_IDENTITY_ACCESS == item.issue_type ||
                RuleType.LACK_OF_MFA == item.issue_type || RuleType.ACCOUNT_TAKEOVER == item.issue_type
                ?
                ((Object.keys(item.issue_rule_list[0]?.search_field_info?.search_field || {}).length > 3) || (item?.issue_rule_list?.length > 1)
                    || (sourceKeys?.length > 1 || destKeys?.length > 1 || dirKeys.length > 1)) :
                ((Object.keys(item.issue_rule_list[0]?.search_field_info?.search_field || {}).length > 2) || (item?.issue_rule_list?.length > 1)
                    || (sourceKeys?.length > 1 || destKeys?.length > 1))

        const moreLi = condtn && <li>
            <strong>More...</strong>
        </li>
        if (moreLi) {
            result.push(moreLi);
        }
    }
    return result;
}
export const PlaybookActivate = ({ id, name, status, payload, onPlaybookActivateClose }: IPlaybookActivate) => {
    const { Playbook } = PlaybookService();

    const activateDeactivatePb = () => {
        const msg = status ? 'Playbook activated successfully.' : 'Playbook deactivated successfully.';
        Playbook.ACTIVATE(id, status, msg, payload, () => {
            onPlaybookActivateClose(true);
        }, onPlaybookActivateClose);
    }

    return <ModalPopup
        title={status ? `Activate Playbook` : 'Deactivate Playbook'}
        onCloseHandler={onPlaybookActivateClose}
        isOpen={true}
    >
        <div className="font14" style={{ margin: "20px 0px" }}>
            {status ? <span>This will activate playbook : <strong>{name}</strong></span> :
                <span>This will de-activate playbook : <strong>{name}</strong></span>
            }
            <br></br>
            <br></br>
            Do you want to continue?
        </div>
        <div className="popup-container-button-container dialog-button">
            <button type="button"
                className={"float_right button_styled button_gray"}
                onClick={onPlaybookActivateClose}
            >
                No
            </button>
            <button type={"button"}
                className={"float_right button_styled"}
                onClick={activateDeactivatePb}
            >
                Yes
            </button>
        </div>
    </ModalPopup>
}


export const PlaybookCloseIncPopUp = ({ showConfirm, onCloseHandler, onNoHandler, onKeepInc }: IPlaybookCloseIncPopUpProps) => {
    return <ModalPopup title="Close Existing Incidents?"
        classNames="advanced-playbook-inc-close-container"
        isOpen={showConfirm} onCloseHandler={onCloseHandler}>
        <div className="font12" style={{ margin: '20px 0px' }}>Since the playbook logic has changed, existing incidents may no longer be relevant.
            You can choose to either keep the previously created incidents for this playbook or close all of them.
        </div>
        <div className="popup-container-button-container dialog-button">
            <button type="button" onClick={onCloseHandler}
                id={'Cancel'}
                className={"float_right button_styled button_gray "}>
                {'Cancel'}
            </button>
            <button type={"button"}
                style={{ marginLeft: '31%' }}
                id={'Close'}
                className={"float_right button_styled "}
                onClick={onNoHandler}>
                {'Close Incidents'}
            </button>
            <button type={"button"}
                id={'Keep'}
                className={"float_right button_styled "}
                onClick={onKeepInc}>
                {'Keep Incidents'}
            </button>
        </div>
    </ModalPopup >
}