
import { useState } from 'react'
import { IPatternFormValue } from '../../playbooks/ref/PatternForm/PatternFormGenerator';
import { Tooltip } from '@mui/material';
import { WeelkSelector } from '../../playbooks/ref/WeekSelector';
import { useExceptionAdvanceSearch } from './useExceptions';
import { IAMSelectOption } from '../../../components/core/AMSelect/AMSelect';
export const ExceptionRulesSummary = ({ criteria }: any) => {
    const { getConfigItemByName } = useExceptionAdvanceSearch();
    const [sohwWeeklyConfig, setShowWeeklyConfig] = useState(false);
    const renderValue = (j: IPatternFormValue) => {
        const c = getConfigItemByName(j.searchKey);
        let val = j.value;
    
        if (c?.type === 'list') {
            const v = c.selectOptions?.find((i: IAMSelectOption) => i.optionValue === j.value);
            if (v) {
                val = v?.optionLabel;
            }
        }
    
        // Handle special case for 's_type' to prefix with 'NHI-' if not starting with 'User'
        if (j.searchKey === 's_type') {
            val = Array.isArray(val)
                ? val.map((item) => {
                      // Check if item is an object
                      if (item && typeof item === 'object' && 'value' in item) {
                          return item?.value && typeof item.value === 'string' && !item.value.startsWith('User')
                              ? { ...item, value: `NHI-${item.value}` }
                              : item;
                      }
                      // Handle if item is a string
                      if (typeof item === 'string' && !item.startsWith('User')) {
                          return `NHI-${item}`;
                      }
                      return item; // Return as is if neither condition is met
                  })
                : val;
        }
    
        return (
            <span>
                {j.searchKey === 'flow_time' ? (
                    <span>
                        <label
                            className="link-text"
                            onClick={() => setShowWeeklyConfig(true)}
                        >
                            Click to view access time details
                        </label>
                        {sohwWeeklyConfig && (
                            <WeelkSelector
                                formType="view"
                                defaultState={j.value}
                                handleClose={() => setShowWeeklyConfig(false)}
                                handleSave={(e) => {
                                    setShowWeeklyConfig(false);
                                    console.log(e);
                                }}
                            />
                        )}
                    </span>
                ) : (
                    <label>
                        {Array.isArray(val)
                            ? val.map((i) => (typeof i === 'string' ? i : i.value)).join(', ')
                            : val}
                    </label>
                )}
            </span>
        );
    };
    

    const renderTitle = (j: IPatternFormValue) => {
        const c = getConfigItemByName(j.searchKey);
        let val: any = j.value;
        if (c?.type == 'list') {
            const v = c.selectOptions?.find((i: IAMSelectOption) => i.optionValue == j.value);
            if (v) {
                val = v?.optionLabel;
            }
        }
        if (j.searchKey === 's_type') {
            val = Array.isArray(val)
                ? val.map((item) => {
                      // Check if item is an object
                      if (item && typeof item === 'object' && 'value' in item) {
                          return item?.value && typeof item.value === 'string' && !item.value.startsWith('User')
                              ? { ...item, value: `NHI-${item.value}` }
                              : item;
                      }
                      // Handle if item is a string
                      if (typeof item === 'string' && !item.startsWith('User')) {
                          return `NHI-${item}`;
                      }
                      return item; // Return as is if neither condition is met
                  })
                : val;
        }
        if (Array.isArray(val) && val.length > 0) {
            val = val.map((i: IAMSelectOption | string) => typeof i == 'string' ? i : i.value)
        }
        return j.searchKey !== 'flow_time' ?
            <ul className='add-exception-summary-ul'>{j.value && ((!Array.isArray(val) && val?.split(',')) || val).map((i: string) => (<li>{i}</li>))}</ul>
            : ''
    }

    return <><h5 style={{ marginBottom: '2%' }}>Rules</h5>
        {
            criteria?.map((i: any, ind: number) => {
                return <fieldset className='add-exception-form-fieldset add-exception-sumary'>
                    <legend className='rule-legend'>Rule {ind + 1}</legend> {i.map((j: IPatternFormValue) => {
                        return <div className='rule-flex-container'> <label>{getConfigItemByName(j.searchKey)?.label}</label>
                            <label>{j.operator}</label>
                            <Tooltip
                                classes={{ tooltip: 'pattern-form-generator generic-tooltip-popper scrollbar-container add-exception' }}
                                PopperProps={{
                                    className: 'pattern-form-generator popper-class', style: { zIndex: 10000000, maxWidth: '28%' }
                                }}
                                title={renderTitle(j)}>
                                {
                                    renderValue(j)
                                }
                            </Tooltip>
                        </div>
                    })}
                </fieldset>
            })
        }</>
}