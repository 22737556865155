import { Breadcrumbs, Typography } from '@mui/material';
import React, { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { getBredcumbConfigById } from '../../../common';
import './AMBreadcrumb.css';
import { BreadcrumbConfig, useAMBreadcrumb } from './AMBreadcrumbContext';
import { convertToPascal } from '../../../utils/util-methods';

interface IAMBreadcrumb {
    classes?: {
        containerClass: string
    }
    selectedItems?: any
}
export const AMBreadcrumb = ({ classes, selectedItems }: IAMBreadcrumb) => {
    const history = useHistory();
    const state = history.location.state as any;
    const breadcrumbId = state?.breadcrumbId;
    const bread = getBredcumbConfigById(breadcrumbId) as BreadcrumbConfig;
    const { breadcrumbList, removeBreadcrumb, clearBreadcrumbs } = useAMBreadcrumb(bread);
    
    if (state?.breadcrumbId === 'none' && breadcrumbList?.length > 0) {
        clearBreadcrumbs()
    }

    const findNameById = (idToFind: string): string => {
        for (const obj in selectedItems) {
            if (selectedItems.hasOwnProperty(obj)) {
                const foundItem = selectedItems[obj].playbooks.find((item: any) => item._id === idToFind.trim());
                if (foundItem) {
                    return 'All ' + foundItem.name;
                }
            }
        }
        return 'All ' + idToFind;
    }

    return <>
        {
            useMemo(() => {
                return breadcrumbList && breadcrumbList.length > 0 ?
                    <div className={'ambreadcrumb-container ' + classes?.containerClass}

                        style={{ width: '100%', top: '0.5rem' }}>
                        <Breadcrumbs aria-label="breadcrumb"
                            separator={''}
                            maxItems={5}
                            itemsAfterCollapse={3}
                            classes={{ li: 'am-breadcrump-list', ol: 'am-breadcrump-ol', root: 'am-breadcrump-root' }}>
                            {
                                breadcrumbList ? breadcrumbList.map((item: any, index: number) => {
                                    return item.isLink ?
                                        (breadcrumbList.findIndex((i: any, ind: number) => {
                                            return i.title == item.title && ind > index
                                        }) == -1 ?
                                            <Link
                                                onClick={(e: any) => removeBreadcrumb(item.title, breadcrumbId, e)}
                                                to={{
                                                    pathname: item.url, state: {
                                                        ...item?.state
                                                    },
                                                    search: item?.search
                                                }} title={item.title} className={'right-chev' + (item.title.length > 15 ? ' big-name' : '')}>
                                                <p style={{ textDecoration: 'none' }}
                                                    title={convertToPascal(item.title)}
                                                >{convertToPascal(item.title)}</p>
                                            </Link> : null) :
                                        (index == breadcrumbList.length - 1 ?
                                            <strong className='breadcrumb-title' title={item.title}>{selectedItems ? findNameById(item.title.replace('All ', '').replace('Directories having', '').replace('issues', '').replace('Assets having', '').replace('Identities having', '')) : item.title}</strong>
                                            : null)
                                }) : null
                            }
                        </Breadcrumbs>
                    </div> : null
            }, [breadcrumbList, selectedItems])
        }
    </>
}