import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import {
  withQueryParams,
  StringParam,
  NumberParam,
  ArrayParam,
  withDefault,
} from "use-query-params";
import { useHistory, Link } from "react-router-dom";
import SlidingPanel, { PanelType } from "react-sliding-side-panel";
import "react-sliding-side-panel/lib/index.css";
import { Api } from "../../components/Axios";
import { AddSensor, EditPanel, ApiKey } from "./sub";
import ReactPaginate from "react-paginate";
import { useToasts } from "../../components/core";
import { ActionMenu } from "../../theme/ActionMenu";
import Popup from "reactjs-popup";
import moment from "moment";
import purify from "dompurify";
import { useAuthDetails } from "../../components/Authorization";
import { SensorCollector } from "./reference/SensorCollectorPopUp";
import { Tooltip } from "@mui/material";
import { SensorUpdatePopUp } from "./reference/SensorUpdatePopUp";
import { SENSOR_SETTINGS_KEYS, SensorLabels } from "./constants/sensor-labels";
import { RegenerateAPIKeyConfirm } from "./reference/RegenerateAPIKeyConfirm";
import "./Sensor.scss";
import ModalPopup from "../../components/core/AMModalPopup/ModalPopup";
import { useSensor } from "./sub/useSensor";
import { AMToolTipAd } from "../../components/core/AMTooltipAd/AMTooltipAd";
import AMAdminTable, {
  ITableAction,
  ITableColumn,
  ITableReference,
  ITableResult,
} from "../../components/core/AMTable/AMAdminTable";
import { convertToCommaValue, formatDate, isAmopsUser } from "../../utils/util-methods";
import {
  AMAdminSearchTable,
  IAMAdminSearchTableRef,
} from "../ref/AMAdminCommonComponents";
import InfoIcon from "@mui/icons-material/Info";
import { SensorPopup } from "./SensorPopup";
import { FlowSensorContainer } from "./sub/FlowSensorContainer";
import { SiemConfiguration } from "./sub/SiemConfiguration";
import action from "../issue_prev/action-popup/action";
import { CheckBox, Height } from "@mui/icons-material";
import CheckboxAutocomplete from "../../components/core/AMAutoComplete/AMAutoComplete";
import SensorSelection from "./sensorSelection";
import { GlobalConsts } from "../../GlobalConst";


// import './listing.css'

type Search = {
  query?: string;
};

type Listing = {
  _id: string;
  name: string;
  location?: string;
  host_name?: string;
  ips?: [];
  type: string;
  last_heart_beat?: string;
};

type Result = {
  total: number;
  result: Listing[];
  rpp: number;
  sort_by: string;
  order_by: string;
};

type ActionType = {
  type?: string;
  id?: string;
  name?: string;
  sensorType?: number;
};

interface Params {
  rpp?: number;
  sort_by?: string;
  order_by?: string;
  q?: string;
  page?: number;
}

interface SensorCollection {
  name: string;
  data_src: string;
  flows: string;
  type: number;
  sensor_name: string;
  last_flow_time: string;
  collector_sensor_id: string;
  collector_sensor_name: string;
  _id: string;
  collector_id: string;
  is_collector_info_present: boolean;
  collectors_count: number;
  proxy_id: number;
  proxy_name: string;
}

const is4K = window.matchMedia("(min-width: 2560px)").matches ? 1 : 1;

const Groups = ({ query, setQuery }: any) => {
  const { register, handleSubmit } = useForm<Search>();
  const childRef = useRef<ITableReference>();
  const { authDetails } = useAuthDetails()!;
  const {
    q: q,
    page: page_number,
    rpp: record_per_page,
    order_by: order,
    sort_by: sort,
  } = query;
  const [actionType, setActionType] = useState<ActionType>({ type: "" });
  const [openPanel, setOpenPanel] = React.useState<boolean>(false);
  const [panelType, setPanelType] = React.useState<PanelType>("right");
  const [panelSize, setPanelSize] = React.useState<number>(30);
  const [panelTitle, setPanelTitle] = useState<string>("");
  const [apiKey, setApiKey] = useState<string>("");
  const [result, setResult] = useState<
    ITableResult<SensorCollection | any> | any
  >();
  const [showHeartbeatDots, setHeartbeatDots] = useState<Boolean>(false);
  const { addToast } = useToasts();
  const keyRef = useRef<HTMLTextAreaElement>(null);
  const [loadAPI, setLoadAPI] = useState<Boolean | undefined>(true);
  const loadAPIRef = useRef(loadAPI);
  const [showCollector, setShowCollector] = useState<boolean>(false);
  const [showGenerateKey, setShowGenerateKey] = useState(false);
  const [selectedVal, setSelectedVal] = useState<any>("");
  const handleSelectionChange = (event: any, filteredValue: any) => {
    setSelectedVal(filteredValue[0]);
  };
  const history = useHistory();
  loadAPIRef.current = loadAPI;
  let p: Params = {
    q: q,
    page: page_number,
    rpp: record_per_page || 100,
    order_by: order,
    sort_by: sort,
  };

  const [nameSortDir, setNameSortDir] = useState<string | undefined>(
    "tablesort_down"
  );
  const [locationSortDir, setLocationSortDir] = useState<string | undefined>(
    "tablesort_down"
  );
  const [hostnameSortDir, setHostnameSortDir] = useState<string | undefined>(
    "tablesort_down"
  );
  const [heartbeatSortDir, setHeartbeatSortDir] = useState<string | undefined>(
    "tablesort_down"
  );
  const [showSensorUpdate, setShowSensorUpdate] = useState<boolean>(false);
  const [sensorUpdateText, setSensorUpdateText] = useState<string>("");
  const { clearSensorDataStore } = useSensor();
  const highlightSortDir = () => {
    if (query.sort_by === "name" && query.order_by === "asc") {
      setNameSortDir("tablesort_up_selected");
    } else if (query.sort_by === "name" && query.order_by === "desc") {
      setNameSortDir("tablesort_down_selected");
    } else if (query.sort_by === "location" && query.order_by === "asc") {
      setLocationSortDir("tablesort_up_selected");
    } else if (query.sort_by === "location" && query.order_by === "desc") {
      setLocationSortDir("tablesort_down_selected");
    } else if (query.sort_by === "hostname" && query.order_by === "asc") {
      setHostnameSortDir("tablesort_up_selected");
    } else if (query.sort_by === "hostname" && query.order_by === "desc") {
      setHostnameSortDir("tablesort_down_selected");
    } else if (query.sort_by === "heartbeat" && query.order_by === "asc") {
      setHeartbeatSortDir("tablesort_up_selected");
    } else if (query.sort_by === "heartbeat" && query.order_by === "desc") {
      setHeartbeatSortDir("tablesort_down_selected");
    }
  };

  const searchRef = useRef<IAMAdminSearchTableRef>();

 

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
    Listing();
    highlightSortDir();
    }
    return () => {
      isMounted = false; // Cleanup on component unmount
    };
  }, [query]);



 

  /*  useEffect(() => {        
         // Listing();
         highlightSortDir();
         const interval = setInterval(() => {
         if(loadAPIRef.current){
                 Listing()
             }
     }, 15000)
         return () => {
           clearInterval(interval);
         }
     }, [query]) */

  const deleteConfirm = () => {
    const headers = { Operation: "DELETE" };
    Api.post("/sensors/" + actionType?.id, "", { headers: headers })
      .then((res: { data: any }) => {
        addToast("Sensor deleted successfully.", {
          appearance: "success",
          autoDismiss: true,
        });
        p.page = 1;
        setQuery(p);
        Listing();
        closeConfirm();
      })
      .catch((error: any) => {
        closeConfirm();
        Listing();
        if (error.response.status === 403 && error.response.data.errors) {
          addToast(`${error.response.data.errors}`, {
            appearance: "error",
            autoDismiss: true,
            autoHideDuration: 5000,
          });
        }
      });
  };

  useEffect(() => {
    clearSensorDataStore();
    switch (actionType?.type) {
      case "add":
        setPanelTitle("Add Sensor");
        setLoadAPI(false);
        setOpenPanel(true);
        setSelectedSensorType('');
        return;

      case "edit":
        setPanelTitle("Edit Sensor");
        setLoadAPI(false);
        setOpenPanel(true);
        setShowNextSetup(true);
        return;

      case "delete":
        setLoadAPI(false);
        setOpenConfirm(true);
        return;
      case "view":
        setLoadAPI(false);
        Api.get("/sensors/" + actionType.id)
          .then((res: { data: any }) => {
            setApiKey(res.data.jwt);
            setOpen(true);
          })
          .catch((error: any) => {});
        return;
      case "regenerate":
        setLoadAPI(false);
        setShowGenerateKey(true);
        break;
      case "view-collector":
        /* setShowCollector(true); */
        sessionStorage.setItem("sensor", JSON.stringify(actionType));
        history.push(`/deploy/collector?order_by=asc&sort_by=name&rpp=100`);
        console.log(actionType);
        return;
      default:
        return;
    }
  }, [actionType]);

  const onSubmit = (data: any) => {
    p.q = data.query;
    p.page = 1;
    setQuery(p);
  };

  const handleOnClosed = () => {
    setOpenPanel(false);
    Listing();
  };

  const onSensorSubmit = (data: any) => {
    if (childRef?.current?.onSearch) {
      sessionStorage.removeItem("sensor");
      childRef?.current?.onSearch(data);
    }
  };

  const getItemLabel = (
    option: { key: string; value: string } | { key: string; value: string }[]
  ) => {
    if (Array.isArray(option)) {
      return option[0]?.value ? option[0]?.value : "";
    } else {
      return option?.value ? option?.value : "";
    }
  };

  const [showNextSetUp, setShowNextSetup] = useState(false);

  const addSensorNext = () => {

    if(selectedSensorType === '14'){
        history.push('/deploy/supportedCollector?category=SIEM')
    } else {
    setShowNextSetup(true);
    }
  };

  const [selectedSensorType, setSelectedSensorType] = useState<string | null>(
    null
  );

  const sensorMap = [
    { key: "3", value: "flow" },
    { key: "100", value: "hostedFS" },
    { key: "1", value: "ad" },
    { key: "17", value: "adlds" },
    { key: "10", value: "enrichment" },
    { key: "14", value: "siem" }
  ];

  const sensorSelection = (val: string) => {
  

    const selected = sensorMap.find((obj: any) => obj.value === val);
    if (selected) {
      setSelectedSensorType(selected.key);
    }
  };

  const getTitleForAdd = (sensorType) => {
    switch (sensorType) {
        case '1':
            return "Add AD Sensor";
        case '10':
            return "Add Enrichment Sensor";
       case '17':
            return "Add AD LDS Sensor";
        case '3':
        case '100':
            return "Add Flow Sensor";
        case '14':
            return "Add SIEM Sensor";
        default:
            return "Choose Sensor Type";
    }
};

const getTitleForEdit = (sensorType) => {
    switch (sensorType.toString()) {
        case '1':
            return "Manage AD Sensor";
        case '17':
              return "Manage AD  LDS Sensor";
        case '10':
            return "Manage Enrichment Sensor";
        case '3':
        case '100':
            return "Manage Flow Sensor";
        case '14':
            return "Manage SIEM Sensor";
        default:
            return "Choose Sensor Type";
    }
};

const getTitle = (actionType, selectedSensorType, showNextSetUp) => {
  if (actionType.type === "add" && showNextSetUp) {
      return getTitleForAdd(selectedSensorType);
  } else if (actionType.type === "edit") {
      return getTitleForEdit(selectedSensorType);
  } else {
      return "Choose Sensor Type";
  }
};

const userDetails = localStorage.getItem('user') as string;
const user = JSON.parse(userDetails);


const getSubtitleForAdd = (sensorType) => {
  switch (sensorType.toString()) {
      case '1':
          return "Leverage an AuthMind AD Sensor to collect events and insights from a Microsoft AD Directory.";
      case '17':
            return "Leverage an AuthMind AD  LDS Sensor to collect events and insights from a Microsoft AD LDS Directory.";
      case '10':
          return "Enrichment Sensors are an alternative to a local Flow Sensor to enrich host details";
      case '3':
      case '100':
          return "Leverage a Flow Sensor to collect from one or more local data sources and/or local SIEMs.";
      case '14':
          return "Integrate with an existing SIEM to collect one or more data sources to streamline deployments.";
      default:
          return "Select from one of the following sensors to be used to collect data from your environment.";
  }
};

const getSubtitle = (actionType,showNextSetUp) => {
  if (showNextSetUp || actionType.type === 'edit') {
      return getSubtitleForAdd(selectedSensorType);
  } else {
      return "Select from one of the following sensors to be used to collect data from your environment.";
  }
};



const subtitle = getSubtitle(actionType,showNextSetUp);


const deploymentGuides = [
  {
      sensorType: "1",
      url: `${GlobalConsts.DEPLOYMENT_LINK}/installing-active-directory-sensor?${GlobalConsts.DOC_TOKEN}`,
  },
  {
    sensorType: "17",
    url: `${GlobalConsts.DEPLOYMENT_LINK}/installing-active-directory-sensor?${GlobalConsts.DOC_TOKEN}`,
 },
  {
      sensorType: "3",
      url: `${GlobalConsts.DEPLOYMENT_LINK}/installing-a-flow-sensor?${GlobalConsts.DOC_TOKEN}`,
  },
  {
      sensorType: "10",
      url: `${GlobalConsts.DEPLOYMENT_LINK}/installing-an-enrichment-sensor?${GlobalConsts.DOC_TOKEN}`,
  },
  {
      sensorType: "Hosted Flow Sensor",
      url: `${GlobalConsts.DEPLOYMENT_LINK}/installing-a-flow-sensor?${GlobalConsts.DOC_TOKEN}`,
  },
  {
      sensorType: "14",
      url: `${GlobalConsts.DEPLOYMENT_LINK}/collect-from-siem?${GlobalConsts.DOC_TOKEN}`,
  },
];
  const getGuideLink = (selectedSensorType: string) => {
  return showNextSetUp ? (deploymentGuides.find((obj)=> obj.sensorType === selectedSensorType?.toString()))?.url : `${GlobalConsts.DEPLOYMENT_LINK}/deployment-overview?${GlobalConsts.DOC_TOKEN}`;
}

  const RightPanel = () => {
    if ([12, 13, 14,15,16,20].includes(actionType.sensorType)) {
      return (
        <SensorPopup
          isOpen={openPanel}
          onCloseHandler={() => {
            setOpenPanel(false);
            setShowNextSetup(false);
            Listing();
          }}
          title={"Manage SIEM Vendor"}
          classNames="sensor-modal-popup-container"
        >
          <SiemConfiguration
            onCloseHandler={() => {
              setOpenPanel(false);
              setShowNextSetup(false);
              Listing();
            }}
            id={actionType.id}
            sensorType={actionType.sensorType}
            AfterSensorOperation={AfterSensorOperation}
          />
        </SensorPopup>
      );
    } else {
      return (
        <SensorPopup
          isOpen={openPanel}
          onCloseHandler={() => {
            setOpenPanel(false);
            setShowNextSetup(false);
            Listing();
          }}
          title={
            getTitle(actionType, selectedSensorType, showNextSetUp)
        }
          classNames="sensor-modal-popup-container"
          subTitle={
            getSubtitle(actionType,showNextSetUp)
        }
        guideLink={getGuideLink(selectedSensorType)}
        >
          {actionType.type === "add" && (
            <div>
              {!showNextSetUp && (
                <div className="sensor-form-container">
                  <div className="sensor-group">
                    <SensorSelection
                      selectedSensor={sensorMap.find((obj: any) => obj.key === selectedSensorType)?.value}
                      onClick={(val) => sensorSelection(val)}
                    ></SensorSelection>
                  </div>

                  <div className="sensor-footer-container">
                    <div className="launch_button">
                      <button
                        type="button"
                        onClick={() => {
                          setOpenPanel(false);
                          setShowNextSetup(false);
                        }}
                        className={"float_left stepper-action-btn button_grey"}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={addSensorNext}
                        type="button"
                        className={
                          !selectedSensorType ?  'disabled float_right stepper-action-btn button_styled' : 'float_right stepper-action-btn button_styled'
                        }
                        disabled={!selectedSensorType}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {showNextSetUp && (
                <FlowSensorContainer
                  onCloseHandler={() => {
                    setOpenPanel(false);
                    setShowNextSetup(false);
                    Listing();
                    
                  }}
                  id={actionType.id}
                  AfterSensorOperation={AfterSensorOperation}
                  sensorType={selectedSensorType}
                />
              )}
            </div>
          )}
          {actionType.type === "edit" && (
            <div>
              <FlowSensorContainer
                onCloseHandler={() => {
                  setOpenPanel(false);
                  setShowNextSetup(false);
                  Listing();
                }}
                id={actionType.id}
                AfterSensorOperation={AfterSensorOperation}
                sensorType={selectedSensorType}
              />
            </div>
          )}
        </SensorPopup>
      );
    }
    // return (
    //     <ModalPopup isOpen={openPanel} onCloseHandler={() => { setOpenPanel(false) }} title={actionType?.type === "add" ? 'Add Sensor' : 'Edit Sensor'}
    //         classNames='sensor-modal-popup-container'
    //     >
    //         {actionType?.type === "add" &&
    //             <AddSensor AfterSensorOperation={AfterSensorOperation} />
    //         }

    //         {actionType?.type === "edit" &&
    //             <EditPanel id={actionType.id} AfterSensorOperation={AfterSensorOperation} />
    //         }
    //     </ModalPopup >
    // )
  };

  const updateDehashedMsg = (result: any) => {
    if (result.result) {
      result.result.forEach((sensor: any) => {
        if (
          sensor?.sensor_settings &&
          Object.keys(sensor?.sensor_settings)?.length > 0
        ) {
          Object.keys(sensor.sensor_settings).forEach((key: string) => {
            const msg = sensor?.sensor_settings[key];
            if (msg.includes("dehashed")) {
              delete sensor.sensor_settings[key];
            }
          });
          if (
            sensor.sensor_settings &&
            Object.keys(sensor.sensor_settings).length == 0
          ) {
            delete sensor.sensor_settings;
          }
        }
      });
    }
  };

  const Listing = () => {
    Api.get("/sensors", {
      params: p,
    })
      .then((res: { data: any }) => {
     
      if (isAmopsUser(user.email)) {
          setResult(res.data);
          updateDehashedMsg(res.data);
      } else {
        const filteredResult = res.data.result.filter((item: any) => !item.is_am_hosted);
        const filteredData = {
            ...res.data,
            result: filteredResult
        };
        setResult(filteredData);
        updateDehashedMsg(filteredData);
      }

      // setResult(res.data);
      // updateDehashedMsg(res.data);

    
      })
      .catch((error: any) => {});
  };

  const Action = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    clearSensorDataStore();
    setActionType({
      id: e.currentTarget.id,
      type: e.currentTarget.dataset.action,
      name: e.currentTarget.dataset.sensorname,
      sensorType: e.currentTarget.dataset.type,
    });
    e.preventDefault();
  };

  const Paginate = (selectedPage: any) => {
    p.page = selectedPage.selected === 0 ? 1 : selectedPage.selected + 1;
    setQuery(p);
  };

  const AfterSensorOperation = (action: string, msg?: string) => {
    setOpenPanel(false);
    setLoadAPI(true);
    if (action === "add") {
      p.page = 1;
      p.q = "";
      setQuery(p);
      Listing();
    } else {
      Listing();
    }

    if (action == "edit" && msg) {
      setSensorUpdateText(msg);
      setShowSensorUpdate(true);
    }
    setShowNextSetup(false);
    clearSensorDataStore();
  };

  const LoadSorting = (field: string) => {
    if (p.sort_by === field) {
      p.order_by = p.order_by === "asc" ? "desc" : "asc";
      setQuery(p);
    } else {
      p.sort_by = field;
      p.order_by = "desc";
      setQuery(p);
    }
  };

  const SortBy = (name: string) => {
    if (p.sort_by === name) {
      return p.order_by === "desc" ? "tablesort_down" : "tablesort_up";
    }
  };

  // const Sorting = (name: string, field: string) => {
  //     const fl = name.toLowerCase()
  //     return (
  //         <Link to="#" data-field={field} onClick={LoadSorting}>
  //             <div className={SortBy(field)}>{name}</div>
  //         </Link>
  //     )
  // }
  const FormatDate = (dte: any) => {
    moment.locale("en");
    if (typeof dte == "undefined") {
      return <></>;
    }
    return <>{moment(dte).format("MMM DD YYYY,h:mm A")}</>;
  };

  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setOpen(false);
    setLoadAPI(true);
  };

  const [openConfirm, setOpenConfirm] = useState(false);
  const closeConfirm = () => {
    setOpenConfirm(false);
    setLoadAPI(true);
  };

  const handleDownload = () => {
    const element = document.createElement("a");
    const file = new Blob([apiKey], { type: "application/octet-stream" });
    element.href = URL.createObjectURL(file);
    element.download = "refresh.tkn";
    document.body.appendChild(element);
    element.click();
    element.parentNode?.removeChild(element);
  };

  const confirmRegenerate = () => {
    Api.get("/sensors/" + actionType?.id + "/regenerate")
      .then((res: { data: any }) => {
        setShowGenerateKey(false);
        setActionType({
          id: actionType?.id,
          type: "view",
          name: actionType?.name,
          sensorType: actionType?.sensorType,
        });
        addToast("Sensor key regenerated successfully.", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error: any) => {});
  };

  const isActive = (u: any) => {
    if ([4, 6, 7, 8].includes(u.type)) {
      return (
        moment.duration(moment().diff(moment(u.last_flow_time))).asHours() < 8
      );
    }
    return (
      moment.duration(moment().diff(moment(u.last_flow_time))).asHours() < 2
    );
  };

  const isIdle = (u: any) => {
    if ([4, 6, 7, 8].includes(u.type)) {
      return (
        moment.duration(moment().diff(moment(u.last_flow_time))).asHours() >
          8 &&
        moment.duration(moment().diff(moment(u.last_flow_time))).asHours() < 9
      );
    }
    return (
      moment.duration(moment().diff(moment(u.last_flow_time))).asHours() > 1 &&
      moment.duration(moment().diff(moment(u.last_flow_time))).asHours() < 2
    );
  };

  const getIpListing = (u: any) => {
    const list = u.ips
      ?.filter((e: any) => !/fe80::|172.17.0.1|%docker/.test(e))
      ?.map(
        (e: any) =>
          `<li class="ellipsis" 
        title='${e}' style='list-style:none;'>${e}</li>`
      )
      .join("");
    return purify.sanitize(list);
  };

  const SensorTableColumns: ITableColumn<SensorCollection>[] = [
    {
      headerText: "Name",
      key: "name",
      classes: "align_left ellipsis vert_middle",
      sortable: true,
      html: (data: SensorCollection) => {
        return (
          <div style={{ display: "flex" }} className="ellipsis">
            <div style={{ width: "100%" }}>
              <div
                style={{
                  maxWidth: `${is4K * 190}px`,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  display: "inline-block",
                }}
              >
                {data.name ? data.name : data.collector_id}
              </div>

              {data.is_collector_info_present ? (
                <>
                  <Tooltip
                    classes={{ tooltip: "generic-tooltip-popper" }}
                    title={
                      <span>To view collectors, click the Action menu</span>
                    }
                  >
                    <div className="font10">
                      <i>
                        (
                        {data.collectors_count > 1
                          ? `${data.collectors_count} Collectors`
                          : `${data.collectors_count} Collector`}
                        )
                      </i>
                    </div>
                  </Tooltip>
                </>
              ) : null}
              {data.proxy_id ? (
                <Tooltip
                  classes={{ tooltip: "sensor-proxy-tooltip" }}
                  title={
                    <>
                      {data.proxy_name ? (
                        <span>Proxy Name: {data.proxy_name}</span>
                      ) : (
                        "Proxy Name - N.A."
                      )}
                    </>
                  }
                >
                  <div className="sensor-proxy-icon"></div>
                </Tooltip>
              ) : null}
            </div>
          </div>
        );
      },
      styles: {
        width: is4K * 200,
      },
    },
    {
      headerText: "Site Code",
      key: "location",
      sortable: true,
      classes: "align_left vert_middle ",
      html: (data: any) => {
        return (
         <>
        {data?.location && !['12','13','14','15','16'].includes(data.type.toString()) && <div> {data.location}</div>} 
        
        {data?.site_codes &&  ['12','13','14','15','16'].includes(data.type.toString()) && <div>
            {data?.site_codes?.map((siteCode: string) => (
              <div key={siteCode} style={{ display: "flex" }} className="ellipsis">
                <div
                  style={{
                    maxWidth: `${is4K * 190}px`,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {siteCode}
                </div>
              </div>
            ))}
          </div>
         }      
         </> 
        );
      },
    },
    {
      headerText: "Sensor Hostname",
      key: "host_name",
      classes: "align_left vert_middle",
      sortable: true,
      html: (data: any) => {
        if (isAmopsUser(user.email) && data?.host_names_amops) {
          return (
            <>
            {data?.host_names_amops && ['12','13','14','15','16','20'].includes(data.type.toString()) && <div>
                {data?.host_names_amops?.map((hostname: string) => (
                  <div key={hostname} style={{ display: "flex" }} className="ellipsis">
                    <div
                      style={{
                        maxWidth: `${is4K * 190}px`,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {hostname}
                    </div>
                  </div>
                ))}
              </div>
              }
            </>
           );
        } else {
          return (
          <>
           {data?.host_name && !['12','13','14','15','16','20'].includes(data.type.toString()) && <div> {data.host_name}</div>} 
           {data?.host_names && ['12','13','14','15','16','20'].includes(data.type.toString()) && <div>
               {data?.host_names?.map((hostname: string) => (
                 <div key={hostname} style={{ display: "flex" }} className="ellipsis">
                   <div
                     style={{
                       maxWidth: `${is4K * 190}px`,
                       textOverflow: "ellipsis",
                       overflow: "hidden",
                     }}
                   >
                     {hostname}
                   </div>
                 </div>
               ))}
             </div>
            }
            </>
           );
        }
      },
    },
    {
      headerText: "Sensor IPs",
      key: "ips",
      classes: "align_left vert_middle",
      sortable: true,
      html: (data: any) => {
        if (isAmopsUser(user.email) && data?.host_names_amops) {
          return (
            <div>
              {data?.ips?.map((ip: string) => (
                <div key={ip} style={{ display: "flex" }} className="ellipsis">
                  <div
                    style={{
                      maxWidth: `${is4K * 190}px`,
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {ip}
                  </div>
                </div>
              ))}
              {!data?.ips  && [12,13,14,15,16,20].includes(data.type)  && <div>
                  -
              </div> }
            </div>
          );
        } else { 
          return (
            <div>
              {data?.ips?.map((ip: string) => (
                <div key={ip} style={{ display: "flex" }} className="ellipsis">
                  <div
                    style={{
                      maxWidth: `${is4K * 190}px`,
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {ip}
                  </div>
                </div>
              ))}
              {!data?.ips  && [12,13,14,15,16,20].includes(data.type)  && <div>
                  -
              </div> }
            </div>
          );
        }
      },
    },
    {
      headerText: "Last Heartbeat",
      headerHtml: () => {
        return (
          <div>
            <div>Last Heartbeat</div>
            <div>Last flow</div>
            <div>Errors (if any)</div>
          </div>
        );
      },
      key: "last_flow_time",
      sortable: true,
      classes: "align_left vert_middle",
      html: (data: SensorCollection) => {
        return (
          <div>
            {data.last_heart_beat &&
            data.last_heart_beat !== showHeartbeatDots ? (
              <div className="heartbeat_flow">
                {FormatDate(data.last_heart_beat)}

                {moment
                  .duration(moment().diff(moment(data.last_heart_beat)))
                  .asSeconds() < 120 ? (
                  <span className="heartbeat_green_dot" title="Last heartbeat">
                    &#9679;
                  </span>
                ) : moment
                    .duration(moment().diff(moment(data.last_heart_beat)))
                    .asSeconds() > 120 &&
                  moment
                    .duration(moment().diff(moment(data.last_heart_beat)))
                    .asSeconds() < 300 ? (
                  <span className="heartbeat_orange_dot" title="Last heartbeat">
                    &#9679;
                  </span>
                ) : (
                  <span className="heartbeat_red_dot" title="Last heartbeat">
                    &#9679;
                  </span>
                )}
              </div>
            ) : (
              <div className="heartbeat_flow" title="Last heartbeat">
                No Heartbeat
                <span className="heartbeat_yellow_dot" title="Last heartbeat">
                  &#9679;
                </span>
              </div>
            )}
            {data.last_flow_time &&
            moment(data.last_flow_time).isAfter("2021-12-31T01:14:00Z") ? (
              <div className="heartbeat_flow">
                {FormatDate(data.last_flow_time)}

                {isActive(data) ? (
                  <span className="heartbeat_green_dot" title="Last flow">
                    &#9679;
                  </span>
                ) : isIdle(data) ? (
                  <span className="heartbeat_orange_dot" title="Last flow">
                    &#9679;
                  </span>
                ) : (
                  <span className="heartbeat_red_dot" title="Last flow">
                    &#9679;
                  </span>
                )}
              </div>
            ) : (
              <div className="heartbeat_flow" title="Last flow">
                {data.type === 10 ? "" : "No Last Flow"}
                {/* No Last Flow */}
                {data.type === 10 ? (
                  ""
                ) : (
                  <span className="heartbeat_yellow_dot" title="Last flow">
                    &#9679;
                  </span>
                )}
              </div>
            )}
            {data.sensor_settings && (
              <>
                Errors
                <Tooltip
                  classes={{ tooltip: "sensor-proxy-tooltip" }}
                  title={
                    <ul style={{ marginLeft: "5%" }}>
                      {Object.keys(data.sensor_settings).filter(item => data.sensor_settings[item] != '').map(
                        (sensor_setting, idx) => (
                          <li
                            key={`errors_${idx}`}
                            style={{ marginBottom: "2%" }}
                          >
                            {SensorLabels[sensor_setting] || sensor_setting} -{" "}
                            {data.sensor_settings[sensor_setting]}
                          </li>
                        )
                      )}
                    </ul>
                  }
                >
                  <span
                    style={{
                      position: "relative",
                      float: "right",
                      paddingLeft: 0,
                    }}
                    className="heartbeat_red_dot"
                  >
                    &#9679;
                  </span>
                </Tooltip>
              </>
            )}
          </div>
        );
      },
      styles: {
        width: is4K * 150,
      },
    },
    {
      headerText: "Flows",
      key: "flows",
      classes: "vert_middle",
      sortable: true,
      html: (data: SensorCollection) => {
        return <span>{convertToCommaValue(data.flows)}</span>;
      },
    },
    {
      headerText: "Type",
      key: "type",
      classes: "align_left vert_middle",
      sortable: true,
      html: (data: SensorCollection) => {
        return (
          <div>
            {data.type && data.type === 1   && (
              <span>
                {data?.directory_type == "ADLDS" ? (
                  <>AD Sensor (LDS)</>
                ) : (
                  <>AD Sensor (DS)</>
                )}
              </span>
            )} 
            {data.type && data.type === 2 && <span>LDAP Service</span>}
            {data.type && data.type === 3 && (
              <span>
                Flow Sensor {data?.is_am_hosted && <span>(Hosted)</span>}
              </span>
            )}
            {data.type && data.type === 4 && <span>Azure AD Sensor</span>}
            {data.type && data.type === 5 && (
              <span>Azure Directory Sensor</span>
            )}
            {data.type && data.type === 6 && <span>OneLogin Sensor</span>}
            {data.type && data.type === 7 && <span>Okta Sensor</span>}
            {data.type && data.type === 8 && (
              <span>Google Workspace Sensor</span>
            )}
            {data.type && data.type === 9 && <span>Ping Identity Sensor</span>}
            {data.type && data.type === 10 && <span>Enrichment Sensor</span>}
            {data.type && data.type === 12 && <span>Splunk SIEM</span>}
            {data.type && data.type === 13 && <span>IBM Qradar SIEM</span>}
            {data.type && data.type === 14 && <span>Sumologic SIEM</span>}
            {data.type && data.type === 15 && <span>Splunk Cloud SIEM</span>}
            {data.type && data.type === 16 && (
              <span>IBM QRadar (Cloud-Native SaaS) SIEM</span>
            )}
             {data.type && data.type === 17   && (
              <span>
                {data?.directory_type == "ADLDS" ? (
                  <>AD Sensor (LDS)</>
                ) : (
                  <>AD Sensor (DS)</>
                )}
              </span>
            )}
            {data.type && data.type === 18   && (
              <span>
               
                AD Sensor (DS) WEF
                
              </span>
            )}
            {data.type && data.type === 19  && (
              <span>
               
                AD Sensor (LDS) WEF
              </span>
            )}
            {data.type && data.type === 20  && (
              <span>
               
                Kafka SIEM
              </span>
            )}
          </div>
        );
      },
    },
  ];

  const CollectorActions: ITableAction<SensorCollection | unknown>[] = [
    {
      actionId: "edit-sensor",
      actionLabel: "Manage",
    },
    {
      actionId: "view-collector",
      actionLabel: "View Collectors",
      shouldDisplay: (item:any) => { return item.collectors_count > 0 }
    },
    {
      actionId: "delete-sensor",
      actionLabel: "Delete",
    },
  ];

  CollectorActions[1].actionCallback = function (
    i: SensorCollection | any,
    e: any
  ) {
    clearSensorDataStore();
    setActionType({
      id: i._id,
      type: "view-collector",
      name: i.name,
      sensorType: i.type,
    });
  };

  CollectorActions[2].actionCallback = function (
    i: SensorCollection | any,
    e: any
  ) {
    setLoadAPI(false);
    setOpenConfirm(true);
    setActionType({
      id: i._id,
      type: "delete",
      name: i.name,
      sensorType: i.type,
    });
  };

  CollectorActions[0].actionCallback = function (
    i: SensorCollection | any,
    e: any
  ) {
    setPanelTitle("Edit Sensor");
    setLoadAPI(false);
    setOpenPanel(true);
    setActionType({
      id: i._id,
      type: "edit",
      name: i.name,
      sensorType: i.type,
    });
    setSelectedSensorType(i.type);
  };

  const onAddSensor = () => {
    setActionType({ id: "", type: "add" });
  };

  const getActions = () => {
    const action = {
      action: {
        label: "Add",
        onClickHandler: onAddSensor,
      },
    };

    return authDetails?.permissions.Admin.sensors != "readonly"
      ? action
      : null;
  };

 

  const SearchTable = () => {
    return (
      <AMAdminSearchTable
        searchTitle=""
        searchPlaceHolder="Name, Device Name and IP"
        query={query.q}
        ref={searchRef}
        onSearchValue={onSensorSubmit}
        {...getActions()}
      />
    );
  };

  return (
    <>
      <RightPanel />


          
      <div>
       

       

        <SearchTable />

        <div className="flow_table_container sensor-collector-container">
          {/* <div className="search_section">
                <div data-id="tab1" style={{ marginRight: 5 }} className={"tab"}
                    onClick={() => {
                        sessionStorage.removeItem('sensor');
                        history.push(`/sensors?order_by=asc&sort_by=name`)
                    }}>SENSORS</div>
                <div data-id="tab2" style={{ marginRight: 5 }} className={"tab selected_tab"}
                >COLLECTORS</div>
            </div> */}
          <AMAdminTable
            columns={SensorTableColumns}
            data={result}
            ref={childRef}
            {...(CollectorActions?.length > 0 &&
            authDetails.permissions.Admin.sensors !== "readonly"
              ? { actions: CollectorActions }
              : null)}
            query={query}
            setQuery={setQuery}
            refresh={Listing}
          />
        </div>
      </div>
      <Popup
        open={open}
        closeOnDocumentClick
        closeOnEscape={false}
        onClose={closeModal}
        overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
        modal
        //lockScroll
      >
        <div className="modal">
          <div className="close" onClick={closeModal}></div>
          <div className="header">API Key for {actionType?.name}</div>
          <div className="content scroll_enable api-key">
            <div className="break_word font14">{apiKey}</div>

            {/*<textarea ref={keyRef} defaultValue={apiKey} style={{opacity:0}}></textarea> */}
          </div>
          <div className="popup_footer">
            <button
              style={{ display: "inline" }}
              type="button"
              className="button_main"
              onClick={handleDownload}
            >
              Download API key
            </button>
            <button
              style={{ float: "right" }}
              type="button"
              onClick={() => navigator.clipboard.writeText(apiKey)}
              className="button_main"
            >
              Copy to Clipboard
            </button>
          </div>
        </div>
      </Popup>

      {/* Delete confirmation Popup */}
      <Popup
        open={openConfirm}
        closeOnDocumentClick
        closeOnEscape={false}
        onClose={closeConfirm}
        overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
        modal
      >
        <div className="modal">
          <div className="close" onClick={closeConfirm}></div>
          <div className="header">Delete Sensor</div>
          <div className="content">
            <div className="font16 margintop20 marginbottom20 delete-sensor-container">
              <div>Are you sure you want to delete </div>
              <AMToolTipAd title={actionType.name || ""}>
                <span>
                  {actionType?.name ? (
                    <div className="delete-sensor-name">
                      <strong> {` ${actionType.name}`}</strong>{" "}
                    </div>
                  ) : (
                    " selected sensor"
                  )}
                </span>
              </AMToolTipAd>
              <div>sensor?</div>
            </div>
          </div>
          <div className="popup_footer">
            <button
              style={{ display: "inline" }}
              type="button"
              className="button_gray width25per margintop10"
              onClick={closeConfirm}
            >
              No
            </button>
            <button
              style={{ float: "right" }}
              type="button"
              className="button_main width25per"
              onClick={deleteConfirm}
            >
              Yes
            </button>
          </div>
        </div>
      </Popup>
      {showSensorUpdate ? (
        <SensorUpdatePopUp
          closeConfirm={() => setShowSensorUpdate(false)}
          text={sensorUpdateText}
        ></SensorUpdatePopUp>
      ) : null}
      {showGenerateKey ? (
        <RegenerateAPIKeyConfirm
          closeRegenerate={() => setShowGenerateKey(false)}
          confirmGenerate={confirmRegenerate}
          sensorName={actionType?.name || ""}
        />
      ) : null}
      {showCollector ? (
        <SensorCollector
          data={actionType}
          closeConfirm={() => setShowCollector(false)}
        ></SensorCollector>
      ) : null}
    </>
  );
};

export default withQueryParams(
  {
    q: StringParam,
    page: StringParam,
    rpp: NumberParam,
    sort_by: StringParam,
    order_by: StringParam,
    filters: withDefault(ArrayParam, []),
  },
  Groups
);
