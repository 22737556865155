import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { Api } from '../../../components/Axios';
import { useToasts } from '../../../components/core';
import './installSensor.scss';
import AMAdminTable from '../../../components/core/AMTable/AMAdminTable';
import { isAmopsUser } from "../../../utils/util-methods";
import { RegenerateAPIKeyConfirm } from '../reference/RegenerateAPIKeyConfirm';

const { REACT_APP_HOST_IP } = process.env;
const is4K = window.matchMedia('(min-width: 2560px)').matches ? 1 : 1;

const deploymentGuides = [
    {
        sensorType: "AD Sensor",
        url: "https://docs.authmind.com/authmind-deployment-guide/Working-version/installing-active-directory-sensor?t=ugHs-iLNlb_WjZs4yBJDyg",
    },
    {
        sensorType: "Flow Sensor",
        url: "https://docs.authmind.com/authmind-deployment-guide/Working-version/installing-a-flow-sensor?t=ugHs-iLNlb_WjZs4yBJDyg",
    },
    {
        sensorType: "Enrichment Sensor",
        url: "https://docs.authmind.com/authmind-deployment-guide/Working-version/installing-an-enrichment-sensor?t=ugHs-iLNlb_WjZs4yBJDyg",
    }
];

const queryObj = { q: '', page: 1, rpp: 10, order_by: '', sort_by: '' };

export const InstallSensor = ({ sensorId = '', sensorType = '' }) => {
    const downloadTableRef = useRef();
    const { addToast } = useToasts();
    const [tableColumns, setTableColumns] = useState([]);
    const [tableData, setTableData] = useState(null);
    const [query, setQuery] = useState(structuredClone(queryObj));
    const [apiKey, setApiKey] = useState('');
    const [lastUpdatedDate, setLastUpdatedDate] = useState('');
    const [showGenerateKey, setShowGenerateKey] = useState(false);
    const [sensorName, setSensorName] = useState('');

    const onDownload = (text) => {
        const element = document.createElement('a');
        const file = new Blob([text], { type: 'application/octet-stream' });
        element.href = URL.createObjectURL(file);
        element.download = 'refresh.tkn';
        document.body.appendChild(element);
        element.click();
        element.parentNode?.removeChild(element);
    };

    const onCopy = (text) => {
        navigator.clipboard.writeText(text);
        addToast('API key copied.', {
          appearance: 'success',
          autoDismiss: true
        });
    };

    const onRegenerate = () => {
        setShowGenerateKey(true);
    };

    const regeneratekey = () => {
        Api.get(`/sensors/${sensorId}/regenerate`)
        .then((res: { data: any }) => {
            fetchApiKey();
            setShowGenerateKey(false);
            addToast('Sensor key regenerated successfully.', {
                appearance: 'success',
                autoDismiss: true
            });
        })
        .catch((error: any) => {
            addToast('Sorry, something went wrong there, try again.', {
                appearance: 'error',
                autoDismiss: true
            });
        });
    }

    useEffect(() => {
        Api.get('/download_list')
            .then((res) => {
                let downloadObj = res.data.find(item => item.download_type == sensorType)
                let guide = deploymentGuides.find(item => item.sensorType === sensorType);
                const result = downloadObj.versions.map(item => { 
                    return {download_link: item, deployment_guide: guide, download_type: downloadObj.download_type, version: item}
                })
                setTableData({ result: result });
            })
            .catch((error: any) => {
                if (error.response && error.response.status === 500) {
                    setTableData([]);
                    addToast('Sorry, something went wrong there, try again.', {
                        appearance: 'error',
                        autoDismiss: true
                    });
                }
            });
    }, []);

    useEffect(() => {
        fetchApiKey();
    }, []);

    const fetchApiKey = () => {
        Api.get(`/sensors/${sensorId}`)
            .then((res: { data: any }) => {
                setApiKey(res.data.jwt);
                setLastUpdatedDate(res.data.modified_date);
                setSensorName(res.data.name);
            })
            .catch((error: any) => {
                addToast('Sorry, something went wrong there, try again.', {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    };

    const handleDownload = async (downloadType: string, version: string) => {
        const datas = window.localStorage.getItem('user');
        if (datas !== null) {
            const user = JSON.parse(datas);
            const downloadUrl = `${REACT_APP_HOST_IP}/api/download?download_type=${encodeURIComponent(
                downloadType
            )}&version=${encodeURIComponent(
                version
            )}&auth_token=${encodeURIComponent(user.token)}`;
            window.open(downloadUrl);
        } else {
            console.error('Something went wrong. Please try after some time.');
        }
    };

    const handleGuideClick = (sensorType: string) => {
        const selectedGuide = deploymentGuides.find(
            (guide) =>
                guide.sensorType.toLowerCase() === sensorType.toLowerCase()
        );

        if (selectedGuide) {
            window.open(selectedGuide.url, '_blank');
        }
    };

    const TableColumns = [
        {
            headerText: 'Download Link',
            key: 'download_link',
            classes: 'align_left ellipsis',
            sortable: false,
            html: (data) => {
                return (
                    <a
                        href='#'
                        onClick={(e) => {
                            e.preventDefault();
                            handleDownload(data.download_type, data.version);
                        }}
                        style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            lineHeight: '24px'
                        }}
                        className='link-text'
                    >
                        {data.download_link}
                    </a>
                );
            },
            styles: {
                width: is4K * 200
            }
        },
        {
            headerText: 'Deployment Guide',
            key: 'deployment_guide',
            classes: 'align_left ellipsis',
            sortable: false,
            html: (data) => {
                return (
                    <a
                        href='#'
                        onClick={(e) => {
                            e.preventDefault();
                            handleGuideClick(data.download_type);
                        }}
                        style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            lineHeight: '24px'
                        }}
                        className='link-text'
                    >
                        {`Deployment Guide`}
                    </a>
                );
            },
            styles: {
                width: is4K * 200
            }
        }
    ];

    const apiKeyPreview = () => {
      if (apiKey === '')
          return '';
      return apiKey.substring(0, 15) + '...' + apiKey.slice(-10);
    }

    const userDetails = localStorage.getItem('user') as string;
    const user = JSON.parse(userDetails);

    return (
        <>
            <div className='install-sensor-container'>
                <div className='container-item scrollable-table'>
                    <AMAdminTable
                        columns={TableColumns}
                        data={tableData}
                        ref={downloadTableRef}
                        query={query}
                        setQuery={setQuery}
                    ></AMAdminTable>
                </div>
                <div className='container-item flow-sensor-api-key-group'>
                    <label>API Key for Sensor {' '}
                    <span className="sub-text">(Copy or Download the API key below to use as part of the Sensor installation process.)</span>
                    </label>
                    <input
                        type='text'
                        className="api-key-input"
                        placeholder='API Key for Flow Sensor'
                        value={apiKeyPreview()}
                        disabled
                    />
                    <span className='timestamp-overlay'>
                      {lastUpdatedDate !== '' ? `Last Updated: ${moment(lastUpdatedDate).format(
                            'MMM DD YYYY, hh:mm A'
                        )}` : null}
                    </span>
                </div>

                <div className='container-item'>
                    <div className='action-buttons-group'>
                        <button
                            onClick={() => onDownload(apiKey)}
                            type='button'
                            className={'button_styled'}
                        >
                            Download API Key
                        </button>

                        <button
                            onClick={() => onCopy(apiKey)}
                            type='button'
                            className={'button_styled'}
                        >
                            Copy to Clipboard
                        </button>

                        {isAmopsUser(user.email) && (<button
                            onClick={onRegenerate}
                            type='button'
                            className={'button_styled'}
                        >
                            Regenerate API Key
                        </button>)}
                    </div>
                </div>
            </div>
            {showGenerateKey && <RegenerateAPIKeyConfirm confirmGenerate={regeneratekey} sensorName={sensorName} closeRegenerate={() => setShowGenerateKey(false)} />} 
        </>
    );
};
