import React, { useEffect, useReducer } from 'react';
import { useTimeBasedSearchReducer } from './TimeBasedSearchReducer';
import TimeBasedSearchDockingComponent from './components/TimeBasedSearchDockingComponent';
import {
    TimeBasedSearchDispatchContextProvider,
    TimeBasedSearchPropsContextProvider,
    TimeBasedSearchStateContextProvider
} from './useTimeBasedSearch';
import './TimeBasedSearchStyle.scss';
import ACTION_TYPES from './TimeBasedSearchActionTypes';

const TimeBasedSearchContainer = (props) => {

    const isEnabledRaw = localStorage.getItem("TIME_BASED_SEARCH_FEATURE");
    let isEnabled = false;
    if (isEnabledRaw) {
        isEnabled = JSON.parse(isEnabledRaw);
    }

    const { TimeBasedSearchReducer, initialStore } = useTimeBasedSearchReducer();
    const [store, dispatchAction] = useReducer(
        TimeBasedSearchReducer,
        initialStore
    );

    useEffect(() => {
        // console.log({props})
        if (props.filters && props.filters.length > 0 && !props.forAdvancedSearch) {
            setTimeout(() => {
                dispatchAction({ type: ACTION_TYPES.UPDATE_FROM_PROPS, value: props.filters })
            }, 50)
        }
        if (props.filters && props.filters.length === 0 && (!props.forAdvancedSearch || props.isClearFilter)) {
            dispatchAction({ type: ACTION_TYPES.CLEAR_FILTERS, value: store });
        }
    }, [props.filters])

    useEffect(() => {
        // console.log({props})
        if (props.filters && props.filters.length > 0 && props.forAdvancedSearch) {
            setTimeout(() => {
                dispatchAction({ type: ACTION_TYPES.UPDATE_FROM_PROPS, value: props.filters })
            }, 50)
        }
        // if (props.filters && props.filters.length === 0) {
        //     dispatchAction({ type: ACTION_TYPES.CLEAR_FILTERS, value: store });
        // }
    }, [props.filters])

    return (
        <>
            {
                isEnabled && <div className='time-based-search-container'>
                    <TimeBasedSearchPropsContextProvider value={props}>
                        <TimeBasedSearchDispatchContextProvider value={dispatchAction}>
                            <TimeBasedSearchStateContextProvider value={store}>
                                <TimeBasedSearchDockingComponent></TimeBasedSearchDockingComponent>
                            </TimeBasedSearchStateContextProvider>
                        </TimeBasedSearchDispatchContextProvider>
                    </TimeBasedSearchPropsContextProvider>
                </div>
            }
        </>
    );
};

export default TimeBasedSearchContainer;
