import React, { useEffect, useState } from 'react';
import '../../sub/siemConfiguration.scss';
import TagsInput from 'react-tagsinput';
import InfoIcon from '@mui/icons-material/Info';


export const KafkaSiemConfiguration = ({ register , setValue ,sensorData }) => {
    const [kafka_brokers, setKafka_brokers] = useState([]);
    const AddTags = (tag: any) => {
        setKafka_brokers(tag);
        setValue(tag);
    };

    const registerFields = () => {
        register('kafka_brokers', {
            required: true,
            shouldUnregister: true
        });

    
       
    };

    useEffect(()=>{
            if(sensorData?.kafka_brokers &&  sensorData?.kafka_brokers.length > 0){
            setValue("kafka_brokers",sensorData?.kafka_brokers);
            setKafka_brokers(sensorData?.kafka_brokers);
            } else {
                setValue("kafka_brokers",[]);
                setKafka_brokers([]);
            }
        
        
    },[sensorData])

    useEffect(() => {
        registerFields();
    }, []);

    return (
        <div className='siem-vendor-configuration-container'>
            <div className={`form_sub_section`}>
                <div className='form_sub_section_header'>
                    Collect Kafka Logs
                </div>
                <div className='form_sub_section_content'>
                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>Kafka Brokers*</label>
                            <TagsInput
                                value={kafka_brokers}
                                onChange={AddTags}
                                inputProps={{
                                    placeholder: 'Enter Kafka Brokers'
                                }}
                            />
                            <div className='warning-container'>
                                <InfoIcon
                                    style={{
                                        fontSize: '1.5em',
                                        color: '#87929E',
                                        fill: 'none',
                                        stroke: '#87929E',
                                        marginBottom: '0.2em'
                                    }}
                                />
                                <span className='sub-text'>
                                    Note: Hit 'Enter' or 'Tab' key after each
                                    Broker.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


