import moment from "moment";
import { getRuleName } from "../../utils/util-methods";
import { getIssueNameById } from "../../pages/playbooks/helpers/playbook-helper";

const RiskyEntityPathMap = {
    '/posture/identities': {
        label: 'Identity',
        query: 'identity_name',
        shadowNonePath: 'identities'
    },
    '/posture/assets': {
        label: 'Asset',
        query: 'asset_name',
        shadowNonePath: 'assets'
    },
    '/posture/directory': {
        label: 'Directory',
        query: 'dir_name',
        shadowNonePath: 'directories'
    },
    '/posture/accesses': {
        label: 'Access',
        query: 'asset_name',
        shadowNonePath: 'accesses'
    }
};

interface Breadcrumb {
    title: string,
    url?: string,
    isLink: boolean,
    state?: any,
    search?: string
}

const WidgetBreadcrumbs = [
    {
        id: 'TopNewRiskyPosture',
        customData: {},
        getBreadcrumb: function () {
            return this.breadcrumbArray;
        },
        clearOldCrumbs: true,
        breadcrumbArray: [] as any,
        transform: function (history: any, customData?: any) {
            const type = history.location.pathname;
            const query = getSearchQuery(history);
            let value = undefined;
            if (query?.length > 0) {
                let entityType = RiskyEntityPathMap[type as keyof typeof RiskyEntityPathMap];
                const entityValue = getQueryValue(query, entityType.query, true);
                if (!entityValue) {
                    return [];
                } else
                    return [{
                        title: 'Dashboard - Top New Risks',
                        url: '/',
                        isLink: true
                    },
                    /* {
                        title: 'Top New Risks',
                        isLink: false
                    }, */
                    {
                        title: 'Issues for ' + entityType.label + ' \"' + entityValue + '\"',
                        isLink: false
                    }]
            } else {
                return [];
            }
        }
    },
    {
        id: 'RiskLevelPosture',
        clearOldCrumbs: true,
        customData: {},
        getBreadcrumb: function () {
            return this.breadcrumbArray;
        },
        breadcrumbArray: [] as any,
        transform: function (history: any, customData?: any) {
            const query = getSearchQuery(history);
            if (query?.length > 0) {
                const risk = getQueryValue(query, 'risk')
                const rule_name = getQueryValue(query, 'rule_name');
                const rule_type = getQueryValue(query, 'rule_name');
                if (rule_type && risk) {
                    return [{
                        title: 'Dashboard',
                        url: '/',
                        isLink: true
                    },
                    {
                        title: `${risk.charAt(0).toUpperCase()}${risk.slice(1)}-risk issues for "${rule_type}"`,
                        isLink: false
                    }]
                } else if (!risk || !rule_name) {
                    return [];
                } else
                    return [{
                        title: 'Dashboard',
                        url: '/',
                        isLink: true
                    },
                    {
                        title: `${risk.charAt(0).toUpperCase()}${risk.slice(1)}-risk issues for "${rule_name}"`,
                        isLink: false
                    }]
            } else {
                return [];
            }
        }
    },
    {
        id: 'LomPosture',
        clearOldCrumbs: true,
        customData: {},
        getBreadcrumb: function () {
            return this.breadcrumbArray;
        },
        breadcrumbArray: [] as any,
        transform: function (history: any, customData?: any) {
            const query = getSearchQuery(history);
            if (query?.length > 0) {
                const risk = getQueryValue(query, 'risk');
                const rule_name = getQueryValue(query, 'rule_name');
                if (!risk && !rule_name) {
                    return [];
                } else
                    return [{
                        title: 'Dashboard - Lack of MFA',
                        url: '/',
                        isLink: true
                    },
                    /* {
                        title: 'Lack of MFA',
                        isLink: false
                    }, */
                    {
                        title: !risk ? `Assets with Lack of MFA issues` : `Assets with ${risk}-risk Lack of MFA issues`,
                        isLink: false
                    }]
            } else {
                return [];
            }
        }
    },
    {
        id: 'WeakPosture',
        customData: {},
        clearOldCrumbs: true,
        getBreadcrumb: function () {
            return this.breadcrumbArray;
        },
        breadcrumbArray: [] as any,
        transform: function (history: any, customData?: any) {
            const query = getSearchQuery(history);
            if (query?.length > 0) {
                const risk = getQueryValue(query, 'risk');
                const rule_name = getQueryValue(query, 'rule_name');
                if (!risk && !rule_name) {
                    return [];
                } else
                    return [{
                        title: 'Dashboard - Credential Risk',
                        url: '/',
                        isLink: true
                    },
                    /*   {
                          title: 'Weak Passwords',
                          isLink: false
                      }, */
                    {
                        title: !risk ? `Identities with ${rule_name}` : `Identities with ${risk}-risk ${rule_name}`,
                        isLink: false
                    }]
            } else {
                return [];
            }
        }
    },
    {
        id: 'CompromisedPosture',
        customData: {},
        clearOldCrumbs: true,
        getBreadcrumb: function () {
            return this.breadcrumbArray;
        },
        breadcrumbArray: [] as any,
        transform: function (history: any, customData?: any) {
            const query = getSearchQuery(history);
            if (query?.length > 0) {
                const risk = getQueryValue(query, 'risk');
                const rule_name = getQueryValue(query, 'rule_name');
                if (!risk && !rule_name) {
                    return [];
                } else {
                    return [{
                        title: 'Dashboard - Compromised',
                        url: '/',
                        isLink: true
                    },
                    /*  {
                         title: 'Compromised',
                         isLink: false
                     }, */
                    {
                        title: !risk ? `Identities with Compromised Passwords or Information` :
                            `Identities with ${risk}-risk Compromised Passwords or Information`,
                        isLink: false
                    }]
                }
            } else {
                return [];
            }
        }
    },
    {
        id: 'ShadowActivityPosture',
        customData: {} as any,
        clearOldCrumbs: true,
        getBreadcrumb: function () {
            return this.breadcrumbArray;
        },
        breadcrumbArray: [] as any,
        transform: function (history: any, customData?: any) {
            const query = getSearchQuery(history);
            let issue_type_value, asset_value, title, showAllTitle;
            if (query?.length > 0) {
                issue_type_value = getQueryValue(query, 'rule_name');
                const path = history.location.pathname;
                switch (path) {
                    case '/identities':
                        if (!issue_type_value) {
                            issue_type_value = getQueryValue(query, 'identity_playbooks');
                        }
                        break;
                    case '/assets':
                        if (!issue_type_value) {
                            issue_type_value = getQueryValue(query, 'asset_playbooks');
                        }
                        break;
                    case '/directory':
                        if (!issue_type_value) {
                            issue_type_value = getQueryValue(query, 'directory_playbooks');
                        }
                        break;
                }

                const assetName = query.find((item: string) => ['asset_name', 'identity_name', 'dir_name'].find((i: string) => item.includes(i)));
                asset_value = assetName?.slice(assetName.indexOf(':') + 1);
                this.customData.issue_type_value = issue_type_value || this.customData.issue_type_value;
                const type = history.location.pathname;
                const noneData = RiskyEntityPathMap[type as keyof typeof RiskyEntityPathMap];
                switch (issue_type_value) {
                    case `Suspicious Outbound Access`:
                        asset_value && issue_type_value ? title = `Suspicious Outbound Accesses by identity ${asset_value}` :
                            showAllTitle = `All identities with Suspicious Outbound Accesses`
                            ;
                        break;
                    case `Shadow Identity Systems`:
                        asset_value && issue_type_value ?
                            title = `Identities using Shadow Identity Systems ${asset_value}` :
                            showAllTitle = 'All Shadow Identity Systems'
                        break;
                    case `Suspicious Inbound Access`:
                        asset_value && issue_type_value ? title = `Suspicious Inbound Accesses to asset ${asset_value}` :
                            showAllTitle = 'All assets with Suspicious Inbound Accesses'

                        break;
                    case `Shadow Access`:
                        asset_value && issue_type_value ? title = `Identities doing Shadow Access to asset ${asset_value}` :
                            showAllTitle = 'All assets having Shadow Accesses';
                        break;
                    default:
                        asset_value && issue_type_value ?
                            title = `Identities accessing ${issue_type_value} ${asset_value}` :
                            showAllTitle = `All ${issue_type_value}`
                }

                if (!issue_type_value && !asset_value) {
                    return [];
                } else if (!issue_type_value && this.customData.issue_type_value) {
                    switch (this.customData.issue_type_value) {
                        case `Suspicious Outbound Access`:
                            title = `All accesses by identity ${asset_value}`;
                            break;
                        case `Shadow Identity Systems`:
                            title = `All accesses using directory ${asset_value}`;
                            break;
                        default:
                            title = `All accesses to asset ${asset_value}`;
                            break;
                    }
                }

                return [{
                    title: `Dashboard - ${customData.pageName} - ${this.customData.issue_type_value}`,
                    url: '/',
                    isLink: true
                },
                /*  {
                     title: 'Shadow Activity',
                     isLink: false
                 }, */
                /*  {
                     title: `${this.customData.issue_type_value}`,
                     isLink: false
                 }, */
                {
                    title: !asset_value ? `${showAllTitle}` : `${title}`,
                    isLink: false
                }];
            } else {
                return [];
            }
        }
    },
    {
        id: 'Past24Incidents',
        customData: {},
        clearOldCrumbs: true,
        getBreadcrumb: function () {
            return this.breadcrumbArray;
        },
        breadcrumbArray: [] as any,
        transform: function (history: any, customData?: any) {
            const q = getSearchQuery(history);
            let s_time;
            if (q?.length)
                s_time = getQueryValue(q, 's_time');
            if (!s_time) {
                return [];
            }
            return [{
                title: 'Dashboard',
                url: '/',
                isLink: true
            },
            {
                title: 'Incidents in past 24 hours',
                isLink: false
            }];
        }
    },
    {
        id: 'RecentAccessIncidents',
        customData: {},
        getBreadcrumb: function () {
            return this.breadcrumbArray;
        },
        clearOldCrumbs: true,
        breadcrumbArray: [] as any,
        transform: function (history: any, customData?: any) {
            const q = getSearchQuery(history);
            if (q?.length > 0) {
                const risk_value = getQueryValue(q, 'id');
                return [{
                    title: 'Dashboard - Total Issues',
                    url: '/',
                    isLink: true
                },
                /*  {
                     title: 'Total Issues',
                     isLink: false
                 }, */
                {
                    title: `Incident #${risk_value}`,
                    isLink: false
                }
                ]
            }
        }
    },
    {
        id: 'TopNewRiskyIncidents',
        customData: {},
        clearOldCrumbs: true,
        getBreadcrumb: function () {
            return this.breadcrumbArray;
        },
        breadcrumbArray: [] as any,
        transform: function (history: any, customData?: any) {
            const name = customData.entity_name || customData.name;
            let entity_type = ``;

            if (customData.entity_type)
                entity_type = customData.entity_type;
            else
                entity_type = customData.entity_Name;

            const issue_type = customData.matching_rules ? (customData.matching_rules.length > 1 ?
                `${customData.matching_rules.length} types of` : `${customData.matching_rules[0]}`) :
                customData.incident_names.split(',').length > 1 ?
                    `${customData.incident_names.split(',').length} types of` : customData.incident_names;
            if (customData) {
                const q = getSearchQuery(history);
                if (q?.length) {
                    const rl = getQueryValue(q, 'rule_name');
                    const asset = getQueryValue(q, 'd_name') || getQueryValue(q, 's_name') || getQueryValue(q, 'dir_hostname');
                    if (!rl || !asset) {
                        return [];
                    }
                } else {
                    return [];
                }
                return [{
                    title: `Dashboard - ${customData?.heading}`,
                    url: '/',
                    isLink: true
                },
                /* {
                    title: 'Top New Risks',
                    isLink: false
                }, */
                {
                    title: `${issue_type} incidents for ${entity_type} “${name}” ${customData?.title}`,
                    isLink: false
                }
                ]
            } else {
                return [];
            }
        }
    },
    {
        id: 'RiskyProtocolsIncidents',
        customData: {},
        clearOldCrumbs: true,
        getBreadcrumb: function () {
            return this.breadcrumbArray;
        },
        breadcrumbArray: [] as any,
        transform: function (history: any, customData?: any) {
            const q = getSearchQuery(history);
            if (q?.length > 0) {
                const risk_value = getQueryValue(q, 'risk');
                const rule_name = getQueryValue(q, 'rule_name');
                const d_protocol = getQueryValue(q, 'd_protocol');
                if (rule_name && (!risk_value && !d_protocol)) {
                    return [{
                        title: 'Dashboard - Risky Protocols',
                        url: '/',
                        isLink: true
                    },
                    {
                        title: `Incidents with Auth Protocol Quality issues`,
                        isLink: false
                    }]
                } else if (!risk_value || !rule_name || !d_protocol) {
                    return [];
                } else
                    return [{
                        title: 'Dashboard - Risky Protocols',
                        url: '/',
                        isLink: true
                    },
                    {
                        title: !risk_value ? `Incidents with Auth Protocol Quality issues` :
                            `Incidents with ${risk_value}-risk Auth Protocol Quality issues`,
                        isLink: false
                    }]
            } else {
                return [];
            }
        }
    },
    {
        id: 'BadHashsIncidents',
        customData: {},
        clearOldCrumbs: true,
        getBreadcrumb: function () {
            return this.breadcrumbArray;
        },
        breadcrumbArray: [] as any,
        transform: function (history: any, customData?: any) {
            const q = getSearchQuery(history);
            if (q?.length > 0) {
                const risk_value = getQueryValue(q, 'risk');
                const rule_name = getQueryValue(q, 'rule_name') || getQueryValue(q, 'rule_name');
                const hash = getQueryValue(q, 'hash');
                if (rule_name && (!risk_value && !hash)) {
                    return [{
                        title: 'Dashboard - Bad Hashes',
                        url: '/',
                        isLink: true
                    },
                    {
                        title: `Directories with Auth Hash Security issues`,
                        isLink: false
                    }]
                } else if (!risk_value || !rule_name || !hash) {
                    return [];
                } else
                    return [{
                        title: 'Dashboard - Bad Hashes',
                        url: '/',
                        isLink: true
                    },
                    {
                        title: !risk_value ? `Incidents with Auth Hash Security issues` :
                            `Incidents with ${risk_value}-risk Auth Hash Security issues`,
                        isLink: false
                    }]
            } else {
                return [];
            }
        }
    },
    {
        id: 'ShadowActivityIncidents',
        customData: {},
        getBreadcrumb: function () {
            return this.breadcrumbArray;
        },
        clearOldCrumbs: true,
        breadcrumbArray: [] as any,
        transform: function (history: any, customData?: any) {
            let rule_value, asset_value;
            const query = getSearchQuery(history);
            if (query?.length) {
                rule_value = getQueryValue(query, 'rule_name');
                const path = history.location.pathname;
                switch (path) {
                    case '/identities':
                        if (!rule_value) {
                            rule_value = getQueryValue(query, 'identity_playbooks');
                        }
                        break;
                    case '/assets':
                        if (!rule_value) {
                            rule_value = getQueryValue(query, 'asset_playbooks');
                        }
                        break;
                    case '/directory':
                        if (!rule_value) {
                            rule_value = getQueryValue(query, 'directory_playbooks');
                        }
                        break;
                }
                const assetName = query.find((item: string) => ['dir_hostname', 'd_name', 's_name'].find((i: string) => item.includes(i)));
                asset_value = assetName?.slice(assetName.indexOf(':') + 1);
                let title = `Incidents for All issues`, showAllTitle = `Incidents for All issues`;
                if (rule_value?.split(',').length > 3) {
                    title = showAllTitle = `Incidents for all Shadow Activity issues`;
                } else {
                    if (rule_value && asset_value) {
                        if (`Suspicious Outbound Access` == rule_value) {
                            title = `Incidents for Suspicious Outbound Access by identity "${asset_value}"`
                        } else if ([`Shadow Access`, `Shadow External Access`, `Suspicious Inbound Access`].includes(rule_value)) {
                            title = `Incidents for ${rule_value} to asset "${asset_value}"`;
                        } else {
                            title = `Incidents for ${rule_value} "${asset_value}"`;
                        }
                        showAllTitle = `All incidents for ${rule_value} issues`
                    } else if (!rule_value) {
                        return [];
                    } else if (!asset_value) {
                        if (`Suspicious Outbound Access` == rule_value) {
                            title = `Incidents for Suspicious Outbound Access`;
                            showAllTitle = title;
                        } else if ([`Shadow Access`, `Shadow External Access`, `Suspicious Inbound Access`].includes(rule_value)) {
                            title = `Incidents for ${rule_value}`;
                            showAllTitle = title = `Incidents for ${rule_value}`;
                        } else {
                            showAllTitle = title = `Incidents for ${rule_value}`;
                        }
                    }
                }

                return [{
                    title: `Dashboard - ${customData.pageName} - ${rule_value}`,
                    url: '/',
                    isLink: true
                },
                /*  {
                     title: 'Shadow Activity',
                     isLink: false
                 },
                 {
                     title: `${rule_value}`,
                     isLink: false
                 }, */
                {
                    title: !asset_value ? showAllTitle :
                        title,
                    isLink: false
                }]
            } else {
                return [];
            }
        }
    },
    {
        id: 'TrendIncidents',
        customData: {},
        clearOldCrumbs: true,
        getBreadcrumb: function () {
            return this.breadcrumbArray;
        },
        breadcrumbArray: [] as any,
        transform: function (history: any, customData?: any) {
            let risk_value, start_time_value, end_time_value;
            const query = getSearchQuery(history);
            if (query?.length > 0) {
                risk_value = getQueryValue(query, 'risk');
                start_time_value = getQueryValue(query, 's_m_time');
                end_time_value = getQueryValue(query, 'e_m_time');
                if (!start_time_value || !end_time_value || !risk_value) {
                    return [];
                }
                start_time_value = moment.unix(+start_time_value).format('MMM DD, YYYY hh:mm A')
                end_time_value = moment.unix(+end_time_value).format('MMM DD, YYYY hh:mm A')
                return [{
                    title: 'Dashboard - Incidents Trends',
                    url: '/',
                    isLink: true
                },
                /* {
                    title: 'Incidents Trends',
                    isLink: false
                }, */
                {
                    title: `${risk_value.charAt(0).toUpperCase()}${risk_value.slice(1)}-risk incidents between ${start_time_value} and ${end_time_value}`,
                    isLink: false
                }]
            } else {
                return [];
            }
        }
    },
    {
        id: 'IdentityPosture',
        customData: {},
        getBreadcrumb: function () {
            return this.breadcrumbArray;
        },
        breadcrumbArray: [] as any,
        clearOldCrumbs: true,
        transform: function (history: any, customData?: any) {
            let rule_type_value = undefined, base_title;
            let title, base_url;
            const query = getSearchQuery(history);
            if (query && query.length > 0) {
                rule_type_value = getRuleName(getQueryValue(query, 'rule_name'));

                const path = history.location.pathname;
                switch (path) {
                    case '/identities':
                        if (!rule_type_value) {
                            rule_type_value = getQueryValue(query, 'identity_playbooks');
                        }
                        const i_type_value = getQueryValue(query, 'identity_type');
                        const is_known_value = getQueryValue(query, 'is_known');
                        if (rule_type_value) {
                            title = `Identities having ${rule_type_value} issues`;
                        } else if (i_type_value) {
                            if (i_type_value == 'User' && is_known_value == 'false') {
                                title = `Identities of type Unresolved User`;
                            } else if (i_type_value == 'Service' && is_known_value == 'false') {
                                title = `Identities of type Unresolved Service`;
                            } else
                                title = `Identities of type ${i_type_value}`;
                        }
                        base_url = '/identities';
                        base_title = 'Identities';
                        break;
                    case '/assets':
                        if (!rule_type_value) {
                            rule_type_value = getQueryValue(query, 'asset_playbooks');
                        }
                        const a_type_value = getQueryValue(query, 'asset_type');
                        if (rule_type_value) {
                            title = `Assets having ${rule_type_value} issues`
                        } else if (a_type_value) {
                            title = `Assets of type ${a_type_value}`;
                        }
                        base_url = '/assets';
                        base_title = 'Assets';
                        break;
                    case '/directory':
                        if (!rule_type_value) {
                            rule_type_value = getQueryValue(query, 'directory_playbooks');
                        }
                        const dir_type_value = getQueryValue(query, 'dir_type');
                        if (rule_type_value) {
                            title = `Directories having ${rule_type_value} issues`
                        } else if (dir_type_value) {
                            title = `Directories of type ${dir_type_value}`;
                        }
                        base_url = '/directory';
                        base_title = 'Identity Systems';
                        break;
                }

                if (title) {
                    return [{
                        title: base_title,
                        url: base_url,
                        isLink: true
                    },
                    {
                        title: title,
                        isLink: false
                    }
                    ]
                }
            }
        }
    },
    {
        id: 'PostureWithIncident',
        customData: {},
        getBreadcrumb: function () {
            return this.breadcrumbArray;
        },
        breadcrumbArray: [] as any,
        transform: function (history: any, customData?: any) {
            let rule_type_value = undefined, base_title, type_value;
            let title, base_url;
            const query = getSearchQuery(history);
            if (query && query.length > 0) {
                rule_type_value = getRuleName(getQueryValue(query, 'rule_name'));
                const path = history.location?.state?.prevPath;
                switch (path) {
                    case '/identities':
                        if (!rule_type_value) {
                            rule_type_value = getQueryValue(query, 'identity_playbooks');
                        }
                        type_value = getQueryValue(query, 's_type');
                        title = `All incidents of identity type ${type_value}`
                        base_url = '/identities';
                        base_title = 'Identities';
                        break;
                    case '/assets':
                        if (!rule_type_value) {
                            rule_type_value = getQueryValue(query, 'asset_playbooks');
                        }
                        type_value = getQueryValue(query, 'd_type');
                        title = `All incidents of asset type ${type_value}`;
                        base_url = '/assets';
                        base_title = 'Assets';
                        break;
                    case '/directory':
                        if (!rule_type_value) {
                            rule_type_value = getQueryValue(query, 'directory_playbooks');
                        }
                        type_value = getQueryValue(query, 'dir_type');
                        title = `All incidents of directory type ${type_value}`;
                        base_url = '/directory';
                        base_title = 'Identity Systems';
                        break;
                }
                if (!type_value || !rule_type_value) {
                    return [];
                } else
                    if (title) {
                        return [{
                            title: base_title,
                            url: base_url,
                            isLink: true
                        },
                        {
                            title: title,
                            isLink: false
                        }
                        ]
                    } else {
                        return [];
                    }
            }
            return []
        }
    },
    {
        id: 'IdentityIncidents',
        customData: {},
        getBreadcrumb: function () {
            return this.breadcrumbArray;
        },
        breadcrumbArray: [] as any,
        transform: function (history: any, customData?: any) {
            this.breadcrumbArray = [];
            let rule_value, identity_value, title;
            const query = getSearchQuery(history);
            if (query?.length > 0) {
                rule_value = getQueryValue(query, 'rule_name');
                if (!rule_value) {
                    rule_value = getQueryValue(query, 'identity_playbooks');
                }
                identity_value = getQueryValue(query, 's_name', true);
                const prevBreadId = history?.location?.state?.prevWidget;
                const prevBread = getBredcumbConfigById(prevBreadId)?.getBreadcrumb();
                if (rule_value) {
                    title = `${rule_value} incidents for "${identity_value}"`
                } else {
                    title = `All incidents for identity "${identity_value}"`;
                }
                const baseBread: Breadcrumb = {
                    url: history?.location?.state?.prevUrl,
                    isLink: true,
                    state: {
                        breadcrumbId: prevBreadId
                    },
                    search: history?.location?.state?.prevSearch,
                    title: 'Identities'
                };

                let isIdExist = false;

                if (prevBread?.length > 0) {
                    const index = prevBread.map((item: Breadcrumb) => item.title).findIndex((item: string) => item == "Identities");
                    if (index > -1) {
                        prevBread.splice(index, 1, baseBread);
                        isIdExist = true;
                    }
                    this.breadcrumbArray.push(...prevBread);
                }
                const currentBreads = [];
                if (!isIdExist) {
                    currentBreads.push(baseBread);
                }
                currentBreads.push({
                    title, isLink: false
                });
                return !identity_value ? [] : currentBreads;
            } else {
                return [];
            }
        }
    },
    {
        id: 'AssetsIncidents',
        customData: {},
        getBreadcrumb: function () {
            return this.breadcrumbArray;
        },
        breadcrumbArray: [] as any,
        transform: function (history: any, customData?: any) {
            this.breadcrumbArray = [];
            let rule_value, identity_value, title;
            const query = getSearchQuery(history);
            if (query?.length > 0) {
                rule_value = getQueryValue(query, 'rule_name');
                if (!rule_value) {
                    rule_value = getQueryValue(query, 'asset_playbooks');
                }

                identity_value = getQueryValue(query, 'd_name', true);
                const prevBreadId = history?.location?.state?.prevWidget;
                const prevBread = getBredcumbConfigById(prevBreadId)?.getBreadcrumb();
                if (rule_value) {
                    title = `${rule_value} incidents for "${identity_value}"`
                } else {
                    title = `All incidents for asset "${identity_value}"`;
                }
                const baseBread: Breadcrumb = {
                    url: history?.location?.state?.prevUrl,
                    isLink: true,
                    state: {
                        breadcrumbId: prevBreadId
                    },
                    search: history?.location?.state?.prevSearch,
                    title: 'Assets'
                };

                let isIdExist = false;

                if (prevBread?.length > 0) {
                    const index = prevBread.map((item: Breadcrumb) => item.title).findIndex((item: string) => item == "Assets");
                    if (index > -1) {
                        prevBread.splice(index, 1, baseBread);
                        isIdExist = true;
                    }
                    this.breadcrumbArray.push(...prevBread);
                }
                const currentBreads = [];
                if (!isIdExist) {
                    currentBreads.push(baseBread);
                }
                currentBreads.push({
                    title, isLink: false
                });
                return !identity_value ? [] : currentBreads;
            } else {
                return [];
            }
        }
    },
    {
        id: 'DirectoryIncidents',
        customData: {},
        getBreadcrumb: function () {
            return this.breadcrumbArray;
        },
        breadcrumbArray: [] as any,
        transform: function (history: any, customData?: any) {
            this.breadcrumbArray = [];
            let rule_value, identity_value, title;
            const query = getSearchQuery(history);
            if (query?.length > 0) {
                rule_value = getQueryValue(query, 'rule_name');
                if (!rule_value) {
                    rule_value = getQueryValue(query, 'directory_playbooks');
                }
                identity_value = getQueryValue(query, 'dir_name', true);
                const prevBreadId = history?.location?.state?.prevWidget;
                const prevBread = getBredcumbConfigById(prevBreadId)?.getBreadcrumb();
                if (rule_value) {
                    title = `${rule_value} incidents for directory "${identity_value}"`
                } else {
                    title = `All incidents for directory "${identity_value}"`;
                }
                const baseBread: Breadcrumb = {
                    url: history?.location?.state?.prevUrl,
                    isLink: true,
                    state: {
                        breadcrumbId: prevBreadId
                    },
                    search: history?.location?.state?.prevSearch,
                    title: 'Identity Systems'
                };

                let isIdExist = false;

                if (prevBread?.length > 0) {
                    const index = prevBread.map((item: Breadcrumb) => item.title).findIndex((item: string) => item == "Identity Systems");
                    if (index > -1) {
                        prevBread.splice(index, 1, baseBread);
                        isIdExist = true;
                    }
                    this.breadcrumbArray.push(...prevBread);
                }
                const currentBreads = [];
                if (!isIdExist) {
                    currentBreads.push(baseBread);
                }
                currentBreads.push({
                    title, isLink: false
                });
                return !identity_value ? [] : currentBreads;
            } else {
                return [];
            }
        }
    },
    {
        id: 'AccessIncidents',
        customData: {},
        getBreadcrumb: function () {
            return this.breadcrumbArray;
        },
        breadcrumbArray: [] as any,
        transform: function (history: any, customData?: any) {
            this.breadcrumbArray = [];
            let rule_value, identity_value, title;
            const query = getSearchQuery(history);
            if (query?.length > 0) {
                rule_value = getQueryValue(query, 'd_name', true);
                identity_value = getQueryValue(query, 's_name', true) || getQueryValue(query, 'dir_hostname', true);
                const prevBreadId = history?.location?.state?.prevWidget;
                const prevBread = getBredcumbConfigById(prevBreadId)?.getBreadcrumb();

                title = `All incidents for identity "${identity_value}" accessing asset "${rule_value}"`

                const baseBread: Breadcrumb = {
                    url: history?.location?.state?.prevUrl,
                    isLink: true,
                    state: {
                        breadcrumbId: prevBreadId
                    },
                    search: history?.location?.state?.prevSearch,
                    title: 'Accesses'
                };

                let isIdExist = false;

                if (prevBread?.length > 0) {
                    const index = prevBread.map((item: Breadcrumb) => item.title).findIndex((item: string) => item == "Accesses");
                    if (index > -1) {
                        prevBread.splice(index, 1, baseBread);
                        isIdExist = true;
                    }
                    this.breadcrumbArray.push(...prevBread);
                }
                const currentBreads = [];
                if (!isIdExist) {
                    currentBreads.push(baseBread);
                }
                currentBreads.push({
                    title, isLink: false
                });
                this.breadcrumbArray.push(...currentBreads);
                return (!rule_value || !identity_value) ? [] : currentBreads;
            } else {
                return []
            }
        }
    },
    {
        id: 'PostureAccesses',
        customData: {} as any,
        getBreadcrumb: function () {
            return this.breadcrumbArray;
        },
        breadcrumbArray: [] as any,
        transform: function (history: any, customData?: any) {
            this.breadcrumbArray = [];
            let asset_value, identity_value, title, baseTitle = '', dir_name, auth_type;
            const query = getSearchQuery(history);
            if (query?.length > 0) {
                asset_value = getQueryValue(query, 'asset_name', true);
                identity_value = getQueryValue(query, 'identity_name', true);
                dir_name = getQueryValue(query, 'dir_name', true);
                auth_type = getQueryValue(query, 'auth_type', true);
                const prevBreadId = history?.location?.state?.prevWidget;
                const prevBread = getBredcumbConfigById(prevBreadId)?.getBreadcrumb();
                this.customData = customData || this.customData;
                if (asset_value) {
                    title = `All identities accessing asset "${asset_value}"`;
                    baseTitle = `Assets`
                } else if (identity_value) {
                    title = `All assets accessed by identity "${identity_value}"`;
                    baseTitle = `Identities`
                } else if (this.customData && this.customData.path) {
                    if (this.customData.path == 'identity') {
                        title = `All identities authenticating with directory "${this.customData.directory_name}"`;
                    } else if (this.customData.path == 'asset') {
                        title = `All assets authenticating with directory "${this.customData.directory_name}"`;
                    } else if (this.customData.path == 'failDir') {
                        title = `Accesses with unsuccessful authentications on directory "${this.customData.directory_name}"`;
                        if (!auth_type) {
                            /* dir_name = null; */
                        }
                    }
                    baseTitle = 'Identity Systems'
                }


                const baseBread: Breadcrumb = {
                    url: history?.location?.state?.prevUrl,
                    isLink: true,
                    state: {
                        breadcrumbId: prevBreadId
                    },
                    search: history?.location?.state?.prevSearch,
                    title: baseTitle
                };

                let isIdExist = false;

                if (prevBread?.length > 0) {
                    const index = prevBread.map((item: Breadcrumb) => item.title).findIndex((item: string) => item == baseTitle);
                    if (index > -1) {
                        prevBread.splice(index, 1, baseBread);
                        isIdExist = true;
                    }
                    // this.breadcrumbArray.push(...prevBread);
                }
                const currentBreads = [];
                if (!isIdExist) {
                    currentBreads.push(baseBread);
                }
                currentBreads.push({
                    title, isLink: false
                });
                this.breadcrumbArray.push(...currentBreads);
                return (!asset_value && !identity_value && !dir_name) ? [] : currentBreads;
            } else {
                return [];
            }
        }
    },
    {
        id: 'WidgetTrends',
        customData: {},
        getBreadcrumb: function () {
            return this.breadcrumbArray;
        },
        breadcrumbArray: [] as any,
        clearOldCrumbs: true,
        transform: function (history: any, customData?: any) {
            this.breadcrumbArray = [];
            let rule_value, first_flow_time;
            const query = getSearchQuery(history);
            if (query?.length > 0) {
                rule_value = getQueryValue(query, 'rule_name', true);
                first_flow_time = getQueryValue(query, 's_time', true);
                const crumbs = [
                    {
                        title: `Dashboard - ${customData.pageName}`,
                        url: '/',
                        isLink: true
                    }
                ]
                if (rule_value && first_flow_time) {
                    crumbs.push({
                        title: rule_value.split(',').length > 3 ?
                            `All Shadow Activity Incidents for last 7 days` :
                            `Incidents with ${rule_value} for last 7 days`,
                        isLink: false,
                        url: ''
                    });
                    return crumbs;
                } else {
                    return [];
                }
            } else {
                return []
            }
        }
    },
    {
        id: 'WidgetIncidents',
        customData: {},
        clearOldCrumbs: true,
        getBreadcrumb: function () {
            return this.breadcrumbArray;
        },
        breadcrumbArray: [] as any,
        transform: function (history: any, customData?: any) {
            let rl;
            const q = getSearchQuery(history);
            if (q?.length) {
                rl = getQueryValue(q, 'rule_name');
                if (!rl) {
                    return [];
                }
            } else {
                return [];
            }
            return [{
                title: `Dashboard - ${customData.pageName}`,
                url: '/',
                isLink: true
            },
            /* {
                title: 'Top New Risks',
                isLink: false
            }, */
            {
                title: `${rl} incidents`,
                isLink: false
            }
            ]

        }
    },
    {
        id: 'DirectoryAssetServices',
        customData: {},
        getBreadcrumb: function () {
            return this.breadcrumbArray;
        },
        breadcrumbArray: [] as any,
        transform: function (history: any, customData?: any) {
            this.breadcrumbArray = [];
            let rule_value, title;
            const query = getSearchQuery(history);
            if (query?.length > 0) {
                rule_value = getQueryValue(query, 'dir_hostname', true);
                const prevBreadId = history?.location?.state?.prevWidget;
                const prevBread = getBredcumbConfigById(prevBreadId)?.getBreadcrumb();

                title = `All services for "${rule_value}"`

                const baseBread: Breadcrumb = {
                    url: history?.location?.state?.prevUrl,
                    isLink: true,
                    state: {
                        breadcrumbId: prevBreadId
                    },
                    search: history?.location?.state?.prevSearch,
                    title: 'Identity Systems'
                };

                let isIdExist = false;

                if (prevBread?.length > 0) {
                    const index = prevBread.map((item: Breadcrumb) => item.title).findIndex((item: string) => item == "Identity Systems");
                    if (index > -1) {
                        prevBread.splice(index, 1, baseBread);
                        isIdExist = true;
                    }
                    this.breadcrumbArray.push(...prevBread);
                }
                const currentBreads = [] as any;
                if (!isIdExist) {
                    currentBreads.push(baseBread);
                }
                currentBreads.push({
                    title, isLink: false
                });
                this.breadcrumbArray.push(...currentBreads);
                return (!rule_value) ? [] : currentBreads;
            } else {
                return []
            }
        }
    },
    {
        id: 'UnknownAccess',
        clearOldCrumbs: true,
        customData: {},
        getBreadcrumb: function () {
            return this.breadcrumbArray;
        },
        breadcrumbArray: [] as any,
        transform: function (history: any, customData?: any) {
            const query = getSearchQuery(history);
            if (query?.length > 0) {
                const sTime = getQueryValue(query, 's_m_time');
                const eTime = getQueryValue(query, 'e_m_time');
                const ruleType = getQueryValue(query, 'rule_name');
                if ((!sTime && !eTime )) {
                    return [];
                } else
                    return [{
                        title: `Dashboard - Unknown Access ${ruleType ? " - "+ruleType : ""}`,
                        url: '/',
                        isLink: true
                    },
                    /* {
                        title: 'Lack of MFA',
                        isLink: false
                    }, */
                    {
                        title: `${ruleType ? ruleType + " incidents ": "Incidents "}between ${moment.unix(+sTime).format('MMM DD, YYYY hh:mm A')} and ${moment.unix(+eTime).format('MMM DD, YYYY hh:mm A')}`,
                        isLink: false
                    }]
            } else {
                return [];
            }
        }
    },
];

const getSearchQuery = (history: any) => {
    let tempQuery = history?.location?.search?.split('&').filter((item: string) => item.includes('q='))[0] || null;
    tempQuery = tempQuery?.slice(tempQuery.indexOf('q=') + 2);
    tempQuery = decodeURIComponent(tempQuery);
    const q = history.location?.query?.q || tempQuery;
    return q ? q.split('+') : null;
}

const removeEnclosedDoubleQuotes = (str:String) => {
    // Check if the string starts and ends with double quotes
    if (str?.startsWith('"') && str?.endsWith('"')) {
      // Remove the outermost double quotes
      str = str.slice(1, -1);
    }
    return str;
};

const getQueryValue = (query: string[], entityType: string, includeSeparator?: boolean) => {
    const searchObject = query.find((item: string) => item.includes(entityType));
    if (searchObject) {
        let queryValue: any = includeSeparator ? searchObject?.slice(searchObject.indexOf(':') + 1) : searchObject?.split(':')[1];
        queryValue = removeEnclosedDoubleQuotes(queryValue)

        if (queryValue && entityType == 'rule_name') {
            if (typeof queryValue == 'string') {
                queryValue = queryValue.split(",")
            }
            queryValue = queryValue.map((i) => getIssueNameById(i)).join(",")
        }
        return queryValue;
    } else {
        return '';
    }
}

export const getBredcumbConfigById = (id: string) => {
    return WidgetBreadcrumbs.find((item: any) => item.id == id);
}