import React, { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";
import styles from '../grid/Playbooks.module.css';
import './CSVOverridePopUp.scss';
import { CSVLink } from "react-csv";
import { useCSVImport } from "./useCSVImport";
import PreviewTabCsv, {
    getCSVFields, DragDropFile, ICsvActionButton,
    ImportFailErrorPopUp, getCSVSampleData
} from "./CSVOverrideSub";
import { usePlaybookConfig } from "./useCustomConfig";
import { validateCSV } from "../helpers/CSVValidator";
import { usePartnerStateContext } from "../../../store/PartnerContextProvider";
import { RuleType } from "../constants/Constants";

const CustomError = {
    "InvalidQuotes": `Row - {row} File has invalid quotes.`,
    "TooFewFields": `Row - {row} File has invalid fields.`
}

const _4k = window.matchMedia("(min-width: 2560px)").matches ? 1 : 1;

export const CSVOverridePopUp = ({ handleClose, handleProceed, ruleType }: any) => {
    const { handleOnCSVImportChange } = useCSVImport();
    const contentRef = useRef<HTMLDivElement>(null);
    const preViewRef = useRef<any>(null);
    const [pageCount, setPageCount] = useState(1);
    const [buttons, setButtons] = useState<Array<ICsvActionButton>>([]);
    const csvLinkEl = useRef<any>();
    const [fileData, setFileData] = useState<any>({});
    const [errors, setErrors] = useState<string | undefined>('');
    const [isHeader, setIsHeader] = useState(true);
    const [showImportError, setShowImportError] = useState<any>({
        show: false,
        errorList: []
    });
    const {
        updateResponseBodyBeforeLoad,
        convertCsvData
    } = usePlaybookConfig();
    const { PartnerConfigState } = usePartnerStateContext()

    const handleNext = () => {
        setPageCount(pageCount + 1);
    }

    const handleBack = () => {
        setPageCount(pageCount - 1);
    }

    const handleOk = () => {
        if (preViewRef?.current?.getHeaderValue() &&
            fileData?._parseWithHeader?.errors?.length > 0) {
            const errList = [] as Array<string>;
            setErrors(undefined);
            setIsHeader(preViewRef?.current?.getHeaderValue());
            fileData?._parseWithHeader?.errors?.forEach((i: any) => {
                const messageOb = i;
                const msg = CustomError[messageOb.code as keyof typeof CustomError] ?
                    CustomError[messageOb.code as keyof typeof CustomError].replace('{row}', messageOb.row + 1) :
                    `Row ${messageOb.row + 1}` + messageOb.message;
                errList.push(msg);
            })
            setShowImportError({ show: true, errorList: errList })

        } else if (!preViewRef?.current?.getHeaderValue() &&
            fileData?._parseWithoutHeader?.errors?.length > 0) {
            const errList = [] as Array<string>;
            setErrors(undefined);
            setIsHeader(preViewRef?.current?.getHeaderValue());
            fileData?._parseWithoutHeader?.errors?.forEach((i: any) => {
                const messageOb = i;
                const msg = CustomError[messageOb.code as keyof typeof CustomError] ?
                    CustomError[messageOb.code as keyof typeof CustomError].replace('{row}', messageOb.row + 1) :
                    `Row ${messageOb.row + 1}` + messageOb.message;
                errList.push(msg);
            })
            setShowImportError({ show: true, errorList: errList })
        } else {
            const e = validateImport(preViewRef?.current?.getHeaderValue() ?
                fileData.parsedWithHeader : fileData.parsedWithoutHeader
            );
            if (e) {
                handleProceed(
                    preViewRef?.current?.getHeaderValue() ?
                        fileData.parsedWithHeader : fileData.parsedWithoutHeader,
                    preViewRef?.current?.getHeaderValue()
                );
            }
        }
    }

    const validateImport = (data: any) => {
        const d = updateResponseBodyBeforeLoad(ruleType || '', data, preViewRef?.current?.getHeaderValue(), { isCSV: true });
        const er = convertCsvData(ruleType || '', d);
        const erList = validateCSV(ruleType || '', er, true) as Array<string>;
        if (erList?.length > 0) {
            setShowImportError({ show: true, errorList: erList })
            return false;
        }
        return true;
    }

    const ButtonsMap = {
        next: {
            label: 'Next',
            handler: handleNext,
            class: 'policy_save_button'
        },
        back: {
            label: 'Back',
            handler: handleBack,
            class: 'policy_defualt_button'
        },
        import: {
            label: 'Import',
            handler: handleOk,
            class: 'policy_save_button'
        },
        cancel: {
            label: 'Cancel',
            handler: handleClose,
            class: 'policy_defualt_button'
        }
    };

    const loadBasedOnPageCount = () => {
        if (pageCount == 1) {
            return <ImportCSVFirst />;
        } else if (pageCount == 2) {
            return <DragDropFileContent />
        } else if (pageCount == 3) {
            return <PreviewTab />
        }
    }

    const onDropFile = (e: File) => {
        const f = handleOnCSVImportChange(e);
        f.then(({ parsedWithoutHeader, parsedWithHeader, preview }: any) => {
            console.log(parsedWithHeader, parsedWithoutHeader)
            setFileData({
                parsedWithoutHeader: parsedWithoutHeader.data,
                preview, parsedWithHeader: parsedWithHeader.data,
                headers: parsedWithHeader?.meta?.fields || [],
                _parseWithHeader: parsedWithHeader
                , _parseWithoutHeader: parsedWithoutHeader
            });
            if (parsedWithoutHeader?.data?.length === 0) {
                setErrors('Selected file is empty.')
            } else {
                setErrors('');
                setPageCount(3);
            }
        })
    }

    useEffect(() => {
        setErrors('');
        switch (pageCount) {
            case 1: setButtons([ButtonsMap.cancel, ButtonsMap.next])
                break;
            case 2: setButtons([
                ButtonsMap.back
            ])
                break;
            case 3: setButtons([
                ButtonsMap.back, ButtonsMap.import
            ])
                break;
            default: break;
        }
    }, [pageCount])

    const downloadSampleCSV = async () => {
        csvLinkEl?.current?.link.click();
    }

    const onFileReject = () => {
        setErrors('Invalid file format.')
    }

    const ImportCSVFirst = () => {
        return <>
            <h6 style={{ fontWeight: '100', marginTop: '3%' }} className="font14">
                <strong>Note:</strong>
                <ul style={{ marginLeft: '3%' }}>
                    <li>All the columns in the CSV are mandatory and all the values should be enclosed in double quotes.</li>
                    <li>This action will <strong>override</strong> any configuration previously defined for the playbook.</li>
                </ul>
            </h6>
            <div className="font14 font-family-metro-regular" style={{ marginTop: '2%', fontWeight: 'normal' }}>
                The format of the CSV file should be:
            </div>
            <div className="sample_csv">
            </div>
            <div
            ><span
                className="link-text font14" onClick={downloadSampleCSV}>Download sample CSV file</span>
                {PartnerConfigState?.PartnerShortProduct && <CSVLink
                    headers={getCSVFields(ruleType)}
                    filename={`${PartnerConfigState?.PartnerShortProduct?.replaceAll(" ", "_")}_SamplePolicies.csv`}
                    data={getCSVSampleData(ruleType)!}
                    ref={csvLinkEl}
                />}
            </div>
        </>
    }

    const DragDropFileContent = () => {
        return <DragDropFile onFileDrop={onDropFile} errors={errors} onFileReject={onFileReject} />
    }

    const PreviewTab = () => {
        return <PreviewTabCsv parsedWithHeader={fileData.parsedWithHeader}
            parsedWithoutHeader={fileData.parsedWithoutHeader}
            previewText={fileData.preview} headers={fileData.headers}
            ref={preViewRef}
            errors={errors}
            headerSelected={isHeader}
            ruleType={ruleType}
        />
    }

    const getRuleName = () => {
        const res = Object.values(RuleType).find(item => item === ruleType);
        return res ? res : '';
    }

    return <Popup
        closeOnDocumentClick={false}
        overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
        open={true}
        closeOnEscape={false}
        modal
    >
        <div style={{ marginBottom: '5%' }} className={`${styles['modal']} unauth-asset-access-issue-container`}>
            <div className={styles.close} onClick={handleClose}></div>
            <div className={styles.header}>
                <div>{'Import CSV'}</div>
                <span className="font12 font-family-metro-regular">Import your existing access rules into {getRuleName()} playbook.</span>
            </div>
            <div className={styles.content + ' width100per'} ref={contentRef} style={{ height: (410 * _4k) }}>
                {
                    loadBasedOnPageCount()
                }
            </div>
            <div className="shadow_footer fl"></div>
            <div className="popup_footer fl">
                {
                    buttons.map((b: ICsvActionButton) => {
                        return <div className={b.class} onClick={b.handler}>{b.label}</div>
                    })
                }
            </div>
        </div>
        {showImportError?.show && showImportError.errorList.length > 0 && <ImportFailErrorPopUp error={showImportError.errorList} handleClose={() => {
            setShowImportError(false);
            setPageCount(2);
        }} />}
    </Popup>
}