import InfoIcon from '@mui/icons-material/Info';
import { Box, Chip, Dialog, DialogContent, DialogTitle, List, ListItem, ListItemText, Tab, Tabs, Tooltip } from '@mui/material';
import moment from 'moment';
import * as qs from "qs";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CSVLink } from "react-csv";
import { Data } from 'react-csv/components/CommonPropTypes';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router';
import { Link, useLocation } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { ArrayParam, NumberParam, StringParam, withDefault, withQueryParams } from 'use-query-params';
import { IDScore } from '../../../common/IDScore/IDScore';
import { Api } from '../../../components/Axios';
import { useToasts } from '../../../components/core';
import { AMCountryFlag } from '../../../components/core/AMCountryFlag/AMCountryFlag';
import { IdentitiesObj, IssueFilters, IssueParams, RulesDetailObj, ServerResponse } from '../../../types/response-types';
import { convertToCommaValue, findDifferenceInDays, findNameById, getExportFileName, getTimeDiffString, isIssueType, trimAfterSecondWord } from '../../../utils/util-methods';
import FlowTrend from '../../issue_prev/flow-trend/flow-trend';
import { IdentitiesActionMenu, IdentitiesColumnActionMenu } from '../constants/IDPostureMenu';
import { ENTITIES, POSTURE_ISSUE } from '../constants/Constants';
import '../identity_asset.scss';
import '../posture_root_tooltip.css';
import { BasePostureActionMenu } from '../ref/PostureActionMenus/BasePostureActionMenu';
import { PostureMenuItem, PostureMenuItemAction } from '../ref/PostureActionMenus/PostureActionMenu';
import PostureFilter from '../../../components/core/PostureFilter/PostureFilter';
import { FilterWidget } from '../../../components/core/PostureFilter/PostureFilterWidget';
import { getAdditionalResultCellValue, getSearchFilterObjectFromTags, getTagsForSearchInput, getTagsForTimeBasedSearch, getTimeBasedSearchQueryParam } from '../../../components/core/PostureFilter/posture-filter-utils';
import { formatCSVData, getHeaders, useScrollIssue, useLensSearch, usePostureAdvanceSearch, usePostureArchival } from '../ref/Hooks/Posture';
import { ScrollIssueData, ScrollIssueHeader, ScrollIssueHeaderRef } from '../ref/ScrollIssue/ScrollIssue';
import axios, { AxiosError, CancelToken } from 'axios';
import { usePostureStateContext } from '../../../store/PostureStateContextProvider';
import Tags from '../../issue_prev/issues/tags/tags';
import AMMultiCheckbox from '../../../components/core/AMMultiCheckbox/AMMultiCheckbox';
import { AMCheckbox } from '../../../components/core/AMCheckbox/AMCheckbox';
import { IPostureActionButtonsRef, PostureActionButtons, PostureArchiveTooltip } from '../ref/PostureArchive/PostureArchive';
import { PostureService } from '../ref/Hooks/PostureService';
import { useAuthDetails } from '../../../components/Authorization';
import { PostureCategory } from '../ref/PostureCategories/PostureCategory';
import { PlayBookView } from '../sub/PlayBookView';
import { useValidatePostureIssuesSearchResult } from '../ref/Hooks/PostureIssues';
import AdditionalResult from '../sub/AdditionalResult';
import { PlaybookTableActions } from '../../playbooks/ref/AdvancedPlaybook/PlaybookTableActions';
import { IPlaybookActions } from '../../playbooks/ref/AdvancedPlaybook/PlaybookTypes';
import { PostureSearchTemplate, notSupportedKeys } from '../constants/PostureSearchTemplate';
import TimeBasedSearchContainer from '../../../components/TimeBasedSearch/TimeBasedSearchContainer';
import { AVAILABLE_PRESETS, TIME_BASED_SEARCH_FIELD } from '../../../components/TimeBasedSearch/Constants';
import { supportedOperators } from '../../issue_prev/issues/constant/issueTemplate';
import { getIssueNameById } from '../../playbooks/helpers/playbook-helper';
import { useTimeBasedSearchActions } from '../../../components/TimeBasedSearch/TimeBasedSearchActions';

const Identities = ({ query, setQuery }: any) => {
    const checkFlag = false;
    const search = useLocation().search;
    const location = useLocation();
    const hard_refresh_state: boolean = new URLSearchParams(search).get('hard_refresh') === "true" ? true : false;
    const [tableFetchError, setTableFetchError] = useState('No records found.');
    const [issueHeader, setIssueHeaders] = useState<Array<string>>([]);
    const scrollIssueRef = useRef<ScrollIssueHeaderRef>(null);
    const [showAnimation, setShowAnimation] = useState(false);
    const [openPanelFilterWidgets, setOpenPanelFilterWidgets] = useState(false);
    const [filterData, setFilterData] = useState<IssueFilters | undefined>();
    const disabled_filter_state: boolean = new URLSearchParams(search).get('disable_filter') === "true" ? true : false;
    const [disabledFilter, setDisabledFilter] = useState<boolean>(disabled_filter_state);
    const [selectedColumn, setSelectedColumn] = useState(query.sort_by || '');
    const [currentSort, setCurrentSort] = useState(query.order_by == 'asc' ?
        ' tablesort_up tablesort_up_selected ' : ' tablesort_down tablesort_down_selected ');
    const [loading, setLoading] = useState(false);
    const [searchInput, setSearchInput] = useState<string>('');
    const [tagFlag, setTagFlag] = useState<boolean | undefined>();
    const [lastUpdate, setLastUpdate] = useState<string>();
    const [openFlow, setOpenFlow] = useState<boolean>(false);
    const [flowsTrendResponseData, setFlowsTrendResponseData] = useState<any>(undefined);
    const [flowsChartDetails, setFlowsChartDetails] = useState<any>({ chartTitle: '' });
    // ID Asset Widget Begins                   
    const { updateColumnEffect, scrollToIssueColumnFn } = useScrollIssue();
    const { handleApplyAdvancedSearch, handleManualSearch, getSearchHeaders, PartnerConfigState,
        preMetaDataHandler
    } = usePostureAdvanceSearch();
    const { PostureSearchState, PostureChartState } = usePostureStateContext();
    const { tags, setTags } = PostureSearchState;
    const { handleLensSearchFn, handleSearchFn } = useLensSearch(searchInput, setTags, setSearchInput, setTagFlag);
    const [isClearAll, setIsClearAll] = useState(false);
    const { isPresetPeriodOptionSelected } = useTimeBasedSearchActions();
    const {
        selectAll, onSetSelectRow, getSelectRow, selectAllIndeterminate,
        setArchivalData, setOpenIssuesCount, onArchive,
        selectCount, isIncidentsOpen, PostureMultiSelectCheckboxItems, resetSelectionState, setCurrentPage,
        currentPage, onArchiveEstimate } = usePostureArchival('Identity');
    const {
        responseData, setResponseData,
        showAdvanceSearch, setShowAdvanceSearch,
        setIdWidget, ruleWidget, setRuleWidget,
        setRuleRootIdentity, setShowGraph,
        setSelectedItems, items, setItems,
        widgetData, setWidgetData, totalCount, setTotalCount
    } = PostureChartState;
    const { PostureSummary } = PostureService();
    const { authDetails } = useAuthDetails()!;
    const { isValidResultCountThreshold, matchingPlaybookFound } = useValidatePostureIssuesSearchResult();
    const [selectedField, setSelectedField] = useState(null);
    const [additionalFields, setAdditionalFields] = useState([]);
    const [showAdditionalResult, setShowAdditionalResult] = useState(false);
    const [additionalResultHighlightClass, setAdditionalResultHighlightClass] = useState('');
    const {
        q: q,
        page: page_number,
        rpp: record_per_page,
        order_by: order,
        sort_by: sort,
        rule_name: rule_name,
        d_protocol: d_protocol,
        risk: risk,
        hash: hash,
        d_name: d_name,
        s_time: s_time,
        e_time: e_time,
        hard_refresh: hard_refresh
    } = query;

    let history = useHistory();
    const { addToast } = useToasts();
    const reloadRef = useRef() as React.MutableRefObject<HTMLDivElement>;

    const archiveBtnRef = useRef<IPostureActionButtonsRef>(null);
    const updatedExtremesRef = useRef({ min: null, max: null });
    const MoreActionsOptions: IPlaybookActions<Array<any>> = [
        {
            actionCallback: () => {
                if (archiveBtnRef?.current?.onArchiveBtnClick)
                    archiveBtnRef?.current?.onArchiveBtnClick()
            },
            actionId: 'mark-archive',
            actionLabel: 'Archive',
            isDisabled: selectCount == 0,
            isApplicable: authDetails?.permissions?.Posture?.manual_archive == 'readwrite'
        }
    ];
    let param: IssueParams = {
        q: q,
        page: page_number,
        rpp: record_per_page,
        order_by: order,
        sort_by: sort,
        rule_name: rule_name,
        d_protocol: d_protocol,
        risk: risk,
        hash: hash,
        d_name: d_name,
        s_time: s_time,
        e_time: e_time,
        hard_refresh: hard_refresh
    };
    const [isTimeBasedSearchApplied, setIsTimeBasedSearchApplied] = useState(false);
    const searchParams = new URLSearchParams(location.search);
    const hasReportType = searchParams.has('reportType') && searchParams.get('reportType')?.trim() !== '' || 
    searchParams.has('ruleType') && searchParams.get('ruleType')?.trim() !== '';

    useEffect(() => {
        setShowAdditionalResult(true)
    }, [query.q]);

    const highlightSortDir = () => {
        if (query.order_by === 'asc') {
            setCurrentSort(' tablesort_up tablesort_up_selected ');
        } else if (query.order_by === 'desc') {
            setCurrentSort(' tablesort_down tablesort_down_selected ');
        }
    };

    const [openModal, setOpenModal] = useState<boolean>(false);

    const handleClose = () => {
        setOpenModal(false);
    }

    const openSettingModal = () => {
        setOpenModal(true);
    }

    const { appliedFilterTimestamp } = useTimeBasedSearchActions();

    const [zoomLevel, setZoomLevel] = useState('hourly');

   
    const  getItemIconClass = (item)=> {

        switch (item?.type) {
          case "Device":
            return "device_icon";
          case "App":
            return "device_icon";
          case "Service":
            return "gear_icon_issue";
          case "Service/Application":
            return item?.identity_category_list && item?.identity_category_list.length > 0 ?  "cloud_service_application_account_icon": "service_application_account_icon";
          case "Service/Computer Account":
            return item?.identity_category_list && item?.identity_category_list.length > 0 ? "cloud_service_computer_account_icon" : "service_computer_account_icon";
          case "Service/Key and Secret":
            return item?.identity_category_list && item?.identity_category_list.length > 0 ? "cloud_service_key_secret__icon" : "service_key_secret__icon";  
          case "Service/Service Account":
            return item?.identity_category_list && item?.identity_category_list.length > 0 ? "cloud_service_service_account_icon": "service_service_account_icon";
          case "Service/Service Principal":
            return item?.identity_category_list && item?.identity_category_list.length > 0 ? "cloud_service_service_principal_icon" : "service_service_principal_icon";
          case "Service/Token":
                return  item?.identity_category_list && item?.identity_category_list.length > 0 ? "cloud_service_service_token_icon"  :"service_service_token_icon";
          case "User/Agent":
            return "user_agent_icon";
          case "User/Background Browsing":
            return "user_browsing_icon";
          case "User":
            return "user_interactive_icon";
          default:
            // If identity_category_list exists, return 'settings_icon_cloud', otherwise 'settings_icon'
            return item?.identity_category_list ? 'settings_icon_cloud' : 'settings_icon';
        }
      }

  const getItemIconTitle  = (item) => {
    switch (item?.type) {
        case "Device":
            return !item.is_known_identity ? "Unresolved  NHI-Device" : "NHI-Device";
        case "App":
            return !item.is_known_identity ? "Unresolved  NHI-App" : "NHI-App";
        case "Service":
            return !item.is_known_identity ? "Unresolved  NHI-Service" : "NHI-Service";
        case "Service/Application":
            return !item.is_known_identity ? "Unresolved  NHI-Application" : "NHI-Application";
        case "Service/Computer Account":
            return !item.is_known_identity ? "Unresolved NHI-Service/Computer Account" : "NHI-Service/Computer Account";
        case "Service/Service Account":
            return !item.is_known_identity ? "Unresolved NHI-Service" : "NHI-Service";
        case "Service/Service Principal":
            return !item.is_known_identity ? "Unresolved NHI-Service/Service Principal" : "NHI-Service/Service Principal";
        case "Service/Key and Secret":
                return !item.is_known_identity? "Unresolved NHI-Service/Key and Secret" : "NHI-Service/Key and Secret";
        case "Service/Token":
                return !item.is_known_identity? "Unresolved NHI-Service/Token" : "NHI-Service/Token";
        case "User/Agent":
           return !item.is_known_identity ? "Unresolved User/Agent":"User/Agent";
        case "User/Background Browsing":
           return !item.is_known_identity ? "Unresolved User/Background Browsing":"User/Background Browsing";
        case "User":
           return !item.is_known_identity ? "Unresolved  User":"User";
        default:
          // If identity_category_list exists, return 'settings_icon_cloud', otherwise 'settings_icon'
          return item?.identity_category_list ? 'settings_icon_cloud' : 'settings_icon';
      }

  }                                 
      

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        let tempTags: string[] = [];
        if (param.q) {
            // tempTags = [...param.q];
            tempTags = q.split('+');
        }
        if (param.rule_name && !tempTags.includes(param.rule_name)) {
            tempTags.push(param.rule_name);
        }
        if (param.risk && !tempTags.includes(param.risk?.toString())) {
            tempTags.push(param.risk?.toString());
        }
        if (param.d_protocol && !tempTags.includes(param.d_protocol)) {
            tempTags.push(param.d_protocol);
        }
        if (param.d_name && !tempTags.includes(param.d_name)) {
            tempTags.push(param.d_name);
        }
        if (param.hash && !tempTags.includes(param.hash)) {
            tempTags.push(param.hash);
        }
        if (
            param.s_time &&
            param.e_time &&
            !tempTags.includes(param.s_time?.toString()) &&
            !tempTags.includes(param.e_time?.toString())
        ) {
            tempTags.push(param.s_time?.toString(), param.e_time?.toString());
        }

        if (tempTags.length > 0) {
            setTags(tempTags);
            setTagFlag(false);
        } else {
            setTags([]);
        }

        highlightSortDir();
        if (
            !history.location.pathname.includes('matchrule') &&
            !history.location.pathname.includes('flow')
        ) {
            fetchIdentities(undefined, signal);
        }
        return () => {
            controller.abort(); // Cancel the API request
        };
    }, [query]);

    useEffect(() => {

        const fetchData = async () => {
            try {
                const a = await fetchPlayBookData();
                const b = await fetchWidget();
                //  const c =   await setTimeout(()=>{fetchUserSelection()},300);


            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (location.pathname.includes("/identities")) {
            fetchData();
        }
    }, []);


    useEffect(() => {
        if (location.pathname.includes("/identities")) {
            fetchWidget();
        }
    }, []);

    const scrollToIssueColumn = () => {
        scrollToIssueColumnFn(query, q, scrollIssueRef, 'identities');
    };


    const fetchIdentities = async (cancelToken?: CancelToken, signal?) => {
        setLoading(true);
        setTableFetchError('No records found.');
        const searchHeaders = await getSearchHeaders(param);
        Api.get('/identities', {
            params: param,
            paramsSerializer: (params) =>
                qs.stringify(params, { arrayFormat: 'repeat' }),
            cancelToken: cancelToken,
            signal,
            ...searchHeaders
        })
            .then((res) => {
                setLoading(false);
                if (res.status === 200) {
                    setTotalCount(res.data.total);
                    // const d = validateLocalIps(res.data, 'host_detail')
                    setResponseData(res.data);
                    setArchivalData(res.data);
                    setOpenIssuesCount(res.data.total);
                    setLastUpdate(moment().format('MMM DD YYYY, hh:mm A'));
                    scrollToIssueColumn();
                    if (currentPage) {
                        resetSelectionState();
                    }
                    setCurrentPage(false);
                }
            })
            .catch((err: AxiosError) => {
                if (err.code == 'ERR_CANCELED') {
                    return;
                }
                setLoading(false);
                setResponseData({ result: 'error' } as ServerResponse);
                addToast('Error while fetching data.', {
                    appearance: 'error',
                    autoDismiss: true
                });
                setTableFetchError(`An error occurred while processing your request. Please check your input and try again. If the error persists, please reach out to ${PartnerConfigState?.PartnerShortProduct} support.`);
            });
    };

    const reloadIdentities = () => {
        reloadRef?.current?.classList.add('reload_spin');
        param.hard_refresh = true;
        setQuery(param);

        fetchIdentities();

        const fetchData = async () => {
            try {
                const a = await fetchWidget();
                //  const b =   await setTimeout(()=>{fetchUserSelection()},300);


            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        outDated();
        setTimeout(() => {
            if (reloadRef?.current?.classList.contains('reload_spin')) {
                reloadRef.current.classList.remove('reload_spin');
            }
        }, 750);
    };

    const LoadSorting = (field: string) => {
        if (param.sort_by === field) {
            param.order_by = param.order_by === 'asc' ? 'desc' : 'asc';
            setQuery(param);
        } else {
            param.sort_by = field;
            param.order_by = 'desc';
            setQuery(param);
        }
    };

    const handleSort = (e: React.MouseEvent) => {
        const col = e.currentTarget.getAttribute('data-headerName');
        setSelectedColumn(col);
        LoadSorting(col || '');
    };


    const handleRuleFilterClick = (e: React.MouseEvent<HTMLDivElement>) => {
        let idx = e.currentTarget.id?.toString();
        const prevState = history?.location?.state as any;
        const prevUrl = history.location.pathname;
        const prevSearch = history.location.search;
        let ruleName = e.currentTarget.getAttribute('data-rulename');
        const isIssue = isIssueType(items, ruleName);
        ruleName = findNameById(items, ruleName);
        const timeSearchParam = getTimeBasedSearchQueryParam(PostureSearchTemplate, tags);

        if (timeSearchParam === "") {
            isIssue ? history.push(
                '/issues?disable_filter=true&order_by=desc&page=1&q=rule_name:' +
                ruleName +
                '%2Bstatus:Open%2Bs_name:' +
                encodeURIComponent(idx) +
                '&sort_by=issue_flows_count',
                {
                    prevWidget: prevState?.breadcrumbId,
                    breadcrumbId: 'IdentityIncidents',
                    prevUrl,
                    prevSearch
                }
            ) : history.push(
                '/issues?disable_filter=true&order_by=desc&page=1&q=pb_name:' +
                encodeURIComponent(ruleName) +
                '%2Bstatus:Open%2Bs_name:' +
                encodeURIComponent(idx) +
                '&sort_by=issue_flows_count',
                {
                    prevWidget: prevState?.breadcrumbId,
                    breadcrumbId: 'IdentityIncidents',
                    prevUrl,
                    prevSearch
                }
            );
        } else {
            isIssue ? history.push(
                '/issues?disable_filter=true&order_by=desc&page=1&q=rule_name:' +
                ruleName +
                '%2Bstatus:Open%2Bs_name:' +
                encodeURIComponent(idx) +
                '%2B' + encodeURIComponent(timeSearchParam) +
                '&sort_by=issue_flows_count',
                {
                    prevWidget: prevState?.breadcrumbId,
                    breadcrumbId: 'IdentityIncidents',
                    prevUrl,
                    prevSearch
                }
            ) : history.push(
                '/issues?disable_filter=true&order_by=desc&page=1&q=pb_name:' +
                encodeURIComponent(ruleName) +
                '%2Bstatus:Open%2Bs_name:' +
                encodeURIComponent(idx) +
                '%2B' + encodeURIComponent(timeSearchParam) +
                '&sort_by=issue_flows_count',
                {
                    prevWidget: prevState?.breadcrumbId,
                    breadcrumbId: 'IdentityIncidents',
                    prevUrl,
                    prevSearch
                }
            );
        }
    };

    const getChartTitle = (identityName: string, startDate: any = null, endDate: any = null) => {
        let timeUnit = "Hourly";

        if (startDate) {
            if (endDate) {
                const totalDurationHours = moment(endDate).diff(moment(startDate), 'hours');

                if (totalDurationHours < 12) {
                    timeUnit = "Minute-wise";
                } else if (totalDurationHours < 48) {
                    timeUnit = "10-Minute-wise";
                }
                // else timeUnit remains "Hourly"
            }

            return (
                <span
                    title={`${timeUnit} flows trend (${getTimeDiffString(startDate, endDate)}) for Identity: ${identityName}`}
                >{`${timeUnit} flows trend (${getTimeDiffString(startDate, endDate)}) for Identity: ${trimAfterSecondWord(identityName, 35)}`}</span>
            );
        } else {
            return (
                <span
                    title={`Hourly flows trend for Identity: ${identityName}`}
                >{`Hourly flows trend for Identity: ${trimAfterSecondWord(identityName, 35)}`}</span>
            );
        }
    };


    function getSearchFields() {
        let timeFilter = appliedFilterTimestamp();
        if (timeFilter.length > 0) {

            let startDate = moment(timeFilter[0]).unix();
            let endDate = moment(timeFilter[1]);

            if (endDate.isAfter(moment())) {
                endDate = moment().unix();
            } else {
                endDate = endDate instanceof moment ? endDate.unix() : moment(endDate).unix();
            }

            // Calculate the time difference in hours
            let diffInHours = (endDate - startDate) / 3600;

            // Determine the interval based on the difference
            let interval;
            if (diffInHours < 12) {
                interval = '1 MINUTE';
                setZoomLevel('1-minute');
            } else if (diffInHours < 48) {
                interval = '10 MINUTE';
                setZoomLevel('10-minute');
            } else {
                interval = '1 HOUR'; // Replace 'default_interval' with your desired default interval.
                setZoomLevel('hourly');
            }

            let searchFields = {
                "any_activity_time": {
                    "value": [startDate, endDate],
                    "type": "between"
                },
                "interval": interval
            };

            // Convert to JSON and encode
            return encodeURIComponent(JSON.stringify(searchFields));
        
        } else {
            
        }
        return null; // Return null or an empty string if timeFilter is empty
    }


    function getSearchFieldsForDate(min, max) {


        let startDate = moment(min).unix();
        let endDate = moment(max);

        if (endDate.isAfter(moment())) {
            endDate = moment().unix();
        } else {
            endDate = endDate instanceof moment ? endDate.unix() : moment(endDate).unix();
        }



        // Calculate the time difference in hours
        let diffInHours = (endDate - startDate) / 3600;

        // Determine the interval based on the difference
        let interval;
        if (diffInHours < 12) {
            interval = '1 MINUTE';
            setZoomLevel('1-minute');
        } else if (diffInHours < 48) {
            interval = '10 MINUTE';
            setZoomLevel('10-minute');
        } else {
            interval = '1 HOUR'; // Replace 'default_interval' with your desired default interval.
            setZoomLevel('hourly');
        }

        let searchFields = {
            "any_activity_time": {
                "value": [startDate, endDate],
                "type": "between"
            },
            "interval": interval
        };

        // Convert to JSON and encode
        return encodeURIComponent(JSON.stringify(searchFields));



    }

    const cancelTokenSourceRef = useRef<any>(null);

    const handleRangeChange = (min: any, max: any) => {

        const searchHeaders = {
            headers: {
                search_fields: getSearchFieldsForDate(min, max)
            }
        };

        updatedExtremesRef.current = { min, max };


        const currentTime = +new Date();

        const data = JSON.parse(sessionStorage.getItem('flowData'));


        const encodedIdentityName = data ? encodeURIComponent(data?.id) : '';

        const flowTrendURL = `/assets/flowcounts?search_type=identity&search_value1=${encodedIdentityName}`;

        // Cancel the previous request if one exists
        if (cancelTokenSourceRef.current) {
            cancelTokenSourceRef.current.cancel('Operation canceled due to new request.');
        }

        // Create a new cancel token for the new request
        cancelTokenSourceRef.current = axios.CancelToken.source();
        try {
            Api.get(flowTrendURL, {
                ...searchHeaders,
                cancelToken: cancelTokenSourceRef.current.token, // Attach the cancel token
            })
                .then((res: any) => {
                    if (!res?.data?.length || res?.data?.length <= 0) {
                        setFlowsTrendResponseData({
                            flows: [],
                            expiry_time: currentTime + 600000
                        });

                        // Chart header and color
                        // const chartTitle = getChartTitle(identityName);
                        // setFlowsChartDetails({
                        //     chartTitle, itemData: {
                        //         queryData: `identity_name:${encodedIdentityName}`
                        //     }
                        // });
                        return;
                    }

                    let result = JSON.parse(JSON.stringify(res?.data)) || [];
                    result.sort((a: any, b: any) =>
                        a?.time < b?.time ? -1 : 1
                    );
                    const cachedFlows = {
                        flows: result,
                        encodedIdentityName,
                        risk,
                        expiry_time: currentTime + 600000
                    };
                    setFlowsTrendResponseData(cachedFlows);


                    const chartTitle = appliedFilterTimestamp()?.length > 0 ? getChartTitle(
                        data?.id,
                        min,
                        max,

                    ) : max ? getChartTitle(
                        data?.id,
                        min,
                        max

                    ) : getChartTitle(
                        data?.id,
                        min

                    );
                    setFlowsChartDetails({
                        chartTitle, itemData: {
                            queryData: `identity_name:${encodedIdentityName}`
                        }
                    });


                })
                .catch((er) => {
                    console.log(er);
                });
        }
        catch (error) {
            if (axios.isCancel(error)) {
                console.log('Previous request canceled.');
            } else {
                console.log(error);
            }
        }
    }

    // Newer code show flows data chart on popup.
    const handleFlowClick = (event: any, data: any) => {
        setOpenFlow(true);
        setZoomLevel('hourly');
        updatedExtremesRef.current = { min: null, max: null };
        let { id: identityName } = data;
        const currentTime = +new Date();
        const cachedFlowData: any = sessionStorage.getItem(
            `identities-flow-trend-${identityName}`
        );

        sessionStorage.setItem('flowData', JSON.stringify(data));

        const encodedIdentityName = encodeURIComponent(identityName);

        const flowTrendURL = `/assets/flowcounts?search_type=identity&search_value1=${encodedIdentityName}`;

     


            const searchHeaders = {
                headers: {
                    search_fields: getSearchFields()
                }
            };

            // searchHeaders.headers.search_fields = ;
            Api.get(flowTrendURL, searchHeaders)
                .then((res: any) => {
                    if (!res?.data?.length || res?.data?.length <= 0) {
                        setFlowsTrendResponseData({
                            flows: [],
                            expiry_time: currentTime + 600000
                        });

                        // Chart header and color
                        const chartTitle = getChartTitle(identityName);
                        setFlowsChartDetails({
                            chartTitle, itemData: {
                                queryData: `identity_name:${encodedIdentityName}`
                            }
                        });
                        return;
                    }

                    let result = JSON.parse(JSON.stringify(res?.data)) || [];
                    result.sort((a: any, b: any) =>
                        a?.time < b?.time ? -1 : 1
                    );
                    const cachedFlows = {
                        flows: result,
                        encodedIdentityName,
                        risk,
                        expiry_time: currentTime + 600000
                    };
                    setFlowsTrendResponseData(cachedFlows);

                    // Chart header and color
                    const startFromDate = Math.max(
                        moment(result?.[0]?.time).valueOf(),
                        moment().subtract(90, 'days').valueOf()
                    );



                    const chartTitle = appliedFilterTimestamp()?.length > 0 ? getChartTitle(
                        identityName,
                        appliedFilterTimestamp()[0],
                        appliedFilterTimestamp()[1],

                    ) : getChartTitle(
                        identityName,
                        startFromDate
                    );
                    setFlowsChartDetails({
                        chartTitle, itemData: {
                            queryData: `identity_name:${encodedIdentityName}`
                        }
                    });

                    sessionStorage.setItem(
                        `identities-flow-trend-${encodedIdentityName}`,
                        JSON.stringify(cachedFlows)
                    );
                })
                .catch((er) => {
                    console.log(er);
                });
    };

    useEffect(() => {
        if (!openFlow) {
            setFlowsTrendResponseData(undefined);
        }
    }, [openFlow]);

    const handleIssueFilterClick = (e: React.MouseEvent<HTMLDivElement>) => {
        let idx = e.currentTarget.id?.toString();
        const prevState = history?.location?.state as any;
        const prevUrl = history.location.pathname;
        const prevSearch = history.location.search;
        if (idx.includes(',')) {
            idx = `"${idx}"`;
        }
        const timeSearchParam = getTimeBasedSearchQueryParam(PostureSearchTemplate, tags);

        if (timeSearchParam === "") {
            history.push(
                '/issues?disable_filter=true&hard_refresh=true&order_by=desc&page=1&q=s_name:' +
                encodeURIComponent(idx) +
                `%2Brule_name^Shadow External Access` +
                '%2Bstatus:Open&sort_by=gen_timestamp',
                {
                    prevWidget: prevState?.breadcrumbId,
                    breadcrumbId: 'IdentityIncidents',
                    prevUrl,
                    prevSearch
                }
            );
        } else {

            history.push(
                '/issues?disable_filter=true&hard_refresh=true&order_by=desc&page=1&q=s_name:' +
                encodeURIComponent(idx) +
                `%2Brule_name^Shadow External Access` +
                '%2Bstatus:Open%2B' + encodeURIComponent(timeSearchParam) + '&sort_by=gen_timestamp',
                {
                    prevWidget: prevState?.breadcrumbId,
                    breadcrumbId: 'IdentityIncidents',
                    prevUrl,
                    prevSearch
                }
            );
        }
    };

    const handleAssetsAccess = (assetid: any) => {
        // fetchAssetsMapping(assetid);
        const prevState = history?.location?.state as any;
        const prevUrl = history.location.pathname;
        const prevSearch = history.location.search;
        if (assetid.includes(',')) {
            assetid = `"${assetid}"`;
        }

        const timeSearchParam = getTimeBasedSearchQueryParam(PostureSearchTemplate, tags);
        if (timeSearchParam === "") {

            history.push(
                '/posture/accesses?page=1&q=identity_name%3A' +
                encodeURIComponent(assetid) +
                '&sort_by=flow_count&order_by=desc',
                {
                    prevWidget: prevState?.breadcrumbId,
                    breadcrumbId: 'PostureAccesses',
                    prevUrl,
                    prevSearch
                }
            );
        } else {
            history.push(
                '/posture/accesses?page=1&q=identity_name%3A' +
                encodeURIComponent(assetid) + "%2B" + encodeURIComponent(timeSearchParam) +
                '&sort_by=flow_count&order_by=desc',
                {
                    prevWidget: prevState?.breadcrumbId,
                    breadcrumbId: 'PostureAccesses',
                    prevUrl,
                    prevSearch
                }
            );
        }
    };

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    const getExceptionData = (item: IdentitiesObj, rule_name: string) => {
        return [
            `s_name:${item._id}`,
            // `src_hostnames:` + item.host_detail?.map((item: any) => item.hostname).join(',') || '',
            // `src_ips:` + item.host_detail?.map((item: any) => item.ip).join(',') || '',
            `rule_name:` + rule_name
        ];
    };

    const loadBasePostureActionProps = (
        item: IdentitiesObj,
        rule_name: string,
        issue_count: number
    ) => {
        return {
            actionData: item,
            menuItems: loadColumnActionLabels(rule_name),
            getContent: () => [`s_name:${item._id}`, `rule_name:${rule_name}`],
            exceptionData: getExceptionData(item, rule_name),
            classes: 'posture-column action_button',
            incContent: () => [
                `inc_count:${issue_count}`,
                `rule_name:${rule_name}`,
                `s_name:${item._id}`
            ],
            closeIncQuery: `{"status":"Open","is_external":"false","s_name":"${item._id}","rule_name":"${rule_name}"}`,
            issueCount: issue_count,
            reloadList: fetchIdentities,
            keyName: `identity-${item._id}`,
            showId: rule_name
        };
    };

    const getRules = (item: IdentitiesObj, actionType?: string) => {
        let rule = '';
        if (item?._id?.toLowerCase().includes('scanner') && widgetData) {
            const arr = [
                ...Object.values(widgetData.identity_rules_summary.rules)
            ];
            arr.map((i: any) => {
                rule += i.name + ',';
            });
        } else {
            Object.values(item.rules).map((i: RulesDetailObj) => {
                const isIssue = isIssueType(items, i.name);
                if (isIssue) {
                    if (actionType == 'exception') {

                        if (i.issue_count > 0 && i.entity_type === 'Identity') {
                            rule += i.name + ',';
                        }
                    } else {
                        if (i.issue_count > 0 && i.entity_type === 'Identity') {
                            rule += i.name + ',';
                        }
                    }
                }
            });
        }
        return rule.substring(0, rule.length - 1);
    };

    const getIssueCount = (item: IdentitiesObj) => {
        return item.rules
            ? Object.values(item.rules).reduce(
                (prev: RulesDetailObj, i: RulesDetailObj) => ({
                    ...prev,
                    issue_count: prev.issue_count + i.issue_count
                }),
                {
                    issue_count: 0,
                    issue_flow_count: 0,
                    issue_risk: 4,
                    name: ''
                }
            ).issue_count
            : 0;
    };




    const loadColumnActionLabels = (rule_name: string) => {
        const IDCopy = JSON.parse(
            JSON.stringify(IdentitiesColumnActionMenu)
        ) as any;
        const exceptionAction = IDCopy.find(
            (item: PostureMenuItem) =>
                item.action == PostureMenuItemAction.ADD_EXCEPTION
        );
        if (exceptionAction) {
            switch (rule_name) {
                case 'Compromised Password':
                    exceptionAction.label = `Ignore this identity’s compromised passwords (Add Exception)`;
                    break;
                case 'Suspicious Outbound Access':
                    exceptionAction.label = `Ignore this identity’s suspicious outbound accesses (Add Exception)`;
                    break;
                case 'Compromised User':
                    exceptionAction.label = `Ignore this identity’s compromised user issues (Add Exception)`;
                    break;
                case 'Weak Password':
                    exceptionAction.label = `Ignore this identity’s weak passwords (Add Exception)`;
                    break;
            }
        }

        const closeIncident = IDCopy.find(
            (item: PostureMenuItem) =>
                item.action == PostureMenuItemAction.CLOSE_INCIDENT
        );
        if (closeIncident) {
            const ruleLabel = getIssueNameById(findNameById(items, rule_name))
            closeIncident.label = `Close existing '${ruleLabel}' issues for this identity`;
        }
        return IDCopy;
    };

    const handleMouseEnterHost = (index: number, hostname: string, e: any) => {
        const ele = e.target.parentElement.parentElement.getElementsByClassName(
            `host-name-list-${index}-${hostname}`
        )[0];
        if (ele && ele.classList) {
            ele.classList.add('show-block');
            ele.classList.remove('hidden-block');
        }
    };

    const handleMounseLeaveHost = (index: number, hostname: string, e: any) => {
        const ele = e.target.getElementsByClassName(
            `host-name-list-${index}-${hostname}`
        )[0];
        if (ele && ele.classList) {
            ele.classList.add('hidden-block');
            ele.classList.remove('show-block');
        }
    };

    useEffect(() => {
        updateColumnEffect(showAnimation, history, q, 'identities');
    }, [showAnimation]);

    useEffect(() => {
        if (issueHeader?.length > 0 && responseData?.result?.length) {
            setShowAnimation(true);
        }
    }, [responseData, issueHeader]);

    const handleFilterWidget = (widgetState: any) => {
        if (filterData) {
            setOpenPanelFilterWidgets(widgetState);
        } else {
            addToast(
                'Unable to apply filters, please retry in few minutes or contact administrator.',
                { appearance: 'error' }
            );
        }
    };

    const handleFilterDisabled = (widgetState: any) => {
        setDisabledFilter(widgetState);
        // reloadIssuesDisabled(widgetState);
    };

    const onCloseAdvanceSearch = useCallback(() => {
        setShowAdvanceSearch(false);
    }, []);


    const applySearchHandler = useCallback(
        (data: any, clearAll: boolean = false, deletedKeys = []) => {
            let tempFilterObj = getSearchFilterObjectFromTags(PostureSearchTemplate, tags);
            let filterObj = {};
            for (let i in tempFilterObj) {
                if (deletedKeys.indexOf(i) == -1)
                    filterObj[i] = tempFilterObj[i];
            }

            filterObj = { ...filterObj, ...data }

            handleApplyAdvancedSearch(
                filterObj,
                clearAll,
                setIsClearAll,
                setTags,
                setTagFlag,
                ENTITIES.IDENTITY.pageType
            );
            resetSelectionState();
        },
        [tags, q]
    );

    const applyTimeBasedSearchHandler = useCallback((data, clear) => {
        let filterObj = getSearchFilterObjectFromTags(PostureSearchTemplate, tags);
        if (clear) {
            if (filterObj.hasOwnProperty(TIME_BASED_SEARCH_FIELD)) {
                delete filterObj[TIME_BASED_SEARCH_FIELD];
                clear = false;
            }
            clear = Object.keys(filterObj).length === 0 ? true : false;
        } else {
            filterObj = { ...filterObj, ...data }
        }
        handleApplyAdvancedSearch(
            filterObj,
            clear,
            setIsClearAll,
            setTags,
            setTagFlag,
            ENTITIES.IDENTITY.pageType
        );
    },
        [tags, q]
    )

    useEffect(() => {
        let filterObj = getSearchFilterObjectFromTags(PostureSearchTemplate, tags);
        if (filterObj[TIME_BASED_SEARCH_FIELD]) {
            setIsTimeBasedSearchApplied(true);
        } else {
            setIsTimeBasedSearchApplied(false);
        }
    }, [tags])

    useEffect(() => {
        handleManualSearch(tags, ENTITIES.IDENTITY.pageType)
    }, [tags])

    const onAdvanceSearchClick = () => {
        setShowAdvanceSearch(true);
    }

    const handleAddtionalResultColumn = (val) => {
        setShowAdditionalResult(val);
        if (additionalResultHighlightClass === "")
            setAdditionalResultHighlightClass("glowing-animation");
    }

    useEffect(() => {
        if (additionalResultHighlightClass !== "" && showAdditionalResult) {
            setTimeout(() => {
                setAdditionalResultHighlightClass("");
            }, 15000)
        }
    }, [additionalResultHighlightClass])

    const renderData = (response: any) => {
        return response.map((item: IdentitiesObj) => {
            const itemName: any = item._id.split(" on ")[1];
            let id_hostsList: any = [];
            {
                item.host_detail && item.host_detail.map((group: any) => {
                    if (group.hostname !== "" && group.hostname !== undefined && group.hostname !== null) {
                        if (item.host_detail.length === 1 && group.hostname?.toLowerCase() === "unknown") {
                            id_hostsList.push({
                                "hostname": group.hostname,
                                "city_name": group.city_name,
                                "country_code2": group.country_code2,
                                "country_name": group.country_name,
                                "state_name": group.state_name
                            })
                        }
                        else {
                            if (group.hostname?.toLowerCase() !== "unknown")
                                id_hostsList.push({
                                    "hostname": group.hostname,
                                    "city_name": group.city_name,
                                    "country_code2": group.country_code2,
                                    "country_name": group.country_name,
                                    "state_name": group.state_name
                                })
                        }
                    }
                })
            }

            return (
                <React.Fragment>
                    <tr key={item._id?.toString()}>
                        {
                            authDetails?.permissions?.Posture?.manual_archive == 'readwrite' &&
                            <td style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 25 : 25, padding: '0px' }}>
                                <AMCheckbox indeterminate={false} onClick={onSetSelectRow.bind(this, item)} checked={getSelectRow(item)}
                                    disabled={false} /></td>
                        }
                        <td style={window.matchMedia("(min-width: 2560px)").matches || !showAdditionalResult ? { width: "35%" } : { width: "35%" }} className='action-btn-cell copy_field'>
                            <div className="copy_host_count">
                                <span className='droplist'>
                                    <Tooltip
                                        classes={{ tooltip: 'posture-root-column-container scrollbar-container' }}
                                        title={<div className="service_list2">

                                            {item?.full_name?.toLowerCase() !== item?._id?.toLowerCase() ?
                                                <div>
                                                    <h2 style={{ marginBottom: 0 }} className={(id_hostsList?.length === 0 ? 'no_border_bottom' : '')}>
                                                        <span className="ellipsis_idassetname tooltip_ellipses" title={item.full_name?.toString()}>{item.full_name?.toString()}</span>
                                                        <div className="copy_idassetname tooltip_copy_title" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(item.full_name)}></div>
                                                    </h2>
                                                    <h2 style={{ marginTop: 0, paddingTop: '6px' }} className={(id_hostsList?.length === 0 ? 'no_border_bottom' : '')}>
                                                        <span className="ellipsis_idassetname tooltip_ellipses" title={item._id?.toString()}>{item._id?.toString()}</span>
                                                        <div className="copy_idassetname tooltip_copy_title" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(item._id)}></div>
                                                    </h2>
                                                </div>
                                                : <h2 className={(id_hostsList?.length === 0 ? 'no_border_bottom' : '')}>
                                                    <span className="ellipsis_idassetname tooltip_ellipses" title={item._id?.toString()}>{item._id?.toString()}</span>
                                                    <div className="copy_idassetname tooltip_copy_title" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(item._id)}></div>
                                                </h2>}

                                            {id_hostsList.length > 0 ?
                                                <table>
                                                    <tr>
                                                        <th className="align_left">Hosts</th>
                                                    </tr>
                                                    {id_hostsList.map((group: any, index: any) => {
                                                        if (index < 5) {
                                                            return (<tr key={group.hostname + '-' + index}

                                                            >
                                                                <td className="hostname_td align_left display-flex-column" onMouseOver={(e) => handleMouseEnterHost(index, group.hostname, e)} onMouseLeave={(e) => handleMounseLeaveHost(index, group.hostname, e)}>

                                                                    <div
                                                                        style={{ display: 'flex' }}

                                                                    >
                                                                        {group.country_code2 ?
                                                                            <span className="flag_wrapper">
                                                                                {group.country_name ?
                                                                                    <div className="flag_icon" style={{ position: 'relative' }}>
                                                                                        <AMCountryFlag countryCode={group.country_code2} />
                                                                                    </div>
                                                                                    :
                                                                                    null}
                                                                            </span>
                                                                            :
                                                                            <span className="flag_wrapper">
                                                                                {group.country_name ?
                                                                                    <div className="location_icon">
                                                                                    </div>
                                                                                    :
                                                                                    <div className="flag_icon" style={{ position: 'relative' }}>
                                                                                        <div className='no_flag'></div>
                                                                                    </div>}
                                                                            </span>}
                                                                        <span className="ellipsis_idassetname id-flag">{group.hostname}</span>
                                                                        <div style={{
                                                                            flex: 1
                                                                        }}>
                                                                            <div className="copy_idassetname" title="Copy this text to Clipboard"
                                                                                style={{ position: 'relative', flex: 1, marginLeft: 0, float: 'right' }}
                                                                                onClick={() => navigator.clipboard.writeText(group.hostname)}></div>
                                                                        </div>

                                                                    </div>
                                                                    <div className={`hidden-block  paddingLeft20 host-name-list-${index}-${group.hostname}`} >
                                                                        {group.hostname ? <li>
                                                                            <strong>Hostname</strong>: {group.hostname}</li> : null}
                                                                        {group.country_name ? <li><strong>Country</strong>: {group.country_name}</li> : null}
                                                                        {group.state_name ? <li><strong>State</strong>: {group.state_name}</li> : null}
                                                                        {group.city_name ? <li><strong>City</strong>: {group.city_name}</li> : null}
                                                                        {group.ip ? <li><strong>Ip</strong>: {group.ip}</li> : null}
                                                                    </div>
                                                                </td>
                                                            </tr>);
                                                        }
                                                    })}
                                                    {id_hostsList.length > 5 ?
                                                        <tr>
                                                            <td className="hostname_td align_left">
                                                                <span className="ellipsis_idassetname id-flag">...</span>
                                                            </td>
                                                        </tr> : null}
                                                </table>
                                                : null}
                                        </div>}>
                                        <div>
                                            <span className={(item.is_known_identity === false && item?.type === "User" && id_hostsList.length > 1) ?
                                                "ellipsis_idassetname_host droplist" :
                                                "ellipsis_idassetname  droplist"}>
                                                {/* <span style={{ display: 'flex' }}> */}
                                                {item._id.split(" on ")[0]} {itemName !== undefined ? " on " : ""}
                                                <i style={{ marginRight: 2 }}>{itemName !== undefined ? itemName : ""}</i>
                                                {/* </span> */}
                                            </span>
                                            <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: 2, marginLeft: 2, transform: 'skewX(-10deg)' }} />
                                        </div>
                                    </Tooltip>

                                </span>
                                <div className="copy_idassetname copy_idassetname_id" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(item._id)}></div>
                                {
                                    getRules(item).length > 0 ?
                                        <BasePostureActionMenu actionData={item}
                                            menuItems={getRules(item).length > 0
                                                ? IdentitiesActionMenu :
                                                IdentitiesActionMenu.filter((item: PostureMenuItem) => item.action == PostureMenuItemAction.ADD_EX_COPWD_USR)
                                            }
                                            exceptionData={[`s_name:${item._id}`, `rule_name:` + getRules(item, 'exception')]} classes={"posture action_button"}
                                            getContent={() => [`s_name:${item._id}`,
                                            `rule_name:` + getRules(item, 'exception')]}
                                            incContent={() => [`inc_count:${getIssueCount(item)}`, `s_name:${item._id}`, `rule_name:` + getRules(item)]}
                                            closeIncQuery={`{"status":"Open","is_external":"false","s_name":"${item._id}"` + `,"rule_name":"` + getRules(item) + `"}`}
                                            issueCount={getIssueCount(item)}
                                            reloadList={fetchIdentities}
                                            keyName={`identity-${item._id}`}
                                        /> : null
                                }

                                {(item.is_known_identity === false && item?.type === "User" && id_hostsList.length > 1) ? <span className="host_count"><i>(on multiple hosts)</i></span> : null}
                            </div>
                        </td>
                        <td style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 50 : 50 }}>
                            <PostureCategory
                                type={item.type}
                                categories={item.identity_category_list || []}
                                title={getItemIconTitle(item)}
                            >


                                <div className='asset_type_icons'>
                                    <div className={getItemIconClass(item)} >
                                        {(item.is_known_identity === false && (item?.type === "User" || item?.type === "User/Agent" || item?.type === "User/Background Browsing") ) ? <span title={"Unresolved User"}>?</span> : null}
                                        {(item.is_known_identity === false && (item?.type === "Service" || item?.type === "Service/Application" ||  item?.type === 'Service/Computer Account' || item?.type === 'Service/Key and Secret' || item?.type === 'Service/Token' || item?.type === 'Service/Service Principal' || item?.type === 'Service/Service Account')) ? <span>?</span> : null}
                                    </div>
                                </div>
                            </PostureCategory>
                        </td>
                        <td style={{ padding: "0", border: "none!important", width: "15%" }}>
                            <tr className='dot_outer'>
                                <ScrollIssueData data={item.rules} handleRuleFilterClick={handleRuleFilterClick}
                                    loadBasePostureActionProps={loadBasePostureActionProps} issueList={issueHeader} postureObject={item} selectedItems={items} isAdditionalResultColVisible={showAdditionalResult} tableWidth={"307px"} isTimeBasedSearchApplied={isTimeBasedSearchApplied} />
                            </tr>
                        </td>
                        <td style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 65 : 65 }}>
                            {item.flow_count === 0 ?
                                <div>1</div>
                                : (findDifferenceInDays(item.latest_time) <= (widgetData?.posture_view_date_range || 60)) ?
                                    <div className={`${item.flow_count > 1 ? 'shadowbox' : ''}`} id={item._id?.toString()} onClick={(event) => { if (item.flow_count > 1) { handleFlowClick(event, { id: item._id?.toString(), risk: undefined }) } }}>
                                        {item.flow_count?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>
                                    :
                                    <div id={item._id?.toString()}>
                                        {item.flow_count?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>
                            }
                        </td>
                        {/*  <td style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 152 : 70 }}>
                            {item.rules_count !== 0 ?
                                <div className="shadowbox num_flows" onClick={handleFlowClick} id={item._id?.toString()}>
                                    <span>{convertToCommaValue(item.rules_count)}</span>
                                </div>
                                :
                                <div id={item._id?.toString()}>
                                    <span>{convertToCommaValue(item.rules_count)}</span>
                                </div>
                            }
                        </td> */}
                        <td style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 70 : 70 }}>
                            {item.issue_count !== 0 ?
                                <div className="shadowbox num_flows" onClick={handleIssueFilterClick} id={item._id?.toString()}>
                                    <span>{convertToCommaValue(item.issue_count)}</span>
                                </div>
                                :
                                <div id={item._id?.toString()}>
                                    <span>{convertToCommaValue(item.issue_count)}</span>
                                </div>
                            }
                        </td>
                        <td style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 70 : 70 }}>
                            {item.asset_count !== 0 ?
                                <div className="shadowbox num_flows" onClick={() => { handleAssetsAccess(item._id?.toString()) }} id={item._id?.toString()}>
                                    <span>{convertToCommaValue(item.asset_count)}</span>
                                </div>
                                :
                                <div id={item._id?.toString()}>
                                    <span>{convertToCommaValue(item.asset_count)}</span>
                                </div>
                            }
                        </td>
                        {showAdditionalResult && <td className={additionalResultHighlightClass} style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 110 : 110 }}>
                            {getAdditionalResultCellValue(item, selectedField, ENTITIES.IDENTITY.pageType)}
                        </td>
                        }
                        <td style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 110 : 110 }}>
                            <div className="" style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 90 : 90, margin: "0 auto" }}>
                                {moment(item.latest_time).format('MMM DD YYYY, hh:mm A')}
                            </div>
                        </td>
                        <td style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 80 : 80 }}>
                            <IDScore data={item} />
                        </td>
                    </tr>
                </React.Fragment>
            );
        });
    }

    const handlePaginate = (selectedPage: any) => {
        param.page = selectedPage.selected === 0 ? 1 : selectedPage.selected + 1
        setQuery(param)
    }

    const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
        handleSearchFn(e, tags)
    }

    const handleLensSearch = () => {
        handleLensSearchFn(tags);
    }

    useEffect(() => {
        if (tagFlag && tags.length >= 0 || isClearAll) {
            param.page = 1;
            param.q = tags.join("+");
            param.d_name = undefined;
            param.risk = undefined;
            param.d_protocol = undefined;
            param.hash = undefined;
            param.e_time = undefined;
            param.s_time = undefined;
            param.rule_name = undefined;
            if (param.q === '') {
                param.reportType = undefined;
                param.ruleType = undefined;
            }
            setQuery(param);
            setIsClearAll(false); // reset clearall status.
            setTagFlag(false);
        }
    }, [tags, tagFlag])

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.target.value)
    }

    const deleteTag = (index: number) => {
        const anyActivityTimeTag = tags.filter(item => item.indexOf(TIME_BASED_SEARCH_FIELD) > -1);
        let newTags = getTagsForSearchInput(tags).filter((tag, i) => i !== index)
        if (anyActivityTimeTag && anyActivityTimeTag.length > 0) {
            newTags = [...newTags, ...anyActivityTimeTag];
        }

        setTags(newTags);

        setTagFlag(true);
        PostureSearchState.setIsAdSearchApplied(false);
    }

    const fetchWidget = () => {
        setLoading(true);
        const queryParams = {
            ...(hard_refresh_state && { 'hard_refresh': 'true' })
        }
        PostureSummary.GET(queryParams, (res: any) => {
            setLoading(false);
            if (res.status === 200) {
                setWidgetData(undefined);
                // setIssueHeaders(res.data.identity_rule_types);
                setTimeout(() => setWidgetData(res.data))
                //Set ID widget 
                setIdWidget(res.data.identity_summary)
                const rulesArray: any = [];
                //Set All rule root data
                rulesArray.push(...Object.values(res.data.identity_rules_summary.rules))
                //Set Asset Widget Data
                // setRuleWidget(rulesArray);
                // setRuleWidgetCopy(rulesArray);
                localStorage.setItem('identitiesRule', JSON.stringify(rulesArray));

                const rulesRoot: any = [];
                rulesRoot.push(...Object.values(res.data.identity_rules_summary))
                //SetRuleRootCount Data
                setRuleRootIdentity(res.data.identity_rules_summary.root_cause_detail);
                scrollToIssueColumn();
            }
        }, (err: any) => {
            console.log(err)
            setLoading(false);
        });
    }


    const handleGotoPage = () => {
        let pageValue = (document.getElementsByName("page_no")[0] as HTMLTextAreaElement).value;
        pageValue = (pageValue !== "") ? pageValue : "1";
        let currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.set('page', pageValue);
        (document.getElementsByName("page_no")[0] as HTMLTextAreaElement).value = "";
        history.push(window.location.pathname + "?" + currentUrlParams?.toString());
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    //Download CSV
    const headers = [
        { label: "Identity Name", key: "_id" },
        { label: "Identity Type", key: "type" },
        { label: "Hosts 1", key: "host_detail[0].hostname" },
        { label: "Hosts 2", key: "host_detail[1].hostname" },
        { label: "Hosts 3", key: "host_detail[2].hostname" },
        { label: "Hosts 4", key: "host_detail[3].hostname" },
        { label: "Hosts 5", key: "host_detail[4].hostname" },
        { label: "# Flows", key: "flow_count" },
        /* { label: "Overall # Issues Matched", key: "rules_count" }, */
        { label: "Overall # Incidents", key: "issue_count" },
        { label: "# Assets Accessed", key: "asset_count" },
        { label: "Latest Activity", key: "latest_time" },
        { label: "Score", key: "score" }
    ];

    const [downData, setDownData] = useState<string | Data>([]);
    const [totalDownloaded, setTotalDownloaded] = useState<number | undefined>(0)
    const [loadingCSV, setLoadingCSV] = useState({ loading: false, setData: false })
    const currDownData: any = [];
    const csvLinkEl = useRef<any>();


    const getUserList = async (selectedPage?: any) => {
        param.page = selectedPage === 0 ? 1 : selectedPage + 1;
        param.rpp = 100;
        const searchHeaders = await getSearchHeaders(param);
        return Api.get("/identities", {
            params: { ...param, is_export_csv: true },
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
            ...searchHeaders
        })
            .then(res => {
                // res.data = validateLocalIps(res.data, 'host_detail');
                currDownData.push(...formatCSVData(res));
                return currDownData;
            }).catch((error: any) => {
                setLoadingCSV({ loading: false, setData: false })
                if (error.response.status === 500 || error.response.status === 524) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 404) {
                    addToast("We are not able to find associated email, please check and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            });
    }

    const downloadCSV = async () => {
        setTotalDownloaded(0);
        setLoadingCSV({ loading: true, setData: false })
        let i = 0;
        do {
            const downData = await getUserList(i);
            setTotalDownloaded(i * 100);
            if (i > Math.floor(totalCount! / 100)) {
                setDownData(downData);
                setTimeout(() => {
                    setLoadingCSV({ loading: false, setData: true });
                    if (csvLinkEl && csvLinkEl?.current && csvLinkEl?.current?.link) {
                        csvLinkEl?.current?.link?.click();
                    }
                });
            }
            i = i + 1;
        } while (i <= Math.floor(totalCount! / 100) + 1)

    }

    const handleTabClick = (to: string) => {
        if (PostureSearchState.isAdSearchApplied) {
            const tempTags = tags.map(item => encodeURIComponent(item));
            history.push(`/${to}?order_by=desc&sort_by=score&q=${tempTags.join('%2B')}`)
        } else {
            history.push(`/${to}?order_by=desc&sort_by=score`);
        }
    }

    useEffect(() => {
        if (PostureSearchState.isAdvancedSearchOpen) {
            setShowAdvanceSearch(true)
        }
    }, [])

    useEffect(() => {
        PostureSearchState.setIsAdvancedSearchOpen(showAdvanceSearch);
    }, [showAdvanceSearch]);


    const getPlayBookData = (updatedItems: any) => {

        // setItems(updatedItems);
       updatedItems = updatedItems.filter((item)=> !(item.is_issue_type && item.name === 'Posture Issues') )
        setSelectedItems(updatedItems);
        //setSelectedItems({ 'identity': updatedItems,identity_default_view:false});
        // Create another array with _id property as value
        const newArray = updatedItems.map(item => item._id);
        // Sort the new array based on the order property value
        newArray.sort((a, b) => {
            const orderA = updatedItems.find(item => item._id === a).order;
            const orderB = updatedItems.find(item => item._id === b).order;
            return orderA - orderB;
        });
        setIssueHeaders(newArray);
        setShowGraph(false);

        if (JSON.parse(localStorage.getItem('identitiesRule'))) {

            const filteredData = JSON.parse(localStorage.getItem('identitiesRule'))?.filter(item => newArray.includes(item.name));
            setRuleWidget(filteredData);

        }
    }

    useEffect(() => {
        if (ruleWidget) {
            setShowGraph(true);
        }

    }, [ruleWidget]);




    const fetchPlayBookData = async () => {
        await Api.get("/pbviewplaybooks?type=identity", {
            timeout: 30000,
        })
            .then((res) => {
                //res.data = responseAdapter(res.data);
                setItems(res.data);
            })
            .catch((err) => {
                if (err.response?.status === 401) {
                    window.location.href = "/login";
                }
            });
    };

    const matchingPlaybookFoundFn = () => {
        const newParams = { ...query };
        newParams.entity_type = ENTITIES.IDENTITY.id;
        setQuery(newParams);
        matchingPlaybookFound(newParams, getSearchHeaders)
    }

    const onFieldSelect = (field, fields) => {
        setSelectedField(field);
        setAdditionalFields(fields);
    }

    const [showOudatedData, setShowOutdatedData] = useState(false);

    const outDated = () => {
        let clearOutdatedDataInterval = null;
        setShowOutdatedData(false);

        let tempTags = getTagsForTimeBasedSearch(tags);

        tempTags = Object.keys(AVAILABLE_PRESETS).filter(item => {
            if (tempTags && tempTags.length > 0)
                return item === tempTags[0][1]
            return false;
        })
        if (tempTags && tempTags.length > 0) {
            if (clearOutdatedDataInterval)
                clearInterval(clearOutdatedDataInterval);
            clearOutdatedDataInterval = setTimeout(() => {
                if (isPresetPeriodOptionSelected()) {
                    setShowOutdatedData(true);
                }
            }, 30000);
        }
        return () => {
            if (clearOutdatedDataInterval)
                clearInterval(clearOutdatedDataInterval);
        }
    }
    useEffect(() => {
        outDated();
    }, [tags])

    return (
        <>
            <div className="clrBoth margintop10"></div>
            <div className='hide-table-border-top'></div>
            <div className="">
                <div className="flow_table_container identity-container">
                    <div className={showAdditionalResult ? "posture_search_section posture_search_section_additional_col" : "posture_search_section posture_search_section_no_additional_col"}>
                        <TimeBasedSearchContainer onApply={applyTimeBasedSearchHandler} filters={getTagsForTimeBasedSearch(tags)} apiUrl={'/posture/daily_flow_count?entity_type=identity'} pageType={'identities'}></TimeBasedSearchContainer>
                        <PostureArchiveTooltip numberOfDays={widgetData?.posture_view_date_range} />
                        <PostureActionButtons
                            entity='identities'
                            selectAll={selectAll}
                            onArchiveClick={onArchive.bind(null, reloadIdentities)}
                            selectCount={selectCount} isOpenIncidents={isIncidentsOpen}
                            ref={archiveBtnRef} isHidden={true} onArchiveEstimate={onArchiveEstimate.bind(null)} />
                        {showAdvanceSearch && <PostureFilter
                            filterTemplate={PostureSearchTemplate.filter(item => item.search_key !== TIME_BASED_SEARCH_FIELD)}
                            notSupportedKeys={notSupportedKeys}
                            filters={tags}
                            isOpen={showAdvanceSearch} onApplyFilter={applySearchHandler}
                            onClose={onCloseAdvanceSearch}
                            disableCreatePlaybookBtn={isValidResultCountThreshold(responseData)}
                            matchingPlaybookFoundFn={matchingPlaybookFoundFn}
                            entityType={ENTITIES.IDENTITY.id}
                            query={query}
                            showCreatePlaybookBtn={query && query['playbook_type'] === POSTURE_ISSUE.type ? false : true}
                            preMetaDataHandler={preMetaDataHandler}
                            isTimeBasedSearchApplied={isTimeBasedSearchApplied}
                            applyTimeBasedSearchHandler={applyTimeBasedSearchHandler}
                            apiUrl={'/posture/daily_flow_count?entity_type=identity'} pageType={'identities'}
                            PartnerConfigState={PartnerConfigState}
                        />}
                        {
                            openPanelFilterWidgets ?
                                <FilterWidget toggleWidget={handleFilterWidget}
                                    disabledState={handleFilterDisabled}
                                    updateAfterFilter={reloadIdentities} issueFiltersData={filterData} /> : ""
                        }
                    </div>
                    <div id="tab1" className="tab-panel">
                        <table id="table" className={showAdditionalResult ? "id_asset_tb sticky_table_top posture-table-width" : "id_asset_tb sticky_table_top"}>
                            <thead>
                                <tr>
                                    <th colSpan={13} className="" style={{ background: "#ffffff", color: "#4e5267", paddingRight: '0px' }} >
                                        <div className='flex-space-between'>
                                            <div>
                                                <div className="reload_container">
                                                    <div ref={reloadRef} className="reload_color_icon" onClick={reloadIdentities}></div>
                                                </div>

                                                <div className="table_title">Last Updated: {lastUpdate}</div>
                                                {showOudatedData ? <Chip label="Data could be outdated, reload to update" color="error" className='outdated-data-msg' /> : <></>}
                                            </div>
                                            <div className='maxWidth550 display-flex'>
                                                {/* <div className="search_white_icon">&nbsp;</div> */}
                                                {/* <input type="text" className="table_search clear_search" onKeyUp={handleSearch} placeholder="Searched item" title=""/> */}
                                                <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                                    <div className={`search_container ${hasReportType ? 'active-report-type' : ''}`} >
                                                        <div className="search_white_icon" style={{ cursor: 'pointer' }}
                                                            onClick={handleLensSearch}>&nbsp;</div>&nbsp;
                                                        <Tags tags={getTagsForSearchInput(tags)} deleteTag={deleteTag} />&nbsp;
                                                        {/* {tags?.map((tag, index) => <div key={tag + index} title={tag?.toString()} className="search_tag">{tag}&nbsp;<span className="search_close_btn" onClick={() => deleteTag(tag)}>X</span></div>)}&nbsp; */}
                                                        <input
                                                            className="search_input"
                                                            value={searchInput}
                                                            placeholder="Search..."
                                                            onKeyDown={handleSearch}
                                                            onChange={handleSearchChange}
                                                        />
                                                        {getTagsForSearchInput(tags)?.length > 0 ? <span style={{ cursor: 'pointer' }} onClick={() => {
                                                            setTagFlag(true);
                                                            PostureSearchState.setIsAdSearchApplied(false)
                                                            if (isTimeBasedSearchApplied) {
                                                                const tempTags = tags.filter(item => item.indexOf(TIME_BASED_SEARCH_FIELD) > -1);
                                                                setTags(tempTags);
                                                            } else {
                                                                setTags([])
                                                            }
                                                        }}>&nbsp;X</span> : null}
                                                    </div>
                                                </div>
                                                <div className='issue-advance-search-btn-con display_flex_center'
                                                    style={{ float: 'right' }}
                                                ><button onClick={onAdvanceSearchClick} className={'button_styled float_left issue-advance-search'} >Advanced Search</button>
                                                    <Tooltip classes={{ tooltip: 'playbook-level-widget-tooltip' }} title="Configure View : Identity Issue">
                                                        <div
                                                            className="gear-setting-icon setting-identity-icon"
                                                            onClick={openSettingModal}>
                                                        </div>
                                                    </Tooltip>
                                                    <PlaybookTableActions actions={MoreActionsOptions} rowData={{}}
                                                        isDisabled={selectCount == 0 || showAdvanceSearch || isTimeBasedSearchApplied}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </th>
                                </tr>

                                <tr>
                                    {
                                        authDetails?.permissions?.Posture?.manual_archive == 'readwrite' &&
                                        <th className={showAdditionalResult ? "posture-table-checkbox-column" : ''} style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 30 : 30, padding: '0px' }}>
                                            <AMMultiCheckbox
                                                indeterminate={selectAllIndeterminate}
                                                menuItems={PostureMultiSelectCheckboxItems}
                                                onClick={PostureMultiSelectCheckboxItems[0].callback}
                                                // checked={selectAll || false}
                                                checked={selectAll}
                                                // disabled={!openIssuesCount || openIssuesCount < 1}
                                                disabled={false}
                                            />
                                        </th>}
                                    <th data-headerName="_id" className={(selectedColumn == '_id' ? currentSort : " tablesort_down")} onClick={handleSort} style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 275 : 275 }} ><span className='align_left float_left'>Identity Name</span></th>
                                    <th data-headerName="type" className={(selectedColumn == 'type' ? currentSort : " tablesort_down")} onClick={handleSort} style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 40 : 40 }}>Type</th>
                                    <th className="no_padding" style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 202 : 202 }}>
                                        <ScrollIssueHeader issueList={issueHeader} title='Identity Issue' query={query} handleSort={handleSort} ref={scrollIssueRef} selectedItems={items} isAdditionalResultColVisible={showAdditionalResult} tableWidth={"307px"} />
                                    </th>
                                    <th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 65 : 65 }} data-headerName="flow_count" className={(selectedColumn == 'flow_count' ? currentSort : " tablesort_down")} onClick={handleSort}>Overall Number<br />of<br />Flows</th>
                                    <th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 70 : 70 }} data-headerName="issue_count" className={(selectedColumn == 'issue_count' ? currentSort : " tablesort_down")} onClick={handleSort}>Overall<br />Number<br />of<br />Incidents</th>
                                    <th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 70 : 70 }} data-headerName="asset_count" className={(selectedColumn == 'asset_count' ? currentSort : " tablesort_down")} onClick={handleSort}>Number<br />of<br />Assets<br />Accessed</th>
                                    {showAdditionalResult && (
                                        selectedField !== null ? (
                                            <th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 110 : 110 }} data-headerName={selectedField['search_key']}
                                                className={additionalResultHighlightClass + ' ' + (additionalFields.includes(selectedColumn) ? currentSort : " tablesort_down") + (additionalFields.length > 1 ? ' additional_result_sort_icon' : '')}
                                                onClick={handleSort}>
                                                <div className='additional-results-col'>
                                                    <AdditionalResult options={tags} filterTemplate={PostureSearchTemplate} notSupportedKeys={notSupportedKeys} pageType={ENTITIES.IDENTITY.pageType} onFieldSelect={onFieldSelect} handleAddtionalResultColumn={handleAddtionalResultColumn} />
                                                </div>
                                            </th>)
                                            : (
                                                <th className={additionalResultHighlightClass} style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 110 : 110 }}>
                                                    <div className='additional-results-col'>
                                                        <AdditionalResult options={tags} filterTemplate={PostureSearchTemplate} notSupportedKeys={notSupportedKeys} pageType={ENTITIES.IDENTITY.pageType} onFieldSelect={onFieldSelect} handleAddtionalResultColumn={handleAddtionalResultColumn} />
                                                    </div>
                                                </th>
                                            )
                                    )
                                    }
                                    <th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 110 : 110 }} data-headerName="latest_time" className={(selectedColumn == 'latest_time' ? currentSort : " tablesort_down")} onClick={handleSort}>Latest Activity</th>
                                    <th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 80 : 80 }} data-headerName="score" className={(selectedColumn == 'score' ? currentSort : " tablesort_down")} onClick={handleSort}>Score</th>
                                </tr>

                            </thead>

                            <tbody >
                                {
                                    loading ?
                                        <tr>
                                            <td colSpan={13} style={{ textAlign: 'center' }}><div className='loader spinner'></div></td>
                                        </tr> :
                                        responseData?.result == 'error' || responseData?.result.length == 0 ?
                                            <tr>
                                                <td colSpan={13} style={{ textAlign: 'center' }}>{tableFetchError}</td>
                                            </tr>
                                            :
                                            responseData && responseData.result !== null && responseData.result.length > 0 ? renderData(responseData.result) :
                                                <tr>
                                                    <td colSpan={13} style={{ textAlign: 'center' }}><div className='loader spinner'></div></td>
                                                </tr>
                                }
                            </tbody>
                        </table>
                        <div className="clrBoth"></div>
                    </div>
                    <div className="download_section">
                        <div>
                            <button type={('button')} onClick={downloadCSV} className={"width150 " + (loadingCSV.loading ? 'loader export_loader' : 'button_styled')}>Export CSV</button>

                            {loadingCSV.loading ?
                                <div className='loading_info'>
                                    Received <span ><b>{totalDownloaded}</b></span> of <span ><b>{totalCount}</b></span> Identities
                                </div>
                                : null}

                            {PartnerConfigState?.PartnerShortProduct && <CSVLink
                                headers={getHeaders(headers, issueHeader, 'Identity Issues')}
                                filename={getExportFileName(PartnerConfigState?.PartnerShortProduct, 'Identities')}
                                data={downData!}
                                ref={csvLinkEl}
                            />}
                        </div>
                        <div style={{ marginLeft: '1rem', marginTop: '1rem' }}>
                            <Link to={'/archivedata'} className='link-text font12'>Identities inactive for more than 60 days are archived here.</Link>
                        </div>
                    </div>

                    <div className="float_right goto_page">Go to page:
                        <input
                            type="text"
                            name="page_no"
                            className=""
                            defaultValue=""
                            placeholder="No."
                        />
                        <button type="button" onClick={handleGotoPage} className="button_gray float_right">Go</button>
                    </div>
                    <ReactPaginate
                        previousLabel={"← Previous"}
                        nextLabel={"Next →"}
                        forcePage={param.page !== undefined ? (param.page - 1) : 0}
                        pageCount={responseData && responseData.result.length > 0 ? (Math.ceil(responseData.total / responseData.rpp)) : 1}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                        onPageChange={handlePaginate}
                    />
                </div>
            </div >
            {openFlow ? <FlowTrend data={flowsTrendResponseData} chartDetails={flowsChartDetails} onRangeChange={handleRangeChange} closeFlowPopup={() => setOpenFlow(false)} zoomLevel={zoomLevel}  updatedExtremes={updatedExtremesRef}  showZoom={true}/> : ''
            }

            <PlayBookView widId='identity' openModal={openModal} handleClose={handleClose} getData={getPlayBookData}></PlayBookView>

        </>
    )
}

export default React.memo(withQueryParams({
    q: StringParam,
    page: StringParam,
    rpp: NumberParam,
    sort_by: StringParam,
    order_by: StringParam,
    rule_name: StringParam,
    d_protocol: StringParam,
    risk: NumberParam,
    hash: StringParam,
    d_name: StringParam,
    s_time: NumberParam,
    e_time: NumberParam,
    hard_refresh: StringParam,
    filters: withDefault(ArrayParam, []),
    entity_type: StringParam,
    playbook_type: StringParam
}, Identities));