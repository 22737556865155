import { useContext, useState } from "react";
import { IdAssetWidget, ServerResponse } from "../../../../types/response-types";
import { UsePostureChartsResponse } from "../../../../store/PostureStateContextProvider";

export const usePostureCharts = (): UsePostureChartsResponse => {
    const [responseData, setResponseData] = useState<ServerResponse>();
    const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
    const [idWidget, setIdWidget] = useState<IdAssetWidget>();
    const [ruleWidget, setRuleWidget] = useState<IdAssetWidget>();
    const [ruleRootIdentity, setRuleRootIdentity] = useState<IdAssetWidget>();
    const [showGraph, setShowGraph] = useState(true);
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [items, setItems] = useState<any[]>([]);
    const [widgetData, setWidgetData] = useState<IdAssetWidget>();
    const [totalCount, setTotalCount] = useState<number>(0);

    return {
        responseData, setResponseData,
        showAdvanceSearch, setShowAdvanceSearch,
        idWidget, setIdWidget,
        ruleWidget, setRuleWidget,
        ruleRootIdentity, setRuleRootIdentity,
        showGraph, setShowGraph,
        selectedItems, setSelectedItems,
        items, setItems,
        widgetData, setWidgetData,
        totalCount, setTotalCount
    }
}