import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import Popup from 'reactjs-popup';
import { MatchedRuleData } from '../../../types/response-types';
import { Api } from '../../../components/Axios';
import { RuleType } from '../../playbooks/constants/Constants';
import moment from 'moment';
import { SuspectedList } from '../sub/SuspectedList';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { GlobalConsts } from '../../../GlobalConst';
import { getIssueNameById } from '../../playbooks/helpers/playbook-helper';
import { PlaybookModal } from '../../playbooks/ref/AdvancedPlaybook/PlaybookModal';

const SuspectedPbList = [
    RuleType.SUSPECTED_ATT_DIS_ACC.toString(), RuleType.SUSPECTED_ATT_LOCK_ACC.toString(), RuleType.SUSPECTED_ATT_EXP_ACC.toString(),
    RuleType.SUSPECTED_ATT_LOCK_ACC.toString(), RuleType.SUSPECTED_DIR_BOT.toString(), RuleType.SUSPECTED_PASS_SPRAY.toString(),
    RuleType.ENUM_AD_ADMINS.toString(), RuleType.ENUM_AD_USERS.toString(), RuleType.UNAUTH_LOGIN_DEVICE.toString(),
    RuleType.UNAUTH_LOGIN_TIME.toString(), RuleType.NTLM_RELAY_ATTACK.toString(), RuleType.SUSPECTED_BRUTE_FORCE.toString()
];

const dir_names = ["onelogin", "one login", "one-login", "okta", "ping", "pingone", "ping identity", "ping-identity",
    "workspace", "google workspace", "google-workspace", "azure", "azure ad", "azure-ad", "microsoft"]

type Props = {
    data: MatchedRuleData | undefined,
    closeMatchRule?: any
}

const _4k = window.matchMedia("(min-width: 2560px)").matches ? 1 : 1;
const PlaybookLinks = {
    'Shadow Assets': (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-shadow-asset?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    'Shadow Identity Systems': (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-shadow-directory?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    'Shadow Access': (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-shadow-access?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    'Auth Hash Security': (data?: MatchedRuleData) => {
        if (data?.matching_rule_details.matching_field_value == 'sha1') {
            window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-sha-1-related-issues?${GlobalConsts.DOC_TOKEN}`, '_blank');
        } else if (data?.matching_rule_details.matching_field_value == 'md4') {
            window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-md4-related-issues?${GlobalConsts.DOC_TOKEN}`, '_blank');
        } else if (data?.matching_rule_details.matching_field_value == 'md5') {
            window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-md5-related-issues?${GlobalConsts.DOC_TOKEN}`, '_blank');
        } else if (data?.matching_rule_details.matching_field_value == 'rc2') {
            window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-rc2-and-rc4-related-issues?${GlobalConsts.DOC_TOKEN}`, '_blank');
        } else if (data?.matching_rule_details.matching_field_value == 'rc4') {
            window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-rc2-and-rc4-related-issues?${GlobalConsts.DOC_TOKEN}`, '_blank');
        }
    },
    'Auth Protocol Quality': (data?: MatchedRuleData) => {
        if (data?.matching_rule_details.matching_field_value.toLocaleLowerCase() == 'smtp') {
            window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-smtp-related-issues?${GlobalConsts.DOC_TOKEN}`, '_blank');
        } else if (data?.matching_rule_details.matching_field_value.toLocaleLowerCase() == 'imap') {
            window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-imap-related-issues?${GlobalConsts.DOC_TOKEN}`, '_blank');
        } else if (data?.matching_rule_details.matching_field_value.toLocaleLowerCase() == 'pop') {
            window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-pop-related-issues?${GlobalConsts.DOC_TOKEN}`, '_blank');
        } else if (data?.matching_rule_details.matching_field_value.toLocaleLowerCase() == 'ftp') {
            window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-ftp-related-issues?${GlobalConsts.DOC_TOKEN}`, '_blank');
        } else if (data?.matching_rule_details.matching_field_value.toLocaleLowerCase() == 'smb') {
            window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-smb-related-issues?${GlobalConsts.DOC_TOKEN}`, '_blank');
        } else if (data?.matching_rule_details.matching_field_value.toLocaleLowerCase() == 'nfs') {
            window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-nfs-related-issues`, '_blank');
        } else if (data?.matching_rule_details.matching_field_value.toLocaleLowerCase() == 'telnet') {
            window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-telnet-related-issues`, '_blank');
        } else if (data?.matching_rule_details.matching_field_value.toLocaleLowerCase() == 'ldap') {
            window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-ldap-related-issues?${GlobalConsts.DOC_TOKEN}`, '_blank');
        } else if (data?.matching_rule_details.matching_field_value.toLocaleLowerCase() == 'radius') {
            window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-radius-related-issues?${GlobalConsts.DOC_TOKEN}`, '_blank');
        } else if (data?.matching_rule_details.matching_field_value.toLocaleLowerCase() == 'diameter') {
            window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-diameter-related-issues?${GlobalConsts.DOC_TOKEN}`, '_blank');
        } else if (data?.matching_rule_details.matching_field_value.toLocaleLowerCase() == 'ntlm') {
            window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-ntlm-related-issues?${GlobalConsts.DOC_TOKEN}`, '_blank');
        } else if (data?.matching_rule_details.matching_field_value.toLocaleLowerCase() == 'ntlmv1') {
            window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-ntlm-related-issues?${GlobalConsts.DOC_TOKEN}`, '_blank');
        } else if (data?.matching_rule_details.matching_field_value.toLocaleLowerCase() == 'ntlmv2') {
            window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-ntlm-related-issues?${GlobalConsts.DOC_TOKEN}`, '_blank');
        } else if (data?.matching_rule_details.matching_field_value.toLocaleLowerCase() == 'http') {
            window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-http-related-issues?${GlobalConsts.DOC_TOKEN}`, '_blank');
        }

    },
    'Auth Hash Quality': (data?: MatchedRuleData) => {
        if (data?.matching_rule_details.matching_field_name == 'am_analysis.hash_analysis.hash_hex_length') {
            window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-password-hash-length?${GlobalConsts.DOC_TOKEN}`, '_blank')
        } else if (data?.matching_rule_details.matching_field_name == 'am_analysis.hash_analysis.hash_salt_bits') {
            window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-password-salt?${GlobalConsts.DOC_TOKEN}`, '_blank');
        }
    },
    'Compromised Password': (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-compromised-password?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    'Compromised User': (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-compromised-user?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    'Exposed Assets': (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-exposed-assets?${GlobalConsts.DOC_TOKEN}`, '_blank')
    },
    'Lack of MFA': (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-lack-of-mfa?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    'Shadow External Access': (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-shadow-external-access?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    'Suspicious Inbound Access': (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-suspicious-inbound-access?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    'Suspicious Outbound Access': (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-suspicious-outbound-access?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    'Unknown Access': (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-unknown-access?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    'Unknown SaaS Access': (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-unknown-saas-access?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    'Weak Password': (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-weak-password?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    [RuleType.ACCESS_FROM_UNAUTH_COUNTRIES]: (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-access-from-unauthorized-country?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    [RuleType.ACCESS_TO_UNAUTH_COUNTRIES]: (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-access-to-unauthorized-country?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    [RuleType.ACCESS_FROM_PUBLIC_VPN]: (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-access-using-public-ip?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    [RuleType.ACCESS_TO_PUBLIC_VPN]: (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-access-using-public-ip?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    [RuleType.ACCESS_FROM_ANON_IP]: (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-access-using-anonymous-ip-wip?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    [RuleType.ACCESS_TO_ANON_IP]: (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-access-using-anonymous-ip-wip?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    [RuleType.UNAUTHORIZED_ASSET_ACCESS]: (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-unauthorized-asset-access?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    [RuleType.UNAUTHORIZED_IDENTITY_ACCESS]: (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-unauthorized-identity-access?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    [RuleType.DEVIATION_IN_DAILY_ASSET]: (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-deviation-in-daily-asset-activity-?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    [RuleType.SUSPECTED_BRUTE_FORCE]: (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-suspected-identity-brute-force-att?${GlobalConsts.DOC_TOKEN}`, '_blank');
    },
    [RuleType.SUSPECTED_ATT_DIS_ACC]: (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-suspected-attack-on-disabled-accou?${GlobalConsts.DOC_TOKEN}`);
    },
    [RuleType.SUSPECTED_ATT_LOCK_ACC]: (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-suspected-attack-on-locked-account?${GlobalConsts.DOC_TOKEN}`);
    },
    [RuleType.SUSPECTED_ATT_EXP_ACC]: (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-suspected-attack-on-expired-accoun?${GlobalConsts.DOC_TOKEN}`);
    },
    [RuleType.ENUM_AD_ADMINS]: (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-enumeration-of-ad-admins?${GlobalConsts.DOC_TOKEN}`);
    },
    [RuleType.ENUM_AD_USERS]: (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-enumeration-of-ad-users?${GlobalConsts.DOC_TOKEN}`);
    },
    [RuleType.SUSPECTED_DIR_BOT]: (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-suspected-directory-bot-attack?${GlobalConsts.DOC_TOKEN}`);
    },
    [RuleType.NTLM_RELAY_ATTACK]: (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-suspected-ntlm-relay-attack?${GlobalConsts.DOC_TOKEN}`);
    },
    [RuleType.SUSPECTED_PASS_SPRAY]: (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-suspected-password-spray-attack?${GlobalConsts.DOC_TOKEN}`);
    },
    [RuleType.UNAUTH_LOGIN_DEVICE]: (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-unauthorized-login-device?${GlobalConsts.DOC_TOKEN}`);
    },
    [RuleType.UNAUTH_LOGIN_TIME]: (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-unauthorized-login-time?${GlobalConsts.DOC_TOKEN} `);
    },
    [RuleType.ACCOUNT_TAKEOVER]: (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-impossible-travel?${GlobalConsts.DOC_TOKEN} `);
    },
    [RuleType.SUSPECTED_ACCESS_TOKEN]: (data?: MatchedRuleData) => {
        window.open(`${GlobalConsts.REMEDIATION_LINK}/remediation-for-suspected-access-token-sharing?${GlobalConsts.DOC_TOKEN} `);
    }
}

const MatchruleDetails = ({ data, closeMatchRule }: Props) => {

    let history = useHistory();
    let snippetRef = useRef() as React.MutableRefObject<HTMLDivElement>;
    const [loading, setLoading] = useState<boolean>(false);
    const [flowResponse, setFlowResponse] = useState<any>(undefined);
    const [flowError, setFlowError] = useState('Fetching latest data...');
    const [suspectedPopup, setSuspectedPopUp] = useState(false)
    const [showShareIcon, setShowShareIcon] = useState(true);
    const [matchRuleData, setMatchRuleData] = useState<MatchedRuleData>();

    useEffect(() => {
        if (flowResponse && flowResponse?.incident_info) {
            const matchData = { ...flowResponse?.incident_info }
            setMatchRuleData(matchData);
        }

    }, [flowResponse])

    useEffect(() => {

        const currentTime = +new Date();
        const flowData: any = sessionStorage.getItem(`${data?.flowId}-${data?.issueId}`);
        if (!flowData || ((JSON.parse(flowData)?.expiry_time) < (currentTime))) {
            updateListItem()
            setLoading(true);
            Api.get(`/issues/rawflow?q=flow_id:${data?.flowId}%2Bincident_id:${data?.issueId}`).then(((res: any) => {
                setLoading(false);
                setFlowResponse(res?.data?._source);
                const matchData = { ...res?.data?._source?.incident_info }
                const result = JSON.parse(JSON.stringify(res?.data?._source));
                result.expiry_time = currentTime + 600000;
                sessionStorage.setItem(`${data?.flowId}-${data?.issueId}`, JSON.stringify(result));
                if (matchData?.matching_rule_details?.highlighted_field_value?.length || false) {
                    matchData?.matching_rule_details?.highlighted_field_value.forEach((field: string) => highlightData(res?.data?._source, field));
                } else {
                    highlightData(res?.data?._source, updateMatchingField(matchData));
                }
            })).catch(er => {
                console.log(er);
                setLoading(false);
                setFlowResponse(undefined);
                setFlowError('Error occurred while fetching flow data')
            });
        } else {
            setLoading(true)
            const parsedData = JSON.parse(flowData);
            const match_rule_details = parsedData?.incident_info;
            setFlowResponse(parsedData)
            setTimeout(() => {
                setLoading(false)
                if (match_rule_details?.matching_rule_details?.highlighted_field_value?.length || false) {
                    setMatchRuleData({ ...flowResponse?.incident_info });
                    match_rule_details?.matching_rule_details?.highlighted_field_value.forEach((field: string) => highlightData(parsedData, field));
                } else {
                    highlightData(JSON.parse(flowData), updateMatchingField(match_rule_details));
                }
                updateListItem();
            }, 500)

        }
        const d = document.getElementById('unauth-access-gentimestamp');
        if (d) {
            d.innerHTML = moment(data?.genTimestamp).format('MMM DD YYYY,') + ' ' + moment(data?.genTimestamp).format('h:mm A')
        }

    }, [])

    const handleClose = () => {
        // history.goBack();
        closeMatchRule();
    }

    const updateMatchingField = (data: any) => {
        if (data) {
            if (data?.matching_rule_details?.matching_field_name === 'am_analysis.am_destination.Protocol') {
                return "am_destination.Protocol";
            } else if (data?.matching_rule_details?.matching_field_name == 'am_analysis.password_analysis.crack_times_seconds.online_no_throttling_10_per_second') {
                return 'am_analysis.password_analysis.crack_times_display.online_no_throttling_10_per_second';
            } else {
                return data?.matching_rule_details?.matching_field_name!;
            }
        }
        return '';
    }

    const highlightData = (flowData: any, fieldName: string) => {
        const exludeFields = ['NA'];
        try {
            if (data?.status?.toLowerCase() == 'resolved' && snippetRef?.current) {
                snippetRef.current.innerHTML = '<pre><code>' + JSON.stringify(flowData, null, 2) + '</code></pre>';
                return;
            }
            let result = '', objkeys = '';
            var highlightedField = fieldName.split(".");

            if (fieldName == 'is_external_access' && !flowData['is_external_access']) {
                flowData['is_external_access'] = false;
            }

            if (!exludeFields.includes(fieldName)) {
                try {
                    if (highlightedField.length === 1 && flowData[fieldName] !== undefined) {
                        flowData[fieldName] = ("<mark id='highlightmatchrule' style='scroll-margin: 20px;'>" + flowData[fieldName] + "</mark>").replaceAll(',', ',<br/>');
                    } else if (highlightedField.length === 2 && flowData[highlightedField[0]] && flowData[highlightedField[0]][highlightedField[1]] !== undefined) {
                        flowData[highlightedField[0]][highlightedField[1]] =
                            ("<mark id='highlightmatchrule' style='scroll-margin: 20px;'>" +
                                flowData[highlightedField[0]][highlightedField[1]] + "</mark>").replaceAll(',', ',<br/>');
                    } else if (highlightedField.length === 3 && flowData[highlightedField[0]] && flowData[highlightedField[0]][highlightedField[1]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]] !== undefined) {
                        const str = JSON.stringify(flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]]);
                        flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]] =
                            ("<mark id='highlightmatchrule' style='scroll-margin: 20px;'>" +
                                str + "</mark>").replaceAll(',', ',<br/>');
                    } else if (highlightedField.length === 4 && flowData[highlightedField[0]] && flowData[highlightedField[0]][highlightedField[1]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]] !== undefined) {
                        flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]] =
                            ("<mark id='highlightmatchrule' style='scroll-margin: 20px;'>" +
                                flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]] + "</mark>").replaceAll(',', ',<br/>');
                    } else if (highlightedField.length === 5 && flowData[highlightedField[0]] && flowData[highlightedField[0]][highlightedField[1]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]][highlightedField[4]] !== undefined) {
                        flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]][highlightedField[4]] =
                            ("<mark id='highlightmatchrule' style='scroll-margin: 20px;'>" +
                                flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]][highlightedField[4]] +
                                "</mark>").replaceAll(',', ',<br/>');
                    } else if (highlightedField.length === 6 && flowData[highlightedField[0]] && flowData[highlightedField[0]][highlightedField[1]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]][highlightedField[4]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]][highlightedField[4]][highlightedField[5]] !== undefined) {
                        flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]][highlightedField[4]][highlightedField[5]] =
                            ("<mark id='highlightmatchrule' style='scroll-margin: 20px;'>" +
                                flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]][highlightedField[4]][highlightedField[5]] +
                                "</mark>").replaceAll(',', ',<br/>');
                    } else if (highlightedField.length === 7 && flowData[highlightedField[0]] && flowData[highlightedField[0]][highlightedField[1]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]][highlightedField[4]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]][highlightedField[4]][highlightedField[5]] && flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]][highlightedField[4]][highlightedField[5]][highlightedField[6]] !== undefined) {
                        flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]][highlightedField[4]][highlightedField[5]][highlightedField[6]] =
                            ("<mark id='highlightmatchrule' style='scroll-margin: 20px;'>" +
                                flowData[highlightedField[0]][highlightedField[1]][highlightedField[2]][highlightedField[3]][highlightedField[4]][highlightedField[5]][highlightedField[6]] +
                                "</mark>").replaceAll(',', ',<br/>');
                    }
                } catch (error) {
                    console.log('Field is not available in flowData:', error);
                }
            }

            if (snippetRef?.current) {
                snippetRef.current.innerHTML = '<pre><code>' + JSON.stringify(flowData, null, 2).replaceAll('\\', '') + '</code></pre>';
                let highglightELement = document.getElementById('highlightmatchrule');
                highglightELement?.scrollIntoView();
            }

        } catch (er) {
            setFlowError('Incorrect flow data received.');
            console.log(er);
            setFlowResponse(undefined);
        }
    }

    const updateListItem = () => {
        if (data?.matching_rule_name == RuleType.COMPROMISED_USER) {
            const listItems = document.querySelectorAll('.code-snippet li');
            const count = document.querySelector('.breach-count')?.innerHTML;
            if (count && parseInt(count) > 5) {
                const ol = document.createElement(`ol`);
                ol.innerHTML = `<i style="list-style: none;text-decoration: none;">...</i>`;
                listItems[4].after(ol);
            }
        }
    }

    const handleRemediationClick = () => {
        const playBookName = data?.matching_rule_name || '';
        PlaybookLinks[playBookName as keyof typeof PlaybookLinks](data);
    }

    const handleSuspectedClick = () => {
        setSuspectedPopUp(true)
    }

    const copyData = () => {
        if (flowResponse?.incident_info?.matching_rule_details) {
            setShowShareIcon(false);
            navigator.clipboard.writeText(snippetRef.current.innerText)
            // Show the ShareIcon again after 5 seconds
            setTimeout(() => {
                setShowShareIcon(true);
            }, 5000);
        }
    }

    const [openPlayBookModal, setOpenPlayBookModal] = useState(false);
    const [playbookId, setPlaybookId] = useState('');

    return (
        <>

            <PlaybookModal
                isOpen={openPlayBookModal}
                onCloseHandler={() => {
                    // history.replace(`/playbooks${history.location.search}`);
                    // fetchPlaybooks();
                    setOpenPlayBookModal(false);
                }}
                playbookId={playbookId}
                title="View Playbook"
                formType='view' />
            {
                <Popup overlayStyle={{ zIndex: 100, background: 'rgba(227, 242, 253, .6)' }}
                    open={true}
                    closeOnDocumentClick={false}
                    closeOnEscape={false}
                    modal
                // //lockScroll
                >

                    <div className={"modal"}>

                        <div className={"close"} onClick={handleClose}></div>
                        <div className={"header"}>
                            <div style={{ display: 'flex' }}>
                                {flowResponse?.incident_info?.matching_rule_details?.matching_field_name === 'am_analysis.am_destination.Protocol' ? (
                                    <span>Auth Protocol Quality</span>
                                ) : data?.matching_rule_name === 'Authentication Quality' ? (
                                    <span>Auth Hash Quality</span>
                                ) : (
                                    <span
                                        className='issue-title-more-details'
                                        title={(data?.matching_rule_name === 'Suspected Attack on Expired AD Account') ? 'Suspected Attack on Expired AD Account/Password' :
                                            getIssueNameById(data?.matching_rule_name)
                                        }
                                    > {data?.matching_rule_name?.replaceAll(' ', '').toLowerCase() == 'shadowassets/directory' ?
                                        (data?.dest_is_directory ? 'Shadow Identity Systems' : 'Shadow Asset') :
                                        (data?.matching_rule_name === 'Suspected Attack on Expired AD Account') ? 'Suspected Attack on Expired AD Account/Password ' : getIssueNameById(data?.matching_rule_name)}</span>
                                )}<span> - Incident #{data?.issueId}</span>
                            </div>
                        </div>
                        <div className={"content pad_bottom_10"}>

                            {/* <div>
                            <label><strong>Issue: </strong> 
                                {flowResponse?.incident_info?.matching_rule_details?.matching_field_name === 'am_analysis.am_destination.Protocol' ? (
                                    <span>Auth Protocol Quality</span> 
                                ) : data?.matching_rule_name === 'Authentication Quality' ? (
                                    <span>Auth Hash Quality</span>
                                ) : (
                                    <span>{data?.matching_rule_name}</span>
                                )}
                            </label>
                            <label><strong>Matching Field:</strong> {flowResponse?.incident_info?.matching_rule_details?.matching_field_name}</label>
                            {/* <label><strong>Field Value:</strong> {flowResponse?.incident_info?.matching_rule_details?.matching_field_value}</label> }
                            <label><strong>Details:</strong></label>
                        </div> */}
                            <label style={{ height: 'auto' }}><strong>Incident Summary:</strong></label>
                            <label className='code-snippet' style={{ marginTop: 0, height: 'auto', marginBottom: window.matchMedia("(min-width: 2560px)").matches ? 13 : 13 }}>
                                {
                                    data?.incident_summary ?
                                        <div dangerouslySetInnerHTML={{ __html: data?.incident_summary.replaceAll('Suspected Attack on Expired AD Account', 'Suspected Attack on Expired AD Account/Password') || '' }} /> :
                                        <div>Summary is not available.</div>

                                }</label>
                            <label style={{ height: 'auto' }}><strong>Matching Playbook:</strong></label>
                            {data?.closure_reason !== 'Playbook deleted' && <label className='code-snippet link-text'
                                onClick={() => {
                                    setPlaybookId(data?.playbook_id);
                                    setOpenPlayBookModal(true);
                                }}
                                style={{ marginTop: 0, height: 'auto', marginBottom: window.matchMedia("(min-width: 2560px)").matches ? 13 : 13 }}>
                                {
                                    data?.playbook
                                }</label>
                            }
                            {data?.closure_reason === 'Playbook deleted' &&
                                <label className='code-snippet' style={{ marginTop: 0, height: 'auto', marginBottom: window.matchMedia("(min-width: 2560px)").matches ? 13 : 13 }}>
                                    {data?.playbook}
                                </label>
                            }
                            {data?.matching_rule_name !== RuleType.UNAUTHORIZED_ASSET_ACCESS ?
                                <><label style={{ height: 'auto' }}><strong>Matching Field:</strong></label>
                                    <label style={{ marginTop: 0, height: 'auto', marginBottom: window.matchMedia("(min-width: 2560px)").matches ? 13 : 13 }}>
                                        <code>{flowResponse?.incident_info?.matching_rule_details?.matching_field_name}</code></label></> : null}
                            <div
                                style={
                                    { display: 'flex', alignItems: 'center', width: '101%' }
                                }
                            >
                                <label>
                                    <strong>Raw Flow Data:</strong>
                                </label>
                                {showShareIcon ? (
                                    <div className='copy_btn' title={'Click here to copy raw flow'} style={{ display: 'block' }}
                                        // onClick={() => navigator.clipboard.writeText(JSON.stringify(flowResponse || JSON.parse(sessionStorage.getItem(`${data?.flowId}`) || ''), null, 6))}
                                        onClick={copyData}
                                    ></div>
                                )
                                    :
                                    (
                                        <CheckBoxIcon style={{ color: 'green', fontSize: '16px', borderRadius: '10%' }} />
                                    )}
                            </div>
                            {
                                flowResponse?.incident_info?.matching_rule_details ?
                                    <div ref={snippetRef} className="am_snippet" id="am_snippet_highlight">
                                        {loading ?
                                            <><div className='loader spinner'></div><div className='fetch-lat-data'>Fetching latest data...</div></> :
                                            flowResponse ? <pre style={{ "background": "#333", "color": "#ddd" }} >{JSON.stringify(flowResponse, null, 6)}</pre>
                                                : <span>{flowError}</span>
                                        }
                                    </div>
                                    : <><div className={loading ? 'loader spinner' : ''}></div><div className='fetch-lat-data'>{flowError}</div></>}
                            <div style={{ display: 'flex', width: '100%', alignItems: 'center', gap: 10 }}>
                                {
                                    data?.suspected_source_info &&
                                    ((data.matching_rule_name == RuleType.SUSPECTED_DIR_BOT.toString() &&
                                        (!dir_names.filter((i: string) => data?.misc?.directory_hostname.toLowerCase().includes(i)).length ||
                                            !dir_names.filter((i: string) => data?.misc?.directory_name.toLowerCase().includes(i)).length)) ||
                                        (SuspectedPbList.includes(data?.matching_rule_name) && data.matching_rule_name != RuleType.SUSPECTED_DIR_BOT.toString())
                                    ) &&
                                    <>
                                        <div className='shadowbox' style={{
                                            width: _4k * 215, height: _4k * 34, display: 'flex', alignItems: 'center',
                                            cursor: 'pointer'
                                        }} onClick={handleSuspectedClick}>
                                            <div className={'suspected_icon'} style={{
                                                width: _4k * 40, height: _4k * 25, backgroundSize: _4k * 17,
                                                margin: 0, padding: 0
                                            }} ></div>
                                            <label style={{
                                                fontSize: _4k * 12, margin: 0, height: 'auto', cursor: 'pointer'
                                            }}
                                            >Suspected Sources of Attack</label>
                                        </div>
                                    </>
                                }
                                <button style={{ float: 'right', marginLeft: 'auto' }} type="button" className="button_main width40per" onClick={handleRemediationClick}>Recommended Remediations</button></div>
                        </div>
                    </div>

                </Popup >}
            {suspectedPopup && <SuspectedList
                handleClose={() => setSuspectedPopUp(false)}
                resultArray={data?.suspected_source_info}
                source={data?.sourceName || ''}
                matchingRule={data?.matching_rule_name || ''}
            />
            }
        </>
    )
}

export default React.memo(MatchruleDetails);
