import { AutoCompleteInput } from "../../../../components/core/AutoCompleteInput/AutoCompleteInput";
import { IPatternConfigItem, IPatternFormOptions } from "./PatternFormGenerator";
import { usePatternFormUtil } from "./usePatternFormUtil";
import { fabClasses, Tooltip } from '@mui/material';
import { useEffect } from 'react';
import { AMSelect, IAMSelectOption } from "../../../../components/core/AMSelect/AMSelect";
import { AMMultiSel } from "../../../../components/core/AMMultiSel/AMMultiSel";
import { DefaultWeekSelectorState, WeelkSelector } from "../WeekSelector";
import React from "react";
import CheckboxAutocomplete from "../../../../components/core/AMAutoComplete/AMAutoComplete";

export const PatternFormInput = ({ config, watch, index, onChange, value, ref, setIsValid, formType, isValid, setValue }: any) => {
    const { validateControl, getPlaceHolderDetails, getConfigItemByName } = usePatternFormUtil(config, watch);
    const [sohwWeeklyConfig, setShowWeeklyConfig] = React.useState(false);
    const values = watch();

    const getControlType = (index: number) => {
        if (values?.searchFilter?.[index]) {
            const conf = getConfigItemByName(values?.searchFilter?.[index].searchKey) as IPatternConfigItem;
            const op = values?.searchFilter?.[index].operator;
            if (conf && conf?.id) {
                const c = conf.operators.find((v: IPatternFormOptions) => v.id === op);
                return c?.valueType || conf.type;
            }
        }
        return '';
    }

    const loadOptions = (index: number) => {
        if (values?.searchFilter?.[index]) {
            const conf = getConfigItemByName(values?.searchFilter?.[index].searchKey) as IPatternConfigItem;
            if (conf && conf?.id) {
                return conf.selectOptions?.map((value: IAMSelectOption) => {
                    if (typeof value == 'string') {
                        return { key: value, value: value ,  showSeparator: false }
                    } else {
                        return { ...value, key: value.optionValue, value: value.optionLabel,  showSeparator: false }
                    }
                }) || [];
            }
        }
        return [];
    }

    
    const returnId = (index: number) => {
        if (values?.searchFilter?.[index]) {
            const conf = getConfigItemByName(values?.searchFilter?.[index].searchKey) as IPatternConfigItem;
            return conf ? conf?.id : '';
        }
        return '';
            
    }

    const getItemListLabel = (option, index) => {
        const options = loadOptions(index);
        if (option) {
            if (option?.key) {
                return option.value;
            } else {
                const op = options.filter((i: IAMSelectOption) => i.key == option)[0];
                if (op) {
                    console.log(op)
                    return op.optionLabel;
                }
            }
        }
        return "";
    }

    const getItemLabel = (opt) => getItemListLabel(opt, index)

    const getMultipleValue = (options, value) => {


        if (!Array.isArray(value)) {
          return (value || '').split(',').filter((i) => i);
        } else {

        value = value.map((v) => {
            let updatedKey = v.key;
            let updatedValue = v.value;
    
            if (updatedKey === 'Service/Application') {
                updatedKey = 'Application';
                updatedValue = 'Application';
            } else if (updatedKey === 'Service/Service Account') {
                updatedKey = 'Service';
                updatedValue = 'Service';
            }
    
            return { key: updatedKey, value: updatedValue };
        });
    }
      
        // Check if all user options are selected
        const allUserOptions = options.filter(option => getItemLabel(option).toLowerCase().startsWith('user'));
        const selectedUserOptions = value.filter(option => getItemLabel(option).toLowerCase().startsWith('user'));
      
        if (allUserOptions.length - 1  === selectedUserOptions.length) {
          const userWildcard = options.find(option => option.key === 'Users');
          if (userWildcard && !value.includes(userWildcard)) {
            value = [...value, userWildcard];
          }
        }
      
        // Check if all service options are selected
        const allServiceOptions = options.filter(option => getItemLabel(option).toLowerCase().startsWith('service')) || options.filter(option => getItemLabel(option).toLowerCase().startsWith('device')) || options.filter(option => getItemLabel(option).toLowerCase().startsWith('application'));
        const selectedServiceOptions = value.filter(option => getItemLabel(option).toLowerCase().startsWith('service'));
      
        if (allServiceOptions.length  === selectedServiceOptions.length) {
          const serviceWildcard = options.find(option => option.key === 'Non Human Identities (NHI)');
          if (serviceWildcard && !value.includes(serviceWildcard)) {
            value = [...value, serviceWildcard];
          }
        }
      
        return value;
      };
      

    const getControlBasedOnType = () => {
        const type = getControlType(index);
        switch (type) {
            case 'array-autocomplete':
                return <CheckboxAutocomplete
                    label={getPlaceHolderDetails(index)}
                    name={`searchFilter${index}.value`}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>, config: Array<IAMSelectOption>): undefined => {
                        const t = e.target;
                        let val = config.filter((i) => i);
                        if (config?.length > 0) {
                            if (val && Array.isArray(val)) {
                                val = val.filter((i) => i);
                            }
                            const isValid = validateControl(val, index);
                            if (t.classList) {
                                if (!isValid) {
                                    t.classList.add('error-input');
                                    setIsValid(false);
                                } else {
                                    t.classList.remove('error-input');
                                    setIsValid(true);
                                }
                            }
                        }
                        onChange(val);
                    }}
                    multiple={true}
                    hideClearBtn={true}
                    options={loadOptions(index)}
                    value={Array.isArray(value) ? value : (value || '').split(',').filter((i) => i)}
                    classes={{ root: `pattern-form-control-input-text pattern-form-input-multi-select select-input` }}
                    formType={formType}
                    getItemLabel={(opt) => getItemListLabel(opt, index)}
                    disabled={['view'].includes(formType)}
                />
            case "multi-select":
                return <CheckboxAutocomplete
                    label={getPlaceHolderDetails(index)}
                    name={`searchFilter${index}.value`}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>, config: Array<IAMSelectOption>): undefined => {
                        const t = e.target;
                        let val = config.filter((i) => i);
                        if (config?.length > 0) {
                            if (val && Array.isArray(val)) {
                                val = val.filter((i) => i);
                            }
                            const isValid = validateControl(val, index);
                            if (t.classList) {
                                if (!isValid) {
                                    t.classList.add('error-input');
                                    setIsValid(false);
                                } else {
                                    t.classList.remove('error-input');
                                    setIsValid(true);
                                }
                            }
                        }
                        onChange(val);
                    }}
                    multiple={true}
                    hideClearBtn={true}
                    groupAble={returnId(index) === 's_type'}
                    options={loadOptions(index)    
                    }
                    value={getMultipleValue(loadOptions(index),value)}
                    classes={{ root: `pattern-form-control-input-text pattern-form-input-multi-select select-input` }}
                    formType={formType}
                    getItemLabel={(opt) => getItemListLabel(opt, index)}
                    showSeparator={true} 
                />
            case `list`:
                return <CheckboxAutocomplete
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>, config: Array<IAMSelectOption>): undefined => {
                        const sel = config[0];
                        onChange(sel.key);
                        setValue(`searchFilter[${index}].value`, sel.key);
                    }}
                    multiple={false}
                    hideClearBtn={true}
                    options={loadOptions(index)}
                    value={value}
                    classes={{ root: `pattern-form-control-input-text searchFilter${index}.value` }}
                    formType={formType}
                    getItemLabel={(opt) => getItemListLabel(opt, index)}
                />

            case `week-selector`:
                return (<span className="pattern-form-control-input-text" style={{ border: 'none' }}>
                    <label className="link-text" onClick={() => setShowWeeklyConfig(true)}
                        style={{ marginTop: '0.6em' }}
                    >Choose days of the week and times per day</label>
                    {sohwWeeklyConfig && <WeelkSelector defaultState={value || DefaultWeekSelectorState} handleClose={() => {
                        setShowWeeklyConfig(false)
                    }} handleSave={(e) => {
                        setValue(`searchFilter[${index}].value`, e);
                        setShowWeeklyConfig(false);
                        console.log(e)
                    }} />}
                </span>
                )
            default:
                return <input ref={ref} name={`searchFilter[${index}].value`}
                    id={`searchFilter${index}.value`}
                    value={value || []}
                    onChange={(e) => {
                        const t = e.target;
                        onChange(e);
                        const val = t.value;
                        const isValid = validateControl(val, index);
                        if (!isValid) {
                            t.classList.add('error-input');
                            setIsValid(false);
                        } else {
                            t.classList.remove('error-input');
                            setIsValid(true);
                        }
                    }}
                    autoComplete={"off"}
                    className={`pattern-form-control-input-text pattern-form-control-input-text-custom searchFilter${index}.value`}
                    placeholder={getPlaceHolderDetails(index)}
                    readOnly={['view', 'edit'].includes(formType)}
                // {...!['view', 'edit'].includes(formType) && { title: getPlaceHolderDetails(index) }}
                />
        }
    }
    const renderControl = () => {
        return getControlBasedOnType()
    }

    const InputTooltip = () => {        
        const c = getConfigItemByName(values.searchFilter[index].searchKey);
        if (isValid) {
            let v = [];
            if (c?.id == 'flow_time') {
                return `Details can't be viewed`
            }
            if (value && !Array.isArray(value) && typeof value === 'object') {
                return <>Details cannot be previewed</>;
            } else if (value && Array.isArray(value)) {
                v = value;
            } else {
                v = value.split(',');
            }
            if (v?.length === 1) {
                return <span>{v[0]}</span>
            }
            return <ul className="pattern-form-generator-ul">{v.map((i: string) => <li>{i}</li>)}</ul>
        } else {
            if (values?.searchFilter?.[index].value && c?.error) {
                return <span className="error-tooltip">{c.error}</span>
            } else {
                return getPlaceHolderDetails(index) || '';
            }
        }
    }

    return <>
        <Tooltip
            title={<InputTooltip />}
            className="pattern-form-input-tooltip"
            classes={{ tooltip: 'pattern-form-generator generic-tooltip-popper scrollbar-container' }}
            PopperProps={{
                className: 'pattern-form-generator popper-class', style: { zIndex: 30000, maxWidth: '28%' }
            }}
        >
            {
                renderControl()
            }
        </Tooltip>
    </>
}