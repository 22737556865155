import { RuleType } from "../../../pages/playbooks/constants/Constants";

export const ExcludeRiskRuleList = [
    RuleType.WEAK_PASSWORD, RuleType.COMPROMISED_PASSWORD, RuleType.COMPROMISED_USER,
    RuleType.UNKNOWN_ACCESS, RuleType.SHADOW_EXTERNAL_ACCESS, RuleType.DEVIATION_IN_DAILY_ASSET,
    RuleType.AUTH_PROTOCOL_QUALITY
]

export const flattenSelectedItems = (input) => {
    if (input) {
        const result: Array<any> = [];
        result.push(...input['id_access_infra'])
        result.push(...input['unauthorised_access'])
        result.push(...input['shadow_activity'])
        return result;
    }
    return []
};