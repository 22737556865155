import { Api } from "../../../../components/Axios";
import { useToasts } from "../../../../components/core";
import { IPlaybook } from "./PlaybookTypes";

export const PlaybookService = () => {
    const { addToast } = useToasts();

    const Playbook = {
      POST: (
            request: any,
            successCallback?: any,
            errrCallback?: any
        ) => {
            Api.post('/playbooks', request).then((res) => {
                addToast('Playbook added successfully', {
                    appearance: 'success',
                    autoDismiss: true
                });
                if (successCallback) {
                    successCallback(res.data);
                }
            }).catch((er) => {
                if ((er.response.status == 400 || er.response.status == 500) && errrCallback) {
                    errrCallback(er.response.data)
                }
            })
        },
        GET: (id:any, successCallback?: any, errrCallback?:any) => {
            Api.get(`/playbooks/${id}`).then((res) => {
                if (successCallback) {
                    successCallback(res.data)
                }
            }).catch((er) => {
                if ((er.response.status == 400 || er.response.status == 404 || er.response.status == 500) && errrCallback) {
                    errrCallback(er.response.data)
                }
            })
        },
        PUT: (request: any,
            id: string,
            successCallback?: any, errrCallback?: any) => {
            const headers = { 'Operation': 'PUT' }
            Api.post(`/playbooks/${id}`, request, { headers: headers }).then((res) => {
                addToast('Playbook updated successfully.', {
                    appearance: 'success',
                    autoDismiss: true
                });
                if (successCallback) {
                    successCallback(res.data);
                }
            }).catch((er) => {
                if ((er?.response?.status == 400 || er.response.status == 404 || er?.response?.status == 500) && errrCallback) {
                    errrCallback(er.response.data)
                }
            })
        },
        GET_UNIQUE: async (successCallback) => {
            await Api.get('issues/uniquefields').then((res) => {
                if (successCallback) {
                    res.data['d_type'] = ['Application',
						'Device',
						'Service',
						'Service/Computer Account',
						"Service/Key and Secret",
						'Service/Service Principal',
					    "Service/Token"];
                    res.data['s_type'] = ['Non Human Identities (NHI)',
						'Application',
						'Device',
						'Service',
						'Service/Computer Account',
						"Service/Key and Secret",
						'Service/Service Principal',
					    "Service/Token",
						'Users',
						'User',
						'User/Agent',
						'User/Background Browsing'];
                    successCallback(res.data)
                }
            })
        },
        ACTIVATE: (id: string, status: boolean, msg: string, payload: IPlaybook,
            successCallback?: any, errorCallback?: any) => {
            const headers = { 'Operation': 'PUT' };
            Api.post(`/playbooks/activate?playbook_id=${id}&activate=${status}`,
                { ...payload, activate: status, is_active: status }, { headers }).then((res) => {
                    addToast(msg, {
                        appearance: 'success',
                        autoDismiss: true
                    });
                    if (successCallback) {
                        successCallback();
                    }
                }).catch(() => {
                    if (errorCallback) {
                        errorCallback();
                    }
                })

        },
        GET_ALL: (total, successCallback?: any) => {
            Api.get("/playbooks?rpp=" + total).then((res) => {
                if (successCallback) {
                    successCallback(res.data)
                }
            })
        },
    }
    return { Playbook }
}