import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Menu } from '@mui/material';
import {
    useTimeBasedSearchDispatchContext,
    useTimeBasedSearchPropsContext,
    useTimeBasedSearchStateContext,
    useTimeBasedSearchStorage
} from '../useTimeBasedSearch';
import ACTION_TYPES from '../TimeBasedSearchActionTypes';
import TimeBasedSearchPopupLayout from './TimeBasedSearchPopupLayout';
import {
    AVAILABLE_PRESETS,
    PERIOD_OPTIONS,
    TIME_RANGE_OPERATORS
} from '../Constants';
import moment from 'moment';
import { CalendarIcon } from '@mui/x-date-pickers';
import { PROTOCOL_HEADERS } from '../../../pages/sensors/reference/QRadarCollector/Constants';
import CloseIcon from "@mui/icons-material/Close";

const TimeBasedSearchDockingComponent = () => {
    const store = useTimeBasedSearchStateContext();
    const dispatchAction = useTimeBasedSearchDispatchContext();
    const storage = useTimeBasedSearchStorage();
    const props = useTimeBasedSearchPropsContext();
    const anchorRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (!store.showPopup) return;
        document.addEventListener('scroll', handleClose);
        return () => document.removeEventListener('scroll', handleClose);
    }, [store.showPopup]);

    const handleClose = (event: Event) => {
        event.stopPropagation();
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }
        dispatchAction({ type: ACTION_TYPES.RESET_FILTERS, value: false });
    };

    const onSummaryClick = () => {
        dispatchAction({ type: ACTION_TYPES.SHOW_POPUP, value: true });
    };

    const getPresetSummary = (state) => {
        const data = state ? state : storage.fetchDataFromStore();

        if (!data) return <></>;
        if (data && data?.selectedPeriod === PERIOD_OPTIONS?.CUSTOM_RANGE?.id)
            return getCustomRangeSummary(data);

        if (data && !data?.selectedPreset) return <></>;

        return <></>;
    };

    const getCustomRangeSummary = (data) => {
        if (
            data.selectedTimeRange !== TIME_RANGE_OPERATORS.BETWEEN.id
        ) {
            const timeRangeKey = Object.keys(TIME_RANGE_OPERATORS).find(
                (item) =>
                    TIME_RANGE_OPERATORS[item].id === data.selectedTimeRange
            );
            if (!timeRangeKey || data.selectedTimeRange === TIME_RANGE_OPERATORS.EQUALS.id) return <></>;
            return (
                <span className='summary-recents'>
                    {TIME_RANGE_OPERATORS[timeRangeKey]['label'].replace(/,/g, '')}
                </span>
            );
        }

        return <></>;
    };

    const getPresetDateSummary = (data) => {
        const startDate = data.selectedPresetStartDate * 1000;
        const endDate = data.selectedPresetEndDate * 1000;
        switch (data.selectedPreset) {
            case AVAILABLE_PRESETS.LS_60_MINS.id:
                return AVAILABLE_PRESETS.LS_60_MINS.label;
                // return `${moment(startDate)
                //     .format('lll').replace(/,/g, '')} - ${moment(endDate).format('lll').replace(/,/g, '')}`;
            case AVAILABLE_PRESETS.LS_4_HRS.id:
                return AVAILABLE_PRESETS.LS_4_HRS.label;
                // return `${moment(startDate)
                //     .format('lll').replace(/,/g, '')} - ${moment(endDate).format('lll').replace(/,/g, '')}`;
            case AVAILABLE_PRESETS.LS_24_HRS.id:
                return AVAILABLE_PRESETS.LS_24_HRS.label;
                // return `${moment(startDate)
                //     .format('lll').replace(/,/g, '')} - ${moment(endDate).format('lll').replace(/,/g, '')}`;
            case AVAILABLE_PRESETS.LS_3_DAYS.id:
                return AVAILABLE_PRESETS.LS_3_DAYS.label;
                // return `${moment(startDate)
                //     .format('ll').replace(/,/g, '')} - ${moment(endDate).format('ll').replace(/,/g, '')}`;
            case AVAILABLE_PRESETS.LS_7_DAYS.id:
                return AVAILABLE_PRESETS.LS_7_DAYS.label;
                // return `${moment(startDate)
                //     .format('ll').replace(/,/g, '')} - ${moment(endDate).format('ll').replace(/,/g, '')}`;
            case AVAILABLE_PRESETS.LS_14_DAYS.id:
                return AVAILABLE_PRESETS.LS_14_DAYS.label;
                // return `${moment(startDate)
                //     .format('ll').replace(/,/g, '')} - ${moment(endDate).format('ll').replace(/,/g, '')}`;
            default:
                return `No filter applied`;
        }
    };

    const getDateSummary = (state) => {
        let res = `${moment()
            .subtract(60, 'days')
            .format('ll').replace(/,/g, '')} - ${moment().format('ll').replace(/,/g, '')}`;
        const data = state ? state : storage.fetchDataFromStore();
        if (data && data?.selectedPeriod === PERIOD_OPTIONS?.PRESETS?.id) {
            res = getPresetDateSummary(data);
        } else if (
            data &&
            data?.selectedPeriod === PERIOD_OPTIONS?.HISTOGRAM?.id
        ) {
            const startDate = data?.selectedStartDateHistogram > 9999999999 ? data?.selectedStartDateHistogram : data?.selectedStartDateHistogram * 1000;
            const endDate = data?.selectedEndDateHistogram > 9999999999 ? data?.selectedEndDateHistogram : data?.selectedEndDateHistogram * 1000;
            res = `${moment(startDate).format(
                'lll'
            ).replace(/,/g, '')} - ${moment(endDate).format('lll').replace(/,/g, '')}`;
        } else {
            if (
                data &&
                data?.selectedTimeRange === TIME_RANGE_OPERATORS.AFTER.id
            ) {
                res = moment(data?.selectedStartDate).format('lll').replace(/,/g, '');
            } else if (
                data?.selectedTimeRange === TIME_RANGE_OPERATORS.EQUALS.id
            ) {
                res = moment(data?.selectedStartDate).format('ll').replace(/,/g, '');
            } else if (
                data &&
                data?.selectedTimeRange === TIME_RANGE_OPERATORS.BETWEEN.id
            ) {
                res = `${moment(data?.selectedStartDate).format(
                    'lll'
                ).replace(/,/g, '')} - ${moment(data?.selectedEndDate).format('lll').replace(/,/g, '')}`;
            }
        }
        return res;
    };

    const onClear = (e) => {
        e.stopPropagation();
        dispatchAction({ type: ACTION_TYPES.CLEAR_FILTERS, value: store });
        props.onApply(null, true);
    };

    const getPageDockingJSX = () => (
        <>
            <div className='time-based-search-docking'>
                {/* <div className='left-arrow'></div> */}
                <div className='time-search-title'>Time Filter: </div>
                <div
                    onClick={onSummaryClick}
                    id='time-based-search-btn'
                    aria-controls={
                        store.showPopup ? 'time-based-search-popup' : undefined
                    }
                    aria-haspopup='true'
                    aria-expanded={store.showPopup ? 'true' : undefined}
                    ref={anchorRef}
                    className='time-based-search-summary'
                >
                    {store.isFilterApplied ? (
                        <>
                            <span>
                                {getPresetSummary()} {getDateSummary()}
                            </span>
                            <span className='time-based-search-date-icon-container'>
                                <CalendarIcon
                                    className='time-based-search-date-icon'
                                    style={{ fill: 'rgba(0,0,0, 0.6)' }}
                                />
                            </span>
                            <span
                                className='time-based-search-clear-x'
                                onClick={(e) => onClear(e)}
                            >
                                X
                            </span>                            
                        </>
                    ) : (
                        <>
                        <span>
                            No filter applied
                        </span>
                        <span className='time-based-search-date-icon-container'>
                            <CalendarIcon
                                className='time-based-search-date-icon'
                                style={{ fill: 'rgba(0,0,0, 0.6)' }}
                            />
                        </span>
                    </>
                    )}
                </div>

                {/* <div className='right-arrow'></div> */}
            </div>
            <Menu
                anchorEl={anchorRef.current}
                id='time-based-search-popup'
                aria-labelledby='time-based-search-btn'
                open={store.showPopup}
                onClose={handleClose}
                // transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                // anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                className='time-based-search-popup'
            >
                <MenuItem key={'select_period'} disableRipple={true}>
                    <TimeBasedSearchPopupLayout></TimeBasedSearchPopupLayout>
                </MenuItem>
            </Menu>
        </>
    );

    const getAdvancedSearchDockingJSX = () => {
        return (
            <>
                <div className='time-based-search-docking'>
                    {/* <div className='left-arrow'></div> */}
                    <div
                        onClick={onSummaryClick}
                        id='time-based-search-btn'
                        aria-controls={
                            store.showPopup ? 'time-based-search-popup' : undefined
                        }
                        aria-haspopup='true'
                        aria-expanded={store.showPopup ? 'true' : undefined}
                        ref={anchorRef}
                        className='time-based-search-summary'
                    >
                        {(store.selectedPeriod == PERIOD_OPTIONS.PRESETS.id && store.selectedPreset == "") ? (
                                <>
                                    <span>
                                        No filter applied
                                    </span>
                                    <span className='time-based-search-date-icon-container'>
                                        <CalendarIcon
                                            className='time-based-search-date-icon'
                                            style={{ fill: 'rgba(0,0,0, 0.6)' }}
                                        />
                                    </span>
                                </>
                            ) : (
                            <>
                                <span>
                                        {/* {getPresetSummary(store)} */}
                                        {getDateSummary(store)}
                                </span>
                                <span className='time-based-search-date-icon-container'>
                                    <CalendarIcon
                                        className='time-based-search-date-icon'
                                        style={{ fill: 'rgba(0,0,0, 0.6)' }}
                                    />
                                    </span>
                                    <CloseIcon
                                        style={{
                                        position: "absolute",
                                        right: 8,
                                        top: "50%",
                                        cursor: "pointer",
                                        transform: "translateY(-50%)",
                                        color: "#777",
                                        fontSize: "16px",
                                        }}
                                        onClick={(e) => onClear(e)}
                                    />
                                {/* <span
                                    className='time-based-search-clear-x'
                                    onClick={(e) => onClear(e)}
                                >
                                    X
                                </span> */}
                            </>
                        )}
                    </div>

                    {/* <div className='right-arrow'></div> */}
                </div>
                <Menu
                    anchorEl={anchorRef.current}
                    id='time-based-search-popup'
                    aria-labelledby='time-based-search-btn'
                    open={store.showPopup}
                    onClose={handleClose}
                    // transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                    // anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                    className='time-based-search-popup'
                >
                    <MenuItem key={'select_period'} disableRipple={true}>
                        <TimeBasedSearchPopupLayout></TimeBasedSearchPopupLayout>
                    </MenuItem>
                </Menu>
            </>
        );
    }

    return (
        <>
            {props.forAdvancedSearch ?
                getAdvancedSearchDockingJSX() : getPageDockingJSX()
            }
        </>
    )
};

export default TimeBasedSearchDockingComponent;
