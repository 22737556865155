import React, { useEffect, useRef, useState } from "react";
import "./CredentialRisk.scss";
import { useHistory } from "react-router-dom";

type Props = {
    credentials:
        | {
              [protocol_name: string]: {};
          }
        | undefined
        | null;
    widgetId: string | undefined | null;
};

const RiskColor = new Map([
    [1, "#cccccc"],
    [2, "#fcbf2e"],
    [3, "#fd7122"],
    [4, "#fa1262"],
]);

const RiskValue = new Map([
    [1, "low"],
    [2, "medium"],
    [3, "high"],
    [4, "critical"],
]);

function prepareChartData(credentials) {
    const list = [];
    Object.keys(credentials)?.map((key) => {
        //Logic changed..
        //const values = Object.values(credentials[key].criticality)
        //const max = Math.max(...values);
        //const risk = values.findLastIndex( el => el === max) + 1;
        let risk = 1;
        let riskwiseList= credentials[key]?.criticality || {1:0,2:0,3:0,4:0};
        for(let i=4; i>0; i--) {
            if(riskwiseList[i] > 0){
                risk = i;
                break;
            }
        }
        list.push({ label: key, value: credentials[key]?.id_count || 0, risk: RiskValue.get(risk), color: RiskColor.get(risk) });
    });
    return list;
}

const CredentialsRisk = ({ credentials, widgetId}: Props) => {
    const [credentialsRiskList, setCredentialsRiskList] = useState([]);
    const history = useHistory();
    useEffect(() => {
        if(credentials) {
            const dataObj = prepareChartData(credentials);
            setCredentialsRiskList(dataObj);
        }
    }, [credentials]);

    const clickHandler = (issueType: string, risk: string) => {
        history.push(`/posture/identities?hard_refresh=true&order_by=desc&sort_by=flow_count&page=1&q=rule_name%3A${issueType}`, { breadcrumbId: 'WeakPosture' });
    }
    return (
        <>
            <div key={widgetId} id={widgetId || "cred-risk"} className="credentials-risk-container">
                {credentialsRiskList.map((p) => {
                    const {label,value,risk,color} = p;
                    return (
                        <div onClick={()=>clickHandler(label, risk)} className="cred-risk-block" style={{ color: `${color}` }}>
                            <h1>{value}</h1>
                            <div className="type-label">{label}</div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default CredentialsRisk;
