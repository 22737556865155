import { Tooltip } from "@mui/material";
import moment from "moment";
import { useRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ArrayParam,
  NumberParam,
  StringParam,
  withDefault,
  withQueryParams,
} from "use-query-params";
import { Api } from "../../components/Axios";
import { useToasts } from "../../components/core";
import AMAdminTable, {
  ITableResult,
  ITableColumn,
  ITableAction,
  ITableReference,
} from "../../components/core/AMTable/AMAdminTable";
import { convertToCommaValue, formatDate, getTimeDiffString, trimAfterSecondWord } from "../../utils/util-methods";
import InfoIcon from "@mui/icons-material/Info";
import { useAuthDetails } from "../../components/Authorization";
import {
  AMAdminPopUp,
  AMAdminSearchTable,
  IAMAdminSearchTableRef,
} from "../ref/AMAdminCommonComponents";
import AMTabs from "../../components/core/AMTab/AMTab";
import FlowTrend from "../issue_prev/flow-trend/flow-trend";

interface CollectorCollection {
  name: string;
  data_src: string;
  flows: string;
  flows_count_custom?: number;
  type: string;
  sensor_name: string;
  last_flow_time: string;
  collector_sensor_id: string;
  collector_sensor_name: string;
  _id: string;
  collector_id: string;
}

interface IPopUp {
  type: string;
  data: CollectorCollection | any;
}

const is4K = window.matchMedia("(min-width: 2560px)").matches ? 1 : 1;


const CollectorActions: ITableAction<CollectorCollection | unknown>[] = [
  {
    actionId: "edit-collector-name",
    actionLabel: "Edit Name",
  },
  {
    actionId: "delete-collector",
    actionLabel: "Delete",
  },
];

const CollectorsList = ({ query, setQuery }: any) => {
  const history = useHistory();
  const childRef = useRef<ITableReference>();
  const [data, setData] = useState<
    ITableResult<CollectorCollection | any> | any
  >();
  const [showPopUp, setShowPopUp] = useState<IPopUp>({ type: "", data: null });
  const { addToast } = useToasts();
  const { authDetails } = useAuthDetails()!;
  const searchRef = useRef<IAMAdminSearchTableRef>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [openFlow, setOpenFlow] = useState<boolean>(false);
  const [flowsTrendResponseData, setFlowsTrendResponseData] = useState<any>(undefined);
  const [flowsChartDetails, setFlowsChartDetails] = useState<any>({ chartTitle: '' });
  const updatedExtremesRef = useRef({ min: null, max: null });
  const [zoomLevel, setZoomLevel] = useState('hourly');

  const handleClose = () => {
    setShowPopUp({ type: "", data: null });
  };

  const onFlowsClick = (data) => { 
    setOpenFlow(true);
    let { collector_id: entity, collector_sensor_id } = data;
    const currentTime = +new Date();
    const cachedFlowData: any = sessionStorage.getItem(
        `collector-status-flow-trend-${entity}`
    );

    const encodedEntity = encodeURIComponent(entity);

    const flowTrendURL = `/collectors/flowcounts?search_type=collector&sensor_id=${collector_sensor_id}&search_value=${encodedEntity}`;

    if (
        !cachedFlowData ||
        JSON.parse(cachedFlowData)?.expiry_time < currentTime
    ) {
        Api.get(flowTrendURL)
            .then((res: any) => {
                if (!res?.data?.length || res?.data?.length <= 0) {
                    setFlowsTrendResponseData({
                        flows: [],
                        expiry_time: currentTime + 600000
                    });

                    // Chart header and color
                    const chartTitle = getChartTitle(entity);
                    setFlowsChartDetails({
                        chartTitle
                    });
                    return;
                }

                let result = JSON.parse(JSON.stringify(res?.data)) || [];
                result.sort((a: any, b: any) =>
                    a?.time < b?.time ? -1 : 1
                );
                const cachedFlows = {
                    flows: result,
                    encodedEntity,
                    risk: null,
                    expiry_time: currentTime + 600000
                };
                setFlowsTrendResponseData(cachedFlows);

                // Chart header and color
                const startFromDate = Math.max(
                    moment(result?.[0]?.time).valueOf(),
                    moment().subtract(90, 'days').valueOf()
                );
                const chartTitle = getChartTitle(
                  entity,
                    startFromDate
                );
                setFlowsChartDetails({
                    chartTitle
                });

                sessionStorage.setItem(
                    `collector-status-flow-trend-${encodedEntity}`,
                    JSON.stringify(cachedFlows)
                );
            })
            .catch((er) => {
                console.log(er);
            });
    } else {
        const parsedData = JSON.parse(cachedFlowData);
        setFlowsTrendResponseData(parsedData);

        // Chart header and color
        const { flows } = parsedData;
        const startFromDate = Math.max(
            moment(flows?.[0]?.time).valueOf(),
            moment().subtract(90, 'days').valueOf()
        );
        const chartTitle = getChartTitle(entity, startFromDate);
        setFlowsChartDetails({
            chartTitle
        });
    }

  }

  useEffect(() => {
    if (!openFlow) {
        setFlowsTrendResponseData(undefined);
    }
  }, [openFlow]);

  const getChartTitle = (entity: string, startDate: any = null) => {
    if (startDate)
        return (
            <span
                title={`Hourly flows trend (${getTimeDiffString(
                    startDate
                )}) for Collector: ${entity}`}
            >{`Hourly flows trend (${getTimeDiffString(
                startDate
            )}) for Collector: ${trimAfterSecondWord(
              entity,
                35
            )}`}</span>
        );
    else
        return (
            <span
                title={`Hourly flows trend for Collector: ${entity}`}
            >{`Hourly flows trend for Collector: ${trimAfterSecondWord(
              entity,
                35
            )}`}</span>
        );
  };

  const CollectorTableColumns: ITableColumn<CollectorCollection>[] = [
    {
      headerText: "Collector Name",
      key: "name",
      classes: "align_left ellipsis",
      sortable: true,
      html: (data: CollectorCollection) => {
        return (
          <div style={{ display: "flex" }} className="ellipsis">
           <Tooltip
              classes={{ tooltip: "generic-tooltip-popper" }}
              title={data.name ? data.name : data.collector_id}>
            <div
              style={{
                maxWidth: `${is4K * 190}px`,
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {data.name ? data.name : data.collector_id}
            </div>
            </Tooltip>
            <Tooltip
              classes={{ tooltip: "generic-tooltip-popper" }}
              title={
                <div>
                  <div className="copy_field">
                    Original Collector Name: {data.collector_id}
                    <span
                      className="copy_icon"
                      title="Copy this text to Clipboard"
                      style={{ margin: 0, marginLeft: "0.1em" }}
                      onClick={() =>
                        navigator.clipboard.writeText(data.collector_id)
                      }
                    ></span>
                  </div>
                </div>
              }
            >
              <span style={{ marginLeft: "0.2em", marginTop: "0.1em" }}>
                <InfoIcon
                  style={{
                    fontSize: 14,
                    color: "#d4d8e1",
                    transform: "skewX(-10deg)",
                  }}
                />
              </span>
            </Tooltip>
          </div>
        );
      },
      styles: {
        width: is4K * 200,
      },
    },
    {
      headerText: "Sensor Name",
      key: "collector_sensor_name",
      sortable: true,
      classes: "align_left vert_middle ",
      html: (data: CollectorCollection) => {
        return (
          <div style={{ display: "flex" }} className="ellipsis">
            <Tooltip  classes={{ tooltip: "generic-tooltip-popper" }} title={data.collector_sensor_name}>
            <div  style={{
                maxWidth: `${is4K * 150}px`,
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {data.collector_sensor_name}
            </div>
            </Tooltip>
          </div>
        );
      },
      styles: {
        width: is4K * 160,
      },
    },
    {
      headerText: "Data Source",
      key: "data_src",
      classes: "align_left vert_middle",
      sortable: true,
    },
    {
      headerText: "Status",
      key: "last_flow_time",
      sortable: false,
      classes: "align_center vert_middle",
      html: (data: CollectorCollection) => {
        const hoursSinceLastFlow = moment.duration(moment().diff(moment(data.last_flow_time))).asHours();
    
        if (hoursSinceLastFlow < 1) {
          return (
            <span style={{ position: "relative" ,float:'none' }} className="heartbeat_green_dot">
              &#9679;
            </span>
          );
        } else if (hoursSinceLastFlow >= 1 && hoursSinceLastFlow < 2) {
          return (
            <span style={{ position: "relative" ,float:'none' }} className="heartbeat_orange_dot">
              &#9679;
            </span>
          );
        } else {
          return (
            <span style={{ position: "relative" ,float:'none' }} className="heartbeat_red_dot">
              &#9679;
            </span>
          );
        }
      },
      styles: {
        width: is4K * 50,
      },
    }
  ,  
    {
      headerText: "Flows",
      key: "flows",
      sortable: true,
      classes: "vert_middle",
      html: (data: CollectorCollection) => {
        /*
        *   Commenting below condition until confirmation from business for this change.
        */
        // if (data.flows_count_custom) {
        //   return data.flows_count_custom > 0 ? (
        //     <a
        //     className="shadowbox"
        //     onClick={() => {
        //       onFlowsClick(data);
        //     }}
        //   >
        //     {convertToCommaValue(data.flows_count_custom)}
        //   </a>) : (<span>{convertToCommaValue(data.flows_count_custom)}</span>)
        // } else { 
          return data.flows > 0 ? (
            <a
            className="shadowbox"
            onClick={() => {
              onFlowsClick(data);
            }}
          >
            {convertToCommaValue(data.flows)}
          </a>) : (<span>{convertToCommaValue(data.flows)}</span>)
        // }
      },
    },
    {
      headerText: "Latest Flow",
      key: "last_flow_time",
      sortable: true,
      classes: "align_left vert_middle",
      html: (data: CollectorCollection) => {
        return (
          <div className="heartbeat_flow" style={{ marginTop: "-2px" }}>
            {data.last_flow_time &&
            moment(data.last_flow_time).isAfter("2021-12-31T01:14:00Z") ? (
              <div className="heartbeat_flow">
                {formatDate(data.last_flow_time)}
  
             
              </div>
            ) : (
              <div className="heartbeat_flow" title="Last flow">
                No Last Flow
              </div>
            )}
          </div>
        );
      },
      styles: {
        width: is4K * 110,
      },
    },
   
    {
      headerText: "Type",
      key: "type",
      classes: "align_left vert_middle",
      sortable: true,
    },
  ];
  

  const proceedDelete = () => {
    const headers = { Operation: "DELETE" };
    Api.post(
      "/sensors/collectors",
      {
        sensor_id: showPopUp.data.collector_sensor_id,
        collector_id: showPopUp.data.collector_id,
      },
      { headers }
    )
      .then((res: { data: any }) => {
        handleClose();
        addToast("Collecter has been deleted successfully.", {
          appearance: "success",
          autoDismiss: true,
        });
        setQuery({ ...query, hard_refresh: "true" });
        fetchCollectorList();
      })
      .catch((error: any) => {
        handleError(error);
      });
  };

  const proceedUpdate = (evt?: any) => {
    const headers = { Operation: "PUT" };
    if (evt.key && evt.key != "Enter") {
      return;
    }

    if (evt.key && evt.key == "Enter") {
      evt.preventDefault();
    }

    Api.post(
      "/sensors/collectors",
      {
        sensor_id: showPopUp.data.collector_sensor_id,
        collector_id: showPopUp.data.collector_id,
        collector_name: inputRef.current?.value,
      },
      { headers }
    )
      .then((res: { data: any }) => {
        handleClose();
        addToast("Collecter has been updated successfully.", {
          appearance: "success",
          autoDismiss: true,
        });
        setQuery({ ...query, hard_refresh: "true" });
        fetchCollectorList();
      })
      .catch((error: any) => {
        handleError(error);
      });
  };

  const deletePopUpButtons = {
    primary: {
      label: "Yes",
      action: proceedDelete,
    },
    secondary: {
      label: "No",
      action: handleClose,
    },
  };

  const updatePopUpButtons = {
    primary: {
      label: "Save",
      action: proceedUpdate,
    },
    secondary: {
      label: "Cancel",
      action: handleClose,
    },
  };

  const onSubmit = (data: any) => {
    if (childRef?.current?.onSearch) {
      sessionStorage.removeItem("sensor");
      childRef?.current?.onSearch(data);
    }
  };

  const fetchCollectorList = () => {
    const sensor = sessionStorage.getItem("sensor");
    if (sensor) {
      const sensorOb = JSON.parse(sensor);
      if(sensorOb.typedata === 'manageSensor'){
        query.data_src = encodeURIComponent(sensorOb.data_src);
      }
      Api.get(`/sensors/collectors/${sensorOb?.id}`, { params: query })
        .then((resp: any) => {
          const data = resp.data as ITableResult<CollectorCollection>;
          setData(data);
          if (searchRef?.current?.setSearchValue) {
            searchRef?.current?.setSearchValue(sensorOb?.name);
          }
          if (data?.result?.length == 0) {
            childRef?.current?.onSetTableError(
              `No records found for sensor ${sensorOb?.name}`
            );
          }
        })
        .catch(() =>
          childRef?.current?.onSetTableError(
            "Error occurred while fetching table data."
          )
        );
    } else {
      if (searchRef?.current?.setSearchValue) {
        searchRef?.current?.setSearchValue(query.q);
      }
      Api.get("/sensors/collectors", { params: query })
        .then((resp: any) => {
          const data = resp.data as ITableResult<CollectorCollection>;
          setData(data);
          if (data?.result?.length == 0) {
            childRef?.current?.onSetTableError(`No records found`);
          }
        })
        .catch(() =>
          childRef?.current?.onSetTableError(
            "Error occurred while fetching table data."
          )
        );
    }
  };

  CollectorActions[1].actionCallback = function (
    i: CollectorCollection | unknown,
    e: any
  ) {
    setShowPopUp({ data: i, type: "DeleteCollector" });
  };

  CollectorActions[0].actionCallback = function (
    i: CollectorCollection | any,
    e: any
  ) {
    setShowPopUp({ data: i, type: "UpdateCollector" });
  };

  useEffect(() => {
    fetchCollectorList();
  }, [query]);

  const handleError = (error: any) => {
    handleClose();
    if (error?.response?.status === 500) {
      addToast(error.response.data, {
        appearance: "error",
        autoDismiss: true,
      });
    } else if (error?.response?.status === 419) {
      addToast(
        "We encounted validation problem, please correct and try again.",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } else if (error?.response?.status === 404) {
      addToast(
        "We are not able to find associated email, please check and try again.",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } else if (error?.response?.status == 409 || error?.response?.data) {
      addToast(error.response.data, {
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      addToast("Sorry, something went wrong there, try again.", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const SearchTable = () => {
    return (
      <AMAdminSearchTable
        searchTitle=""
        searchPlaceHolder="Name, Sensor Name, Data Source and Type"
        query={query.q}
        ref={searchRef}
        onSearchValue={onSubmit}
      />
    );
  };

  const DeleteCollectorPopUp = ({
    collectorData,
    popUpButtons,
  }: any | null) => {
    return (
      <AMAdminPopUp
        popUpTitle={"Delete Collector"}
        popUpButtons={popUpButtons}
        handleClose={handleClose}
      >
        <span className="font14">
          Are you sure you want to delete the collector{" "}
          <strong>{collectorData?.name}</strong> ?{" "}
        </span>
      </AMAdminPopUp>
    );
  };

  const UpdateCollectorPopUp = ({
    collectorData,
    popUpButtons,
  }: any | null) => {
    return (
      <AMAdminPopUp
        popUpTitle={"Edit Collector Name"}
        popUpButtons={popUpButtons}
        handleClose={handleClose}
      >
        <form>
          <label className="font14" htmlFor="collector_name">
            Collector Name:{" "}
          </label>
          <input
            id="collector_name"
            type="text"
            defaultValue={collectorData.name}
            onKeyPress={(e) => proceedUpdate(e)}
            ref={inputRef}
          />
        </form>
      </AMAdminPopUp>
    );
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log("Tab changed to:", newValue);
    // Do whatever you want with the newValue here
  };

  return (
    <>
      <SearchTable />
      <div className="flow_table_container sensor-collector-container">
        {/* <div className="search_section">
                <div data-id="tab1" style={{ marginRight: 5 }} className={"tab"}
                    onClick={() => {
                        sessionStorage.removeItem('sensor');
                        history.push(`/sensors?order_by=asc&sort_by=name`)
                    }}>SENSORS</div>
                <div data-id="tab2" style={{ marginRight: 5 }} className={"tab selected_tab"}
                >COLLECTORS</div>
            </div> */}
        <AMAdminTable
          columns={CollectorTableColumns}
          data={data}
          ref={childRef}
          {...(CollectorActions?.length > 0 &&
          authDetails.permissions.Admin.sensors !== "readonly"
            ? { actions: CollectorActions }
            : null)}
          query={query}
          setQuery={setQuery}
          refresh={fetchCollectorList}
        />
      </div>
      {showPopUp.type == "DeleteCollector" ? (
        <DeleteCollectorPopUp
          collectorData={showPopUp.data}
          popUpButtons={deletePopUpButtons}
        />
      ) : null}
      {showPopUp.type == "UpdateCollector" ? (
        <UpdateCollectorPopUp
          collectorData={showPopUp.data}
          popUpButtons={updatePopUpButtons}
        />
      ) : null}
      {openFlow ? <FlowTrend data={flowsTrendResponseData} chartDetails={flowsChartDetails} closeFlowPopup={() => { setOpenFlow(false); setFlowsTrendResponseData(null); setFlowsChartDetails(null) }} showZoom={false} updatedExtremes={updatedExtremesRef}  zoomLevel={zoomLevel}/> : ''
          }
    </>
  );
};

export default withQueryParams(
  {
    q: StringParam,
    page: StringParam,
    rpp: NumberParam,
    sort_by: StringParam,
    order_by: StringParam,
    filters: withDefault(ArrayParam, []),
    hard_refresh: StringParam,
    sensor_id: StringParam,
  },
  CollectorsList
);
