import React, { ReactNode, useEffect } from "react"
import { useForm } from "react-hook-form"
import SlidingPanel from "react-sliding-side-panel"
import Popup from "reactjs-popup"

type IAMAdminRightPanel = {
    openPanel: boolean,
    panelTitle: string,
    handleOnClosed(): any,
    children: any
}

export const AMAdminRightPanel = ({ openPanel, panelTitle, handleOnClosed, children }: IAMAdminRightPanel) => {
    return (
        <SlidingPanel
            type={'right'}
            isOpen={openPanel}
            size={30}
            panelClassName="sidepane fix_width"
            onClosing={handleOnClosed}
        >
            <div className="pane_container">
                <div className="pane_heading">{panelTitle}</div>
                <div className="pane_close" onClick={handleOnClosed}></div>
                <div className="hr_ruler"></div>
                <div className="panel_body">
                    {children}
                </div>
            </div>
        </SlidingPanel>
    )
}

export type IAMAdminPopUpActions = {
    primary: {
        label: string,
        action(): any,
        className?: string
    },
    secondary: {
        label: string,
        action(): any,
        className?: string
    }
}

type IAdminPopUp = {
    popUpTitle: string,
    popUpButtons: IAMAdminPopUpActions,
    handleClose(): any,
    children: ReactNode,
    rootClass?: string,
    subTitle?: React.JSX.Element
}

export const AMAdminPopUp = ({ popUpTitle, popUpButtons, handleClose, children, rootClass, subTitle }: IAdminPopUp) => {
    return <Popup overlayStyle={{ zIndex: 15001, background: 'rgba(227, 242, 253, .6)' }}
        open={true}
        closeOnDocumentClick={false}
        modal={true}
        closeOnEscape={false}
        className={rootClass}
    >
        <div className={"modal"}>
            <div className={"close"} onClick={handleClose}></div>
            <div className={"header"}>
                <span className="">{popUpTitle}</span>
                {subTitle ? subTitle : <></>}
            </div>
            <div className={"content"}>
                <div className="">
                    {children}
                    <div className="clrBoth"></div>
                </div>
            </div>
            <div className="popup_footer fl">
                {popUpButtons?.secondary && popUpButtons?.secondary?.action && <div className={`policy_defualt_button ${popUpButtons?.secondary.className}`} onClick={popUpButtons?.secondary?.action}>{popUpButtons?.secondary?.label}</div>}
                {popUpButtons?.primary && popUpButtons?.primary?.action && <div className={`policy_save_button ${popUpButtons?.primary.className}`} onClick={popUpButtons?.primary?.action}>{popUpButtons?.primary?.label}</div>}
            </div>
        </div>
    </Popup>
}


type IAdminTableSearch = {
    query?: string;
}

export type IAMAdminSearchTableRef = {
    setSearchValue(q: string): any
}

type IAMAdminSearchTableProps = {
    query: string,
    searchTitle: string,
    searchPlaceHolder: string,
    onSearchValue(data: any): any,
    action?: {
        label: string,
        onClickHandler(e: any): any
    },
    searchSubHeader?: React.JSX.Element,
    classes?: {
        root?: string
    }
}

export const AMAdminSearchTable = React.forwardRef(({ query, searchTitle, searchPlaceHolder, onSearchValue, action, searchSubHeader, classes }: IAMAdminSearchTableProps, ref: any) => {
    React.useImperativeHandle(ref, () => ({
        setSearchValue(data: string) {
            return onSetSearchValue(data);
        }
    } as IAMAdminSearchTableRef));

    const { register, handleSubmit, setValue, getValues } = useForm<IAdminTableSearch>();

    const onSubmit = (data: any) => {
        onSearchValue(data);
    }

    const onSetSearchValue = (q: string) => {
        setValue('query', q);
    }

    return <div className={"page_title_area " + (searchSubHeader ? ' auto-height ' : '') + classes?.root}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className='font18'>{searchTitle}</div>
                <div className="search_container issue_page scrollbar-container marginleft20 admin-page-search-container">
                    <div className="search_white_icon flex_none" style={{ cursor: 'pointer' }}
                        onClick={handleSubmit(onSubmit)}
                    >&nbsp;</div>&nbsp;
                    <input
                        className="search_input flex_none admin-page"
                        placeholder={searchPlaceHolder}
                        ref={register}
                        name='query'
                        defaultValue={getValues('query') || query || ''}
                    />
                </div>
                <div className={"launch_button " + (action ? " admin_page_launch_btn" : ' hide-launch-button')}>
                    {
                        action ? <button type="button" className="button_main" onClick={action.onClickHandler}>{action.label}</button> :
                            <div className="launch_button button_main" style={{ background: 'transparent' }}></div>
                    }
                </div>
            </div>
            {searchSubHeader ? <>{searchSubHeader}</> : <></>}
        </form>
    </div>
})