import { FormControlLabel, Radio, RadioGroup, RadioGroupProps } from "@mui/material";
import React, { useEffect, useState } from "react";

export interface IAMRadioGroupOption {
    optionValue: string,
    optionLabel: string,
    optionClass?: string
    optionClassClbk?(item: IAMRadioGroupOption): any,
    labelOptions?: {
        renderLabel(option: IAMRadioGroupOption): void
    }
}

interface IAMRadioGroupProps extends RadioGroupProps {
    defaultValue: string,
    onRadioGroupChange(event: React.ChangeEvent<HTMLInputElement>, value: string): any,
    className?: string,
    radioOptions: Array<IAMRadioGroupOption>
}

export const AMRadioGroup = ({ defaultValue, onRadioGroupChange, className, radioOptions }: IAMRadioGroupProps) => {
    const [groupValue, setGroupValue] = useState(defaultValue);

    useEffect(() => {
        if (defaultValue != groupValue) {
            setGroupValue(defaultValue)
        }

    }, [defaultValue])

    return <RadioGroup
        aria-labelledby="mark-known-radio-group"
        name="am-radio-buttons-group"
        onChange={onRadioGroupChange}
        value={groupValue}
        className={'mark-known-popup ' + className}
    >
        {
            radioOptions && radioOptions.map((item: IAMRadioGroupOption) => {
                return <div style={{ display: 'flex' }}><FormControlLabel
                    className={item.optionValue + ' ' + item.optionClass + ' ' + (item?.optionClassClbk ? item?.optionClassClbk(item) : '')}
                    value={item.optionValue} control={<input type='radio' checked={groupValue == item?.optionValue}
                        onChange={onRadioGroupChange}
                        value={item?.optionValue}
                    />}
                    label={item?.labelOptions ? item?.labelOptions?.renderLabel(item) : item.optionLabel} /></div>
            })
        }
    </RadioGroup>
}