import { useCallback, useEffect, useRef, useState } from "react";
import SubHeader from "../components/Layout/SubHeader";
import { ReportProvider } from "../components/Report";
import TopIssues from "../components/Widgets/TopIssues";
import "./Dashboard.css";
import "./DashboardNew.scss";
import DonutChart from "../components/Widgets/shadow-idn-chart/donut-chart";
import IssuesTrendChart from "../components/Widgets/issue-trend-chart/issue-trend-chart";
import { DashboardResponse, DashboardWidgets } from "../types/response-types";
import { Api } from "../components/Axios";
import { dashboardTemplate } from "../constants/DashboardTemplate";
import RiskyProtocol from "../components/Widgets/RiskyProtocol/RiskyProtocol";
import CredentialsRisk from "../components/Widgets/CredentialRisk/CredentialRisk";
import Skeleton from "../components/skeletons/skeleton";
import { keysMapping } from "../constants/widgetDataKeyMappings";
import Past24HrsIncidentFlows from "../components/Widgets/Past24HrsIncidentFlows/Past24HrsIncidentFlows";
import RiskTrendIncidentWidget from "../components/Widgets/RiskTrendIncidentWidget/RiskTrendIncidentWidget";
import UnknownAccess from "../components/Widgets/UnknownAccess/UnknownAccess";
import RecentAccessNew from "../components/Widgets/RecentAccessNew";
import { randomData } from "../components/Widgets/RiskyProtocol/dummyData";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Menu,
  ListItemIcon,
  Checkbox,
  ListItemText,
  List,
  ListItem,
  Collapse,
  Typography,
  Box,
} from "@mui/material";
import React from "react";
import { Block, ExpandLess, ExpandMore } from "@mui/icons-material";
import GroupedMultiSelect from "../components/core/AMMultiselectGroupable/AMMultiSelect";
import AMMultiSelectGroup from "../components/core/AMMultiselectGroupable/AMMultiSelect";
import { deepCompare } from "../utils/util-methods";
import { textTransform } from "@mui/system";
import { Tooltip } from "@material-ui/core";
import Draggable from "react-draggable";
import { getIssueNameById } from "./playbooks/helpers/playbook-helper";
import { RuleType } from "./playbooks/constants/Constants";

let lastIssueId = 0;
let POLLING_SYNC_FLAG = true;
let prevAccessFlowsCount = 0;
const Dashboard = () => {
  const [responseData, setResponseData] = useState<
    DashboardResponse | undefined
  >();
  const [toggleDropdown, setToggleDropdown] = useState<{
    [widgetId: string]: boolean;
  }>({
    id_access_infra: false,
    shadow_activity: false,
    unauthorised_access: false,
  });
  const [playBookData, setPlayBookData] = useState<any>({});

  const [viewType, setViewType] = useState<{ [widgetId: string]: string }>({
    id_access_infra: "issueTypeView",
    shadow_activity: "issueTypeView",
    unauthorised_access: "issueTypeView",
  });
  const [dashboardWidgetValue, setDashboardWidgetValue] =
    useState<DashboardWidgets>();
  const [mfa_total, setMFATotal] = useState<number>(0);
  const [password_total, setPasswordTotal] = useState<number>(0);
  const [compromised_total, setCompromisedTotal] = useState<number>(0);
  const [shadow_dummy_total, setShadowDummyTotal] = useState<boolean>(true);
  const [searchKeyword, setSearchKeyword] = useState<any>("");
  const [clearKeyword, setClearKeyword] = useState<any>(false);
  const currentSearchKeywordRef = useRef(searchKeyword);
  const isWidgetDisabled =
    (dashboardWidgetValue?.mfa ? 1 : 0) +
    (dashboardWidgetValue?.password ? 1 : 0) +
    (dashboardWidgetValue?.protocols ? 1 : 0) +
    (dashboardWidgetValue?.compromised ? 1 : 0) +
    (dashboardWidgetValue?.hashes ? 1 : 0);

  const [odd, setOdd] = useState(0);
  const [openModal, setOpenModal] = useState<string | null>(null);

  const isObjectNotEmpty = (obj: any) => {
    if (obj) {
      return Object.keys(obj).length > 0;
    }
  };

  useEffect(() => {
    // setInterval(() => {
    //     setOdd((prev) => {
    //         const newOdd = prev + 1;
    //         console.log(newOdd);
    //         return newOdd;
    //     });
    // }, 2000);

    fetchData();
    fetchPlayBookData();


    widgetData();
    const pollApi = setInterval(() => {
      fetchData();
    }, 10000);
    return () => {
      clearInterval(pollApi);
      lastIssueId = 0;
    };
  }, []);




  const handleDropdownChange = (event: any) => {
    // setSelectedOptions((prevSelectedOptions) => ({
    //   ...prevSelectedOptions,
    //   [widgetId]: event.target.value,
    // }));
  };

  const handleDropdownToggle = (widgetId: string) => {
    setToggleDropdown((prevStates) => ({
      ...prevStates,
      [widgetId]: !prevStates[widgetId],
    }));
  };

  const fetchPlayBookData = async () => {
    await Api.get("/pbviewplaybooks?type=dashboard", {
      timeout: 30000,
    })
      .then((res) => {
        //res.data = responseAdapter(res.data);
        setPlayBookData(res.data);
      })
      .catch((err) => {
        POLLING_SYNC_FLAG = true;
        if (err.response?.status === 401) {
          window.location.href = "/login";
        }
      });
  };




  type InputData = {
    id_access_infra: { is_playbook_view: boolean; playbook_view: Record<any, any> };
    shadow_activity: { is_playbook_view: boolean; playbook_view: Record<any, any> };
    unauthorised_access: { is_playbook_view: boolean; playbook_view: Record<any, any> };
  };

  type OutputData = {
    id_access_infra: string[];
    shadow_activity: string[];
    unauthorised_access: string[];
  };

  const setDefaultArray = (inputArray: any) => {
    return inputArray.map((item: any) => ({
      _id: item.name,
      is_issue_type: true
    }));

  }


  const fetchUserSelection = async () => {

    await Api.get("/dashboard/playbookview", {
      timeout: 30000,
    })
      .then((res) => {

        const data: any = res.data.result;
        const { _id, ...restData } = data || {};

        if (!restData.id_access_infra_default_view) {
          setSelectedItems(prevSelectedItems => ({
            ...prevSelectedItems,
            id_access_infra: restData['id_access_infra'],
            id_access_infra_default_view: false
          }));
        }
        else {
          setSelectedItems(prevSelectedItems => ({
            ...prevSelectedItems,
            id_access_infra: setDefaultArray(groups['id_access_infra']),
            id_access_infra_default_view: true
          }));

        }

        if (!restData.unauthorised_access_default_view) {
          setSelectedItems(prevSelectedItems => ({
            ...prevSelectedItems,
            unauthorised_access: restData['unauthorised_access'],
            unauthorised_access_default_view: false
          }));
        } else {
          setSelectedItems(prevSelectedItems => ({
            ...prevSelectedItems,
            unauthorised_access: setDefaultArray(groups['unauthorised_access']),
            unauthorised_access_default_view: true
          }));

        }

        if (!restData.shadow_activity_default_view) {
          setSelectedItems(prevSelectedItems => ({
            ...prevSelectedItems,
            shadow_activity: restData['shadow_activity'],
            shadow_activity_default_view: false
          }));
        } else {
          setSelectedItems(prevSelectedItems => ({
            ...prevSelectedItems,
            shadow_activity: setDefaultArray(groups['shadow_activity']),
            shadow_activity_default_view: true
          }));

        }
        // setSelectedItems(restData);

      })
      .catch((err) => {
        POLLING_SYNC_FLAG = true;
        if (err.response?.status === 401) {
          window.location.href = "/login";
        }
      });

  }

  interface SelectedItem {
    _id: string;
    is_issue_type: boolean
  }

  useEffect(() => {
    if (isObjectNotEmpty(playBookData)) setGroupData();
  }, [playBookData]);

  const [groups, setGroup] = useState<{ [widgetId: string]: any[] }>({
    id_access_infra: [],
    shadow_activity: [],
    unauthorised_access: [],
  });

  useEffect(() => {
    if (groups['id_access_infra'].length > 0 || groups['shadow_activity'].length > 0 || groups['unauthorised_access'].length > 0)
      fetchUserSelection();
  }, [groups])

  const [doneClick, setDoneClick] = useState<boolean>(false);

  const [selectedItems, setSelectedItems] = useState<{
    [widgetId: string]: any;
  }>({
    shadow_activity: [],
    id_access_infra: [],
    unauthorised_access: [],
    id_access_infra_default_view: true,
    shadow_activity_default_view: true,
    unauthorised_access_default_view: true
  });

  const handleSelect = (selectedItems: { [widgetId: string]: SelectedItem[] }) => {
    setSelectedItems(selectedItems);
    setDoneClick(true);
  };

  const handleClear = (selectedItems: { [widgetId: string]: string[] }) => {
    setOpenModal('');
    setControlledPosition({ x: 0, y: 0 });
  };

  const getPlayBookSelectionData = () => {
    return selectedItems;
  };

  useEffect(() => {
    if (!deepCompare(selectedItems, {
      shadow_activity: [{ "is_issue_type": true, "id": "Exposed Assets" }, { "is_issue_type": true, "id": "Shadow Access" }, { "is_issue_type": true, "id": "Shadow Assets" }, { "is_issue_type": true, "id": "Shadow Directory" }, { "is_issue_type": true, "id": "Suspicious Inbound Access" }, { "is_issue_type": true, "id": "Suspicious Outbound Access" }, { "is_issue_type": true, "id": "Unknown SaaS Access" }],
      id_access_infra: [{ "is_issue_type": true, "id": "Auth Hash Security" }, { "is_issue_type": true, "id": "Lack of MFA" },
      { "is_issue_type": true, "id": RuleType.ASSETS_WITHOUT_MFA },
      { "is_issue_type": true, "id": "Suspected Directory/IdP Bot Attack" }, { "is_issue_type": true, "id": "Suspected Directory/IdP Identity Brute-force Attack" }, { "is_issue_type": true, "id": "Suspected AD NTLM Relay Attack" }, { "is_issue_type": true, "id": "Suspected Attack on Locked AD Account" }, { "is_issue_type": true, "id": "Suspected Directory/IdP Password Spray Attack" }, { "is_issue_type": true, "id": "Suspected Attack on Disabled AD Account" }, { "is_issue_type": true, "id": "Suspected Attack on Expired AD Account" }, { "is_issue_type": true, "id": "Repeated AD Login Attempts from Invalid Device" }, { "is_issue_type": true, "id": "Repeated AD Login Attempts at Invalid Time" }, { "is_issue_type": true, "id": "Enumeration of AD Users" }, { "is_issue_type": true, "id": "Enumeration of AD Admins" }, { "is_issue_type": true, "id": "Auth Hash Quality" }],
      unauthorised_access: [{ "is_issue_type": true, "id": "Access to Unauthorized Countries" }, { "is_issue_type": true, "id": "Access from Unauthorized Countries" }],
      id_access_infra_default_view: true,
      shadow_activity_default_view: true,
      unauthorised_access_default_view: true
    })) {
      if (doneClick) {
        updateUserSelection(getPlayBookSelectionData());
      }
    }
  }, [selectedItems, doneClick]);

  const handleDoneClick = () => {
    setOpenModal("");
    setControlledPosition({ x: 0, y: 0 });
  };

  const handleViewChange = (widgetId: string, name: string, e: any) => {
    handleDropdownToggle(widgetId);
    setViewType((prevStates) => ({
      ...prevStates,
      [widgetId]: name,
    }));
  };

  const handleOpenModal = (widgetId: string, e: any) => {
    e.stopPropagation();
    setOpenModal(widgetId);
    // Set initial values for the modal content based on the widget type
    // setModalContent(getModalContentByWidgetType(widgetId));
  };

  const renderGroupLabel = (groupId) => {
    return getIssueNameById(groupId)
  }

  const widgetData = () => {
    Api.get("/dashboard/widgets")
      .then((res: { data: any }) => {
        if (res.data) {
          setDashboardWidgetValue((dashboardWidgetValue) => ({
            ...dashboardWidgetValue,
            compromised: !res.data.includes("compromised"),
            hashes: !res.data.includes("hashes"),
            issue_trend: !res.data.includes("issue_trend"),
            mfa: !res.data.includes("mfa"),
            password: !res.data.includes("password"),
            protocols: !res.data.includes("protocols"),
            recent_issues: !res.data.includes("recent_issues"),
            shadow_identites: !res.data.includes("shadow_identites"),
            credentials_risk: !res.data.includes("credentials_risk"),
            id_access_infra: !res.data.includes("id_access_infra"),
            unauthorized_access: !res.data.includes("unauthorized_access"),
            unknown_access: !res.data.includes("unknown_access"),
            top_new_risky: !res.data.includes("top_new_risky")
          }));
        }
      })
      .catch((error: any) => { });
  };
  const fetchData = async () => {
    if (POLLING_SYNC_FLAG) {
      POLLING_SYNC_FLAG = false;
      await Api.get("/dashboard?last_issue_id=" + lastIssueId, {
        timeout: 30000,
      })
        .then((res) => {
          //res.data = responseAdapter(res.data);
          if (prevAccessFlowsCount === 0) {
            prevAccessFlowsCount = res.data.past_24_hours_flows.access_flows;
          }
          setResponseData(res.data);
          POLLING_SYNC_FLAG = true;
          lastIssueId =
            res.data.recent_issues.issues[
              res.data.recent_issues.issues.length - 1
            ]._id;
        })
        .catch((err) => {
          POLLING_SYNC_FLAG = true;
          if (err.response?.status === 401) {
            window.location.href = "/login";
          }
        });
    }
  };

  const updateUserSelection = (data: any) => {

    Api.post("/dashboard/playbookview", data)
      .then((res: { data: any }) => {
      })
      .catch((error: any) => {
        // setLoading(false)
        if (error.response.status === 500) {
          // addToast("Sorry, something went wrong there, try again.", {
          //   appearance: 'error',
          //   autoDismiss: true,
          // })
          // setSErrors(error.response.data)
        } else if (error.response.status === 404) {
          // addToast(error.response.data, {
          //   appearance: 'error',
          //   autoDismiss: true,
          // })
          // setSErrors(error.response.data)
        } else if (error.response.status === 400) {
          // addToast(error.response.data, {
          //   appearance: 'error',
          //   autoDismiss: true,
          // })
          // setSErrors(error.response.data)
        }
      });

  };

  useEffect(() => {
    if (
      responseData &&
      responseData.past_24_hours_flows.access_flows !== prevAccessFlowsCount
    ) {
      setTimeout(() => {
        prevAccessFlowsCount = responseData.past_24_hours_flows.access_flows;
      }, 28 * 1000);
    }
    if (responseData?.mfa_id && Object.keys(responseData?.mfa_id).length > 0) {
      setMFATotal(
        responseData?.mfa_id[1].total_assets +
        responseData?.mfa_id[2].total_assets +
        responseData?.mfa_id[3].total_assets +
        responseData?.mfa_id[4].total_assets
      );
    }
    if (
      responseData?.password_id &&
      Object.keys(responseData?.password_id).length > 0
    ) {
      setPasswordTotal(
        responseData?.password_id[1].total_identities +
        responseData?.password_id[2].total_identities +
        responseData?.password_id[3].total_identities +
        responseData?.password_id[4].total_identities
      );
    }
    if (
      responseData?.compromised_id &&
      Object.keys(responseData?.compromised_id).length > 0
    ) {
      setCompromisedTotal(
        responseData?.compromised_id[1].total_identities +
        responseData?.compromised_id[2].total_identities +
        responseData?.compromised_id[3].total_identities +
        responseData?.compromised_id[4].total_identities
      );
    }
  }, [responseData]);

  const setGroupData = () => {
    const group = ["id_access_infra", "shadow_activity", "unauthorised_access"];

    group.forEach((element) => {
      if (keysMapping) {
        const datakey = keysMapping.get(element);

        if (datakey) {
          setGroup((prevGroup) => {
            // Using the spread operator to create a copy of the previous state
            const updatedGroup = { ...prevGroup };

            // If the group[element] doesn't exist, initialize it as an empty array
            if (!updatedGroup[element]) {
              updatedGroup[element] = [];
            }

            // Push each datakey to the corresponding group
            datakey.forEach((key) => {
              if (playBookData.hasOwnProperty(key)) {
                // Filter playbooks by type matching the element

                let filteredPlaybooks = [];

                if (key === 'Posture Issues') {
                  filteredPlaybooks = playBookData[key].playbooks.filter(
                    (playbook) => playbook.dashboard_widget === element
                  );
                }

                updatedGroup[element].push({
                  name: key,
                  items: key === 'Posture Issues' ? filteredPlaybooks : playBookData[key].playbooks, // Only playbooks matching the element type
                  is_posture_issue: key === 'Posture Issues'
                });

              }
            });

            return updatedGroup;
          });
        }
      }
    });
  };


  interface Item {
    _id: string;
    is_globally_added: boolean;
    name: string;
  }

  interface Group {
    name: string;
    items: Item[];
  }

  interface Groups {
    [key: string]: Group[];
  }

  interface SelectedItems {
    [key: string]: string[];
  }

  interface Subset {
    [key: string]: {
      is_playbook_view: boolean;
      playbook_view: {
        [issueType: string]: Item[];
      };
    };
  }

  function createSubset(selectedItems: SelectedItems, groups: Groups): Subset {
    const subset: Subset = {};

    for (const groupId in selectedItems) {
      const selectedItemsList = selectedItems[groupId];

      if (selectedItemsList.length > 0) {
        const groupInfo = groups[groupId];
        if (groupInfo) {
          subset[groupId] = {
            is_playbook_view: true, // You can modify this based on your requirements
            playbook_view: groupInfo.reduce((acc, group) => {
              const selectedItemsInGroup = group.items.filter((item) =>
                selectedItemsList.includes(item.name)
              );

              if (selectedItemsInGroup.length > 0) {
                acc[group.name] = selectedItemsInGroup;
              }

              return acc;
            }, {} as { [issueType: string]: Item[] }),
          };
        }
      } else {
        subset[groupId] = { is_playbook_view: false, playbook_view: {} }
      }
    }

    return subset;
  }







  const renderWidget = (widgetOptions, widgetData) => {
    switch (widgetOptions.type) {
      case "24hrs_incidents":
        return (
          <div
            className="widget-content"
            style={{ overflow: "visible", left: "0px", padding: "0" }}
          >
            <Past24HrsIncidentFlows past_24_hour_flow={widgetData} />
          </div>
        );

      case "risk_trend":
        return (
          <div
            className="widget-content"
            style={{ height: "100%", maxHeight: "unset", minHeight: "unset" }}
          >
            <RiskTrendIncidentWidget
              irl={widgetData}
              widgetId={widgetOptions.id}
              groupData={groups}
              selectedItems={selectedItems}
              playbookData={playBookData}
            />
          </div>
        );
      case "top_new_risky":
        return (
          <div
            className="widget-content"
            style={{ height: "100%", maxHeight: "100%", minHeight: "100%" }}
          >
            <TopIssues
              top_issues_prop={widgetData}
              isRecentAccessEnabled={
                dashboardWidgetValue?.recent_issues || false
              }
            />
          </div>
        );
      case "recent_access":
        return (
          <div
            className="widget-content"
            style={{
              height: "100%",
              maxHeight: "100%",
              left: "0px",
              padding: "0px",
            }}
          >
            {/* <RecentTransactions recent_issues_prop={widgetData} /> */}
            <RecentAccessNew recent_issues_prop={widgetData} />
          </div>
        );
      case "donut_chart":
        return (
          <div className="widget-content">
            {widgetData ? (
              <>
                <DonutChart
                  widgetTitle={widgetOptions.title}
                  widgetId={widgetOptions.id}
                  ruleListProp={widgetData}
                  isWidgetDisabled={!(isWidgetDisabled > 4) || false}
                  groupData={groups[widgetOptions.ruleCategory]}
                  selectedData={selectedItems}
                />
              </>
            ) : (
              <> </>
            )}
          </div>
        );
      case "blocks":
        return (
          <div
            className="widget-content"
            style={{
              position: "relative",
            }}
          >
            {widgetData ? (
              <CredentialsRisk
                credentials={widgetData}
                widgetId={widgetOptions.id}
              />
            ) : (
              <></>
            )}
          </div>
        );
      case "unknown_access":
        return (
          <>
            {widgetData ? (
              <UnknownAccess
                widgetTitle={widgetOptions.title}
                unknownAccessRules={widgetData}
                widgetId={widgetOptions.id}
              />
            ) : (
              <></>
            )}
          </>
        );
      case "risky_protocols":
        return (
          <div
            className="widget-content"
            style={{
              position: "relative",
            }}
          >
            {widgetData ? (
              <RiskyProtocol
                protocols={widgetData}
                widgetId={widgetOptions.id}
              />
            ) : (
              <></>
            )}
          </div>
        );
      case "issue_trend":
        return (
          <div
            className="widget-content"
            style={{
              position: "relative",
            }}
          >
            <IssuesTrendChart last_24_hours_issues={widgetData} />
          </div>
        );
      default:
        return <></>;
    }
  };

  interface Item {
    _id: string;
    name: string;
  }

  interface ArrayItem {
    name: string;
    items: Item[];
  }

  const findNameById = (array: ArrayItem[], idToFind: string): string => {
    for (const obj of array) {
      const foundItem = obj.items.find((item) => item._id === idToFind);
      if (foundItem) {
        return foundItem.name;
      }
    }
    return idToFind;
  }







  const getWidgetDataFromResponse = useCallback(
    (
      response: any,
      dataKey: string | undefined | null,
      dataCategoryKey: string | undefined | null
    ) => {
      let data = response?.[dataKey || ""];
      if (dataCategoryKey && dataCategoryKey !== "") {
        const widgetData = {};
        const key = dataCategoryKey + '_default_view';
        if (['id_access_infra', 'shadow_activity', 'unauthorised_access'].includes(dataCategoryKey)) {
          if (selectedItems[key]) {
            const rulesList = keysMapping.get(dataCategoryKey);
            rulesList?.forEach((ruleName) => {
              widgetData[ruleName] = data?.[ruleName];
            });
          } else {
            const rulesList = selectedItems[dataCategoryKey];
            rulesList?.forEach((ruleName) => {
              if (ruleName.is_issue_type) {
                data = response?.[dataKey || ""];
                widgetData[ruleName._id] = data?.[ruleName._id];
              } else {
                data = response?.['rules_pbview' || ''];

                //const pbName = findNameById( groups[dataCategoryKey]  , ruleName._id)
                widgetData[ruleName._id] = data?.[ruleName._id];
              }

            });
          }
        } else {
          const rulesList = keysMapping.get(dataCategoryKey);
          rulesList?.forEach((ruleName) => {
            widgetData[ruleName] = data?.[ruleName];
          });
        }
        return widgetData;
      } else {
        return data;
      }
    },
    [selectedItems]
  );

  const handleOnSearchKeyUp = (evt: any, clear: any = false) => {
    localStorage.removeItem("saas_state");
    setSearchKeyword(evt);
    currentSearchKeywordRef.current = evt;
    setClearKeyword(clear);
  };

  const showRow = (widgets: any = []) => {
    try {
      let show = false;
      for (let widget of widgets) {
        show =
          show ||
          dashboardWidgetValue?.[widget.filterKey] ||
          widget.filterKey === "none";
      }
      return show;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const showColumn = (widget: any) => {
    try {
      if (widget.filterKey === "none") return true;
      return dashboardWidgetValue?.[widget.filterKey];
    } catch (error) {
      console.log(error);
    }
  };

  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const handleSelectionChange = (value: string) => {
    setSelectedValues((prevSelectedValues) =>
      prevSelectedValues.includes(value)
        ? prevSelectedValues.filter((v) => v !== value)
        : [...prevSelectedValues, value]
    );
  };

  const handleClose = () => {
    setOpenModal("");
    setControlledPosition({ x: 0, y: 0 });
  };

  const onMultiSelectClear = (selectedItems: {
    [widgetId: string]: string[];
  }) => {
    setOpenModal("");
    localStorage.removeItem("saas_state");
    setSelectedItems(selectedItems);
    setSearchKeyword("");
    currentSearchKeywordRef.current = "";
  };

  const dragContainerRef = useRef(null);
  const onControlledDrag = (e, position) => {
    let { x, y } = position;
    // top bounds check
    if (y < -10) {
      y = -10;
    }
    // bottom bounds check (leave 13% of container top area outside)
    if (y > window.innerHeight - window.innerHeight * 0.13) {
      y = window.innerHeight - window.innerHeight * 0.13;
    }
    // right bounds check (leave 7% of container right area outside)
    if (
      x + dragContainerRef.current.clientWidth >
      window.innerWidth - window.innerWidth * 0.07
    ) {
      x =
        window.innerWidth -
        dragContainerRef.current.clientWidth -
        window.innerWidth * 0.07;
    }

    // left bounds check
    if (x < -(window.innerWidth - 100)) {
      x = -(window.innerWidth - 100);
    }
    setControlledPosition({ x, y });
  };

  const onControlledDragStop = (e, position) => {
    onControlledDrag(e, position);
  };
  const [controlledPosition, setControlledPosition] = useState({
    x: 0,
    y: 0
  });

  return (
    <ReportProvider>
      <SubHeader title=""></SubHeader>

      <div className="dashboard-new-container">
        {dashboardWidgetValue &&
          dashboardTemplate.rows.map((rowItem) => {
            return showRow(rowItem.widgets) ? (
              <div
                className="flex-widgets-container"
                style={{ height: `${rowItem.height}%` }}
              >
                {rowItem.widgets.map((wid) => {
                  const widgetData =
                    getWidgetDataFromResponse(
                      responseData,
                      wid?.dataKey,
                      wid?.ruleCategory
                    )


                  return showColumn(wid) ? (
                    !responseData ? (
                      <div className="widget-container" style={wid?.styles}>
                        <Skeleton type={wid?.type} />
                      </div>
                    ) : (
                      <div className="widget-container" style={wid?.styles}>
                        {/* <Skeleton type={wid?.type} /> */}

                        {wid.title !== "" && !wid?.isCustomTitle ? (
                          <div
                            className={`widget-title ${wid.shadowBorder
                              ? "shadow-border"
                              : "plain-border"
                              }`}
                          >
                            {" "}
                            {wid.type === "donut_chart" && (
                              <div className="top-issues-title-dropdown">

                                {wid.title}{" "}
                                {" "}
                                {(
                                  <Tooltip classes={{ tooltip: 'playbook-level-widget-tooltip' }} title={'Configure View : ' + wid.title}>
                                    <div
                                      className="gear-setting-icon setting-icon"
                                      onClick={(event) =>
                                        handleOpenModal(wid.id, event)
                                      }
                                    ></div>
                                  </Tooltip>
                                )}


                              </div>
                            )}
                            {wid.type !== "donut_chart" && (<>{wid.title}</>)}
                          </div>
                        ) : null}
                        <Draggable
                          position={controlledPosition}
                          handle='.search-header-container'
                          onStop={onControlledDragStop}
                        >
                          <Dialog className="am-groupable-dialog" open={openModal === wid.id} slotProps={{
                            backdrop: {
                              onClick: (event) => event.stopPropagation(),
                            },
                          }}>
                            <DialogTitle ref={dragContainerRef} className='search-header-container'>
                              <span className="groupable-title">Settings - <span style={{ textTransform: 'uppercase' }}>{wid.title}</span> </span>
                              <Box
                                sx={{ float: "right", marginTop: "20px", right: '0', cursor: 'pointer' }}
                                onClick={handleClose}
                                className="popup-container-close-dashboard"
                              >
                              </Box>
                            </DialogTitle>

                            <DialogContent
                              sx={{
                                width: "500px",
                                height: "400px",
                                overflowX: "hidden",
                              }}
                            >
                              <Box sx={{ marginBottom: '15px' }} className="sub-header">  Select Issue Types and Playbooks to display.  </Box>
                              <AMMultiSelectGroup
                                groups={groups}
                                onSelect={handleSelect}
                                handleClearBtn={handleClear}
                                onDoneClick={handleDoneClick}
                                onSearchKeyUp={handleOnSearchKeyUp}
                                doneLabel="Save"
                                selectedItems={selectedItems}
                                widId={wid.ruleCategory}
                                widgetData={responseData}
                                renderGroupLabel={renderGroupLabel}
                              />
                            </DialogContent>
                          </Dialog>
                        </Draggable>

                        {renderWidget(wid, widgetData)}
                      </div>
                    )
                  ) : (
                    <></>
                  );
                })}
              </div>
            ) : (
              <></>
            );
          })}
      </div>
    </ReportProvider>
  );
};

export default Dashboard;

function responseAdapter(data: any) {
  const editedRes = JSON.parse(JSON.stringify(data));

  //editedRes.protocols = critical_8_high_7_medium_4_low_8;
  //editedRes.protocols = critical_2_high_5_medium_8_low_8;
  //editedRes.protocols =  critical_0_high_5_medium_8_low_8;
  editedRes.protocols = randomData;

  //no riskyprotocols
  //editedRes.protocols = {};

  //mock shadow activity
  // if (editedRes) {
  //     // Shadow activity
  //     const obj = editedRes.shadow_summary_id;
  //     if (obj) {
  //         Object.keys(obj).forEach((k) => {
  //             obj[k].last_7days_closed = data.widgets_trend?.[k].last_7days_closed || 0;
  //             obj[k].last_7days_created = data.widgets_trend?.[k].last_7days_created || 0;
  //             obj[k].last_7days_open = data.incidents_counts?.[k]?.last_7days_open || 0;
  //         });
  //     }

  //     // Credential Risk.
  //     const credentials_risk = {
  //         compromised_password: {
  //             "1": editedRes?.compromised_id?.["1"],
  //             "2": editedRes?.compromised_id?.["2"],
  //             "3": editedRes?.compromised_id?.["3"],
  //             "4": editedRes?.compromised_id?.["4"],
  //             label: "Compromised Password",
  //             value: "Compromised Password",
  //         },
  //         compromised_id: {
  //             "1": editedRes?.compromised_id?.["1"],
  //             "2": editedRes?.compromised_id?.["2"],
  //             "3": editedRes?.compromised_id?.["3"],
  //             "4": editedRes?.compromised_id?.["4"],
  //             label: "Compromised User Information",
  //             value: "Compromised User",
  //         },

  //         weak_password: {
  //             "1": editedRes?.password_id?.["1"],
  //             "2": editedRes?.password_id?.["2"],
  //             "3": editedRes?.password_id?.["3"],
  //             "4": editedRes?.password_id?.["4"],
  //             label: "Weak Password",
  //             value: "Weak Password",
  //         },
  //     };
  //     editedRes.credentials_risk = { ...credentials_risk };
  // }
  return editedRes;
}
function key(value: string, index: number, array: string[]): unknown {
  throw new Error("Function not implemented.");
}