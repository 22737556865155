import React, { useEffect, useState, useRef } from "react";
import { useToasts } from "../../../../components/core";
import styles from "../../grid/Playbooks.module.css";
import { Api } from "../../../../components/Axios";
import { AxiosError } from "axios";
import { useExceptionAdvanceSearch, useExceptionService } from "../../../exceptions/ref/useExceptions";
import { IPatternFormValue } from "../PatternForm/PatternFormGenerator";
import { IAMSelectOption } from "../../../../components/core/AMSelect/AMSelect";
import ModalPopup from "../../../../components/core/AMModalPopup/ModalPopup";
import "./PlaybookException.scss";
import { IPlaybookExceptionModalProps } from "./PlaybookTypes";

const PlaybookException = ({
  title,
  ruleType = "",
}: IPlaybookExceptionModalProps) => {
  const [responseData, setResponseData] = useState<any>();
  //   const [customListPopUp, setCustomListPopUp] = useState<IdAssetCustomListState>
  //     ({ data: { pattern: {}, type: [] }, show: false, title: "", patternFormConfig: [] });
  const { addToast } = useToasts();
  const { transformValues, getConfigItemByName } = useExceptionAdvanceSearch();
  const [openException, setOpenException] = useState<boolean>(false);
  const { updateCategoryCritieria } = useExceptionService();
  const openExceptionPopUp = () => {
    setOpenException((openException) => !openException);
  }

  const handleCloseExceptions = (state?: boolean) => {
    setOpenException(false);
  }

  useEffect(() => {
    fetchExceptions();
  }, []);

  const tranformCategory = (data: any) => {
    if (data?.result.length > 0) {
      data.result.forEach((i: any) => {
        updateCategoryCritieria(i);
      });
    }
  };


  const fetchExceptions = () => {
    Api.get("/exception", {
      params: { rpp: 100 },
    })
      .then((res: any) => {
        if (res.status === 200) {
          res.data.result = res.data.result.filter((value: any) => {
            return value.status == 'Active';
          })
          tranformCategory(res.data);
          setResponseData(res.data);
        }
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 401) {
          window.location.href = "/login";
        } else {
          addToast("Sorry, something went wrong there, try again.", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  const getRiskStyle = (risk: string) => {
    return styles[risk.toLowerCase() as keyof typeof styles];
  };

  const renderValue = (i: IPatternFormValue) => {
    const c = getConfigItemByName(i.searchKey);
    let val = i.value;
    if (c?.type == "list") {
      const v = c.selectOptions?.find(
        (k: IAMSelectOption) => k.optionValue == i.value
      );
      if (v) {
        val = v?.optionLabel;
      }
    }

  	if (c.key === 's_type') {
			val = Array.isArray(val)
				? val.map((item) => 
					typeof item === 'string' && !item.startsWith('User')
						? `NHI-${item}`
						: item
				  )
				: val?.value && typeof val === 'string' && !val.startsWith('User')
					? `NHI-${val}`
					: val;
		}
    return (
      <>
        {i.searchKey !== "flow_time"
          ? (val?.split ? val?.split(",") : val)?.join(", ")
          : `Details can't be viewed`}
      </>
    );
  };

  const SummaryCell = ({ index, criteria, data, numberOfRows }: any) => {
    const [localNumberOfRows, setLocalNumberOfRows] = useState(
      numberOfRows || 100
    );
    const [isMore, setIsMore] = useState(false);
    const cellRef = useRef(null);

    const searchFilter = [] as any;
    if (criteria) {
      criteria.forEach((i: any) => {
        searchFilter.push(transformValues(i));
      });
    }

    useEffect(() => {
      if (criteria?.length > 1) {
        let elemText = cellRef.current?.innerText;
        if (elemText && elemText.indexOf('...') > -1)
          setIsMore(true);
      } else {
        criteria.forEach((i: any) => {
          if (i.length > localNumberOfRows) {
            setIsMore(true);
          }
        });
      }

    }, [criteria, numberOfRows])


    const setNumberOfRows = () => {
      if (localNumberOfRows == 2) {
        setLocalNumberOfRows(100);
      } else {
        setLocalNumberOfRows(2);
      }

    };

    return (
      <>
        <div className="summary-cell" ref={cellRef}>
          <div>
            {searchFilter.map((item: any, rowIndex: number) => (
              <div key={rowIndex}>
                {rowIndex <= (localNumberOfRows - 1) && (rowIndex === 0 || localNumberOfRows > 2) && <div>
                  <span>Rule {rowIndex + 1}</span>
                  <ul style={{ marginLeft: "12px" }}>
                  {searchFilter.length > 0 &&
                    searchFilter[rowIndex]
                      .slice(0, localNumberOfRows)
                      .map((i: IPatternFormValue, ind: number) => {
                        if (ind > localNumberOfRows - 1) {
                          if (searchFilter.length - 1 < (localNumberOfRows - 1))
                            return <> More...</>;
                          return null
                        }
                        return (
                          <li className="" key={ind}>
                            <strong>{getConfigItemByName(i.searchKey)?.label || ''}</strong> -{' '}
                            {i.operator} - {renderValue(i)}
                          </li>
                        );
                      }).filter(el => el)}
                    </ul>
                </div>}

              </div>
            ))}
          </div>
          { searchFilter.length === 1 && searchFilter[0].length > localNumberOfRows && <> More...</>}
          { searchFilter.length >= (localNumberOfRows) && <> More...</>}
        </div>
        {isMore && <div
          className='shadowbox'
          style={{ width: '6%', marginTop: '2%', float: 'right' }}
          onClick={() => {
            setNumberOfRows();
          }}
        >
          ...
        </div>}
      </>
    );

  };



  const generateRows = (data: any) => {
    return data
      .filter((value: any) => {
        return value.rule_names.includes(ruleType);
      })
      .map((item: any, index: number) => {
        return (
          <tr key={item._id}>
            <td className="vert_mid_ho_left">
              <div
                title={item.name}
                className="ellipsis_name_reason"
                style={{ width: "450px" }}
              >
                {item.name}
              </div>
            </td>
            <td className="vert_mid_ho_left">
              <SummaryCell
                index={index}
                criteria={item?.criteria}
                data={item}
                numberOfRows={2}
              />
            </td>
          </tr>
        );
      });
  };

  return (
    <>
      {
        responseData && responseData.result && responseData.result.filter((value: any) => {
          return value.rule_names.includes(ruleType) && value.status == 'Active';
        }).length > 0 &&
        <>
          <hr style={{ marginBottom: '10px' }} />
          <div className="margin-top-bottom-8"><b className="section-heading">Related Global Exceptions</b></div>
          {responseData && responseData.result && <div className="exception-note"> There are a total of {responseData.result.filter((value: any) => {
            return value.rule_names.includes(ruleType) && value.status == 'Active';
          }).length == 1 ? `1 exception` : `
          ${responseData.result.filter((value: any) => {
            return value.rule_names.includes(ruleType) && value.status == 'Active';
          }).length}
          exceptions`} found for {ruleType}{'. '} <a className="link-text" onClick={openExceptionPopUp}>View Here</a></div>}

          <ModalPopup
            onCloseHandler={handleCloseExceptions}
            isOpen={openException}
            title={"Exceptions for  " + ruleType}
            classNames="advanced-playbook-exceptions-modal-root-container"
          >
            <div className="playbook-container playbook_list_root_container playbook-exception-container scrollbar-container">
              <div id="tab1" className={styles.table_container}>
                <table
                  id="table"
                  className={styles.playbook_table + " sticky_table_top"}
                >
                  <thead>
                    <tr>
                      <th style={{ width: "25%" }} className={"align_left"}>
                        Description
                      </th>
                      <th style={{ width: "70%" }} className={"align_left"}>
                        Rules
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {responseData && responseData.result.length > 0 ? (
                      generateRows(responseData.result)
                    ) : (
                      <tr>
                        <td colSpan={9} style={{ textAlign: "center" }}>
                          No record found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className="popup-container-button-container dialog-button"
              style={{ justifyContent: "end" }}
            >
              <button type={"button"} className={"float_right button_styled"} onClick={() => handleCloseExceptions(true)}>
                Ok
              </button>
            </div>
          </ModalPopup>
        </>}
    </>
  );
};

export default PlaybookException;
