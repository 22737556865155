import React, { useEffect, useState } from "react"
import { AMRadioGroup, IAMRadioGroupOption } from "../../../components/core/AMRadioGroup/AMRadioGroup"
import { GlobalConsts } from "../../../GlobalConst";


const RadioOptions = [
    {
        optionValue: 'automatic',
        optionLabel: "Smart Threshold",
        labelOptions: {
            renderLabel: (option: IAMRadioGroupOption) => {
                return <div><span>{option?.optionLabel}</span><span className="link-text marginleft10"
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault()
                        window.open(`${GlobalConsts.PLAYBOOK_LINK}/smart-thresholds?${GlobalConsts.DOC_TOKEN}`, "_blank")
                    }}
                >Learn more</span></div>
            }
        }
    },
    {
        optionValue: 'manual',
        optionLabel: "Manual Threshold",
    }
] as Array<IAMRadioGroupOption>;

interface IThresholdSelect {
    onThresholdSelect(event: React.ChangeEvent<HTMLInputElement>, value: string): void,
    defaultValue: string,
    classes?: {
        divClass?: string
    }
}

export const ThresholdSelect = ({ onThresholdSelect, defaultValue, classes }: IThresholdSelect) => {
    const [thresholdValue, setThresholdValue] = useState(defaultValue);
    useEffect(() => {
        if (thresholdValue != defaultValue) {
            setThresholdValue(defaultValue)
        }

    }, [defaultValue])
    return <div className={classes?.divClass || ''}>
        <AMRadioGroup
            defaultValue={thresholdValue}
            radioOptions={RadioOptions}
            onRadioGroupChange={onThresholdSelect}
        />
    </div>
}

