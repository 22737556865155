import React, { useEffect, useState } from 'react';

export const CloudTrailCollector = ({ register, setValue, collectorData, collectionMethodData }) => {
    const [showCollectionTypes, setShowcollectionTypes] = useState(true);
    const [managedVia, setManagedvia] = useState('vpc_managed');

    useEffect(() => {
        if (collectorData) {
            setValue(
                'cloudtrail_sqs',
                collectorData.collector_config?.cloudtrail_sqs
            );
            setValue(
                'cloudtrail_aws_access_key',
                collectorData.collector_config?.cloudtrail_aws_access_key
            );
            setValue(
                'cloudtrail_aws_access_secret',
                collectorData.collector_config?.cloudtrail_aws_access_secret
            );

            if (!collectorData?.collector_config?.cloudtrail_aws_access_key && !collectorData?.collector_config?.cloudtrail_aws_access_secret) {
                setManagedvia('assumed_managed')
            }
        }
    }, [collectorData]);

    useEffect(() => {
        if (collectionMethodData?.is_direct === true && collectionMethodData?.label === 'Cloud Direct') {
            setShowcollectionTypes(false);
        } else {
            setShowcollectionTypes(true);
        }
    }, [collectionMethodData])

    return (
        <div className='collector-configuration-vendor-container'>
            <div className={`form_sub_section`}>
                <div className='form_sub_section_header'>
                    AWS S3 Configuration
                </div>
                <div className='sensor-flex-container'>
                    {showCollectionTypes && <div className='sensor-flex-container-item' style={{ marginLeft: 22 }}>
                        <div className='assumed-role-box-item'>
                            <input
                                type='radio'
                                name='managed_bucket'
                                checked={managedVia === 'vpc_managed'}
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                value={'vpc_managed'}
                                onChange={() =>
                                    setManagedvia('vpc_managed')
                                }
                            />{' '}
                            <label>
                                Access Key and Secret
                            </label>
                        </div>
                        <div className='assumed-role-box-item'>
                            <input
                                type='radio'
                                name='managed_bucket'
                                checked={managedVia === 'assumed_managed'}
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                value={'assumed_managed'}
                                onChange={() =>
                                    setManagedvia('assumed_managed')
                                }
                            />{' '}
                            <label>
                                Cross Account AssumeRole
                            </label></div>
                    </div>}
                </div>
                {
                    managedVia === 'assumed_managed' && <div className='banner'
                        style={{ marginLeft: 22, width: '90%' }}>
                        Note: The IAM role associated with the Flow Sensor instance will be used to access CloudTrail (S3, SQS) logs. This IAM role must have read permissions to the configured SQS and S3 AssumeRole action.
                    </div>
                }
                <div className='form_sub_section_content'>
                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>AWS SQS URL*</label>
                            <input
                                type='text'
                                name='cloudtrail_sqs'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                placeholder={'Enter AWS SQS URL'}
                            />
                        </div>
                    </div>
                    {managedVia === 'vpc_managed' &&
                        <><div className='sensor-flex-container'>
                            <div className='sensor-flex-container-item'>
                                <label>AWS Access Key ID*</label>
                                <input
                                    type='text'
                                    name='cloudtrail_aws_access_key'
                                    ref={register({
                                        required: true,
                                        shouldUnregister: true
                                    })}
                                    placeholder={'Enter AWS Access Key ID'}
                                />
                            </div>
                        </div>

                            <div className='sensor-flex-container'>
                                <div className='sensor-flex-container-item'>
                                    <label>AWS Secret Access Key*</label>
                                    <input
                                        type='text'
                                        name='cloudtrail_aws_access_secret'
                                        ref={register({
                                            required: true,
                                            shouldUnregister: true
                                        })}
                                        placeholder={'Enter AWS Secret Access Key'}
                                    />
                                </div>
                            </div></>}
                </div>
            </div>
        </div>
    );
};
