export const SensorErrors = {
	name: {
		required: 'Please enter sensor name.',
		pattern: 'Invalid sensor name.'
	},
	type: {
		required: 'Please select sensor type.'
	},
	location: {
		required: 'Please enter site code.'
	},

	/* FornEnrichment Sensor */

	dns_name: {
		required: 'Please Enter Base Domain.'
	},
	dns_location: {
		required: 'Please Enter DNS IP Address.',
		invalidIp: 'Please Enter valid DNS IP Address.'
	},
	username: {
		required: 'Please Enter Username'
	},
	password: {
		required: 'Please Enter Password'
	},
	file_path: {
		required: 'Please Enter File Path.'
	},
	ip_address: {
		required: 'Please Enter IP Address.'
	},
	hostname: {
		required: 'Please Enter Host Name.'
	},
	domain: {
		required: 'Please Enter Domain Number.'
	},
	asset_name: {
		required: 'Please Enter Asset Number'
	},

	/*  For Flow Sensor  */
	dns_servers: {
		required: 'DNS Servers list is empty.',
		invalidIp: 'Invalid Ip Provided.'
	},
	aws_query_url: {
		required: 'Enter AWS VPC SQS URL.'
	},
	aws_access_key_id: {
		required: 'Enter AWS VPC Access Key ID.'
	},
	aws_access_key_secret: {
		required: 'Enter AWS VPC Access Key Secret.'
	},
	aws_vpc_flow_log_format: {
		required: 'Enter AWS VPC flow log fields format.'
	},
	p81_sqsurl: {
		required: 'Enter Perimeter 81 SQS URL.'
	},
	p81_accesskey: {
		required: 'Enter Perimeter 81 Access Key.'
	},
	p81_secretkey: {
		required: 'Enter Perimeter 81 Secret Key.'
	},
	azure_nsg_storage_account_name: {
		required: 'Enter Azure Storage Account.'
	},
	azure_nsg_storage_access_key: {
		required: 'Enter Azure Storage Access Key.'
	},
	azure_nsg_container: {
		required: 'Enter Azure Storage Container Name.'
	},

	azure_sp_tenant_id: {
		required: 'Enter Azure Service Principal Tenant Id.'
	},
	azure_sp_client_id: {
		required: 'Enter Azure Service Principal Client Id.'
	},
	azure_sp_client_secret: {
		required: 'Enter  Azure Service Principal Client Secret Key.'
	},
	//For cisco umbrella collector
	cisco_sqsurl: {
		required: 'Enter Cisco SQS URL.'
	},
	cisco_accesskey: {
		required: 'Enter Cisco Access Key.'
	},
	cisco_secretkey: {
		required: 'Enter Cisco Secret Key.'
	},
	managed_bucket: {
		required: 'Select Cisco Umbrella Collect Logs.'
	},
	bucket_arn: {
		required: 'Enter Bucket Datapath.'
	},

	//For CATO network collector
	cato_account_ids: {
		required: 'Enter CATO Account Ids.'
	},
	cato_api_key: {
		required: 'Enter CATO API Key.'
	},

	/*  For AD Sensor  */
	// flow_sensor_id: [],

	/*  For Azure AD Sensor  */
	tanent_id: {
		required: 'Enter Azure AD Tenant Id.'
	},
	client_id: {
		required: 'Enter Azure AD Client Id.'
	},
	client_secret: {
		required: 'Enter Azure AD Client Secret.'
	},

	/* MFA enabled checkbox*/
	/*  mfa_enabled: {
		 required: 'Enter  Azure Service Principal Client Secret Key'
	 }, */
	// mfa_session_timeout: number,
	/*  For OneLogin Sensor  */
	ol_sub_domain: {
		required: 'Enter One Login Subdomain.'
	},
	ol_client_id: {
		required: 'Enter One Login Client Id.'
	},
	ol_client_secret: {
		required: 'Enter One Login Client Secret Key.'
	},

	/*  For Okta Sensor  */
	okta_sub_domain: {
		required: 'Enter Okta Sub domain.'
	},
	okta_api_token: {
		required: 'Enter Okta Api Token.'
	},

	/*  LDAP Service Configuration */
	ldap_base_domain: {
		required: 'Enter LDAP Base Domain.'
	},
	ldap_host: {
		required: 'Enter LDAP Host.'
	},
	ldap_port: {
		required: 'Enter LDAP Port.'
	},
	/*     use_secure_ldap: {
			required: 'Enter  Azure Service Principal Client Secret Key'
		},
	 */
	/* GCP*/
	gcp_project_id: {
		required: 'Enter GCP Project Id.'
	},
	gcp_service_acc_key: {
		required: 'Enter GCP Service Account Key.'
	},
	gcp_topic_name: {
		required: 'Enter GCP Topic Name.'
	},
	gcp_subscription_name: {
		required: 'Enter GCP Service Subscription.'
	},
	/* Google workspace */
	delegate_email: {
		required: 'Enter Google Workspace delegate email.'
	},
	service_acc_key: {
		required: 'Enter Google Workspace service account key.'
	},
	customer_id: {
		required: 'Enter Google Workspace customer id.'
	},

	/* ping id */
	ping_token_endpoint: {
		required: 'Enter ping token endpoint.'
	},
	ping_client_secret: {
		required: 'Enter ping client secret key.'
	},
	ping_client_id: {
		required: 'Enter ping client id.'
	},

	splunk_port: {
		required: 'Please enter splunk port.'
	},
	splunk_token: {
		required: 'Please enter splunk token.'
	},
	splunk_hostname: {
		required: 'Please enter splunk hostname.'
	},
	qradar_url: {
		required: 'Please enter QRadar API URL.'
	},
	qradar_certificate: {
		required: 'Please enter QRadar SSL Certificate.'
	},
	qradar_token: {
		required: 'Please enter QRadar API Token.'
	},
	sumologic_s3_bucket: {
		required: 'Please enter AWS S3 Bucket.'
	},
	sumologic_aws_region: {
		required: 'Please enter AWS Region.'
	},
	sumologic_aws_access_key: {
		required: 'Please enter AWS Access Key ID.'
	},
	sumologic_aws_access_secret: {
		required: 'Please enter AWS Secret Access Key.'
	},
	crowdstrike_client_id: {
		required: 'Enter CrowdStrike Client ID.'
	},
	crowdstrike_secret: {
		required: 'Enter CrowdStrike Secret.'
	},
	crowdstrike_base_url: {
		required: 'Enter CrowdStrike Base URL.'
	},
	cloudtrail_sqs: {
		required: 'Enter AWS SQS URL'
	},
	cloudtrail_aws_access_key: {
		required: 'Enter AWS Access Key ID'
	},
	cloudtrail_aws_access_secret: {
		required: 'Enter AWS Secret Access Key'
	},
	log_group: {
		required: 'Enter CloudWatch Log Group ARN'
	},
	access_secret: {
		required: 'Enter AWS Secret Access Key'
	},
	access_key: {
		required: 'Enter AWS Access Key ID'
	},
	region: {
		required: 'Enter Region'
	},
	collector_name: {
		required: 'Enter Collector Name',
		pattern: 'Invalid Collector Name'
	},
	source_type: {
		required: 'Enter Source Type'
	},
	source_index: {
		required: 'Enter Source Index'
	},
	index: {
		required: 'Enter Index Name'
	},
	source_prefix: {
		required: 'Enter AWS S3 Bucket Folder'
	},
	source_ids: {
		required: 'Enter Source Ids'
	},
	protocol: {
		required: 'Select Header Format'
	},
	qradar_protocol_header_name: {
		required: 'Enter Header Key'
	},
	qradar_protocol_header_pattern: {
		required: 'Enter Header Value'
	},
	collection_method: {
		required: 'Select Collection Method'
	},
	kafka_brokers: {
		required: 'Kafka brokers list is empty.',	
	},
};
