import React, { useEffect, useRef, useState } from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { TicketPerStatus } from '../../../types/response-types';
import moment from 'moment';

type Props={
	ticketStatus: TicketPerStatus | undefined
}

export const ReportByStatus = ({ticketStatus}: Props) => {

	const options: Highcharts.Options = {
		chart: {
			height: window.matchMedia("(min-width: 2560px)").matches ? 240 : 240,
			marginTop: window.matchMedia("(min-width: 2560px)").matches ? 30 : 30,
			marginBottom: window.matchMedia("(min-width: 2560px)").matches ? 60 : 60,
			type: "column",
            style:{
                fontFamily: 'Metropolis-Regular'
            }
		},
		title: undefined,
		tooltip: {
			useHTML: true,
			backgroundColor: undefined,
			borderWidth: 0,
			shadow: false,
			formatter: function(){
				if(this.y){
					return (
					  '<div style="background-color:' +
					  this.color +
					  `;color: ${this.series.name==="Open" ?'#fff':'#4b4547'};`
					  +' z-index: 12000" class="hc-tooltip"> ' +
					  "<b>"
					  + `${this.series.name==="Open" ? 'Open : ' : 'Closed : '}`
					  + Math.abs(this.y) +
					  "</b>" +
					//   "%" +
					  "</div>"
					);
				  }
				  return '';
			}
		},
		plotOptions: {
			line:{
                enableMouseTracking: false,
                marker:{
                    enabled: false
                },
                lineWidth: 1
            },
			column:{
                states:{
                    inactive:{
                        enabled:false
                    }
                },
                showInLegend: false,
                cursor: 'pointer'
            }
		},
		credits: {
			enabled: false
		},
        legend:{
            enabled: false
        }
	};

	const [ticketData, setTicketData] = useState<Highcharts.PointOptionsObject[]>([]);
	const [openTicket, setOpenTicket] = useState<Highcharts.PointOptionsObject[]>([]);
	const [closedTicket, setClosedTicket] = useState<Highcharts.PointOptionsObject[]>([]);
	const [ticketDate, setTicketDate] = useState<string[]>([]);
	const [chartOptions, setChartOptions] = useState<Highcharts.Options>(options);

	useEffect(()=>{
		if(ticketStatus){
			calcData();
		}
	},[ticketStatus])

	const calcData=()=>{
		let index=0;
		// let data = [];
		let date = [];
		let openData = [];
		let closedData = [];
		for(let ob in ticketStatus){
			let totalTickets = ticketStatus[ob].opened + ticketStatus[ob].closed;
			let openPer = totalTickets>0 ? Math.round(((ticketStatus[ob].opened)/totalTickets)*100) : 0;
			let closedPer = totalTickets>0 ? (100 - openPer) : 0;
			// data.push(
			// 	{
			// 		x: index,
			// 		// y: ticketStatus[ob].opened,
			// 		y: openPer,
			// 		color: '#fd045e'
			// 	},
			// 	{
			// 		x: index,
			// 		// y: ticketStatus[ob].closed>0 ?  ticketStatus[ob].closed*-1 : ticketStatus[ob].closed,
			// 		y: ticketStatus[ob].closed>0 ?  closedPer*-1 : closedPer,
			// 		color: '#d5d9e2'
			// 	}
			// );
			openData.push({
				x: index,
				y: ticketStatus[ob].opened===0 ? null : ticketStatus[ob].opened,
				color: '#fd045e'
			})
			closedData.push({
				x: index,
				y: ticketStatus[ob].closed===0 ? null : ticketStatus[ob].closed,
				color: '#d5d9e2',
			})
			date.push(moment.unix(+ob).format('DD MMM'));
			index++;
		}
		// setTicketData(data);
		setOpenTicket(openData);
		setClosedTicket(closedData);
		setTicketDate(date);
	}

	useEffect(()=>{
		setChartOptions(
			{
				...chartOptions,
				yAxis:{
					gridLineWidth: 0,
					lineWidth:1,
					lineColor:'#fff',
					plotLines: [{
						color: '#ccc',
						width: 1,
						value: 0,
						acrossPanes: undefined
					}],
					labels: {
						enabled: false
					},
					title: {
						text: undefined
					},
				},
				xAxis:{
					categories: ticketDate,
					gridLineWidth: 0,
					lineColor: '#777777',
					lineWidth: 1,
					labels: {
						style:{
							fontSize: window.matchMedia("(min-width: 2560px)").matches ? '11px' : '11px'
						}
					},
					className : 'hc-x-axis'
				},
				series: [
					{
						type: "column",
						data: openTicket,
						name: 'Open',
						minPointLength:3
					},
					{
						type: "column",
						data: closedTicket,
						name: 'Closed',
						minPointLength:3
					},
					
					{
						type: "line",
						data: [],
						name: 'Open Point',
						borderWidth:0,
						color: '#fd045e'
					},
					{
						type: "line",
						data: [],
						name: 'Closed Point',
						borderWidth:0,
						color: '#d5d9e2'
					}
				]
			}
			
		)
		
	}, [ticketData, ticketDate, openTicket, closedTicket])

	const chartCallback=(currentChart:Highcharts.Chart)=>{
		setTimeout(() => {
			var chart = currentChart,
			openTicketData = chart.series[0],
			closedTicketData = chart.series[1],
			openTicketLineSeries = chart.series[2],
			closedTicketLineSeries = chart.series[3],
			xAxis = chart.xAxis[0],
			yAxis = chart.yAxis[0],
			openTicketLineData = [],
			closedTicketLineData = [],
			openTicketPoint: any,
			closedTicketPoint: any,
			xPix1,
			yPix1,
			xPix2,
			yPix2,
			i;

			for(i=0;i<openTicketData.data.length;i++){
				openTicketPoint = openTicketData.data[i] !==null ? openTicketData.data[i] : 0;
				xPix1 = openTicketPoint.shapeArgs.x + openTicketPoint.shapeArgs.width / 2 + chart.plotLeft;
				yPix1 = openTicketPoint.shapeArgs.y + chart.plotTop;
				openTicketLineData.push({
					x: xAxis.toValue(xPix1),
					y: yAxis.toValue(yPix1)
				});
				
				
				closedTicketPoint = closedTicketData.data[i] !==null ? closedTicketData.data[i] : 0;
				xPix2 = closedTicketPoint.shapeArgs.x + closedTicketPoint.shapeArgs.width / 2 + chart.plotLeft;
				yPix2 = closedTicketPoint.shapeArgs.y + chart.plotTop;
				closedTicketLineData.push({
					x: xAxis.toValue(xPix2),
					y: yAxis.toValue(yPix2)
				});

			}
			
			openTicketLineSeries.setData(openTicketLineData, true, true, false);
			closedTicketLineSeries.setData(closedTicketLineData, true, true, false);

		}, 100);
		
		
	}

	const chartRef = useRef<{
        chart: Highcharts.Chart;
        container: React.RefObject<HTMLDivElement>;
    }>(null);

    return (
	<>	
		{ ticketStatus === undefined ?
			<div className="empty_open_closed">&nbsp;</div>
		:
        <div className="shadow_box tickets_issue_chart">
			<div className="ticket_chart_title">Tickets: Open vs. Closed</div>
			<HighchartsReact ref={chartRef} highcharts={Highcharts} options={chartOptions} />
			<div className="asignee_legend">
		    	<ul>
		    		<li>Open</li>
		    		<li>Closed</li>
		    	</ul>
		    </div>
        </div>
		}	
	</>	
    )
} 