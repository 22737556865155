import React, { useEffect, useRef, useState } from 'react'
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useToasts } from '../../components/core';
import moment from 'moment'
import {Api} from '../../components/Axios'
import Popup from 'reactjs-popup'
import { Comment, AddUserStatus, AssignedUser } from './sub'
import 'reactjs-popup/dist/index.css';

import './details.css'
import ReportIssue from '../issue_prev/report/report';
import CloseIssuePopup from '../issue_prev/close-issue-popup/close-issue';
import CloseTicket from './sub/CloseTicket'
import { getRisk } from '../../utils/risk-level'
import { convertToCommaValue, getTimeDiffString } from '../../utils/util-methods';
import FlowTrend from '../issue_prev/flow-trend/flow-trend';
import { getIssueNameById } from '../playbooks/helpers/playbook-helper';

type TicketDetails = {
    param2: {
        SourceIp: [],
        SourcePort: number;
        SourceType: string;
        UserEmail: string;
        UserFullName: string;
        UserName: string;
    };
    param3: {
        DestinationIp: [],
        DestinationName: string;
        DestinationPort: number;
        DestinationType: string;
        Protocol: string;
    }
}

type Result = {
    _id: number;
    rule: string;
    policy_applied: string;
    source: string;
    destination: string;
    created_date: string;
    severity: string;
    assignee: string;
    status: string;
    // comments: TicketComment[];
    // latest_comment: LatestComment;
    ticket_details: TicketDetails;
    matching_rule_name: string;
    assignee_name: string;
    assignee_id: string;
    first_obj: FirstObject;
    playbook_name: string;
    playbook_id: string;
    issue_details: string;
    source_info: SourceInfo;
    destination_info: DestinationInfo;
    issue_info: IssueInfo;
    am_analysis: string;
    am_destination: string;
    am_source: string;
    narrations: Narrations[];
    ticket_status: string
}

type Narrations = {
    content: string;
    datetime: string;
    type: string;
    user_name: string;
    comment?: string;
}

type FirstObject = {
    date: string;
    dest_name: string;
    matching_rule_name: string;
    playbook_name: string;
    risk: number;
    src_name: string;
}

type SourceInfo = {
    name: string;
    type: string;
    ip: string;
    port: string;
}

type DestinationInfo = {
    name: string;
    type: string;
    ip: string;
    port: string;
}

type IssueInfo = {
    date:string;
    issue_flows_count:number;
    issue_number:number;
    protocol:string;
    status:string;
    matching_field_name:string;
    issue_flows: IssueFlows[];
}

type IssueFlows = {
    created_date: number;
    flow_id: number;
}

interface Params {
    tid: string
}

type ModalInfo = {
  action_type: any;
  value: string;
}

const TicketDetails = () => {
    let history = useHistory();
    let path = history.location.pathname.split('/');
    let tid = path[path.length-1];
    // const {tid} = useParams<Params>();
    const [ticketId, setTicketId] = useState(+tid);
    const [result, setResult] = useState<Result>()

    const [open, setOpen] = useState(false);
    const [openShowComment, setOpenShowComment] = useState(false);
    const [issueFlows, setIssueFlows] = useState<IssueFlows[]>();
    const [narrations, setNarrations] = useState<Narrations[]>();
    const [flowsTrendResponseData, setFlowsTrendResponseData] = useState<any>(undefined);
    const [flowsChartDetails, setFlowsChartDetails] = useState<any>({ issueId: 0, risk: '', createdDate: null });
    const updatedExtremesRef = useRef({ min: null, max: null });

    // useEffect(()=>{
    //     console.log("STATE : -----------------");
    //     console.log("result : ", result);
    //     console.log("issueFlows : ", issueFlows);
    //     console.log("narrations : ", narrations);
    // })

    useEffect(() => {
        if(!isNaN(ticketId))
            Listing()
    },[])

    const Listing = () => {
        Api.get('/tickets/' + ticketId)
        .then((res: { data: any }) => {
            setResult(res.data)
            setIssueFlows(res.data.issue_info.issue_flows)
            setNarrations(res.data.narrations.reverse())
            var c = res.data.comments.sort((a:any,b:any) => {
                return new Date(a.added_date).getTime() - new Date(b.added_date).getTime()
            }).reverse()

        })
        .catch((error: any) => {
        })
    }

    const FormatDate = (dte:any) => {
        moment.locale('en')
        return (
            <>
                {moment(dte).format('MMM DD, YYYY, h:mm A')}
            </>
        )
    }

    const handleClose=()=>{
        setOpen(false);
    }

    const handleCommentClose=()=>{
        setOpenShowComment(false);
    }

    const handleShowCommentClick=(e:React.MouseEvent<HTMLDivElement>)=>{
        setOpenShowComment(true);
    }

    const handleFlowClick=(event: any, data: any)=>{
        // setOpen(true);
        setOpen(true);
        let { id: issueId, risk } = data;
        const currentTime = +new Date();
        const cachedFlowData: any = sessionStorage.getItem(`flow-trend-${issueId}`);
        const flowTrendURL = `/issues/issueflowcounts/${issueId}`;


        if (!cachedFlowData || ((JSON.parse(cachedFlowData)?.expiry_time) < (currentTime))) {
            Api.get(flowTrendURL).then(((res: any) => {
                if (!res?.data?.length || res?.data?.length <= 0) {
                    setFlowsTrendResponseData({ flows: [], expiry_time: currentTime + 600000 });

                    // Chart header and color
                    const chartTitle = <>{`Incident ${issueId} - Flows Trend`}</>;
                    setFlowsChartDetails({ chartTitle, risk });
                    return;
                }

                let result = JSON.parse(JSON.stringify(res?.data)) || [];
                result.sort((a: any, b: any) => (a?.time < b?.time) ? -1 : 1);
                const cachedFlows = { flows: result, issueId, risk, expiry_time: currentTime + 600000 };
                setFlowsTrendResponseData(cachedFlows);

                // Chart header and color
                const startFromDate = Math.max(moment(result?.[0]?.time).valueOf(), moment().subtract(90, 'days').valueOf());
                const chartTitle = <>{`Incident ${issueId} - Flows Trend (${getTimeDiffString(startFromDate)})`}</>;
                setFlowsChartDetails({ chartTitle, risk });

                sessionStorage.setItem(`flow-trend-${issueId}`, JSON.stringify(cachedFlows));
            }
            )).catch(er => {
                console.log(er);
            });
        }
        else {
            const parsedData = JSON.parse(cachedFlowData);
            setFlowsTrendResponseData(parsedData);

            const {flows} = parsedData || [];
            // Chart header and color
            const startFromDate = Math.max(moment(flows[0]?.time).valueOf(), moment().subtract(90, 'days').valueOf());
            const chartTitle = <>{`Incident ${issueId} - Flows Trend (${getTimeDiffString(startFromDate)})`}</>;
            setFlowsChartDetails({ chartTitle, risk });
        }
    }

    const Timeline = () => {
        return ( 
                <>
                {narrations && narrations.length > 0 && narrations.map((item: Narrations) =>
                    <div className="timeline_inner" key={item.datetime+Math.random()}>
                        { item.type === "issue_action" || item.type === "issue_action_false_postive" || item.type === "issue_action_close" || item.type === "issue_action_edit"
                            || item.type === "issue_action_enroll_mfa" || item.type === "issue_action_block_access" || item.type === "issue_action_pwd_reset" || item.type === "issue_action_add_mfa"
                            || item.type === "issue_action_notify_user" || item.type === "issue_action_notify_admin" || item.type === "playbook_deleted_issue_closed" || item.type === "playbook_deleted" || item.type === "issue_resolved"
                            || item.type === "playbook_name_modified"
                        ? 
                            <div className='left_leaf'>
                                <div className='circle_orange'></div>
                                <div className='box_orange'></div>
                                <div className="timeline_card">
                                    <div className="header">
                                        {item.type === "issue_action_false_postive" ? <div className="action_title">REPORTED</div> : null }
                                        {item.type === "issue_action_close" ? <div className="action_title">CLOSED</div> : null }
                                        {item.type === "issue_action_edit" ? <div className="action_title">EDITED</div> : null }
                                        {item.type === "issue_action_enroll_mfa" ? <div className="action_title">MFA ENROLLMENT</div> : null }
                                        {item.type === "issue_action_block_access" ? <div className="action_title">BLOCKED</div> : null }
                                        {item.type === "issue_action_pwd_reset" ? <div className="action_title">PASSWORD RESET </div> : null }
                                        {item.type === "issue_action_add_mfa" ? <div className="action_title">MFA</div> : null }
                                        {item.type === "issue_action_notify_user" ? <div className="action_title">NOTIFIED</div> : null }
                                        {item.type === "issue_action_notify_admin" ? <div className="action_title">NOTIFIED</div> : null }
                                        {item.type === "playbook_deleted_issue_closed" ? <div className="action_title">CLOSED</div> : null }
                                        {item.type === "playbook_deleted" ? <div className="action_title">PLAYBOOK DELETED</div> : null }
                                        {item.type === "playbook_name_modified" ? <div className="action_title">PLAYBOOK UPDATED</div> : null }
                                        {item.type === "issue_resolved" ? <div className="action_title">RESOLVED</div> : null }
                                        
                                        <div className="action_timestamp">{FormatDate(item.datetime)}</div>
                                    </div>
                                    <div className="card_content">{item.content} <strong>{item.user_name}</strong> 
                                    </div>
                                </div>
                            </div> 
                        : null
                        }
                        <div className="clrBoth"></div>
                        {item.type === "close_ticket" || item.type === "ticket_created" || item.type === "assign_ticket" || item.type === "comment" || item.type === "playbook_deleted_ticket_closed" ? 
                            <div className='right_leaf'>
                                <div className='circle_yellow'></div>
                                <div className='box_yellow'></div>
                                <div className="timeline_card">
                                    <div className="header">
                                        {item.type === "ticket_created" ? <div className="action_title">CREATED</div> : null }
                                        {item.type === "assign_ticket" ? <div className="action_title">ASSIGNED</div> : null }
                                        {item.type === "close_ticket" ? <div className="action_title">CLOSED</div> : null }
                                        {item.type === "playbook_deleted_ticket_closed" ? <div className="action_title">CLOSED</div> : null }
                                        {item.type === "comment" ? <div className="action_title">COMMENTED</div> : null }    
                                        <div className="action_timestamp">{FormatDate(item.datetime)}</div>
                                    </div>
                                        {item.type !== "comment" ? 
                                            <div className="card_content"> {item.content} 
                                                <strong>{item.user_name}</strong> 
                                            </div>
                                        : null }
                                        {item.type === "comment" ? 
                                        <span>  
                                            <div className="comment_div">
                                                <div className="commenter_name">{item.user_name}</div>
                                                <div className="card_comment">{item.comment}</div>
                                            </div>
                                            
                                            <div className="comments_dots_btn" onClick={handleShowCommentClick}>...</div>
                                            
                                                <Popup overlayStyle={{zIndex:15001, background: 'rgba(227, 242, 253, .2)'}} 
                                                    open={openShowComment} 
                                                    closeOnDocumentClick={false}
                                                    modal={true}
                                                    closeOnEscape={false}
                                                >
                                                    <div className={"modal"}>
                                                        <div className={"close"} onClick={handleCommentClose}></div>
                                                        <div className={"header"}>Comment</div>
                                                        <div className={"content"}>
                                                            <div className="comment_inner_box">
                                                                <div className="comment_header_box">
                                                                    <div className="comment_user">
                                                                        <span>{item.user_name}</span>
                                                                    </div>
                                                                    <div className="comment_timestamp">
                                                                        <span>{moment(item.datetime).format('MMM DD YYYY,hh:mm A')}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="comment_box">
                                                                    {item.comment}
                                                                </div>
                                                            </div>
                                                            {/* <div className="">
                                                                {narrations && narrations.length > 0 && narrations.reverse().map((item: Narrations) =>
                                                                    { return item.comment? 
                                                                        ( 
                                                                            <div>
                                                                                <div className="comment_inner_box">
                                                                                        <div className="comment_header_box">
                                                                                            <div className="comment_user">
                                                                                                <span>{item.user_name}</span>
                                                                                            </div>
                                                                                            <div className="comment_timestamp">
                                                                                                <span>{moment(item.datetime).format('MMM DD YYYY - hh:mm A')}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="comment_box">
                                                                                            <span>{item.comment}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                <div className="clrBoth"></div>
                                                                            </div>
                                                                        )
                                                                        : null
                                                                    } 
                                                                )}

                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </Popup>
                                            
                                            </span>  : null
                                        }
                                </div>
                            </div>
                            : null
                        }
                    </div>
                )}

                <div className="left_leaf">
                    <div className="circle_orange"></div>
                    <div className="box_orange"></div>
                    <div className="timeline_card">
                        <div className="header">
                            <div className="action_title">
                                <div className="risk_sign">
                                    { result?.first_obj.risk === 1 ? <span className="low"></span> : null }
                                    { result?.first_obj.risk === 2 ? <span className="medium"></span> : null }
                                    { result?.first_obj.risk === 3 ? <span className="high"></span> : null }
                                    { result?.first_obj.risk === 4 ? <span className="critical"></span> : null }
                                </div>
                                PLAYBOOK MATCH
                            </div>
                            <div className="action_timestamp">{FormatDate(result?.first_obj.date)}</div>
                        </div>
                        <div className="card_content">
                            <strong>Matching Issue: </strong> {getIssueNameById(result?.first_obj.matching_rule_name)}<br/>
                            <strong>Identity: </strong> {(result?.first_obj.src_name)}<br/>
                            <strong>Asset: </strong> {(result?.first_obj.dest_name)}<br/>
                            <strong>Risk: </strong> 
                                { result?.first_obj.risk === 1 ? <span>Low</span> : null }
                                { result?.first_obj.risk === 2 ? <span>Medium</span> : null }
                                { result?.first_obj.risk === 3 ? <span>High</span> : null }
                                { result?.first_obj.risk === 4 ? <span>Critical</span> : null }
                            <br/>
                            <strong>Playbook: </strong> {(result?.first_obj.playbook_name)}
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const handleReport=()=>{
        // setReport(true);
        history.push(`${"/tickets/report/"+result?._id}`,result?._id);
    }

    const handleCloseIssue=()=>{
        history.push("/tickets/closeissue/"+result?._id, result?._id);
    }

    const handleEdit=()=>{
        history.push("/playbooks/edit/"+result?.playbook_id);
    }

    const handleAssignTicket=()=>{
        history.push("/tickets/assign/"+result?._id,{
            ticket_id:result?._id, 
            assignee_id:result?.assignee_id,
            assignee_name:result?.assignee_name
        });
    }

    const handleCloseTicket=()=>{
        history.push("/tickets/close/"+result?._id, result?._id);
    }

    const handleComment=()=>{
        history.push("/tickets/comment/"+result?._id, result?._id);
    }

    return (
        <>
            <Switch>
                <Route path='/tickets/report/:id' render={()=><ReportIssue updateTicketDetails={Listing}/>} />        
                <Route path="/tickets/closeissue/:id" render={()=><CloseIssuePopup updateTicketDetails={Listing}/>}/>
                <Route path="/tickets/assign/:id" render={()=><AssignedUser updateTicketDetails={Listing}/>}/>
                <Route path="/tickets/close/:id" render={()=><CloseTicket updateTicketDetails={Listing}/>}/>
                <Route path="/tickets/comment/:id" render={()=><Comment updateTicketDetails={Listing}/>}/>
            </Switch>
            
            <div className="ticket_severity">
                <div>
                    { result?.first_obj.risk === 1 ? <span className="low"></span> : null }
                    { result?.first_obj.risk === 2 ? <span className="medium"></span> : null }
                    { result?.first_obj.risk === 3 ? <span className="high"></span> : null }
                    { result?.first_obj.risk === 4 ? <span className="critical"></span> : null }
                </div>
                {/* <div className={getRisk(result?.first_obj.risk)?.toLowerCase()}></div> */}
            </div>

            <div className="ticket_title fl">
                <div className="ticket_heading">TICKET {result?.rule} #{result?._id}</div>
                <div className="float_left width100per font14 ellipsis_common"  title={result?.assignee_name}><strong>ASSIGNEE: </strong>{result?.assignee_name} </div>
            </div>
            <div className="ticket_title_mid fl">
                <div className="float_left width100per font14 ellipsis_common" title={result?.matching_rule_name}><strong>RULE: </strong>{result?.matching_rule_name} </div>
                <div className="float_left width100per font14 ellipsis_common" title={result?.playbook_name}><strong>PLAYBOOK: </strong>{result?.playbook_name} </div>
            </div>

            <div className="ticket_title_right fl">
             {/*    <button type="submit" disabled className="edit_playbook_button" style={{cursor: 'not-allowed', color:'#444444', opacity:'0.4',}} 
                //  onClick={handleEdit}
                >Edit Playbook</button>  */}
            </div>

            <div className="tickets_container">
                <div className="issue_ticket_container">
                    <div className="issue_action_box">
                        <h5>INCIDENT ACTIONS</h5>
                        <button disabled={result?.issue_info?.status?.toLowerCase()!=="open" || result?.ticket_status?.toLowerCase()==="closed"} type="submit" className="issue_action_button false_positive_icon" onClick={handleReport}>False positive</button>
                        <button disabled={result?.issue_info?.status?.toLowerCase()!=="open" || result?.ticket_status?.toLowerCase()==="closed"} type="submit" className="issue_action_button close_issue_icon" onClick={handleCloseIssue}>Close Incident</button>
                    </div>
                    <div className="issue_ticket_box">
                        <h5>TICKET ACTIONS</h5>
                        <button disabled={result?.ticket_status?.toLowerCase()==="closed"} type="submit" className="issue_ticket_button assign_icon" onClick={handleAssignTicket}>Assign</button>
                        <button disabled={result?.ticket_status?.toLowerCase()==="closed"} type="submit" className="issue_ticket_button close_issue_icon" onClick={handleCloseTicket}>Close Ticket</button>
                        <button disabled={result?.ticket_status?.toLowerCase()==="closed"} type="submit" className="issue_ticket_button comment_icon" onClick={handleComment}>Comment</button>
                    </div>
                </div>
                <div className="timeline">
                    <div className="timeline_vertical_bar"></div>
                    <Timeline />
                </div>
            </div>

            <div className="tickets_action_container">

                {/* <div className="comment_card">
                    <>
                        <div className="title font_semibold">Comments 
                            <span className="float_right">
                                <button disabled={result?.ticket_status.toLowerCase()==="closed"} type="submit" className="issue_ticket_button comment_icon" onClick={handleComment}>Comment</button>
                            </span>
                        </div>
                        <div className="comment_outer_box">
                            {narrations && narrations.length > 0 && narrations.reverse().map((item: Narrations) =>
                                { return item.comment? 
                                    ( 
                                        <div>
                                            <div className="comment_inner_box">
                                                    <div className="comment_header_box">
                                                        <div className="comment_user">
                                                            <span>{item.user_name}</span>
                                                        </div>
                                                        <div className="comment_timestamp">
                                                            <span>{moment(item.datetime).format('MMM DD YYYY - hh:mm A')}</span>
                                                        </div>
                                                    </div>
                                                    <div className="comment_box">
                                                        <span>{item.comment}</span>
                                                    </div>
                                                </div>
                                            <div className="clrBoth"></div>
                                        </div>
                                    )
                                    : null
                                } 
                            )}

                            {narrations && narrations.length === 0 &&
                                <div className="align_center margin10">No comment(s) found</div>
                            } 
                        </div>

                        
                    </>
                    <div className="clrBoth"></div>

                    
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <input
                            name="description"
                            type="text"
                            className="comment_type float_left"
                            placeholder="Type comment here"
                            ref={register({
                                required: true
                            })}
                        />
                        {errors.description && errors.description.type === 'required' && <div className="error">Please enter comment.</div>}
                        <button className="comment_button"></button>
                    </form>
                   
                </div>
                <div className="clrBoth"></div> */}
                
                <div className="card float_left ">
                    <div className="title">Identity Information</div>
                    {result &&
                        <ul className="card_listing">
                            <li><strong>Type: </strong> {result?.source_info.type}</li>
                            <li><strong>User: </strong> {result?.source_info.name}</li>
                            <li><strong>IP: </strong> {result?.source_info.ip}
                                {result?.destination_info.port ?
                                <span>:</span>
                                : null
                                }
                                {result?.source_info.port}</li>
                            <li>&nbsp;</li>
                        </ul>
                    } 

                </div>

                <div className="card float_right ">
                    <div className="title">Assets Information</div>
                    {result &&
                        <ul className="card_listing">
                            <li><strong>Type: </strong> {result?.destination_info.type}</li>
                            <li><strong>Name: </strong> {result?.destination_info.name}</li>
                            <li><strong>IP: </strong> {result?.destination_info.ip}
                                {result?.destination_info.port ?
                                <span>:</span>
                                : null
                                }
                                {result?.destination_info.port}
                            </li>
                            <li><strong>Protocol: </strong> {result?.issue_info?.protocol}</li>
                        </ul>
                    } 
                </div>
               
                <div className="clrBoth"></div>
                <div className="card width100per margintop20">
                    <div className="title font_semibold">Incident Info</div>
                        <ul className="card_listing">
                            <li><strong>Updated at: </strong>{FormatDate(result?.issue_info?.date)}</li>
                            <span className="tkt_plain_button" 
                            onClick={(event) => { if (result?.issue_info?.issue_flows_count  && result?.issue_info?.issue_flows_count > 1) 
                            { handleFlowClick(event, { id: result?.issue_info?.issue_number.toString(), risk: getRisk(result?.first_obj?.risk)?.toLowerCase() })
                             } }}>
                                <strong>Number of flows: </strong> {convertToCommaValue(result?.issue_info?.issue_flows_count)}
                            </span>
                            <li><strong>Incident #: </strong>{result?.issue_info?.issue_number}</li>
                            <li><strong>Status: </strong> {result?.issue_info?.status}</li>
                            <li><strong>Matching Field: </strong> {result?.issue_info?.matching_field_name}</li>                           
                        </ul>
                    <div className="clrBoth"></div>
                </div>
 
                <div className="issue_details_card margintop20">
                    <div className="title font_semibold">Incident Details</div>
                    <div className=""></div>
                    {result?.issue_details ? 
                        <div className="issue_details_snippet">
                            <pre>{JSON.stringify(JSON.parse(result.issue_details),null,6)}</pre>
                        </div>
                        : null}
                </div>
            </div>
  
            {open ? <FlowTrend data={flowsTrendResponseData} chartDetails={flowsChartDetails} closeFlowPopup={() => setOpen(false)} showZoom={false} updatedExtremes={updatedExtremeRef} /> : ''}

            {/* <Popup overlayStyle={{zIndex:15001, background: 'rgba(227, 242, 253, .6)'}} 
                open={open} 
                closeOnDocumentClick={false}
                modal={true}
                closeOnEscape={false}
            >
                <div className={"modal"}>
                    <div className={"close"} onClick={handleClose}></div>
                    <div className={"header"}>Flows</div>
                    <div className={"content"}>
                        <div className="flowlist_title">Flow Id</div>
                        <div className="flowlist_time_title">Timestamp</div>
                        <div className="issue_list_area">
                            <div className="flowlist fl">
                                <ul>
                                    {issueFlows?.map((item: IssueFlows) => (
                                        <li key={item.flow_id}> <span title={item.flow_id.toString()}>{item.flow_id}</span></li>
                                    ))}
                                </ul> 
                            </div>
                            <div className="flowlist_time fl">
                                <ul>
                                    {issueFlows?.map((item: IssueFlows) => (
                                        <li key={item.created_date}> <span>{moment(item.created_date).format('MMM DD, YYYY, hh:mm:ss')}</span></li>
                                    ))}
                                </ul> 
                            </div>
                        </div>
                    </div>
                </div>
            </Popup> */}
        </>
    )
}

export default TicketDetails