import moment from 'moment';
import { AVAILABLE_PRESETS, PERIOD_OPTIONS, TIME_RANGE_OPERATORS, VALIDATIONS } from './Constants';
import { useTimeBasedSearchStorage } from './useTimeBasedSearch';
import { dateToEpoch, epochToDate, validate } from './utils';
import { getPresetTimeValue, isApplyBtnDisabled } from './TimeBasedutils';
import { resetDates } from './TimeBasedSearchReducer';


export const useTimeBasedSearchActions = () => {
    const storage = useTimeBasedSearchStorage();
    const togglePopup = (state, value) => {
        if (value) {
            const storageData = storage.fetchDataFromStore()
            if (storageData)
                return { ...storageData, showPopup: true }
        }
        return { ...state, showPopup: value };
    };

    const selectPeriod = (state, value) => {
        if (value !== PERIOD_OPTIONS.PRESETS.id) {
            return { ...state, ...resetDates, selectedPeriod: value, selectPeriodPreset: '', isApplyDisabled: false };
        } else { 
            return { ...state, ...resetDates, selectedPeriod: value, isApplyDisabled: false };
        }
    };

    const selectPeriodPreset = (state, value) => {
        const timeValue = getPresetTimeValue(value)
        return { ...state, selectedPreset: value, selectedPresetStartDate: timeValue[0], selectedPresetEndDate: timeValue[1] };
    };

    const applyFilters = (state, value, resolve) => {
        const newState = { ...state, showPopup: false };
        if (validate(newState)) {
            storage.loadDataToStore({ ...newState })
            resolve({ ...newState });
            return { ...newState };
        } else {
            return { ...state }
        }
    };

    const clearFilters = (state, value) => {
        storage.clearDataFromStore();
        return { ...value, showPopup: state.showPopup, selectedPeriod: PERIOD_OPTIONS.PRESETS.id };
    };

    const resetFilters = (state, value) => {
        return { ...value, showPopup: false };
    };

    const updateFromProps = (state, value) => {
        const tempVal = value[0];
        let newUpdateState = {};
        if (tempVal[1].indexOf('_H') > -1 && tempVal[1].indexOf(',') > -1) {
            newUpdateState = {
                selectedPeriod: PERIOD_OPTIONS.HISTOGRAM.id,
                selectedStartDateHistogram: (((tempVal[1].split(','))[0]).split('_'))[0],
                selectedEndDateHistogram: (((tempVal[1].split(','))[1]).split('_'))[0]
            }
        } else if (tempVal[2] === TIME_RANGE_OPERATORS.BETWEEN.id) {
            if (Object.keys(AVAILABLE_PRESETS).includes(tempVal[1])) {
                const storageData = storage.fetchDataFromStore();
                let timeValue = [];
                if (storageData && tempVal[1] === storageData.selectedPreset && storageData.selectedPresetStartDate && storageData.selectedPresetStartDate !== "" && storageData.selectedPresetEndDate && storageData.selectedPresetEndDate !== "") {
                    timeValue = [storageData.selectedPresetStartDate, storageData.selectedPresetEndDate]
                } else { 
                    timeValue = getPresetTimeValue(tempVal[1])
                }
                newUpdateState = {
                    selectedPeriod: PERIOD_OPTIONS.PRESETS.id,
                    selectedPreset: tempVal[1],
                    selectedPresetStartDate: timeValue[0],
                    selectedPresetEndDate: timeValue[1]
                }
            } else {
                newUpdateState = {
                    selectedPeriod: PERIOD_OPTIONS.CUSTOM_RANGE.id,
                    selectedStartDate: epochToDate((tempVal[1].split(','))[0]),
                    selectedEndDate: epochToDate((tempVal[1].split(','))[1]),
                    selectedTimeRange: TIME_RANGE_OPERATORS.BETWEEN.id
                }
            }
        } else if (tempVal[2] === TIME_RANGE_OPERATORS.AFTER.id) {
            newUpdateState = {
                selectedPeriod: PERIOD_OPTIONS.CUSTOM_RANGE.id,
                selectedStartDate: epochToDate((tempVal[1].split(','))[0]),
                selectedTimeRange: TIME_RANGE_OPERATORS.AFTER.id
            }
        } else if (tempVal[2] === TIME_RANGE_OPERATORS.EQUALS.id) {
        newUpdateState = {
            selectedPeriod: PERIOD_OPTIONS.CUSTOM_RANGE.id,
            selectedStartDate: epochToDate((tempVal[1].split(','))[0]),
            selectedEndDate:epochToDate((tempVal[1].split(','))[1]),
            selectedTimeRange: TIME_RANGE_OPERATORS.EQUALS.id
        }
        } else if (tempVal[2] === TIME_RANGE_OPERATORS.BETWEEN.id) {
            newUpdateState = {
                selectedPeriod: PERIOD_OPTIONS.CUSTOM_RANGE.id,
                selectedStartDate: epochToDate((tempVal[1].split(','))[0]),
                selectedEndDate: epochToDate((tempVal[1].split(','))[1]),
                selectedTimeRange: TIME_RANGE_OPERATORS.BETWEEN.id
            }
        }

        const newState = { ...state, ...newUpdateState, isFilterApplied: true }
        storage.loadDataToStore(newState)
        return { ...newState }
    }

    const selectTimeRange = (state, value) => {
        return { ...state, selectedTimeRange: value };
    };

    const selectDate = (state, value, field, operator) => {
        let isApplyDisabled = false;
        if (operator === TIME_RANGE_OPERATORS.AFTER.id) {
            isApplyDisabled = isApplyBtnDisabled(value, moment());
        } else { 
            isApplyDisabled = isApplyBtnDisabled(value, moment(), 60);
        }

        return { ...state, [field]: value, isApplyDisabled, applyBtnValidMsg: isApplyDisabled ? VALIDATIONS.DAYS_60 : state.applyBtnValidMsg };
    };

    const selectDateRange = (state, value) => {
        const isApplyDisabled = isApplyBtnDisabled(value[0], value[1], null)
        return { ...state, selectedStartDate: value[0], selectedEndDate: value[1], isApplyDisabled: isApplyDisabled, applyBtnValidMsg: isApplyDisabled ? VALIDATIONS.DAYS_15 : state.applyBtnValidMsg  }
    }

    const selectDateRangeHistogram = (state, value) => {
        const isApplyDisabled = isApplyBtnDisabled(value[0], value[1], null)
        return { ...state, selectedStartDateHistogram: value[0], selectedEndDateHistogram: value[1], isApplyDisabled: isApplyDisabled, applyBtnValidMsg: isApplyDisabled ? VALIDATIONS.DAYS_15 : state.applyBtnValidMsg  }
    }

    const getTimeBasedSearchAppliedFilterView = () => { 
        return `${getDateSummary()}`
    }

    const getTimeBasedSearchAppliedFilterViewValue = () => { 
        // moment(data.selectedPresetStartDate * 1000).format("MM/DD/YYYY h:mm A")
        let res = {};
        const data = storage.fetchDataFromStore();
        if (data && data?.selectedPeriod === PERIOD_OPTIONS?.PRESETS?.id) {
            if ([AVAILABLE_PRESETS.LS_60_MINS.id, AVAILABLE_PRESETS.LS_4_HRS.id, AVAILABLE_PRESETS.LS_24_HRS.id].indexOf(data.selectedPreset) > -1) {
                res = {operator: '=', val1: moment(data.selectedPresetStartDate * 1000).format("MMM DD YYYY,h:mm A"), val2: moment(data.selectedPresetEndDate * 1000).format("MMM DD YYYY,h:mm A")};
            } else {
                res = {operator: '=', val1: moment(data.selectedPresetStartDate * 1000).format("MMM DD YYYY"), val2: moment(data.selectedPresetEndDate * 1000).format("MMM DD YYYY")};
            }
        } else if (data && data?.selectedPeriod === PERIOD_OPTIONS?.HISTOGRAM?.id) { 
            res = { operator: 'between', val1: moment(data.selectedStartDateHistogram * 1000).format("MMM DD YYYY"), val2: moment(data.selectedEndDateHistogram * 1000).format("MMM DD YYYY")};
        } else {
            if (
                data &&
                    data?.selectedTimeRange === TIME_RANGE_OPERATORS.AFTER.id
            ) {
                res = { operator: '>', val1: moment(data.selectedStartDate).format("MMM DD YYYY,h:mm A"), val2: moment(data.selectedEndDate).format("MMM DD YYYY,h:mm A")};
            } else if (
                data?.selectedTimeRange === TIME_RANGE_OPERATORS.EQUALS.id
            ) {
                res = {operator: '=', val1: moment(data.selectedStartDate).format("MMM DD YYYY,h:mm A"), val2: moment(data.selectedEndDate).format("MMM DD YYYY,h:mm A")};
            } else if (
                data &&
                    data?.selectedTimeRange === TIME_RANGE_OPERATORS.BETWEEN.id
            ) {
                res = { operator: 'between', val1: moment(data.selectedStartDate).format("MMM DD YYYY,h:mm A"), val2: moment(data.selectedEndDate).format("MMM DD YYYY,h:mm A")};
            }
        }
        return res;
    }

    const getPresetDateSummary = (data) => {
        switch (data.selectedPreset) {
            case AVAILABLE_PRESETS.LS_60_MINS.id:
                return `${moment()
                    .subtract(1, 'hour')
                    .format('lll').replace(/,/g, '')} - ${moment().format('lll').replace(/,/g, '')}`;
            case AVAILABLE_PRESETS.LS_4_HRS.id:
                return `${moment()
                    .subtract(4, 'hours')
                    .format('lll').replace(/,/g, '')} - ${moment().format('lll').replace(/,/g, '')}`;
            case AVAILABLE_PRESETS.LS_24_HRS.id:
                return `${moment()
                    .subtract(24, 'hours')
                    .format('lll').replace(/,/g, '')} - ${moment().format('lll').replace(/,/g, '')}`;
            case AVAILABLE_PRESETS.LS_3_DAYS.id:
                return `${moment()
                    .subtract(3, 'days')
                    .format('ll').replace(/,/g, '')} - ${moment().format('ll').replace(/,/g, '')}`;
            case AVAILABLE_PRESETS.LS_7_DAYS.id:
                return `${moment()
                    .subtract(7, 'days')
                    .format('ll').replace(/,/g, '')} - ${moment().format('ll').replace(/,/g, '')}`;
            case AVAILABLE_PRESETS.LS_14_DAYS.id:
                return `${moment()
                    .subtract(14, 'days')
                    .format('ll').replace(/,/g, '')} - ${moment().format('ll').replace(/,/g, '')}`;
            default:
                return `No filter applied`;
        }
    };

    const getDateSummary = () => {
        let res = `No filter applied`;
        const data = storage.fetchDataFromStore();
        if (data && data?.selectedPeriod === PERIOD_OPTIONS?.PRESETS?.id) {
            res = getPresetDateSummary(data);
        } else if (data && data?.selectedPeriod === PERIOD_OPTIONS?.HISTOGRAM?.id) { 
            res = `${moment(data?.selectedStartDateHistogram * 1000).format(
                'll'
            )} - ${moment(data?.selectedEndDateHistogram * 1000).format('ll')}`;
        } else {
            if (
                data &&
                    data?.selectedTimeRange === TIME_RANGE_OPERATORS.AFTER.id
            ) {
                res = moment(data?.selectedStartDate).format('lll');
            } else if (
                data?.selectedTimeRange === TIME_RANGE_OPERATORS.EQUALS.id
            ) {
                res = moment(data?.selectedStartDate).format('ll');
            } else if (
                data &&
                data?.selectedTimeRange === TIME_RANGE_OPERATORS.BETWEEN.id
            ) {
                res = `${moment(data?.selectedStartDate).format(
                    'lll'
                )} - ${moment(data?.selectedEndDate).format('lll')}`;
            }
        }
        return res;
    };

    const getPresetActualTimeValue = (val) => {
        const data = storage.fetchDataFromStore();

        if (data && Object.keys(AVAILABLE_PRESETS).includes(val) && data.selectedPresetStartDate !== "" && data.selectedPresetEndDate !== "") { 
            return [data.selectedPresetStartDate, data.selectedPresetEndDate]
        }
        switch (val) {
            case AVAILABLE_PRESETS.LS_60_MINS.id:
                return [dateToEpoch(moment()
                    .subtract(60, 'minutes').toLocaleString()), dateToEpoch(moment().toLocaleString())];
            case AVAILABLE_PRESETS.LS_4_HRS.id:
                return [dateToEpoch(moment()
                    .subtract(4, 'hours').toLocaleString()), dateToEpoch(moment().toLocaleString())];
            case AVAILABLE_PRESETS.LS_24_HRS.id:
                return [dateToEpoch(moment()
                    .subtract(24, 'hours').toLocaleString()), dateToEpoch(moment().toLocaleString())];
            case AVAILABLE_PRESETS.LS_3_DAYS.id:
                return [dateToEpoch(moment()
                    .subtract(3, 'days').toLocaleString()), dateToEpoch(moment().toLocaleString())];
            case AVAILABLE_PRESETS.LS_7_DAYS.id:
                return [dateToEpoch(moment()
                    .subtract(7, 'days').toLocaleString()), dateToEpoch(moment().toLocaleString())];
            case AVAILABLE_PRESETS.LS_14_DAYS.id:
                return [dateToEpoch(moment()
                    .subtract(14, 'days').toLocaleString()), dateToEpoch(moment().toLocaleString())];
        }
        return val;
    }

    const appliedFilterTimestamp = () => { 
        let res = [];
        const data = storage.fetchDataFromStore();

        if (data) { 
            if (data.selectedPeriod === PERIOD_OPTIONS.PRESETS.id) {
                return [Number(data.selectedPresetStartDate) * 1000, Number(data.selectedPresetEndDate) * 1000]
            } else if (data.selectedPeriod === PERIOD_OPTIONS.HISTOGRAM.id) {
                return [data.selectedStartDateHistogram * 1000, data.selectedEndDateHistogram * 1000]
            } else { 
                return [dateToEpoch(data.selectedStartDate) * 1000, dateToEpoch(data.selectedEndDate) * 1000]
            }
        }

        return res;
    }

    const isPresetPeriodOptionSelected = () => { 
        let res = false;
        const data = storage.fetchDataFromStore();
        if (!data)
            return res;


        if (data && data.selectedPeriod === PERIOD_OPTIONS.PRESETS.id) {
            res = true;
        }

        return res;
    }
    
    return {
        selectDate, selectDateRange, selectPeriod, selectPeriodPreset,
        selectTimeRange, updateFromProps, resetFilters, togglePopup, clearFilters, applyFilters, selectDateRangeHistogram, getTimeBasedSearchAppliedFilterView, getPresetActualTimeValue, appliedFilterTimestamp, getTimeBasedSearchAppliedFilterViewValue, isPresetPeriodOptionSelected
    }
}